import { useQuery } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';

import { FetchUserReasonTagsResponse } from '@stashinvest/shared-types/grpc/fraud_service_v1';
import { config } from 'src/config/config';

export const getUserFraudTags = async (
	userId: string,
	accessToken: string
): Promise<FetchUserReasonTagsResponse> => {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/${userId}/fraudTags`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		}
	);

	const responseBody = await response.json();

	if (!response.ok) {
		// TODO: once Fraud GRPC will be able to return more specific code for error - switch between them in order to provide a more accurate error
		throw new Error('Something went wrong while fetching fraud tags for the user');
	}

	return responseBody;
};

export const useUserFraudTags = (userId: string) => {
	const { oktaAuth } = useOktaAuth();

	return useQuery<FetchUserReasonTagsResponse>(['userReasonTags', userId], () =>
		getUserFraudTags(userId, oktaAuth.getAccessToken())
	);
};
