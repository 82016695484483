import { FC, ReactNode, useContext } from 'react';
import { Flex, Text, Box } from '@stashinvest/ui/dist/es/2';
import {
	CtaDirection,
	Modal,
	ModalActions,
	ModalProps,
	VisibilityContext,
} from '@stashinvest/shared-ui';

interface ActionModalHeaderProps {
	title: string;
	subtitle?: string;
}

export const ActionModalHeader: FC<ActionModalHeaderProps> = ({ title, subtitle }) => {
	return (
		<>
			<Flex width="100%" pt="3px">
				<Text.Title20 fontWeight="bold">{title}</Text.Title20>
			</Flex>
			{subtitle ? (
				<Text.Body16 mt="0.3rem" color="textTertiary">
					{subtitle}
				</Text.Body16>
			) : null}
		</>
	);
};

export type ActionModalProps = ActionModalWithChildrenProps | ActionModalWithBodyProps;

interface BaseActionModalProps extends ActionModalHeaderProps {
	handleConfirm: VoidFunction;
	handleCancel?: VoidFunction;
	onClose?: ModalProps['onClose'];
	width?: string;
	loading?: boolean;
	confirmBtnText?: string;
	cancelBtnText?: string;
}

interface ActionModalWithChildrenProps extends BaseActionModalProps {
	children: ReactNode;
	body?: never;
}

interface ActionModalWithBodyProps extends BaseActionModalProps {
	children?: never;
	body: string;
}

export const ActionModal: FC<ActionModalProps> = ({
	title,
	subtitle,
	children,
	body,
	handleConfirm,
	handleCancel,
	onClose,
	loading = false,
	width = '650px',
	confirmBtnText = 'Confirm',
	cancelBtnText = 'Cancel',
}) => {
	const { setOpen } = useContext(VisibilityContext);
	const handleCancelAndClose = handleCancel
		? () => {
				handleCancel();
				setOpen(false);
		  }
		: undefined;
	return (
		<Modal height="auto" width={width} onClose={onClose}>
			<Box px="1rem">
				<ActionModalHeader title={title} subtitle={subtitle} />
				<Flex py="2rem" flexDirection="column">
					{children ? children : <Text.Label14>{body}</Text.Label14>}
				</Flex>
				<ModalActions
					confirmText={confirmBtnText}
					cancelText={cancelBtnText}
					confirm={handleConfirm}
					cancel={handleCancelAndClose}
					ctaDirection={CtaDirection.ROW}
					loading={loading}
				/>
			</Box>
		</Modal>
	);
};
