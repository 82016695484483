import React, { FC } from 'react';

import { ModalComponent } from '../Modal';
import { DocumentViewerBody, DocumentViewerBodyProps } from './DocumentViewerBody';

/**
 * Fixed Modal, designed to take over a whole page with nowhere else to navigate. To be used by the Identity Document Viewer in UserHub
 * @param {Pick<DocumentViewerBodyProps & {children?: React.ReactNode | undefined}, "title" | "rows" | "items" | "emptyText" | "tracking" | "canDownloadDocs" | "children">} docViewerBodyProps
 * @returns {JSX.Element}
 * @constructor
 */

export const DocumentViewerFixedModal: FC<
	React.PropsWithChildren<DocumentViewerBodyProps>
> = ({ ...docViewerBodyProps }) => (
	<ModalComponent width="800px" height="70vh" showCloseButton={false}>
		<DocumentViewerBody {...docViewerBodyProps} />
	</ModalComponent>
);
