import { FC } from 'react';
import { Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { TrackedLink, TrackedLinkColors } from '@stashinvest/shared-ui';

import { ActiveAdminLink } from 'src/components/ActiveAdminLink';

export const UserSearchFooter: FC = () => (
	<Flex
		mx="40px"
		justifyContent="center"
		flexDirection={{ mobile: 'column', tablet: 'row' }}
	>
		<Text.Label16 as="span" mx="40px" my="10px" align="center">
			<TrackedLink
				to="/"
				color={TrackedLinkColors.textPrimary}
				tracking={{
					page: 'search-results',
					component: 'advanced-search',
					label: 'link',
				}}
			>
				Advanced Search
			</TrackedLink>
		</Text.Label16>
		<Text.Label16 as="span" mx="40px" my="10px" align="center">
			<ActiveAdminLink
				path="/ssn_check"
				color={TrackedLinkColors.textPrimary}
				tracking={{
					page: 'search-results',
					component: 'ssn-checker',
				}}
			>
				SSN Checker
			</ActiveAdminLink>
		</Text.Label16>
		<Text.Label16 as="span" mx="40px" my="10px" align="center">
			<ActiveAdminLink
				path="/admin"
				color={TrackedLinkColors.textPrimary}
				tracking={{ page: 'search-results', component: 'aa-search' }}
			>
				Search in Active Admin
			</ActiveAdminLink>
		</Text.Label16>
	</Flex>
);
