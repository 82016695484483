import { FC } from 'react';
import { Box, Tag } from '@stashinvest/ui';
import { CopyBox } from '@stashinvest/shared-ui';
import { FalseyProp } from 'src/components/FalseyProp';

interface AccountCardCopyBoxProps {
	property: string;
	value?: string | null;
}

export const AccountCardCopyBox: FC<AccountCardCopyBoxProps> = ({ property, value }) => {
	const propBody = value ? <CopyBox value={value} /> : <Tag variant="warning">Empty</Tag>;

	return (
		<Box mt="-2px" pb="5px">
			<FalseyProp
				property={property}
				value={
					<Box pl="10px" mt="2px">
						{propBody}
					</Box>
				}
			/>
		</Box>
	);
};
