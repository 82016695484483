import styled from 'styled-components';

export const Table = styled.table`
	width: 100%;
	text-align: left;
`;

interface TableRowProps {
	borderColor?: string;
}

export const TableRow = styled.tr<TableRowProps>`
	width: 100%;
	border: 0;
	border-bottom: 1px solid
		${({ theme, borderColor = 'bgTargetSecondary' }) => theme.colors[borderColor]};
`;

export interface TableHeaderProps {
	width?: string;
	headerAlignment?: 'left' | 'right';
}

export const TableHeader = styled.th<TableHeaderProps>`
	width: ${({ width }) => width || 'auto'};
	border: 0;
	padding: 10px 15px 10px 0;
	text-align: ${({ headerAlignment }) => headerAlignment || 'left'};
`;

export const TableCell = styled.td`
	border: 0;
	padding: 10px 15px 10px 0;
	border-collapse: collapse;
`;
