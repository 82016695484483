import React, { memo, FC } from 'react';
import { Helmet } from 'react-helmet';

import { extractUUIDs } from '../../utils';
import { useReferral, useTracking, useOktaProfile } from '../../hooks';
import { TrackingProviderProps, TrackingScriptProps } from './TrackingProvider.types';

const TrackingScript: FC<React.PropsWithChildren<TrackingScriptProps>> = memo(
	({ host, tag, groups, zendeskTicket, zendeskUser }) => {
		const redactedPath = extractUUIDs(window.location.pathname);
		const path = redactedPath.amendedString;
		const page = `${host}${path}`;
		const uuids = redactedPath.uuids || '';

		return (
			<Helmet>
				<script async src={`https://www.googletagmanager.com/gtag/js?id=${tag}`}></script>
				<script>
					{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag("js", new Date());
        gtag("config", "${tag}", {
          "page_title": "User Hub",
          "page_location": "${page}",
          "page_path": "${page}",

          // dimension1 = "groups"
          "dimension1": "${groups}",

          // dimension2 = "uuids"
          "dimension2": "${uuids}",

          // dimension5 = "zendeskTicket"
          "dimension5": "${zendeskTicket}",

          // dimension6 = "zendeskUser"
          "dimension6": "${zendeskUser}",
        });
        `}
				</script>
			</Helmet>
		);
	}
);

export const TrackingProvider: FC<React.PropsWithChildren<TrackingProviderProps>> = ({
	googleAnalyticsTag,
	host,
	children,
}) => {
	const { oktaProfile } = useOktaProfile();
	const { zendeskTicket, zendeskUser } = useReferral();
	const hasGroups = oktaProfile && oktaProfile.groups && oktaProfile.groups.length;
	const groups = hasGroups ? oktaProfile.groups?.join(',') : undefined;
	const tag = googleAnalyticsTag;
	useTracking(host);

	return (
		<>
			{groups && zendeskTicket !== null && zendeskUser !== null ? (
				<TrackingScript
					tag={tag}
					groups={groups}
					zendeskTicket={zendeskTicket}
					zendeskUser={zendeskUser}
					host={host}
				/>
			) : null}
			{children}
		</>
	);
};
