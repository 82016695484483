import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { TrackingHosts } from '../../providers';
import { extractUUIDs } from '../../utils';

export const useTracking = (host: TrackingHosts) => {
	const [prevPage, setPrevPage] = useState('');
	const location = useLocation();

	useEffect(() => {
		const redactedPath = extractUUIDs(location.pathname);
		const path = redactedPath.amendedString;
		const page = `${host}${path}`;
		const uuids = redactedPath.uuids || '';
		const gaLoaded = window && window.gtag;

		// if the new URL is different from the previously
		// visited URL, track a page event
		if (prevPage && prevPage !== location.pathname && gaLoaded) {
			window.gtag('event', 'page_view', {
				page_title: 'User Hub',
				page_location: page,
				page_path: page,

				// dimension2 = "uuids"
				dimension2: uuids,
			});
		}

		setPrevPage(location.pathname);

		// eslint-disable-next-line
	}, [prevPage, location]);
};
