import styled from 'styled-components';
import { Box } from '@stashinvest/ui';

export const AnimatedContent = styled(Box)`
	transition-property: left;
	transition-duration: 0.3s;
	transition-timing-function: ease-in;
`;

export const AnimatedItem = styled(Box)`
	opacity: ${({ active }) => (active ? '1' : '0')};
	transition-property: opacity;
	transition-duration: 0.2s;
	transition-timing-function: ease-in;
`;
