import React, { FC, useContext } from 'react';
import Select, { components, OptionProps, Props } from 'react-select';
import { ThemeContext } from 'styled-components';
import { Flex, Box, CheckIcon } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { useField } from 'formik';
import { ComboboxOption, getStyles, getTheme, menuTextColor } from './comboboxHelpers';

export interface ComboboxProps {
	label: string;
	name: string;
	placeholder?: string;
	options?: Array<ComboboxOption>;
	selectProps?: Props<ComboboxOption>;
}

const OptionComponent = (props: OptionProps<ComboboxOption>) => {
	return (
		<Flex alignItems="center">
			<components.Option {...props} />
			{props.isSelected ? <CheckIcon color={menuTextColor} /> : null}
		</Flex>
	);
};

const defaultOption = { value: '', label: 'Select', isDisabled: true };

export const FormikCombobox: FC<ComboboxProps> = ({
	label,
	name,
	options,
	placeholder,
	selectProps = {},
}) => {
	const stashTheme = useContext(ThemeContext);
	const styles = getStyles(stashTheme);
	const theme = getTheme(stashTheme);

	const [field, meta, helpers] = useField(name);
	const inputOptions = [defaultOption, ...(options ? options : [])];
	return (
		<Box my="xs" opacity={selectProps.isDisabled ? 0.5 : 1}>
			<Text.Label14
				as="label"
				mb="s8"
				fontWeight="medium"
				htmlFor={name}
				display="inline-block"
				color="textPrimary"
				data-testid="combobox-label"
			>
				{label}
			</Text.Label14>
			<Select
				isMulti={false}
				options={inputOptions}
				placeholder={placeholder ?? 'Select'}
				menuPortalTarget={document.body}
				styles={styles}
				theme={theme}
				inputId={name}
				{...field}
				components={{
					Option: OptionComponent,
					// disable separator bar
					IndicatorSeparator: () => null,
				}}
				onKeyDown={(event) => {
					// when used in a modal, prevents 'esc' key from exiting modal
					if (event.key === 'Escape') {
						event.stopPropagation();
					}
				}}
				onChange={(opt) => {
					helpers.setValue((opt as ComboboxOption)?.value);
				}}
				onBlur={() => {
					helpers.setTouched(true);
				}}
				value={inputOptions?.find((v) => v.value === field.value)}
				{...selectProps}
			/>
			{meta.error && meta.touched && (
				<Text.Label12 color="textNegativePrimary" mt="xs" data-testid="combobox-error">
					{meta.error}
				</Text.Label12>
			)}
		</Box>
	);
};
