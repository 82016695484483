import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { OktaProfileProps } from './useOktaProfile.types';

export const useOktaProfile = () => {
	const [oktaProfile, setOktaProfile] = useState<OktaProfileProps>({
		sub: '',
		email: '',
		email_verified: false,
		groups: [],
	});
	const { authState = {}, oktaAuth } = useOktaAuth() || {};

	useEffect(() => {
		if (authState?.isAuthenticated) {
			(async () => {
				try {
					const profile = await oktaAuth.getUser();
					setOktaProfile(profile);
				} catch {
					console.error('failed to get okta profile');
				}
			})();
		}

		// eslint-disable-next-line
	}, [authState?.isAuthenticated]);

	return { oktaProfile };
};
