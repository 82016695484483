export const FraudReasonMap = {
	'fraud_review:under_review_lock': 0,
	'fraud_review:under_review_pause': 1,
	'fraud_review:under_review_reject': 2,
	'fraud_closure:first_party_fraud': 3,
	'fraud_closure:id_theft': 4,
	'fraud_closure:id_theft_cc': 5,
	'fraud_closure:account_takeover': 6,
	'fraud_closure:account_takeover_cc': 7,
	'fraud_closure:account_handover': 8,
	'fraud_closure:other': 9,
	'fraud_restrict:first_party_fraud': 10,
	'fraud_restrict:id_theft': 11,
	'fraud_restrict:id_theft_cc': 12,
	'fraud_restrict:account_takeover': 13,
	'fraud_restrict:account_takeover_cc': 14,
	'fraud_restrict:account_handover': 15,
	'fraud_restrict:other': 16,
	'fcc_dndbw:suspicious_activity': 17,
	'fcc_dndbw:negative_media': 17,
	'fcc_dndbw:over_3_investigations_n_activity_continues': 17,
	'fcc_dndbw:information_sharing_dndbw': 17,
	'fcc_dndbw:ofac_match': 17,
	'fcc_dndbw:other': 17,
	'fcc_critical_alert:reject': 17,
	'fcc_restriction:ofac_match_tf': 18,
	'fcc_restriction:other': 18,
	'1st-Party ACH Fraud': 19,
	"3rd-Party ACH Fraud (Unauth'd Bank Link)": 20,
	'Suspected Account Takeover': 21,
	'Account Takeover': 22,
	'Suspected ID Theft': 23,
	'Confirmed ID Theft': 24,
	'Securities Free Riding': 25,
	'Terminated by Apex': 26,
	Other: 27,
	'Debit Card Funding Fraud': 28,
	'ACH Pull Fraud (Stash Debit)': 29,
	'Money Laundering': 30,
	Chargeback: 31,
	unknown_reason: 999,
} as const;

export type FraudReasonKey = keyof typeof FraudReasonMap;
