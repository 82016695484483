import React, { FC, useContext } from 'react';
import { ThemeProvider } from '@stashinvest/ui';

import { LocalStorageContext, StorageKeys } from '../LocalStorageProvider';

export const MODE_LIGHT = 'light';
export const MODE_DARK = 'dark';

export interface ThemeProps {
	[key: string]: any;
}

export type ThemeModes = 'light' | 'dark';

export const isDark = (mode: ThemeModes) => mode === MODE_DARK;

interface ThemeModeContextProps {
	mode: ThemeModes;
	setMode(mode: ThemeModes): void;
}

export const ThemeModeContext = React.createContext<ThemeModeContextProps>({
	mode: MODE_DARK,
	setMode: () => {},
});

interface ThemeModeProviderProps {
	themeOverride?: ThemeModes;
}

export const ThemeModeProvider: FC<React.PropsWithChildren<ThemeModeProviderProps>> = ({
	themeOverride,
	children,
}) => {
	const { storage, setLocalStorage } = useContext(LocalStorageContext);
	const mode = themeOverride || storage.themeMode || MODE_DARK;

	const setMode = (mode: ThemeModes) => {
		setLocalStorage({ key: StorageKeys.THEME_MODE, value: mode });
	};

	return (
		<ThemeModeContext.Provider
			value={{
				mode,
				setMode,
			}}
		>
			<ThemeProvider mode={mode} theme="rise">
				{children}
			</ThemeProvider>
		</ThemeModeContext.Provider>
	);
};
