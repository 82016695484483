import { NotFoundComponent } from '@stashinvest/shared-ui';

import { Navigate, RouteObject } from 'react-router-dom';
import { Home } from 'src/pages/Home';
import { UserSearch } from 'src/pages/UserSearch';
import { UserPageContainer, UserPage } from 'src/pages/User';
import { IdentityDocument } from 'src/pages/IdentityDocument';
import { Bank } from 'src/pages/Bank';
import { LoginCallback } from 'src/pages/LoginCallback';

import { Authenticated } from 'src/components/AuthenticatedComponent';
import { AppProviders } from 'src/components/AppProviders';
import { InvestorProfile } from 'src/pages/InvestorProfile';
import { AccountDetails } from 'src/pages/AccountDetails';

const addAuthenticated = (route: RouteObject): RouteObject => ({
	...route,
	element: <Authenticated> {route.element} </Authenticated>,
});

export const HOME_ROUTE = '/';
export const NO_RESULTS_ROUTE = '/no-results';
export const SEARCH = '/search';
export const NOT_FOUND = '/404';
export const USER_ID = '/id/:userId';
export const CALLBACK = '/auth/callback';
export const BANK_ROUTE = '/bank/:userId';
export const IDENTITY_DOCUMENT = '/id/:userId/identity-document/:documentRequestId';
export const ACCOUNT_DETAILS = '/id/:userId/account-details';
export const INVESTOR_PROFILE = '/id/:userId/investor-profile';

export const userRoutes: Array<RouteObject> = [
	{
		path: 'no-results',
		element: <Home />,
	},
	{
		path: 'bank/:userId',
		element: <Bank />,
	},
	{
		path: 'search',
		element: <UserSearch />,
	},
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '404',
		element: <NotFoundComponent />,
	},
	{
		path: 'id/:userId',
		element: (
			<UserPageContainer>
				<UserPage />
			</UserPageContainer>
		),
	},
	{
		path: ACCOUNT_DETAILS,
		element: (
			<UserPageContainer>
				<AccountDetails />
			</UserPageContainer>
		),
	},
	{
		path: INVESTOR_PROFILE,
		element: (
			<UserPageContainer>
				<InvestorProfile />
			</UserPageContainer>
		),
	},
	{
		path: IDENTITY_DOCUMENT,
		element: <IdentityDocument />,
	},
];
export const Routes: Array<RouteObject> = [
	{
		path: '/',
		element: <AppProviders />,
		children: [...userRoutes.map(addAuthenticated)],
	},
	{ path: 'auth/callback', element: <LoginCallback /> },
	{
		path: '*',
		element: <Navigate to="404" replace />,
	},
];
