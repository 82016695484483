// https://github.com/n8tb1t/use-scroll-position
import { useRef, useEffect } from 'react';

import { getScrollPositionArgs, useScrollPositionProps } from './useScrollPostion.types';

const isBrowser = typeof window !== `undefined`;
const zeroPosition = { x: 0, y: 0 };

const getScrollPosition = ({
	element,
	useWindow,
	boundingElement,
}: getScrollPositionArgs) => {
	if (!isBrowser) {
		return zeroPosition;
	}

	if (useWindow) {
		return { x: window.scrollX, y: window.scrollY };
	}

	const targetPosition = (element?.current ?? document.body)?.getBoundingClientRect();
	const containerPosition = boundingElement?.current?.getBoundingClientRect();

	if (!targetPosition) {
		return zeroPosition;
	}

	return containerPosition
		? {
				x: (containerPosition.x || 0) - (targetPosition.x || 0),
				y: (containerPosition.y || 0) - (targetPosition.y || 0),
		  }
		: { x: targetPosition.left, y: targetPosition.top };
};

export const useScrollPosition = ({
	effect,
	deps,
	element,
	useWindow,
	throttleInterval,
	boundingElement,
}: useScrollPositionProps) => {
	const position = useRef(getScrollPosition({ useWindow, boundingElement }));
	let throttleTimeout: number | null = null;

	const callback = () => {
		const currPos = getScrollPosition({ element, useWindow, boundingElement });
		effect({ prevPos: position.current, currPos });
		position.current = currPos;
		throttleTimeout = null;
	};

	useEffect(() => {
		if (!isBrowser) {
			return undefined;
		}

		const handleScroll = () => {
			if (throttleInterval && throttleTimeout === null) {
				throttleTimeout = window.setTimeout(callback, throttleInterval);
			} else {
				callback();
			}
		};

		if (boundingElement) {
			boundingElement.current?.addEventListener('scroll', handleScroll, {
				passive: true,
			});
		} else {
			window.addEventListener('scroll', handleScroll, { passive: true });
		}

		return () => {
			if (boundingElement) {
				boundingElement.current?.removeEventListener('scroll', handleScroll); // eslint-disable-line
			} else {
				window.removeEventListener('scroll', handleScroll);
			}

			if (throttleTimeout) {
				clearTimeout(throttleTimeout);
			}
		};
		// eslint-disable-next-line
	}, deps);
};
