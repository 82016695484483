const uuidRegex = /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g;

export const extractUUIDs = (value: string) => {
	const amendedString = value.replace(uuidRegex, 'uuid');
	const uuids = value.match(uuidRegex)?.join(',');

	return {
		uuids,
		amendedString,
	};
};
