/* eslint-disable react-hooks/rules-of-hooks */
import { ReactNode } from 'react';
import { useUserSearch } from 'src/hooks/useSearch';
import {
	UserSearchParamsType,
	SearchResponseType,
} from '@stashinvest/shared-types/schemas/user';
import { LoadingErrorWrapper } from 'src/components/LoadingErrorWrapper';

interface SearchContainerProps {
	searchParam: UserSearchParamsType;
	perPage: number;
	children(props: {
		response: SearchResponseType;
		searchParam: UserSearchParamsType;
		perPage: number;
	}): ReactNode;
}

export const SearchContainer = ({
	searchParam,
	perPage = 20,
	children,
}: SearchContainerProps) => {
	const { isLoading: loading, data: data, error: error } = useUserSearch(searchParam);

	return (
		<LoadingErrorWrapper loading={loading} error={error as Error}>
			{children({
				response: data as SearchResponseType,
				searchParam,
				perPage,
			})}
		</LoadingErrorWrapper>
	);
};
