import React, { FC } from 'react';
import { PaginationNavLink, PaginationProps } from '../Pagination';

export const QuickPagination: FC<React.PropsWithChildren<PaginationProps>> = ({
	currentPage = 0,
	pageCount = 0,
	onPageClick,
}) => {
	const isFirstPage = currentPage === 1;
	const isLastPage = currentPage === pageCount;

	return (
		<>
			<PaginationNavLink
				type="prev"
				disabled={isFirstPage || pageCount < 1}
				onClick={() => onPageClick(currentPage - 1)}
			/>
			<PaginationNavLink
				type="next"
				disabled={isLastPage || pageCount < 1}
				onClick={() => onPageClick(currentPage + 1)}
			/>
		</>
	);
};
