import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const RightArrowIcon: FC<React.PropsWithChildren<IconProps>> = (props) => (
	<IconContainer {...props}>
		<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.25 10.75l-7-7L12 2l10 10-10 10-1.75-1.75 7-7H2v-2.5h15.25z"
				fill="currentColor"
			/>
		</svg>
	</IconContainer>
);
