import React, { FC } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

const FeatureFlagChildren: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
	<>{children}</>
);

// NOTE: Any flag you want to evaluate must be added to the flagsToRequest
// object, otherwise we won't fetch that flag from LaunchDarkly.
// This is to mitigate overfetching and to allow us to archive flags
// on LaunchDarkly.

export const createFeatureFlagProvider = (
	launchDarklyClientId: string,
	flagsToRequest: {
		[key: string]: boolean;
	}
) =>
	withLDProvider({
		clientSideID: launchDarklyClientId,
		flags: flagsToRequest,
		reactOptions: {
			useCamelCaseFlagKeys: false,
		},
		options: { disableSyncEventPost: false, streaming: false },
	})(FeatureFlagChildren);
