import { FC, useContext } from 'react';
import { LinkVariant, LinkVariants, ReferralContext } from '@stashinvest/shared-ui';
import type { LinkVariantProps, WrapperTrackingProps } from '@stashinvest/shared-ui';
import { formatReferralQuery } from 'src/utils';

export interface BankPortalLinkProps
	extends Omit<LinkVariantProps, 'variant' | 'tracking'> {
	legacy?: boolean;
	path: string;
	tracking: WrapperTrackingProps;
}

const BP_TRACKING_LABEL = 'bank-portal';
const LEGACY_BP_TRACKING_LABEL = 'debit-portal';

export const BankPortalLink: FC<React.PropsWithChildren<BankPortalLinkProps>> = ({
	legacy = false,
	path,
	tracking,
	children,
	...otherProps
}) => {
	const { zendeskTicket, zendeskUser } = useContext(ReferralContext);
	const variant = legacy ? LinkVariants.LEGACY_BANK_PORTAL : LinkVariants.BANK_PORTAL;
	const trackingLabel = legacy ? LEGACY_BP_TRACKING_LABEL : BP_TRACKING_LABEL;
	const queryString = formatReferralQuery({
		path,
		items: { zendeskTicket, zendeskUser, origin: 'user-hub' },
	});

	return (
		<LinkVariant
			{...otherProps}
			variant={variant}
			path={`${path}${queryString}`}
			tracking={{ ...tracking, label: trackingLabel }}
		>
			{children}
		</LinkVariant>
	);
};
