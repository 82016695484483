import { useContext } from 'react';
import { TrackedLinkVariants } from '@stashinvest/shared-ui';
import { ActiveAdminLink } from 'src/components/ActiveAdminLink';
import { UserDetailsContext } from 'src/providers/UserDetailsProvider';

export const UserSearchLink = () => {
	const { data } = useContext(UserDetailsContext);
	const phoneNumber = data?.info.phoneNumber;

	return phoneNumber ? (
		<ActiveAdminLink
			type={TrackedLinkVariants.BUTTON}
			path={`admin/users?utf8=✓&q%5Buser_profile_formatted_phone_number_eq%5D=${phoneNumber}`}
			tracking={{
				page: 'user',
				component: 'lock-items',
			}}
		>
			Search for user in AA
		</ActiveAdminLink>
	) : null;
};
