import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const RobotIcon: FC<React.PropsWithChildren<IconProps>> = (props) => (
	<IconContainer {...props}>
		<svg width="100%" height="100%" viewBox="0 0 21 23" fill="none">
			<path
				d="M12.158 1.643c0 .907-.19 4.38-1.105 4.38-.916 0-2.21 1.096-2.21-4.38A1.65 1.65 0 0110.5 0a1.65 1.65 0 011.658 1.643z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.526 3.286C2.474 3.286 0 5.737 0 8.762v8.762C0 20.548 2.474 23 5.526 23h9.948C18.526 23 21 20.548 21 17.524V8.762c0-3.025-2.474-5.476-5.526-5.476H5.526zm1.658 8.762a1.65 1.65 0 001.658-1.643 1.65 1.65 0 00-1.658-1.643 1.65 1.65 0 00-1.658 1.643 1.65 1.65 0 001.658 1.643zm8.29-1.643a1.65 1.65 0 01-1.658 1.643 1.65 1.65 0 01-1.658-1.643 1.65 1.65 0 011.658-1.643 1.65 1.65 0 011.658 1.643zm-1.658 6.571H7.184c-.915 0-1.658.188-1.658 1.095 0 .908 1.295 1.096 2.21 1.096h5.527c.916 0 2.21-.188 2.21-1.096 0-.907-.742-1.095-1.657-1.095z"
				fill="currentColor"
			/>
		</svg>
	</IconContainer>
);
