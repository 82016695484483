import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	PatchUserProfilePayload,
	PatchUserProfileResponse,
	UserProfile,
} from '@stashinvest/shared-types/userProfile';
import { isErrorResponse } from '@stashinvest/shared-types/errors';
import { useOktaAuth } from '@okta/okta-react';

import { patchUserProfile } from 'src/api';
import { getUserProfileQueryKeys } from './useUserProfile';

export interface UseUpdateAcccountDetailsOptions {
	onSuccess?: (
		response: PatchUserProfileResponse,
		payload: PatchUserProfilePayload
	) => void;
	onError?: (error?: string) => void;
}

export const useUpdateUserProfile = (
	userId: string,
	options: UseUpdateAcccountDetailsOptions = {}
) => {
	const { oktaAuth } = useOktaAuth();
	const queryClient = useQueryClient();

	return useMutation<PatchUserProfileResponse, unknown, PatchUserProfilePayload>(
		(payload: PatchUserProfilePayload) =>
			patchUserProfile(userId, payload, oktaAuth.getAccessToken()),
		{
			onSuccess: (response, payload) => {
				if (!isErrorResponse(response)) {
					// the response contains all updated user profile fields except the contentOnly flag (see `BasicUserProfileResponseBody`),
					// the contentOnly flag cannot change as a side-effect of this update so we assume it is unchanged
					queryClient.setQueryData<UserProfile>(
						getUserProfileQueryKeys(userId),
						(previousUserProfile) => {
							if (previousUserProfile) {
								return {
									...response,
									info: {
										...response.info,
										contentOnly: previousUserProfile.info.contentOnly,
									},
								};
							}
						}
					);
				}
				options?.onSuccess?.(response, payload);
			},
			onError: () => options?.onError?.(),
		}
	).mutateAsync;
};
