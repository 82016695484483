import styled, { css } from 'styled-components';
import { CloseIcon } from '@stashinvest/ui/dist/es/2';
import React from 'react';

const messageMixin = () => css`
	left: 0;
	overflow: hidden;
	display: flex;
	align-items: center;
	white-space: nowrap;
	width: 100%;
	height: 1.3rem;
`;

const StyledErrorMessage = styled.div`
	display: flex;
	${messageMixin};
	color: ${({ theme }) => theme.colors.textNegativePrimary};
	font-size: ${({ theme }) => theme.fonts.body12.fontSize};
	> svg {
		flex: 0 0 0.85rem;
	}
`;

export const ErrorMessage: React.FC<React.PropsWithChildren<unknown>> = ({
	children,
	...rest
}) => (
	<StyledErrorMessage {...rest}>
		<CloseIcon color="currentColor" size="1em" />
		&nbsp;{children}
	</StyledErrorMessage>
);
