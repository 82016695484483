import { z } from 'zod';
import { StashGenericErrorResponse, PartialErrorsSchema } from '../errors';

export const SubscriptionState = z.enum([
	'UNKNOWN',
	'ACTIVE',
	'CLOSING',
	'CLOSED',
	'NO_SUBSCRIPTION',
]);
export type SubscriptionStateType = z.infer<typeof SubscriptionState>;

const Agreement = z.object({
	title: z.string(),
	description: z.string(),
	link: z.string(),
	version: z.string(),
});

const ESignSchema = z.object({
	id: z.number(),
	esignedAt: z.string().datetime(),
	createdAt: z.string().datetime(),
	updatedAt: z.string().datetime(),
	agreements: z.array(Agreement),
});
export type ESign = z.infer<typeof ESignSchema>;

export const ESignaturesSchema = z.object({
	userEsignatures: z.array(ESignSchema),
});

export const ESignaturesResponseSchema = ESignaturesSchema.or(StashGenericErrorResponse);
export type ESignaturesResponse = z.infer<typeof ESignaturesResponseSchema>;
export type ESignaturesType = z.infer<typeof ESignaturesSchema>;

const SubscriptionServiceV2BillingFrequencyObjectSchema = z.object({
	label: z.string().transform((l) => adjustFrequencyLabel(l)),
});

const SubscriptionServiceV2PriceValueSchema = z.number().transform((p) => formatPrice(p));

const SubscriptionServiceV2PriceObjectSchema = z.object({
	price: SubscriptionServiceV2PriceValueSchema,
	billingFrequency: SubscriptionServiceV2BillingFrequencyObjectSchema,
});

const SubscriptionServiceV2TierObjectSchema = z.object({
	tierName: z.string(),
});

const SubscriptionServiceV2PlanObjectSchema = z.object({
	tier: SubscriptionServiceV2TierObjectSchema,
});

export const SubscriptionServiceV2SubscriptionObjectSchema = z
	.object({
		uuid: z.string().uuid(),
		active: z.boolean(),
		anniversaryDay: z.number(),
		plan: SubscriptionServiceV2PlanObjectSchema,
		nextChargeDate: z.coerce.date(),
		currentPrice: SubscriptionServiceV2PriceObjectSchema,
	})
	.or(
		z
			.object({})
			.strict()
			.transform(() => null)
	);
export type SubscriptionServiceV2Subscription = z.infer<
	typeof SubscriptionServiceV2SubscriptionObjectSchema
>;

export const SubscriptionServiceV2SubscriptionResponseSchema = z.object({
	subscription: SubscriptionServiceV2SubscriptionObjectSchema,
});

export const SubscriptionServiceV2InvoiceSchema = z.object({
	collectionCompletedAt: z.string().nullable(),
});

export const SubscriptionServiceV2InvoicesResponseSchema = z.object({
	invoices: z.array(SubscriptionServiceV2InvoiceSchema),
});
export type SubscriptionServiceV2InvoicesResponse = z.infer<
	typeof SubscriptionServiceV2InvoicesResponseSchema
>;

export const SubscriptionServiceLegacyUnitSchema = z.object({
	label: z.string(),
});

export const SubscriptionServiceLegacyPriceSchema = z.object({
	value: z.number().transform((p) => formatPrice(p)),
});

export const SubscriptionServiceLegacyBillingPeriodSchema = z.object({
	unit: SubscriptionServiceLegacyUnitSchema,
	price: SubscriptionServiceLegacyPriceSchema,
});

export const SubscriptionServiceLegacyTierSubscriptionSchema = z.object({
	name: z.string(),
	billingPeriod: SubscriptionServiceLegacyBillingPeriodSchema,
	nextChargeOn: z.coerce.date(),
});
export type SubscriptionServiceLegacyTierSubscription = z.infer<
	typeof SubscriptionServiceLegacyTierSubscriptionSchema
>;

export const SubscriptionServiceLegacySubscriptionResponseSchema = z.object({
	userPlatformTierSubscription: SubscriptionServiceLegacyTierSubscriptionSchema,
});

export const UserSubscriptionSchema = z
	.object({
		_errors: PartialErrorsSchema.optional(),
		tierName: z.string().optional(),
		pricePeriod: z.string().optional(),
		nextChargeOn: z.coerce.date().nullable().optional(),
		subscriptionState: SubscriptionState.nullable(),
		isV2Subscription: z.boolean(),
	})
	.transform((r) => {
		if (r._errors && Object.keys(r._errors).length == 0) delete r._errors;
		return r;
	});
export type UserSubscription = z.infer<typeof UserSubscriptionSchema>;

export const SubscriptionServiceV2SubscriptionActionsSchema = z.object({
	executionDate: z.string().datetime(),
	action: z.string(),
	state: z.string(),
});

export const SubscriptionServiceV2SubscriptionActionsResponseSchema = z.object({
	subscriptionActions: z.array(SubscriptionServiceV2SubscriptionActionsSchema),
});
export type SubscriptionServiceV2SubscriptionActionsResponse = z.infer<
	typeof SubscriptionServiceV2SubscriptionActionsResponseSchema
>;

function adjustFrequencyLabel(label: string): string {
	if (label == 'Annually') return 'Annual';
	else return label;
}

function formatPrice(price: number) {
	return Number(price.toFixed(price % 1 > 0 ? 2 : 0));
}
