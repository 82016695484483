import { join } from 'path-browserify';
import { FC, useContext } from 'react';
import {
	TrackedLink,
	TrackedLinkColors,
	TrackedLinkVariants,
	ReferralContext,
} from '@stashinvest/shared-ui';

import { useConfig } from 'src/hooks/useConfig';
import { RuntimeConfig } from 'src/config/config';
import { formatReferralQuery } from 'src/utils';

export interface ActiveAdminLinkProps {
	path: string;
	py?: string;
	type?: TrackedLinkVariants;
	color?: TrackedLinkColors;
	size?: 'small' | 'medium';
	tracking: {
		page: string;
		component: string;
	};
	style?: object;
	children: React.ReactNode;
}

export interface ExtendedActiveAdminLinkProps extends ActiveAdminLinkProps {
	zendeskTicket: string | null;
	zendeskUser: string | null;
	activeAdminUrl: string;
}

export const ActiveAdminLinkComponent: FC<
	React.PropsWithChildren<ExtendedActiveAdminLinkProps>
> = ({
	path,
	tracking,
	children,
	zendeskTicket,
	zendeskUser,
	activeAdminUrl,
	...otherProps
}) => {
	const queryString = formatReferralQuery({
		path,
		items: { zendeskTicket, zendeskUser },
	});
	const url = `${activeAdminUrl}${join('/', path)}${queryString}`;

	return (
		<TrackedLink
			{...otherProps}
			href={url}
			tracking={{ ...tracking, label: 'active-admin' }}
		>
			{children}
		</TrackedLink>
	);
};

export const ActiveAdminLink: FC<ActiveAdminLinkProps> = (props) => {
	const config: RuntimeConfig = useConfig();
	const referralProps = useContext(ReferralContext);

	return (
		<ActiveAdminLinkComponent
			{...props}
			{...referralProps}
			activeAdminUrl={config.activeAdminUrl}
		/>
	);
};
