export enum StashApps {
	ACTIVE_ADMIN = 'ACTIVE_ADMIN',
	USER_HUB = 'USER_HUB',
	BANK_PORTAL = 'BANK_PORTAL',
	LEGACY_BANK_PORTAL = 'LEGACY_BANK_PORTAL',
}

export enum Environments {
	EDGE = 'EDGE',
	STAGING = 'STAGING',
	PROD = 'PROD',
}

interface EnvironmentBaseUrlConfig {
	EDGE: string;
	STAGING: string;
	PROD: string;
}

export interface BaseUrlConfig {
	ACTIVE_ADMIN: EnvironmentBaseUrlConfig;
	BANK_PORTAL: EnvironmentBaseUrlConfig;
	USER_HUB: EnvironmentBaseUrlConfig;
	LEGACY_BANK_PORTAL: EnvironmentBaseUrlConfig;
}

export interface EnvironmentContextProps {
	environment: Environments;
	baseUrlConfig: BaseUrlConfig;
}

export interface EnvironmentProviderProps {
	environmentToSet: Environments;
	baseUrlConfig?: BaseUrlConfig;
}
