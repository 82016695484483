import { BankAccountStatus } from 'src/types/global';

export interface BankAccountStateTag {
	title: string;
	type: string;
}

export const unknownStateTag = { title: 'Unknown', type: 'danger' };

export const bankAccountStates: Map<BankAccountStatus, BankAccountStateTag> = new Map([
	[BankAccountStatus.CLOSED, { title: BankAccountStatus.CLOSED, type: 'danger' }],
	[BankAccountStatus.CLOSING, { title: BankAccountStatus.CLOSING, type: 'danger' }],
	[BankAccountStatus.LOCKED, { title: BankAccountStatus.LOCKED, type: 'danger' }],
	[BankAccountStatus.NORMAL, { title: BankAccountStatus.NORMAL, type: 'success' }],
	[BankAccountStatus.PENDING, { title: BankAccountStatus.PENDING, type: 'info' }],
	[BankAccountStatus.INACTIVE, { title: BankAccountStatus.INACTIVE, type: 'info' }],
	[BankAccountStatus.RESTRICTED, { title: BankAccountStatus.RESTRICTED, type: 'danger' }],
	[BankAccountStatus.UNKNOWN, unknownStateTag],
]);
