import { useQuery } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';

import { UserProfileOrContentOnlyUserProfile } from '@stashinvest/shared-types';
import { getUserProfile } from 'src/api';

export const getUserProfileQueryKeys = (userId: string) => ['getUserProfile', userId];

export const useUserProfileV2 = (userId: string) => {
	const { oktaAuth } = useOktaAuth();
	return useQuery<UserProfileOrContentOnlyUserProfile, string>(
		getUserProfileQueryKeys(userId),
		() => getUserProfile(userId, oktaAuth.getAccessToken())
	);
};
