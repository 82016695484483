import { CSSProperties, ReactNode } from 'react';
import { TagVariant } from '../../types';
import { LinkVariantsType } from '../LinkVariant';
import { WrapperTrackingProps } from '../../utils/analytics';
import { TrackedButtonVariants } from '../TrackedButton/TrackedButton.types';

type PropTableCellVal = string | number | ReactNode;

export enum PropTableCellVariant {
	'TAG' = 1,
	'TOOLTIP' = 2,
	'CURRENCY' = 3,
	'DATE' = 4,
	'DATETIME' = 5,
	'LINK' = 6,
}

export interface PropTableValProps {
	/**
	 * Used to supply a value to any of the variants, or simply render a string or React Node
	 */
	value?: PropTableCellVal;
	/**
	 * Variant used to choose to render a Link, Tag, Tooltip, Currency, Date or DateTime. See the PropTableCellVariant Type!
	 */
	variant?: PropTableCellVariant;
	/**
	 * Description for tooltip if supplied
	 */
	tooltipBody?: ReactNode;
	/**
	 * Used to give the Tag it's colour
	 */
	tagVariant?: TagVariant;
	/**
	 * Wraps a link around the value
	 */
	link?: {
		path: string;
		variant?: LinkVariantsType;
	};
	/**
	 * The properties used for tracking
	 */
	tracking?: WrapperTrackingProps;
}

export interface RowProps extends PropTableValProps {
	/**
	 * The title for each row
	 */
	title: string | ReactNode;
	/**
	 * Adds a button aligned at the right of the column
	 * that can be a link or a button with a click event
	 */
	action?: {
		label: string;
		path?: string;
		onClick?(): void;
		variant?: LinkVariantsType;
		type?: TrackedButtonVariants;
	};
	/**
	 * Adds a search button to the row
	 * that takes a search URL
	 */
	search?: {
		link: string;
		label: string;
	};
	/**
	 * Adds an emoji to the end of the row
	 */
	emoji?: {
		label: string;
		accessibleLabel: string;
		icon: string;
		style?: CSSProperties;
	};
	testId?: string;
	/**
	 * Controls overflow, useful for long emails
	 */
	overflow?: 'auto' | 'visible' | 'hidden';
	/**
	 * Width of property label
	 */
	labelWidth?: number;
	/**
	 * Display variant of the parent prop table, defaults to `PropTableVariant.NORMAL`.
	 */
	tableVariant?: PropTableVariant;
}

export enum PropTableVariant {
	NORMAL = 'NORMAL',
	COMPACT = 'COMPACT',
}

export type PropTableProps = {
	children?: ReactNode;
	/**
	 * Each row used to construct the UI from config
	 */
	rows: RowProps[];
	/**
	 * Shared tracking for links and buttons
	 */
	tracking: WrapperTrackingProps;
	/**
	 * Width of left column of property labels
	 */
	labelWidth?: number;
	/**
	 * Adds a divider between rows
	 */
	showDividers?: boolean;
	/**
	 * Display variant, defaults to `PropTableVariant.NORMAL`.
	 */
	variant?: PropTableVariant;
};
