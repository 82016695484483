import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
	CardWithPropTable,
	CardTitle,
	EditButtonModalOpener,
} from '@stashinvest/shared-ui';
import {
	InvestorApplication,
	InvestorApplicationQuestionAnswer,
	InvestorApplicationQuestionGroups,
	InvestorApplicationQuestionId,
} from '@stashinvest/shared-types/investorApplication';

import { PageContainer } from 'src/components/PageContainer';
import { PageWrapper } from 'src/components/PageWrapper';
import { LoadingErrorWrapper } from 'src/components/LoadingErrorWrapper';
import { useInvestorApplication } from 'src/hooks/useInvestorApplication';
import { useUserProfileV2 } from 'src/hooks/useUserProfileV2';
import { Grid } from 'src/components/Grid';

import { EditEmploymentDetailsForm } from './components/EditEmploymentDetailsForm';
import { EditInvestmentInfoForm } from './components/EditInvestmentInfoForm';
import { EditSpecialQuestionsForm } from './components/EditSpecialQuestionsForm';

type UserPageParams = { userId: string };

//exporting for reusability
export const filterQuestionsByGroup = (
	data: InvestorApplication | undefined,
	group: string
) => {
	return (
		data?.investorApplication?.find((application) => application.group === group)
			?.questions ?? []
	);
};

const answerDisplayFormatter = (
	answer: InvestorApplicationQuestionAnswer,
	questionId?: InvestorApplicationQuestionId
) => {
	if (answer.value === undefined || answer.value === null) {
		// display "--" for unaswered questions
		return '--';
	}

	switch (questionId) {
		case 'tax_withholding_percent':
		case 'federal_tax_bracket_percent':
			return answer.value !== null ? `${answer.value}%` : '';
	}

	switch (answer.type) {
		case 'Enum':
			const answerLabel = answer.options?.find(({ value }) => value === answer.value)
				?.label;

			if (answerLabel) {
				return answerLabel;
			}
			break;
		case 'Boolean':
			return answer.value ? 'Yes' : 'No';
		case 'Array':
			if (Array.isArray(answer.value)) {
				return answer.value.join(', ');
			}
	}

	return answer.value;
};

const generateTableRows = (questions: InvestorApplicationQuestionGroups) => {
	return questions.map(({ label, answer, id }) => ({
		title: label,
		value: answerDisplayFormatter(answer, id),
	}));
};

export const InvestorProfile: FC = () => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	const {
		isLoading: userProfileLoading,
		data: userProfile,
		error: userProfileError,
	} = useUserProfileV2(userId);

	const {
		data: investorApplicationData,
		isLoading: investorApplicationLoading,
		error: investorApplicationError,
	} = useInvestorApplication({ userId });

	const employmentQuestions = filterQuestionsByGroup(
		investorApplicationData,
		'employment'
	);
	const financialQuestions = filterQuestionsByGroup(investorApplicationData, 'financial');
	const investmentQuestions = filterQuestionsByGroup(
		investorApplicationData,
		'investment'
	);
	const specialQuestions = filterQuestionsByGroup(investorApplicationData, 'special');

	const fullName =
		userProfile?.profile?.firstName && userProfile.profile?.lastName
			? `${userProfile.profile.firstName} ${userProfile.profile.lastName}`
			: undefined;

	return (
		<PageContainer name={fullName} showSearchBox>
			<PageWrapper title="Investor Profile">
				<LoadingErrorWrapper
					loading={investorApplicationLoading || userProfileLoading}
					error={(investorApplicationError || userProfileError) as Error}
				>
					{investorApplicationData?.investorApplication ? (
						<Grid>
							{employmentQuestions.length > 0 ? (
								<CardWithPropTable
									card={{
										title: (
											<CardTitle title="Employment Details">
												<EditButtonModalOpener label="edit employment details">
													<EditEmploymentDetailsForm
														investorApplication={
															investorApplicationData.investorApplication
														}
													/>
												</EditButtonModalOpener>
											</CardTitle>
										),
									}}
									tracking={{
										page: 'Investor Profile',
										component: 'employment-details-tile',
									}}
									table={{
										rows: generateTableRows(employmentQuestions),
										labelWidth: 210,
									}}
								/>
							) : null}

							{investmentQuestions && (
								<CardWithPropTable
									card={{
										title: (
											<CardTitle title="Investment Info">
												<EditButtonModalOpener label="edit investment info">
													<EditInvestmentInfoForm
														investorApplication={
															investorApplicationData.investorApplication
														}
													/>
												</EditButtonModalOpener>
											</CardTitle>
										),
									}}
									tracking={{
										page: 'Investor Profile',
										component: 'investment-info-tile',
									}}
									table={{
										rows: generateTableRows(investmentQuestions),
										labelWidth: 190,
									}}
								/>
							)}

							{financialQuestions.length > 0 ? (
								<CardWithPropTable
									card={{
										title: 'Financial Info',
									}}
									tracking={{
										page: 'Investor Profile',
										component: 'financial-info-tile',
									}}
									table={{
										rows: generateTableRows(financialQuestions),
										labelWidth: 210,
									}}
								/>
							) : null}

							{specialQuestions.length > 0 ? (
								<CardWithPropTable
									card={{
										title: (
											<CardTitle title="Special Questions">
												<EditButtonModalOpener label="edit special questions">
													<EditSpecialQuestionsForm
														investorApplication={
															investorApplicationData.investorApplication
														}
													/>
												</EditButtonModalOpener>
											</CardTitle>
										),
									}}
									tracking={{
										page: 'Investor Profile',
										component: 'special-questions-tile',
									}}
									table={{
										rows: generateTableRows(specialQuestions),
										labelWidth: 210,
									}}
								/>
							) : null}
						</Grid>
					) : null}
				</LoadingErrorWrapper>
			</PageWrapper>
		</PageContainer>
	);
};
