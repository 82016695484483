import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { FormikProps } from 'formik';
import { FormikTextField, Flex, Box } from '@stashinvest/ui/dist/es/2';

import {
	PatchUserProfileSchema,
	UserProfile,
	states,
	countries,
	PatchUserProfileResponse,
	PatchUserProfilePayload,
} from '@stashinvest/shared-types';
import { FormikCombobox } from '@stashinvest/shared-ui';

import { useToast } from 'src/hooks/useToast';
import { useUpdateUserProfile } from 'src/hooks/useUserProfileV2';
import { ModalForm } from 'src/components/ModalForm';
import { schemas } from 'src/utils/form/validation';
import { isObjEqualShallow } from 'src/utils';
import { isErrorResponse } from '@stashinvest/shared-types/errors';
import { AccountDetailsModals } from '../../AccountDetails';

const patchPermanentAddressSchema = PatchUserProfileSchema.pick({
	homeStreetAddress: true,
	homeStreetAddress2: true,
	homeCity: true,
	homeState: true,
	homeCountry: true,
})
	.merge(
		z.object({
			homePostalCode: z.pipeline(
				schemas.zipCode,
				PatchUserProfileSchema.shape.homePostalCode
			),
		})
	)
	.transform((permanantAddress) => {
		for (let [key, value] of Object.entries(permanantAddress)) {
			if (typeof value === 'string') {
				permanantAddress[key as keyof typeof permanantAddress] = value.trim();
			}
		}
		return permanantAddress;
	});

type PatchUserProfileInfoPayload = z.infer<typeof patchPermanentAddressSchema>;
type FormValues = Partial<z.input<typeof patchPermanentAddressSchema>>;
interface EditPermanentAddressFormProps {
	userProfile: UserProfile;
	setModal: React.Dispatch<React.SetStateAction<AccountDetailsModals | undefined>>;
}
type UserPageParams = { userId: string };

const stateOptions = states.map((state) => {
	return { value: state.code, label: state.name };
});
const countryOptions = countries.map((country) => {
	return { value: country.code, label: country.name };
});

interface FormFieldProps {
	formikProps: FormikProps<FormValues>;
}

type PermanentAddress = UserProfile['addresses']['primaryAddress'];
type WriteablePermanentAddressFields = Omit<
	NonNullable<PermanentAddress>,
	'deliveryIndicator'
>;

/** Omits read-only permanent address fields, e.g. delivery indicator */
const getWriteableAddressFields = (
	address: PermanentAddress
): WriteablePermanentAddressFields => {
	return {
		streetAddress: address?.streetAddress ?? null,
		streetAddress2: address?.streetAddress2 ?? null,
		city: address?.city ?? null,
		state: address?.state ?? null,
		postalCode: address?.postalCode ?? null,
		country: address?.country ?? null,
	};
};

const mapPayloadToPermAddressFields: Record<
	keyof PatchUserProfileInfoPayload,
	keyof WriteablePermanentAddressFields
> = {
	homeStreetAddress: 'streetAddress',
	homeStreetAddress2: 'streetAddress2',
	homeCity: 'city',
	homeState: 'state',
	homePostalCode: 'postalCode',
	homeCountry: 'country',
};

/**
 * @param permanentAddress - old permanent address
 * @param payload - submitted payload with new address fields
 */
const getExpectedAddress = (
	permanentAddress: PermanentAddress,
	payload: PatchUserProfileInfoPayload
): WriteablePermanentAddressFields => {
	let expectedAddress: WriteablePermanentAddressFields = {
		streetAddress: permanentAddress?.streetAddress ?? null,
		streetAddress2: permanentAddress?.streetAddress2 ?? null,
		city: permanentAddress?.city ?? null,
		state: permanentAddress?.state ?? null,
		postalCode: permanentAddress?.postalCode ?? null,
		country: permanentAddress?.country ?? null,
	};

	for (const [key, value] of Object.entries(payload)) {
		if (value) {
			expectedAddress[mapPayloadToPermAddressFields[key as keyof typeof payload]] = value;
		}
	}

	return expectedAddress;
};

const FormFields: FC<FormFieldProps> = ({ formikProps }) => {
	const { values } = formikProps;
	const { homeState, homeCountry } = values;
	const [previousAddressCountry, setPreviousAddressCountry] = useState(homeCountry);
	const isAddressInUsa = homeCountry === 'USA';
	const isCountryNotSet = homeCountry === '';

	if (homeCountry !== previousAddressCountry) {
		const previousAddressInUsa = previousAddressCountry === 'USA';
		if ((isAddressInUsa || previousAddressInUsa) && homeState) {
			// if address is changes from/to USA clear state field
			formikProps.setFieldValue('homeState', '');
			formikProps.setFieldError('homeState', undefined);
		}
		setPreviousAddressCountry(homeCountry);
	}

	return (
		<Box width="100%">
			<Box width="100%">
				<FormikTextField label="Street Address" name="homeStreetAddress" />
			</Box>
			<Box width="100%">
				<FormikTextField label="Apartment, Suite etc." name="homeStreetAddress2" />
			</Box>
			<Box width="100%">
				<FormikTextField label="City" name="homeCity" />
			</Box>
			<Flex
				width="100%"
				justifyContent="space-between"
				alignItems="baseline"
				position="relative"
			>
				<Box width="55%">
					{isAddressInUsa || isCountryNotSet ? (
						<FormikCombobox
							name="homeState"
							label="State"
							options={stateOptions}
							placeholder="Select a state"
						/>
					) : (
						<FormikTextField label="State" name="homeState" />
					)}
				</Box>
				<Box width="35%">
					<FormikTextField label="Zip Code" name="homePostalCode" />
				</Box>
			</Flex>
			<Box width="100%">
				<FormikCombobox
					name="homeCountry"
					label="Country"
					options={countryOptions}
					placeholder="Select a country"
					selectProps={{ menuPlacement: 'top' }}
				/>
			</Box>
		</Box>
	);
};

const renderFormFields = (formikProps: FormikProps<FormValues>) => {
	return <FormFields formikProps={formikProps} />;
};

export const EditPermanentAddressForm: FC<EditPermanentAddressFormProps> = ({
	userProfile,
	setModal,
}) => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	const { setToast } = useToast();
	const onUserProfileUpdateSuccess = useCallback(
		(response: PatchUserProfileResponse, payload: PatchUserProfilePayload) => {
			if (!isErrorResponse(response)) {
				// the if statement above is always true (as this only runs after succesful updates) and is only required for type-narrowing
				const oldPrimaryAddress = userProfile['addresses']['primaryAddress'];
				const expectedAddress = getExpectedAddress(oldPrimaryAddress, payload);
				const newAddress =
					response.addresses.primaryAddress &&
					getWriteableAddressFields(response.addresses.primaryAddress);
				if (
					newAddress &&
					!isObjEqualShallow(newAddress, expectedAddress, {
						caseInsensitive: true,
					})
				) {
					setModal(AccountDetailsModals.ADDRESS_UPDATE_OVERWRITTEN);
				} else {
					setToast({ message: 'Permanent Address updated' });
				}
			}
		},
		[userProfile, setModal, setToast]
	);
	const updateUserProfile = useUpdateUserProfile(userId, {
		onSuccess: (response, payload) => onUserProfileUpdateSuccess(response, payload),
	});

	const primaryAddress = userProfile.addresses.primaryAddress;
	const initialValues = {
		homeStreetAddress: primaryAddress?.streetAddress ?? '',
		homeStreetAddress2: primaryAddress?.streetAddress2 ?? '',
		homeCity: primaryAddress?.city ?? '',
		homeState: primaryAddress?.state ?? '',
		homeCountry: primaryAddress?.country ?? '',
		homePostalCode: primaryAddress?.postalCode ?? '',
	};

	const handleSubmit = (payload: Partial<PatchUserProfileInfoPayload>) => {
		return updateUserProfile(payload);
	};
	return (
		<ModalForm
			title="Edit Permanent Address"
			initialValues={initialValues}
			validationSchema={patchPermanentAddressSchema}
			handleSubmit={handleSubmit}
			renderContent={renderFormFields}
		/>
	);
};
