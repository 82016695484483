import { Box, Flex } from '@stashinvest/ui';

import { LockedAlertBanner } from 'src/components/LockedAlertBanner';
import {
	FetchUserReasonTagsResponse,
	ReasonTag,
} from '@stashinvest/shared-types/grpc/fraud_service_v1';
import {
	UserLocksSchemaType,
	UserLockReasonMap,
	UserLockReasonKey,
} from '@stashinvest/shared-types/schemas/userLocks';

export type UserLocksProps = {
	locks?: UserLocksSchemaType;
	fraudTags: FetchUserReasonTagsResponse | undefined;
};

export const UserLocks = ({ locks, fraudTags }: UserLocksProps) => {
	let unresolvedFraudTags: ReasonTag[] = [];
	let unresolvedAmlTags: ReasonTag[] = [];
	if (fraudTags !== undefined) {
		unresolvedFraudTags = fraudTags.reasonTags.filter(
			// fraud_review:under_review tag doesn't result in any locks thus should be ignored
			(t) =>
				!t.resolved &&
				t.category === 'fraud' &&
				t.reasonTag !== 'fraud_review:under_review'
		);
		unresolvedAmlTags = fraudTags.reasonTags.filter(
			(t) => !t.resolved && t.category === 'aml'
		);
	}
	const hasUnresolvedFraudTags = Boolean(unresolvedFraudTags.length);
	const hasUnresolvedAmlTags = Boolean(unresolvedAmlTags.length);

	const renderInvestBanner =
		(!hasUnresolvedFraudTags && locks?.userLocks.locked) ||
		(hasUnresolvedFraudTags &&
			locks?.userLocks.locked &&
			locks.userLocks.reason !== 'USER_LOCKED');
	const renderBankBanner = locks?.bankLocks.locked;

	const investLockReasonKey =
		UserLockReasonMap[('invest' + locks?.userLocks.reason) as UserLockReasonKey];
	const bankLockReasonKeys: number[] = [];
	locks?.bankLocks.reasons.forEach((r) => {
		console.log('this is bank reason', r);
		bankLockReasonKeys.push(UserLockReasonMap[r as UserLockReasonKey]);
	});

	return (
		<Flex flexDirection="column" width="100%">
			{hasUnresolvedFraudTags ? (
				<Box
					mt="30px"
					mx="auto"
					px="30px"
					width="100%"
					maxWidth="1002px"
					key={'Fraud Banner'}
				>
					<LockedAlertBanner
						id={''} // won't be used for fraud tags banners
						title={'Fraud'}
						reasons={[]} // won't be used for fraud tags banners
						fraudTags={unresolvedFraudTags}
					/>
				</Box>
			) : null}
			{hasUnresolvedAmlTags ? (
				<Box
					mt="30px"
					mx="auto"
					px="30px"
					width="100%"
					maxWidth="1002px"
					key={'FCC Banner'}
				>
					<LockedAlertBanner
						id={''} // won't be used for fraud tags banners
						title={'FCC'}
						reasons={[]} // won't be used for fraud tags banners
						fraudTags={unresolvedAmlTags}
					/>
				</Box>
			) : null}
			{renderInvestBanner ? (
				<Box
					mt="30px"
					mx="auto"
					px="30px"
					width="100%"
					maxWidth="1002px"
					key={'investBanner'}
				>
					<LockedAlertBanner
						id={'investBanner'}
						title={'Invest Account Issues'}
						reasons={[investLockReasonKey]}
						comment={locks!.userLocks.comment ? locks!.userLocks.comment : undefined} // Monoliths DB has zero non-null lock events with comments, might be worth removing completely
						adminUser={
							locks!.userLocks.admin_email ? locks!.userLocks.admin_email : undefined
						}
						dateOfAction={
							locks!.userLocks.applied_at ? locks!.userLocks.applied_at : undefined
						}
					/>
				</Box>
			) : null}
			{renderBankBanner ? (
				<Box
					mt="30px"
					mx="auto"
					px="30px"
					width="100%"
					maxWidth="1002px"
					key={'bankBanner'}
				>
					<LockedAlertBanner
						id={'bankBanner'}
						title={'Bank Account Issues'}
						reasons={bankLockReasonKeys}
					/>
				</Box>
			) : null}
		</Flex>
	);
};
