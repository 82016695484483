import { FC } from 'react';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import {
	InvestorApplication,
	InvestorApplicationInputSchema,
} from '@stashinvest/shared-types';

import { useUpdateInvestorApplication } from 'src/hooks/useInvestorApplication';
import { formErrorMessage, schemas } from 'src/utils/form/validation';
import { useToast } from 'src/hooks/useToast';
import { ModalForm } from 'src/components/ModalForm';
import {
	getInitialValuesFromQuestionGroup,
	filterQuestionsByGroup,
	getFormRendererFromQuestionGroup,
	FieldHelpTextMap,
} from '../utils/formUtils';

type UserPageParams = { userId: string };

const editSpecialQuestionsFormSchema = InvestorApplicationInputSchema.pick({
	political_organization: true,
	firm_name: true,
})
	.merge(
		z.object({
			is_control_person: z.pipeline(
				schemas.boolStringToBool,
				InvestorApplicationInputSchema.shape.is_control_person
			),
			is_politically_exposed: z.pipeline(
				schemas.boolStringToBool,
				InvestorApplicationInputSchema.shape.is_politically_exposed
			),
			is_affiliated_exchange_or_finra: z.pipeline(
				schemas.boolStringToBool,
				InvestorApplicationInputSchema.shape.is_affiliated_exchange_or_finra
			),
			company_symbols: z.pipeline(
				schemas.commaDelimitedStringToArray.optional(),
				InvestorApplicationInputSchema.shape.company_symbols
			),
			immediate_family: z.pipeline(
				schemas.commaDelimitedStringToArray.optional(),
				InvestorApplicationInputSchema.shape.immediate_family
			),
		})
	)
	.superRefine(
		(
			{
				is_control_person,
				company_symbols,
				is_politically_exposed,
				political_organization,
				immediate_family,
				is_affiliated_exchange_or_finra,
				firm_name,
			},
			ctx
		) => {
			if (
				is_control_person === true &&
				(!company_symbols || company_symbols.length === 0)
			) {
				ctx.addIssue({
					message: formErrorMessage.required,
					path: ['company_symbols'],
					code: z.ZodIssueCode.custom,
				});
			}

			if (is_politically_exposed === true) {
				if (!political_organization) {
					ctx.addIssue({
						message: formErrorMessage.required,
						path: ['political_organization'],
						code: z.ZodIssueCode.custom,
					});
				}

				if (!immediate_family || immediate_family.length === 0) {
					ctx.addIssue({
						message: formErrorMessage.required,
						path: ['immediate_family'],
						code: z.ZodIssueCode.custom,
					});
				}
			}

			if (is_affiliated_exchange_or_finra === true && !firm_name) {
				ctx.addIssue({
					message: formErrorMessage.required,
					path: ['firm_name'],
					code: z.ZodIssueCode.custom,
				});
			}
		}
	);

type EditSpecialQuestionsFormPayload = z.infer<typeof editSpecialQuestionsFormSchema>;
const multipleCommaSeparatedInputCaption =
	'To submit multiple entries place a comma between each entry';
const captionTextMap: FieldHelpTextMap = {
	company_symbols: multipleCommaSeparatedInputCaption,
	immediate_family: multipleCommaSeparatedInputCaption,
};
const placeholderTextMap: FieldHelpTextMap = {
	company_symbols: 'e.g. AAPL, GOOGL, NVDA',
	immediate_family: 'e.g. John Doe, Jane Smith',
};

interface EditSpecialQuestionsFormProps {
	investorApplication: InvestorApplication['investorApplication'];
}

export const EditSpecialQuestionsForm: FC<EditSpecialQuestionsFormProps> = ({
	investorApplication,
}) => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	const { setToast } = useToast();
	const onSuccess = () => {
		setToast({ message: 'Special Questions updated' });
	};
	const updateInvestorApplication = useUpdateInvestorApplication(userId, { onSuccess });
	const specialQuestions = filterQuestionsByGroup(investorApplication, 'special');
	if (!specialQuestions) {
		return null;
	}
	const renderFormFields = getFormRendererFromQuestionGroup(specialQuestions, {
		captionTextMap,
		placeholderTextMap,
	});
	const initialValues = getInitialValuesFromQuestionGroup(specialQuestions);

	const handleSubmit = async (values: Partial<EditSpecialQuestionsFormPayload>) => {
		// TODO: after the toast component is migrated from bank-portal to shared-ui display it on successful submission using onSuccess option
		await updateInvestorApplication(values);
	};
	return (
		<ModalForm
			title="Edit Special Questions"
			initialValues={initialValues}
			validationSchema={editSpecialQuestionsFormSchema}
			renderContent={renderFormFields}
			handleSubmit={handleSubmit}
			height="80%"
		/>
	);
};
