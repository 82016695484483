interface GetPageLinksProps {
	pages: number[];
	pageCount: number;
	currentPage: number;
}

export const getPageLinks = ({ pages, pageCount, currentPage }: GetPageLinksProps) =>
	pages.filter((page) => {
		// always show first and last pages
		if (page === 1 || page === pageCount) {
			return true;
		}

		// don't show pages beyond the previous page
		if (page < currentPage && page !== currentPage - 1) {
			return false;
		}

		// show the previous page
		if (page === currentPage - 1) {
			return true;
		}

		// show the next page
		if (page <= currentPage + 1) {
			return true;
		}

		return false;
	});
