import { createContext, useState, FC } from 'react';
import { SearchResponseType } from '@stashinvest/shared-types/schemas/user';

export type PotentialResponse = SearchResponseType | undefined;

interface SearchResponseContextProps {
	response: PotentialResponse;
	setResponse(value: PotentialResponse): void;
	clearResponse(): void;
}

export const SearchResponseContext = createContext<SearchResponseContextProps>({
	response: undefined,
	setResponse: () => {},
	clearResponse: () => {},
});

export const SearchResponseProvider: FC<
	React.PropsWithChildren<React.PropsWithChildren>
> = ({ children }) => {
	const [state, setState] = useState<PotentialResponse>();
	const resetState = () => setState(undefined);

	return (
		<SearchResponseContext.Provider
			value={{
				response: state,
				setResponse: setState,
				clearResponse: resetState,
			}}
		>
			{children}
		</SearchResponseContext.Provider>
	);
};
