import React, { FC } from 'react';
import styled from 'styled-components';
import { Tag } from '@stashinvest/ui';
import { textComponentType } from '../../utils';

import { FormattedCurrencyProps, ValidCurrency } from './FormattedCurrency.types';

const FormattedCurrencyContainer = styled.span<{
	enableColors?: boolean;
	isNegative: boolean;
}>`
 ${({ enableColors, isNegative, theme }) => {
		if (!enableColors) {
			return `color: inherit;`;
		}

		const colorValue = isNegative
			? theme.colors.bgNegativeSecondary
			: theme.colors.bgPositiveSecondary;

		return `color: ${colorValue};`;
 }};
}	
`;

const currencyMap = {
	[ValidCurrency.USD]: '$',
};

export const FormattedCurrency: FC<React.PropsWithChildren<FormattedCurrencyProps>> = ({
	currency = ValidCurrency.USD,
	enableColors,
	value,
	nullValue = 'Empty',
	textType,
}) => {
	if (!value && value !== 0) {
		return <Tag variant="warning">{nullValue}</Tag>;
	}
	const TextComponent = textComponentType(textType);
	const isNegative = value.toString().includes('-');
	const valueToDecPoints = parseFloat(value.toString().replace(/,|-/g, '')).toFixed(2);
	return (
		<TextComponent>
			<FormattedCurrencyContainer
				data-testid="fmt-currency-container"
				enableColors={enableColors}
				isNegative={isNegative}
			>
				{isNegative ? '-' : null}
				{currencyMap[currency]}
				{valueToDecPoints}
			</FormattedCurrencyContainer>
		</TextComponent>
	);
};
