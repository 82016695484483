import { FC, useContext } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import queryString from 'query-string';
import { Flex, Loader } from '@stashinvest/ui';
import { PotentialResponse } from 'src/providers/SearchResponseProvider';
import { PageContainer } from 'src/components/PageContainer';
import { BackButton } from 'src/components/BackButton';
import { PageHistoryContext } from 'src/providers/PageHistoryProvider';
import { UserSearchParamsType } from '@stashinvest/shared-types/schemas/user';

import { UserSearchTable, UserSearchTableProps } from './components/UserSearchTable';
import { UserSearchFooter } from './components/UserSearchFooter';
import { SearchContainer } from './components/SearchContainer';

export type UserSearchProps = UserSearchTableProps & {
	searchParam?: UserSearchParamsType;
	autoRedirect: boolean;
};

export interface IUserSearchPageState {
	loading: boolean;
	error: Error | undefined;
	data: PotentialResponse;
}

export const UserSearchComponent: FC<UserSearchProps> = ({
	searchResults,
	onChangePage,
	autoRedirect,
	page,
	perPage,
}: UserSearchProps) => {
	const navigate = useNavigate();
	const { history } = useContext(PageHistoryContext);

	if (!searchResults) {
		return <Loader center />;
	}
	if (searchResults.totalCount === 0) {
		return <Navigate replace to="/no-results" />;
	}
	if (searchResults.totalCount === 1 && autoRedirect) {
		const userId = searchResults.users[0].userId;
		return <Navigate replace to={`/id/${userId}`} />;
	}

	return (
		<>
			<Flex justifyContent="center" my="20px">
				<BackButton
					history={history}
					goBack={() => navigate(-1)}
					push={navigate}
					tracking={{
						page: 'search-results',
						component: 'go-back',
						label: 'button',
					}}
				>
					Go back
				</BackButton>
			</Flex>
			<UserSearchTable
				searchResults={searchResults}
				onChangePage={onChangePage}
				page={page}
				perPage={perPage}
			/>
			<UserSearchFooter />
		</>
	);
};

type QueryParam = string | undefined;

function validateSearchParams(params: UserSearchParamsType): boolean {
	const validParams = Object.values(params).filter((value) => value);
	// per_page and page should always be present
	// so if only they are present - we shouldn't allow search
	if (validParams.length <= 2) {
		return false;
	}
	// search by name should be allowed only if both first and last names are present
	if (
		(params.first_name && !params.last_name) ||
		(!params.first_name && params.last_name)
	) {
		return false;
	}

	return true;
}

export const UserSearch: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queries = queryString.parse(location.search);
	const email = (queries.email || undefined) as QueryParam;
	const firstName = (queries.firstName || undefined) as QueryParam;
	const lastName = (queries.lastName || undefined) as QueryParam;
	const phone = (queries.phone || undefined) as QueryParam;
	const id = (queries.id || undefined) as QueryParam;
	const page = (Number(queries.page) || 1) as number;
	const autoRedirect = Boolean(queries.autoRedirect);
	const searchParams: UserSearchParamsType = {
		page: page.toString(),
		per_page: '20',
		email: email,
		first_name: firstName,
		last_name: lastName,
		phone_number: phone,
	};

	const setPage = (newPage: number) => {
		navigate(
			queryString.stringifyUrl({
				url: location.pathname,
				query: {
					...queries,
					page: Number(newPage) || 1,
				},
			})
		);
	};

	if (id) {
		return <Navigate replace to={`/id/${id}`} />;
	}

	if (!validateSearchParams(searchParams)) {
		return <Navigate replace to="/" />;
	}
	return (
		<PageContainer showSearchBox>
			<SearchContainer perPage={20} searchParam={searchParams}>
				{({ response, searchParam, perPage }) => (
					<UserSearchComponent
						searchResults={response}
						page={page}
						searchParam={searchParam}
						perPage={perPage}
						onChangePage={setPage}
						autoRedirect={autoRedirect}
					/>
				)}
			</SearchContainer>
		</PageContainer>
	);
};
