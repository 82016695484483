import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const HappyFaceIcon: FC<React.PropsWithChildren<IconProps>> = (props) => (
	<IconContainer {...props}>
		<svg width="100%" height="100%" viewBox="0 0 22 22" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM6.286 11a1.572 1.572 0 110-3.144 1.572 1.572 0 010 3.144zM11 16.5a3.146 3.146 0 01-3.143-3.143c0-.434.352-.786.786-.786h4.714c.434 0 .786.352.786.786A3.146 3.146 0 0111 16.5zm4.714-5.5a1.572 1.572 0 11.001-3.144 1.572 1.572 0 010 3.144z"
				fill="currentColor"
			/>
		</svg>
	</IconContainer>
);
