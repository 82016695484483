import { FC } from 'react';
import {
	FeatureFlagIdentifier as FFIdentifier,
	useOktaProfile,
} from '@stashinvest/shared-ui';

export const FeatureFlagIdentifier: FC<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const { oktaProfile } = useOktaProfile();
	return <FFIdentifier oktaProfile={oktaProfile}>{children}</FFIdentifier>;
};
