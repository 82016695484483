import { FC } from 'react';
import { Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { HappyFaceIcon } from '@stashinvest/shared-ui';

export const EmptyComments: FC = () => (
	<Flex
		px="20px"
		pt="10px"
		pb="15px"
		justifyContent="center"
		alignItems="center"
		flexDirection="column"
	>
		<HappyFaceIcon size="48px" color="textDisabled" />
		<Text.Label16 pt="20px">No comments for this user</Text.Label16>
	</Flex>
);
