export const isClipboardSupported = () => Boolean(navigator && navigator.clipboard);

export interface ClipboardProps {
	status: boolean | null;
	value: string | null;
}

export const copyToClipboard = async (value = '') => {
	if (isClipboardSupported()) {
		await navigator.clipboard.writeText(value);

		try {
			const result = await navigator.clipboard.readText();
			return {
				status: true,
				value: result,
			} as ClipboardProps;
		} catch {
			return {
				status: false,
				value: 'Copy blocked by browser permissions',
			} as ClipboardProps;
		}
	}

	return {
		status: false,
		value: 'Copy is unsupported in this browser',
	} as ClipboardProps;
};
