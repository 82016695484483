import { ThemeModes, MODE_DARK } from '../../providers';

export type ThemedColorLabels =
	| 'ERROR'
	| 'WARNING'
	| 'SUCCESS'
	| 'TEXT_ERROR'
	| 'TEXT_WARNING'
	| 'TEXT_INFO'
	| 'TEXT_REDACTED'
	| 'BG_NEUTRAL_INPUT'
	| 'BG_NEUTRAL_BUTTON'
	| 'TEXT_NEUTRAL_BUTTON';

export const colors = {
	bgNegativeAlert: '#6f0021',
	bgWarningAlert: '#935d17',
	bgPositiveAlert: '#005f37',
	actionDark: '#444',
	actionLight: '#e5e5e5',
	neutralInputLight: '#e4edf7',
	neutralBtnLight: '#c1cfde',
	neutralInputDark: '#4c5963',
	neutralBtnDark: '#3d4850',
};

export const themedColorMap = {
	ERROR: {
		dark: colors.bgNegativeAlert,
		light: 'bgNegativeSecondary',
	},
	WARNING: {
		dark: colors.bgWarningAlert,
		light: 'bgWarningSecondary',
	},
	SUCCESS: {
		dark: colors.bgPositiveAlert,
		light: 'bgPositiveSecondary',
	},
	TEXT_ERROR: {
		dark: 'bgNegativeSecondary',
		light: 'bgNegativePrimary',
	},
	TEXT_WARNING: {
		dark: 'bgWarningSecondary',
		light: 'bgWarningPrimary',
	},
	TEXT_INFO: {
		dark: 'textPrimary',
		light: 'textPrimary',
	},
	TEXT_REDACTED: {
		dark: 'textPrimary',
		light: 'textPrimary',
	},
	BG_NEUTRAL_INPUT: {
		dark: colors.neutralInputDark,
		light: colors.neutralInputLight,
	},
	BG_NEUTRAL_BUTTON: {
		dark: colors.neutralBtnDark,
		light: colors.neutralBtnLight,
	},
	TEXT_NEUTRAL_BUTTON: {
		dark: colors.neutralBtnLight,
		light: colors.neutralInputDark,
	},
};

interface ThemedColorProps {
	theme: ThemeModes;
	type: ThemedColorLabels;
}

export const getThemedColor = ({ theme, type }: ThemedColorProps) => {
	const colorMode = theme || MODE_DARK;
	const colorType = type || 'WARNING';
	return themedColorMap[colorType][colorMode];
};
