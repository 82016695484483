import { FC } from 'react';
import { FormikProps } from 'formik';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FormikTextField, FormikSelect, Flex, Box } from '@stashinvest/ui/dist/es/2';
import { ComboboxOption, FormikCombobox } from '@stashinvest/shared-ui';
import {
	PatchUserProfileSchema,
	UserProfile,
	visaTypes,
} from '@stashinvest/shared-types/userProfile';
import { countries } from '@stashinvest/shared-types/constants';

import { useUpdateUserProfile } from 'src/hooks/useUserProfileV2';
import { formErrorMessage, schemas } from 'src/utils/form/validation';
import { useToast } from 'src/hooks/useToast';
import { ModalForm } from 'src/components/ModalForm';

type UserPageParams = { userId: string };

const countryOptions = countries.reduce((acc: ComboboxOption[], country) => {
	return [...acc, { value: country.code, label: country.name }];
}, []);

const patchCitizenshipDetailsSchema = z
	.object({
		citizenshipCountry: z.pipeline(
			schemas.emptyStringToUndefined,
			PatchUserProfileSchema.shape.citizenshipCountry
		),
		permanentResident: z.pipeline(
			schemas.emptyStringToUndefined.pipe(schemas.boolStringToBool.optional()).optional(),
			PatchUserProfileSchema.shape.permanentResident
		),
		birthCountry: z.pipeline(
			schemas.emptyStringToUndefined.optional(),
			PatchUserProfileSchema.shape.birthCountry
		),
		visaType: z.pipeline(
			schemas.emptyStringToUndefined.optional(),
			PatchUserProfileSchema.shape.visaType
		),
		visaExpirationDate: z.pipeline(
			schemas.emptyStringToUndefined
				.pipe(
					schemas.isoDateStringToUtcDate
						.pipe(
							z
								.date()
								.min(
									DateTime.now().plus({ days: 90 }).toJSDate(),
									'The Visa Expiration Date cannot be less than 90 days'
								)
								.max(
									DateTime.now().plus({ years: 10 }).toJSDate(),
									'The Visa Expiration Date cannot be over 10 years'
								)
						)
						.optional()
				)
				.optional(),
			PatchUserProfileSchema.shape.visaExpirationDate
		),
	})
	.superRefine((values, ctx) => {
		const {
			citizenshipCountry,
			permanentResident,
			birthCountry,
			visaType,
			visaExpirationDate,
		} = values;
		const isUSCitizen = citizenshipCountry === 'USA';
		const requiredFields: Set<keyof typeof values> = new Set();

		if (!isUSCitizen) {
			if (permanentResident === undefined) {
				requiredFields.add('permanentResident');
			}
			if (!birthCountry) {
				requiredFields.add('birthCountry');
			}
		}

		if (!isUSCitizen && !permanentResident) {
			if (!visaType) {
				requiredFields.add('visaType');
			}
			if (!visaExpirationDate) {
				requiredFields.add('visaExpirationDate');
			}
		}

		requiredFields.forEach((fieldName) => {
			ctx.addIssue({
				message: formErrorMessage.required,
				path: [fieldName],
				code: z.ZodIssueCode.custom,
			});
		});
	});

type PatchCitizenshipDetailsPayload = z.infer<typeof patchCitizenshipDetailsSchema>;
type FormValues = Partial<z.input<typeof patchCitizenshipDetailsSchema>>;

const boolSelectFieldOptions = [
	{ value: true, label: 'Yes' },
	{ value: false, label: 'No' },
] as const;

interface FormFieldsProps {
	formikProps: FormikProps<FormValues>;
}

const FormFields: FC<FormFieldsProps> = ({ formikProps }) => {
	const {
		permanentResident,
		birthCountry,
		visaType,
		visaExpirationDate,
		citizenshipCountry,
	} = formikProps.values;
	const isUSCitizen = citizenshipCountry === 'USA';
	const isNotPermanentResident = permanentResident && permanentResident === 'false';
	const isPermanentResident = permanentResident === 'true';
	const formikErrors = formikProps.errors;
	const isNonUsaCitizenDependentFieldFilled = Boolean(
		birthCountry ||
			formikErrors.birthCountry ||
			permanentResident ||
			formikErrors.permanentResident ||
			visaExpirationDate ||
			formikErrors.visaExpirationDate ||
			visaType ||
			formikErrors.visaType
	);
	const isNonPermanentResidentDependentFieldFilled =
		visaType ||
		formikErrors.visaType ||
		visaExpirationDate ||
		formikErrors.visaExpirationDate;

	if (isUSCitizen && isNonUsaCitizenDependentFieldFilled) {
		formikProps.setFormikState((prevState) => ({
			...prevState,
			values: {
				...prevState.values,
				visaExpirationDate: '',
				visaType: '',
				permanentResident: '',
				birthCountry: '',
			},
			errors: {},
		}));
	}
	if (!isUSCitizen && isPermanentResident && isNonPermanentResidentDependentFieldFilled) {
		formikProps.setFormikState((prevState) => ({
			...prevState,
			values: { ...prevState.values, visaType: '', visaExpirationDate: '' },
			errors: {},
		}));
	}

	return (
		<Box width="100%" overflow="hidden">
			<Box width="100%">
				<FormikCombobox
					name="citizenshipCountry"
					label="Citizenship"
					options={countryOptions}
					placeholder="Select a country"
				/>
			</Box>
			<Box width="100%">
				<FormikSelect
					name="permanentResident"
					label="Permanent resident"
					disabled={isUSCitizen}
				>
					{boolSelectFieldOptions.map((option) => (
						<option key={option.label} value={String(option.value)}>
							{option.label}
						</option>
					))}
				</FormikSelect>
			</Box>
			<Box width="100%">
				<FormikCombobox
					name="birthCountry"
					label="Birth country"
					options={countryOptions}
					placeholder="Select a country"
					selectProps={{ isDisabled: isUSCitizen }}
				/>
			</Box>
			<Flex width="100%" justifyContent="space-between" alignItems="baseline">
				<Box width="48%">
					<FormikSelect
						name="visaType"
						label="Visa type"
						disabled={isUSCitizen || !isNotPermanentResident}
					>
						{visaTypes.options.map((visaType) => (
							<option key={visaType} label={visaType} value={visaType}>
								{visaType}
							</option>
						))}
					</FormikSelect>
				</Box>
				<Box width="48%">
					<FormikTextField
						type="date"
						label="Visa expiration date"
						name="visaExpirationDate"
						disabled={isUSCitizen || !isNotPermanentResident}
					/>
				</Box>
			</Flex>
		</Box>
	);
};

const renderFormFields = (formikProps: FormikProps<FormValues>) => {
	return <FormFields formikProps={formikProps} />;
};

interface EditCizenshipDetailsFormProps {
	userProfile: UserProfile;
}

const getInitialFormValues = (nationality: UserProfile['nationality']) => {
	// the placeholder/empty option in the select element has a value of "", convert null to "" to select the empty option
	// bool values are converted to strings as their input element is a select which doesn't accept strings
	const permanentResident =
		nationality.permanentResident === null ? '' : String(nationality.permanentResident);
	const birthCountry = nationality.birthCountry === null ? '' : nationality.birthCountry;
	const visaType = nationality.visaType === null ? '' : nationality.visaType;

	const visaExpirationDate = nationality.visaExpirationDate
		? nationality.visaExpirationDate
		: undefined;

	return {
		...nationality,
		birthCountry,
		permanentResident,
		visaType,
		visaExpirationDate,
	};
};

export const EditCitizenshipDetailsForm: FC<EditCizenshipDetailsFormProps> = ({
	userProfile,
}) => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	const { setToast } = useToast();
	const onUserProfileUpdateSuccess = () => {
		setToast({ message: 'Citizenship Details updated' });
	};
	const updateUserProfile = useUpdateUserProfile(userId, {
		onSuccess: onUserProfileUpdateSuccess,
	});

	const handleSubmit = async (values: Partial<PatchCitizenshipDetailsPayload>) => {
		await updateUserProfile(values);
	};

	return (
		<ModalForm
			title="Edit Citizenship Details"
			initialValues={getInitialFormValues(userProfile.nationality)}
			validationSchema={patchCitizenshipDetailsSchema}
			handleSubmit={handleSubmit}
			renderContent={renderFormFields}
		/>
	);
};
