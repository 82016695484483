import { useQuery } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';

import { GetDocumentRequestResponse } from '@stashinvest/shared-types/schemas/papyrus';

import { config } from 'src/config/config';

interface IdentityDocumentsParams {
	userId: string;
	documentRequestId: string;
}

async function fetchIdentityDocuments(
	userId: string,
	documentRequestId: string,
	accessToken: string
) {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/${userId}/documentRequest/${documentRequestId}`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		}
	);

	const responseBody: GetDocumentRequestResponse = await response.json();

	if (!response.ok) {
		switch (response.status) {
			case 401:
				throw new Error('Unauthorized');
			default:
				throw new Error(
					'Something went wrong while fetching identity documents of the user'
				);
		}
	}

	return responseBody;
}

export function useIdentityDocuments({
	userId,
	documentRequestId,
}: IdentityDocumentsParams) {
	const { oktaAuth } = useOktaAuth();

	return useQuery(['identityDocuments', userId, documentRequestId], () =>
		fetchIdentityDocuments(userId, documentRequestId, oktaAuth.getAccessToken())
	);
}
