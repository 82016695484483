import { FC } from 'react';
import { Box } from '@stashinvest/ui';

import { DomainLabel, Domains } from 'src/components/DomainLabel';
import { FalseyProp } from 'src/components/FalseyProp';
import { ActiveAdminLink } from 'src/components/ActiveAdminLink';
import { FormattedDate } from '@stashinvest/shared-ui';

import { AccountCardCopyBox } from '../AccountCardCopyBox';
import { DataField } from '../../../../components/OptionalDataField/DataField';

export interface InvestAccountBodyProps {
	portfolio: string;
	eSig: string;
	atlasId?: string | null;
	accountId: string;
	trackingTitle: string;
}

export const InvestAccountBody: FC<InvestAccountBodyProps> = (props) => {
	const { portfolio, eSig, atlasId, trackingTitle, accountId } = props;

	const hasValue = portfolio && portfolio.length > 0;
	const styleProp = !hasValue ? { textDecoration: 'none' } : {};

	return (
		<>
			<DomainLabel
				domain={Domains.Invest}
				style={{
					position: 'absolute',
					top: '-35px',
					right: 0,
				}}
			/>
			<FalseyProp
				property="Portfolio value"
				value={
					<Box pl="10px">
						<ActiveAdminLink
							path={`admin/accounts/${accountId}/portfolio`}
							tracking={{
								page: 'user',
								component: `${trackingTitle}-account-card-portfolio-value`,
							}}
							style={{ ...styleProp }}
						>
							<DataField
								partialObject={
									portfolio === null
										? {
												portfolio: portfolio,
												_errors: {
													portfolio: {
														message: 'Error',
														description:
															'A system error occurred retrieving the portfolio balance',
														type: 'ERROR',
													},
												},
										  }
										: {}
								}
								propertyName={'portfolio'}
							>
								{portfolio}
							</DataField>
						</ActiveAdminLink>
					</Box>
				}
			/>
			<FalseyProp
				property="E-Sig"
				value={
					<Box pl="10px">
						<FormattedDate timestamp={eSig} showTimezone />
					</Box>
				}
			/>
			<AccountCardCopyBox property="Apex ID" value={atlasId} />
			<ActiveAdminLink
				path={`admin/accounts/${accountId}`}
				tracking={{
					page: 'user',
					component: `${trackingTitle}-account-card`,
				}}
			>
				View account
			</ActiveAdminLink>
			<ActiveAdminLink
				path={`admin/accounts/${accountId}/bank_accounts`}
				tracking={{
					page: 'user',
					component: `${trackingTitle}-account-card`,
				}}
			>
				View external bank links
			</ActiveAdminLink>
		</>
	);
};
