import React, { FC, useState, useContext } from 'react';
import { Box, Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { ThemeModeContext } from '../../providers';
import { HiddenLabel } from '../HiddenLabel';
import {
	getThemedColor,
	copyToClipboard,
	isClipboardSupported,
	ClipboardProps,
} from '../../utils';

import { CopyBoxProps } from './CopyBox.types';
import {
	CopyBoxContainer,
	CopyButton,
	ErrorNotifier,
	CopyBoxIcon,
	CopyConfirmation,
} from './CopyBox.styles';

export const CopyBox: FC<React.PropsWithChildren<CopyBoxProps>> = ({ value, label }) => {
	const { mode } = useContext(ThemeModeContext);
	const [copiedValue, setCopiedValue] = useState<ClipboardProps>({
		status: null,
		value: null,
	});
	const copyBodyBg = getThemedColor({ theme: mode, type: 'BG_NEUTRAL_INPUT' });
	const copyBtnBg = getThemedColor({ theme: mode, type: 'BG_NEUTRAL_BUTTON' });
	const copyBtnText = getThemedColor({ theme: mode, type: 'TEXT_NEUTRAL_BUTTON' });

	const handleCopyClick = async () => {
		const result = await copyToClipboard(value);
		setCopiedValue(result);
		setTimeout(() => {
			setCopiedValue({
				status: null,
				value: null,
			});
		}, 2000);
	};

	const showError = Boolean(copiedValue.status === false);

	return (
		<Box height="28px">
			<Flex flexDirection="row">
				<CopyBoxContainer py="4px" px="10px" bg={copyBodyBg}>
					<Text.Label14>{label ?? value}</Text.Label14>
				</CopyBoxContainer>
				{isClipboardSupported() ? (
					<>
						<CopyButton bg={copyBtnBg} onClick={handleCopyClick}>
							{showError ? (
								<ErrorNotifier p="10px" data-testid="error-notifier">
									<Text.Label14>{copiedValue.value}</Text.Label14>
								</ErrorNotifier>
							) : null}
							<Text.Label14 as="div">
								<CopyBoxIcon color={copyBtnText} size="16px" />
							</Text.Label14>
							<HiddenLabel>Copy Text</HiddenLabel>
						</CopyButton>
						<CopyConfirmation visible={Boolean(copiedValue.status)}>
							Copied!
						</CopyConfirmation>
					</>
				) : null}
			</Flex>
		</Box>
	);
};
