import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const UserCommentIcon: FC<React.PropsWithChildren<IconProps>> = (props) => (
	<IconContainer {...props}>
		<svg width="100%" height="100%" viewBox="0 0 34 21" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 10.5C0 4.701 4.701 0 10.5 0S21 4.701 21 10.5 16.299 21 10.5 21 0 16.299 0 10.5zm10.5 5.727c1.582 0 2.864-.854 2.864-1.909 0-1.054-1.283-1.909-2.864-1.909-1.582 0-2.864.855-2.864 1.91 0 1.053 1.282 1.908 2.864 1.908zM6 10.5a1.5 1.5 0 11.001-3.001A1.5 1.5 0 016 10.5zm9 0a1.5 1.5 0 11.001-3.001A1.5 1.5 0 0115 10.5z"
				fill="currentColor"
			/>
			<path d="M33 12a5 5 0 11-10 0 5 5 0 0110 0z" fill="currentColor" />
			<path d="M26 16l-3 2 6-2m4-4a5 5 0 11-10 0 5 5 0 0110 0z" stroke="currentColor" />
		</svg>
	</IconContainer>
);
