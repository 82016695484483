import { ReactElement } from 'react';
import { FormikProps } from 'formik';
import { AnySchema, SchemaOf } from 'yup';
import {
	QuestionTreeStateHandlers,
	ExtraQuestionTreeItemProps,
} from '../QuestionTreeItem.types';
import { QuestionTreeTypes } from '../../QuestionTree.types';

export const QUESTION_IDENTIFIER = 'question';
export const QUESTION_FIELD_IDENTIFIER = 'question-field';
export type QuestionTreeValuePrimitive = undefined | string | number;
export type QuestionTreeValue =
	| QuestionTreeValuePrimitive
	| Array<QuestionTreeValuePrimitive>;

export interface QuestionFormProps {
	[QUESTION_IDENTIFIER]: QuestionTreeValue;
	[QUESTION_FIELD_IDENTIFIER]: QuestionTreeValue;
}

export interface QuestionCallbackProps {
	answerVal: QuestionTreeValue;
	questionId: ExtraQuestionTreeItemProps['id'];
}

interface InheritedProps
	extends QuestionTreeStateHandlers,
		Omit<ExtraQuestionTreeItemProps, 'variant'> {}

export interface QuestionFormComponentProps extends InheritedProps {
	disabled: boolean;
	schema: SchemaOf<QuestionFormProps> | AnySchema;
	value: QuestionTreeValue;
	callback(props: QuestionCallbackProps): Promise<boolean>;
	children: (props: FormikProps<QuestionFormProps>) => ReactElement;
}

export type QuestionField = {
	id: string;
	label: string;
	value: QuestionTreeValuePrimitive;
	type?: QuestionTreeTypes;
	callback(props: QuestionCallbackProps): Promise<boolean>;
	inlineError?: string;
	active?: boolean;
};
