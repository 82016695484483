import { FC } from 'react';
import { Text } from '@stashinvest/ui/dist/es/2';

import { IsAuto, AuthorEmail } from '../Comments';

interface CommentTitleProps {
	isAuto: IsAuto;
	authorEmail?: AuthorEmail;
}

export const CommentTitle: FC<CommentTitleProps> = ({ isAuto, authorEmail }) => (
	<Text.Label16>
		<Text.Label16 as="span" fontWeight="bold">
			{authorEmail ? authorEmail : 'Auto'}
		</Text.Label16>
		<span>{isAuto && !authorEmail ? ' comment' : ' said'} </span>
	</Text.Label16>
);
