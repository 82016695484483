import { OktaRuntimeConfig } from './okta';

declare global {
	interface Window {
		userhubConfig: RuntimeConfig;
	}
}

export interface RuntimeConfig {
	activeAdminUrl: string;
	debitPortalUrl: string;
	retoolPortalUrl: string;
	expGatewayUrl: string;
	okta: OktaRuntimeConfig;
	googleAnalyticsTag: string;
	ddClientToken: string;
	ddService: string;
	ddRumClientToken: string;
	ddRumApplicationId: string;
	sentryDsn: string;
	env: string;
	launchDarklyClientId: string;
	debug: boolean;
	error?: boolean;
}

const devConfig: RuntimeConfig = {
	activeAdminUrl: process.env.REACT_APP_AA_URL!,
	debitPortalUrl: process.env.REACT_APP_DEBIT_PORTAL_URL!,
	retoolPortalUrl: process.env.REACT_APP_RETOOL_PORTAL_URL!,
	expGatewayUrl: process.env.REACT_APP_EXP_GATEWAY_URL!,
	okta: {
		issuer: `https://${process.env.REACT_APP_ISSUER_URL}/oauth2/default`,
		clientId: process.env.REACT_APP_CLIENT_ID,
	},
	googleAnalyticsTag: 'UA-XXXXXXXX-XX',
	ddClientToken: '',
	ddService: '',
	ddRumApplicationId: '',
	ddRumClientToken: '',
	sentryDsn: '',
	env: 'dev',
	launchDarklyClientId: '5fbd34213cadb3095432e1c9',
	debug: false,
};

//The default config will cause an error to be shown and only contains the bare minimum to report errors to Sentry + DD
const fallBackConfig = {
	error: true,
	ddClientToken: 'pub1584328fbc0c31967f0c1dc6d679ce95',
	ddService: 'userhub',
	ddRumClientToken: 'pubd330f3158aca673e42912be3021d0233',
	ddRumApplicationId: '407a42e8-81f2-4576-a515-4a08eba704ca',
	sentryDsn: 'https://ac1b36add2d34d2b919bed04c685c037@o170962.ingest.sentry.io/5462331',
};

function initializeConfig() {
	const isDev = window.userhubConfig?.env === 'dev' ?? false;
	window.userhubConfig = isDev ? devConfig : window.userhubConfig || fallBackConfig;
	return window.userhubConfig;
}
export const config = initializeConfig();
