import styled from 'styled-components';

interface ExtendedDrawerOpenStateProps {
	isPrimaryOpen: boolean;
	isSecondaryOpen: boolean;
}

export const DrawerBody = styled.div<ExtendedDrawerOpenStateProps>`
	position: absolute;
	z-index: 4;
	top: 0;
	right: 0;
	min-height: 100vh;
	transform: ${({ isPrimaryOpen, isSecondaryOpen }) => {
		if (isPrimaryOpen && isSecondaryOpen) {
			return 'translateX(0px)';
		}

		if (isPrimaryOpen) {
			return 'translateX(500px)';
		}

		return 'translateX(1000px)';
	}};
	transition-property: transform;
	transition-duration: 0.4s;
	transition-timing-function: ease-out;
	pointer-events: all;
`;

interface DrawerPanelProps {
	isPrimary: boolean;
}

const PANEL_WIDTH = '500px';

export const DrawerPanel = styled.div<DrawerPanelProps>`
	position: relative;
	min-height: 100vh;
	width: ${PANEL_WIDTH};
	background-color: ${({ theme, isPrimary }) =>
		isPrimary ? theme.colors.bgSecondary : theme.colors.bgPrimary};
`;
