import z from 'zod';
import { AmountSchema } from '../common';

const BalanceChangeSchema = z.enum(['increase', 'decrease']);

export const TransactionBalanceHistorySchema = z.object({
	transactionId: z.string().uuid(),
	date: z.string().datetime(),
	description: z.string(),
	amount: AmountSchema,
	availableBalanceChange: BalanceChangeSchema.optional(),
	availableBalance: AmountSchema,
	totalBalanceChange: BalanceChangeSchema.optional(),
	totalBalance: AmountSchema,
	// not implemented yet
	changeAmount: AmountSchema.optional(),
});

export type TransactionBalanceHistory = z.infer<typeof TransactionBalanceHistorySchema>;

export interface GetTransactionBalanceHistoryEnvelope {
	transactions: Array<TransactionBalanceHistory>;
}
