import React, { FC } from 'react';
import { Flex } from '@stashinvest/ui/dist/es/2';

import { LinkVariant, LinkVariants } from '../LinkVariant';
import { TrackedLinkColors } from '../TrackedLink';
import { defaultBaseUrlConfig, StashApps } from '../../providers/EnvironmentProvider';

import { NavigationLinkProps } from '.';

const autoStashPaths = [
	'set-schedule',
	'round-ups',
	'smart-stash',
	'recurring-transfers',
];

const recurringTransactionsPaths = [
	'auto-invest',
	'round-ups',
	'smart-stash',
	'auto-add-cash',
];

export const NavigationLink: FC<React.PropsWithChildren<NavigationLinkProps>> = ({
	path,
	title,
	environment,
	onUserHub,
}) => {
	const currentURL = window.location.href;
	const bankPortalURL = defaultBaseUrlConfig[StashApps.BANK_PORTAL][environment];
	const legacyBankPortalURL =
		defaultBaseUrlConfig[StashApps.LEGACY_BANK_PORTAL][environment];

	const isOnAutoStash = autoStashPaths.some((path) => currentURL.includes(path));
	const isOnRecurringTransactions = recurringTransactionsPaths.some((path) =>
		currentURL.includes(path)
	);
	const pathMatcher: Record<string, boolean> = {
		Banking:
			(window.location.pathname.endsWith(path) && onUserHub) ||
			currentURL.startsWith(bankPortalURL) ||
			(currentURL.startsWith(legacyBankPortalURL) && !isOnRecurringTransactions),
		'Auto-Stash': currentURL.startsWith(legacyBankPortalURL) && isOnAutoStash,
		'Recurring transactions':
			currentURL.startsWith(legacyBankPortalURL) && isOnRecurringTransactions,
		Investing: currentURL.startsWith(path),
		Overview:
			currentURL.startsWith(path) ||
			(onUserHub && window.location.pathname.endsWith(path)),
	};

	const isActive = pathMatcher[title];

	return (
		<Flex
			height="100%"
			alignItems="center"
			{...(isActive ? { borderBottom: '2px solid', borderColor: 'iconSelected' } : {})}
			data-testid={`nav-link-${title.toLowerCase().replace(/\s/g, '-')}`}
		>
			<LinkVariant
				path={path}
				color={
					isActive ? TrackedLinkColors.textActionPrimary : TrackedLinkColors.textPrimary
				}
				px="12px"
				size="large"
				variant={LinkVariants.EXTERNAL}
				tracking={{
					page: 'all',
					component: 'navigation',
					label: title.toLowerCase(),
				}}
				hoverStyles={{ color: TrackedLinkColors.textPlaceholder }}
			>
				{title}
			</LinkVariant>
		</Flex>
	);
};
