import { config } from 'src/config/config';
import {
	InvestorApplication,
	PaginatedComment,
	StashworksUserStatusResponseBody,
	PatchUserProfilePayload,
	PatchUserProfileResponse,
	UserProfileOrContentOnlyUserProfile,
} from '@stashinvest/shared-types';
import { getErrorMessageFromErrorResponse } from 'src/utils/error';
import {
	UserSubscription,
	UserSubscriptionSchema,
} from '@stashinvest/shared-types/schemas/subscriptions';

export const fetchUserSubscription = async (
	userId: string,
	accessToken: string
): Promise<UserSubscription> => {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/${userId}/subscription`,
		{ headers: { Authorization: `Bearer ${accessToken}` } }
	);
	const responseBody = await response.json();
	return UserSubscriptionSchema.parse(responseBody);
};

export const fetchStashworksStatus = async (
	userId: string,
	accessToken: string
): Promise<StashworksUserStatusResponseBody> => {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/${userId}/stashworks/status`,
		{
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);
	const responseBody = await response.json();
	return responseBody;
};

export const fetchComments = async (
	opts: {
		userId: string;
		page: number;
		perPage: number;
	},
	accessToken: string
): Promise<PaginatedComment> => {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/${opts.userId}/comments?page=${opts.page}&perPage=${opts.perPage}`,
		{ headers: { Authorization: `Bearer ${accessToken}` } }
	);
	const responseBody = await response.json();

	if (!response.ok) {
		const errorMessage = getErrorMessageFromErrorResponse(
			responseBody,
			"Something went wrong while fetching the user's comments, please try again later"
		);
		return Promise.reject(errorMessage);
	}
	return responseBody;
};

export const fetchInvestorApplication = async (
	userId: string,
	accessToken: string
): Promise<InvestorApplication> => {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/${userId}/investorApplication`,
		{
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	if (!response.ok) {
		switch (response.status) {
			case 401:
				throw new Error('Unauthorized');
			case 403:
				throw new Error('User is either not found or content only');
			case 404:
				throw new Error("This user's investor profile was not found");
			default:
				// TODO: export the gateway error type from shared-types and passthrough the actual error message
				throw new Error(
					'Something went wrong while fetching the user’s investor profile. Please try again later'
				);
		}
	}

	const responseBody = await response.json();
	return responseBody as InvestorApplication;
};

export const getUserProfile = async (
	userId: string,
	accessToken: string
): Promise<UserProfileOrContentOnlyUserProfile> => {
	const response = await fetch(`${config.expGatewayUrl}/api/v1/users/${userId}/profile`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		},
	});

	const responseBody = await response.json();

	if (!response.ok) {
		const errorMessage = getErrorMessageFromErrorResponse(
			response,
			"Something went wrong while fetching the user's profile, please try again later"
		);
		return Promise.reject(errorMessage);
	}

	return responseBody;
};

export const patchUserProfile = async (
	userId: string,
	payload: PatchUserProfilePayload,
	accessToken: string
): Promise<PatchUserProfileResponse> => {
	const response = await fetch(`${config.expGatewayUrl}/api/v1/users/${userId}/profile`, {
		body: JSON.stringify(payload) as BodyInit,
		method: 'POST',
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		},
	});
	const responseBody = await response.json();
	if (!response.ok) {
		return Promise.reject(responseBody);
	}
	return responseBody;
};
