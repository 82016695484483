import React, { FC, useContext } from 'react';
import {
	EnvironmentContext,
	Environments,
	StashApps,
	defaultBaseUrlConfig,
} from '@stashinvest/shared-ui';
import { useParams } from 'react-router-dom';
import { UserDetailsContext } from 'src/providers/UserDetailsProvider';
import { useIsUserContentOnly } from 'src/hooks/useUserIsContentOnly';
import { Sidebar } from '../Sidebar';

type UserPageParams = { userId: string };

export interface SidebarProps {
	/**
	 * Sidebar links
	 */
	links: { title: string; url: string }[];
}
const linkTitles = {
	overview: 'Overview',
	accountDetails: 'Account Details',
	investorProfile: 'Investor Profile',
	accountVerification: 'Account Verification',
	subsManagement: 'Subscription Management',
	comms: 'Communications',
	loginActivity: 'Login Activity',
	promos: 'Promotions',
};

const contentOnlyRestrictedLinks = [
	// linkTitles.accountDetails, restricted by additional ssn logic
	linkTitles.investorProfile,
	linkTitles.comms,
	linkTitles.loginActivity,
];

export const UserPageSidebar: FC = () => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	// even though we can get contentOnly data from the UserDetailsContext
	// it takes a bit longer to load, which results in the links removal to be sluggish
	// so using a separate hook to remove most of them is a nicer solution
	const response = useIsUserContentOnly(userId);
	const isContentOnly = Boolean(response?.data?.isContentOnly);
	const { data } = useContext(UserDetailsContext);
	const notDisplayAccountDetails = Boolean(isContentOnly && !data?.profile.ssn);
	const { environment = Environments.PROD } = useContext(EnvironmentContext);
	if (!userId) {
		return null;
	}
	const links = buildSideBarLinksStructure(
		environment,
		userId,
		isContentOnly,
		notDisplayAccountDetails
	);

	return <Sidebar links={links} />;
};

export const buildSideBarLinksStructure = (
	environment: Environments,
	userId: string,
	isContentOnly: boolean,
	notDisplayAccountDetails: boolean
) => {
	const links = [
		{
			title: linkTitles.overview,
			url: `/id/${userId}`,
		},
		{
			title: linkTitles.accountDetails,
			url: `/id/${userId}/account-details`,
		},
		{
			title: linkTitles.investorProfile,
			url: `/id/${userId}/investor-profile`,
		},
		// TODO - add this link back in when we handle document requests in Q4
		// {
		//   title: linkTitles.accountVerification,
		//   url: `/id/${userId}/account-verification`,
		// },
		{
			title: linkTitles.subsManagement,
			url: `${
				defaultBaseUrlConfig[StashApps.ACTIVE_ADMIN][environment]
			}/admin/users/${userId}/subscriptions_management`,
		},

		{
			title: linkTitles.comms,
			url: `${
				defaultBaseUrlConfig[StashApps.ACTIVE_ADMIN][environment]
			}/admin/users/${userId}/delivery_failures`,
		},

		{
			title: linkTitles.loginActivity,
			url: `${
				defaultBaseUrlConfig[StashApps.ACTIVE_ADMIN][environment]
			}/admin/users/${userId}/login_activities`,
		},

		{
			title: linkTitles.promos,
			url: `${
				defaultBaseUrlConfig[StashApps.ACTIVE_ADMIN][environment]
			}/admin/users/${userId}/promotion_eligibilities`,
		},
	];

	if (isContentOnly) {
		let filteredLinks = links.filter(
			(link) => contentOnlyRestrictedLinks.indexOf(link.title) < 0
		);
		if (notDisplayAccountDetails) {
			filteredLinks = filteredLinks.filter(
				(link) => 'Account Details'.indexOf(link.title) < 0
			);
		}
		return filteredLinks;
	}

	return links;
};
