import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeModeContext, isDark } from '../../providers/ThemeModeProvider';

import { TooltipIcon } from '../../icons';
import { UnstyledButton } from '../UnstyledButton';
import { HiddenLabel } from '../HiddenLabel';
import { TooltipButtonProps, TooltipButtonContainerProps } from './Tooltip.types';

const TooltipButtonContainer = styled(UnstyledButton)`
	overflow: hidden;
	${({ isTag }: TooltipButtonContainerProps) =>
		isTag ? 'padding: 5px 0 5px 5px;' : 'padding: 5px;'}
`;

export const TooltipButton = React.forwardRef<
	HTMLButtonElement,
	React.PropsWithChildren<TooltipButtonProps>
>(({ inverted = false, isTag = false, children }, ref) => {
	const { mode } = useContext(ThemeModeContext);

	return (
		<>
			<TooltipButtonContainer isTag={isTag} ref={ref}>
				{children ? (
					children
				) : (
					<>
						<TooltipIcon
							size="19px"
							inverted={isDark(mode) ? !inverted : inverted}
							display="block"
						></TooltipIcon>
						<HiddenLabel>toggle tooltip</HiddenLabel>
					</>
				)}
			</TooltipButtonContainer>
		</>
	);
});
