import React, { FC, ReactNode, useEffect } from 'react';
import { Box } from '@stashinvest/ui';
import { PrimaryIconButton } from '@stashinvest/ui/dist/es/2';
import { LeftArrowIcon, RightArrowIcon } from '../../icons';
import { AnimatedContent, AnimatedItem } from './ContentSlider.styles';
import { useActiveItem } from './useActiveItem';
import { useScrollPosition } from './useScrollPosition';

export interface ContentSliderItems {
	id: string;
	body: ReactNode;
}

export interface ContentSliderProps {
	/** items we want to scroll through */
	items: ContentSliderItems[];
	onSlideChange?: (value: number) => void;
}

export const ContentSlider: FC<ContentSliderProps> = ({ items, onSlideChange }) => {
	const {
		activeId,
		activeIndex,
		handlePrev,
		handleNext,
		isPrevDisabled,
		isNextDisabled,
	} = useActiveItem(items);

	const { containerRef, scrollRef, scrollWidth, scrollLeft, itemWidth } =
		useScrollPosition(activeIndex, items.length);

	useEffect(() => {
		if (onSlideChange) {
			onSlideChange(activeIndex);
		}
	}, [activeIndex, onSlideChange]);

	const disabledStyle = { pointerEvents: 'none' };

	return (
		<Box position="relative" height="100%">
			<Box position="absolute" top="0" left="0px" zIndex="2" pt="10px">
				<PrimaryIconButton
					label="previous item"
					disabled={isPrevDisabled}
					style={isPrevDisabled ? disabledStyle : {}}
					onClick={handlePrev}
				>
					<LeftArrowIcon size={'24px'} />
				</PrimaryIconButton>
			</Box>
			<Box position="absolute" top="0" right="0px" zIndex="2" pt="10px">
				<PrimaryIconButton
					label="next item"
					disabled={isNextDisabled}
					style={isNextDisabled ? disabledStyle : {}}
					onClick={handleNext}
				>
					<RightArrowIcon size={'24px'} />
				</PrimaryIconButton>
			</Box>
			<Box width="100%" height="100%" overflow="hidden" ref={containerRef}>
				<AnimatedContent
					position="relative"
					height="100%"
					display={'flex'}
					visibility={scrollWidth > 0 ? 'visible' : 'hidden'}
					ref={scrollRef}
					width={scrollWidth || 'auto'}
					left={`-${scrollLeft}px`}
					data-testid="animated-container"
				>
					{items.map((item, index) => (
						<AnimatedItem
							key={item.id}
							height="100%"
							width={itemWidth || 'auto'}
							active={item.id === activeId}
							data-testid={`animated-item-${index}`}
						>
							{item.body}
						</AnimatedItem>
					))}
				</AnimatedContent>
			</Box>
		</Box>
	);
};
