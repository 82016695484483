import { FC, ReactElement } from 'react';
import { Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

export type FalseyPropProps = {
	property: string;
	value?: ReactElement;
	nullValue?: string;
};

export const FalseyProp: FC<FalseyPropProps> = ({
	property,
	value,
	nullValue = 'Unknown',
}) => {
	return (
		<Text.Label16 as="div" whiteSpace="nowrap">
			<Flex alignItems="center" pb="8px">
				{property}: {value || nullValue}
			</Flex>
		</Text.Label16>
	);
};
