/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BankAccountStatus {
	CLOSED = 'CLOSED',
	CLOSING = 'CLOSING',
	INACTIVE = 'INACTIVE',
	LOCKED = 'LOCKED',
	NORMAL = 'NORMAL',
	PENDING = 'PENDING',
	RESTRICTED = 'RESTRICTED',
	UNKNOWN = 'UNKNOWN',
}

export enum BankAccountStatusReason {
	ACCOUNT_BLOCK = 'ACCOUNT_BLOCK',
	ACCOUNT_ESCHEATED = 'ACCOUNT_ESCHEATED',
	BANK_INITIATED = 'BANK_INITIATED',
	BANK_INITIATED_CLOSED = 'BANK_INITIATED_CLOSED',
	CHARGED_OFF = 'CHARGED_OFF',
	CLOSED_WITH_REFUND = 'CLOSED_WITH_REFUND',
	CONFIRMED_ACCOUNT_TAKEOVER = 'CONFIRMED_ACCOUNT_TAKEOVER',
	CONFIRMED_FIRST_PARTY_FRAUD = 'CONFIRMED_FIRST_PARTY_FRAUD',
	CONFIRMED_FRAUD = 'CONFIRMED_FRAUD',
	CONFIRMED_IDENTITY_THEFT = 'CONFIRMED_IDENTITY_THEFT',
	CONFIRMED_OTHER_FRAUD = 'CONFIRMED_OTHER_FRAUD',
	CONFIRMED_THIRD_PARTY_FRAUD = 'CONFIRMED_THIRD_PARTY_FRAUD',
	CUSTOMER_INITIATED = 'CUSTOMER_INITIATED',
	CUSTOMER_INITIATED_CLOSE = 'CUSTOMER_INITIATED_CLOSE',
	CUSTOMER_INITIATED_CLOSED = 'CUSTOMER_INITIATED_CLOSED',
	CUSTOMER_INITIATED_SPEND_DOWN = 'CUSTOMER_INITIATED_SPEND_DOWN',
	CUSTOMER_REPORTED_IDENTITY_THEFT = 'CUSTOMER_REPORTED_IDENTITY_THEFT',
	DECEASED = 'DECEASED',
	ERRORED = 'ERRORED',
	ESCHEATED = 'ESCHEATED',
	GD_CLOSED_DECEASED = 'GD_CLOSED_DECEASED',
	HEALTHY = 'HEALTHY',
	IDENTITY_THEFT = 'IDENTITY_THEFT',
	INACTIVE = 'INACTIVE',
	INACTIVE_DORMANT = 'INACTIVE_DORMANT',
	INACTIVE_ZERO_BALANCE = 'INACTIVE_ZERO_BALANCE',
	LEGAL_RESTRICTION = 'LEGAL_RESTRICTION',
	LOST_STOLEN = 'LOST_STOLEN',
	MANUAL_LOCK = 'MANUAL_LOCK',
	NEGATIVE_BALANCE = 'NEGATIVE_BALANCE',
	NON_SPECIFIC_LEVY = 'NON_SPECIFIC_LEVY',
	OFAC = 'OFAC',
	OVERDRAWN = 'OVERDRAWN',
	POTENTIAL_ACCOUNT_TAKEOVER = 'POTENTIAL_ACCOUNT_TAKEOVER',
	POTENTIAL_FIRST_PARTY_FRAUD = 'POTENTIAL_FIRST_PARTY_FRAUD',
	POTENTIAL_FRAUD = 'POTENTIAL_FRAUD',
	POTENTIAL_IDENTITY_THEFT = 'POTENTIAL_IDENTITY_THEFT',
	POTENTIAL_OTHER_FRAUD = 'POTENTIAL_OTHER_FRAUD',
	POTENTIAL_THIRD_PARTY_FRAUD = 'POTENTIAL_THIRD_PARTY_FRAUD',
	REGISTRATION_FAILED = 'REGISTRATION_FAILED',
	REGISTRATION_NOT_COMPLETE = 'REGISTRATION_NOT_COMPLETE',
	RETURNED_MAIL = 'RETURNED_MAIL',
	SPEND_DOWN = 'SPEND_DOWN',
	STATUS_CHANGED_BY_CSR = 'STATUS_CHANGED_BY_CSR',
	UNDER_REVIEW = 'UNDER_REVIEW',
	UNKNOWN = 'UNKNOWN',
	UNSPECIFIED_CLOSE = 'UNSPECIFIED_CLOSE',
	VERIFICATION_NEEDED = 'VERIFICATION_NEEDED',
	WRITE_OFF = 'WRITE_OFF',
}

export enum BankingPartner {
	CORE = 'CORE',
	GREENDOT = 'GREENDOT',
}

export enum CurrencyCode {
	USD = 'USD',
}

export enum DocumentState {
	COMPLETE = 'COMPLETE',
	PENDING = 'PENDING',
}

export enum State {
	CANCELED = 'CANCELED',
	COMPLETE = 'COMPLETE',
	OUTSTANDING = 'OUTSTANDING',
}

export enum Type {
	GENERIC_DISPUTE_EVIDENCE = 'GENERIC_DISPUTE_EVIDENCE',
	GENERIC_IDENTITY_EVIDENCE = 'GENERIC_IDENTITY_EVIDENCE',
	PASSPORT = 'PASSPORT',
	PASSPORT_WITH_SELFIE = 'PASSPORT_WITH_SELFIE',
	PHOTO_ID = 'PHOTO_ID',
	PHOTO_ID_WITH_SELFIE = 'PHOTO_ID_WITH_SELFIE',
	SOCIAL_SECURITY_DOCUMENT = 'SOCIAL_SECURITY_DOCUMENT',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
