import { useState, useEffect, useRef } from 'react';
import { RefPropType } from '../../utils';

export const useOtherHeights = (containerHeight = 0) => {
	const titleRef = useRef<RefPropType>(null);
	const [titleHeight, setTitleHeight] = useState(0);
	const footerRef = useRef<RefPropType>(null);
	const [footerHeight, setFooterHeight] = useState(0);

	useEffect(() => {
		if (containerHeight > 0) {
			setTitleHeight(titleRef.current?.clientHeight || 0);
			setFooterHeight(footerRef.current?.clientHeight || 0);
		}
	}, [containerHeight]);

	return {
		titleRef,
		titleHeight,
		footerRef,
		footerHeight,
	};
};
