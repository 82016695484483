import React, { FC, ReactNode, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Flex } from '@stashinvest/ui';
import { CloseIcon } from '@stashinvest/ui/dist/es/2';

import {
	VisibilityContext,
	VisibilityContextProps,
} from '../../providers/VisibilityProvider';
import { useEscape } from '../../hooks/useEscape';
import {
	FullPageContainer,
	FullPageOverlay,
	ModalBody,
	ModalCloseBtn,
} from './Modal.styles';

let modalRoot = document.getElementById('modal-root');
if (!modalRoot) {
	modalRoot = document.createElement('div');
	modalRoot.setAttribute('id', 'modal-root');
	document.body.appendChild(modalRoot);
}

export type OnClose = VoidFunction;

export interface ModalProps {
	height?: string;
	width?: string;
	children: ReactNode | ((value: VisibilityContextProps) => ReactNode);
	isFullscreen?: boolean;
	onClose?: OnClose;
	overrideClose?: (closeModal: VoidFunction) => void;
	showCloseButton?: boolean;
}

export const Modal: FC<ModalProps> = ({
	children,
	width,
	height,
	onClose,
	overrideClose,
	isFullscreen,
	showCloseButton = true,
}) => {
	const el = useRef(document.createElement('div'));

	useEffect(() => {
		modalRoot?.appendChild(el.current);
		return () => {
			modalRoot?.removeChild(el.current); // eslint-disable-line
		};
	}, []);

	return ReactDOM.createPortal(
		<VisibilityContext.Consumer>
			{({ open, setOpen }) => (
				<ModalComponent
					height={height}
					width={width}
					setOpen={setOpen}
					open={open}
					onClose={onClose}
					overrideClose={overrideClose}
					isFullscreen={isFullscreen}
					showCloseButton={showCloseButton}
				>
					{children}
				</ModalComponent>
			)}
		</VisibilityContext.Consumer>,
		el.current
	);
};

interface ModalComponentProps extends Partial<VisibilityContextProps>, ModalProps {}

export const ModalComponent = ({
	open = true,
	setOpen,
	width,
	height,
	children,
	onClose,
	overrideClose,
	isFullscreen,
	showCloseButton = true,
}: ModalComponentProps) => {
	const focusedEl = useRef<HTMLButtonElement>(null);

	const closeModal = () => {
		setOpen?.(false);
		onClose?.();
	};

	const modalClosing = () => {
		if (!open) {
			return;
		}
		if (overrideClose) {
			overrideClose(closeModal);
		} else {
			closeModal();
		}
	};

	useEscape(modalClosing);

	useEffect(() => {
		if (open) {
			focusedEl.current?.focus();
		}
	}, [open]);

	return (
		<FullPageContainer isOpen={open} data-testid="modal-container">
			<FullPageOverlay data-testid="modal-overlay" isOpen={open} onClick={modalClosing} />
			<Flex alignItems="center" height="100%">
				<ModalBody
					isOpen={open}
					height={height}
					width={width}
					data-testid="modal-body"
					isFullscreen={isFullscreen}
				>
					{showCloseButton && (
						<ModalCloseBtn
							onClick={modalClosing}
							aria-label="close modal"
							ref={focusedEl}
						>
							<CloseIcon size="1em" mt="3px" ml="-3px" mr="10px" />
						</ModalCloseBtn>
					)}
					{typeof children === 'function' && setOpen
						? children({
								open,
								setOpen,
						  })
						: children}
				</ModalBody>
			</Flex>
		</FullPageContainer>
	);
};
