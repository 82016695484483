export const convertBoolStringToBool = (input: string) => {
	switch (input?.toLowerCase().trim()) {
		case 'yes':
		case 'true':
			return true;
		case 'no':
		case 'false':
			return false;
		default:
			return false;
	}
};
