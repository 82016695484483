import React, { FC, useContext, useState } from 'react';
import { Text, Flex, Box } from '@stashinvest/ui/dist/es/2';
import { Logo } from '@stashinvest/ui';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
	EnvironmentContext,
	Environments,
	StashApps,
	defaultBaseUrlConfig,
} from '../../providers/EnvironmentProvider';
import { ThemeModeContext, isDark } from '../../providers/ThemeModeProvider';
import { useScrollPosition } from '../../hooks';
import { TrackedLink } from '../TrackedLink';
import { SearchForm } from '../SearchForm';
import { NavigationProps } from './Navigation.types';
import { NavigationLink } from './NavigationLink';

const NavContainer = styled(Box).attrs(() => ({ as: 'nav' }))`
	height: 75px;
	position: sticky;
	top: 0;
	z-index: 4;
  background-color: ${({ theme, bgColor }) =>
		theme.colors.hasOwnProperty(bgColor) ? theme.colors[bgColor] : bgColor};
	box-shadow: ${({ applyBoxShadow, isDark }) => {
		const primaryShadowColor = isDark ? 'rgb(0 0 0)' : 'rgb(0 0 0 / 16%)';
		const secondaryShadowColor = isDark ? 'rgb(0 0 0 / 33%)' : 'rgb(0 0 0 / 8%)';

		return applyBoxShadow
			? `0 1px 2px 0 ${primaryShadowColor}, 0 1px 4px 0 ${secondaryShadowColor}`
			: '';
	}}};
`;

// this attempts to match the size of the font-style/element used to display names to reduce content shift
// it assumes an average name length of ~13 characters
const NAME_PLACEHOLDER_SIZE = '145px';

export const buildNavStructure = (
	environment: Environments,
	userId: string | undefined,
	onUserHub: boolean
) => {
	if (!userId) {
		return [];
	}
	const basePath = onUserHub
		? ''
		: `${defaultBaseUrlConfig[StashApps.USER_HUB][environment]}/user`;

	return [
		{
			title: 'Overview',
			url: `${basePath}/id/${userId}`,
		},
		{
			title: 'Investing',
			url: `${
				defaultBaseUrlConfig[StashApps.ACTIVE_ADMIN][environment]
			}/admin/users/${userId}`,
		},
		{
			title: 'Banking',
			url: `${basePath}/bank/${userId}`,
		},
		{
			title: 'Recurring transactions',
			url: `${
				defaultBaseUrlConfig[StashApps.LEGACY_BANK_PORTAL][environment]
			}/users/${userId}/auto-invest`,
		},
	];
};

export const Navigation: FC<React.PropsWithChildren<NavigationProps>> = ({
	name,
	userId,
	showSearchBox = false,
	children,
	onUserHub = false,
	containerStyles = {
		maxWidth: '1440px',
		mx: 'auto',
		px: '48px',
		backgroundColor: 'bgPrimary',
	},
	clearResponse,
	clearSearchTerms,
}) => {
	const [hasScrolled, setHasScrolled] = useState(false);
	const { mode } = useContext(ThemeModeContext);
	const { environment = Environments.EDGE } = useContext(EnvironmentContext);
	const nav = buildNavStructure(environment, userId, onUserHub);
	const linkProps = onUserHub
		? { to: '/' }
		: { href: defaultBaseUrlConfig[StashApps.USER_HUB][environment] };

	useScrollPosition({
		effect: ({ currPos }) => {
			const scrolled = currPos.y > 0;
			if (scrolled !== hasScrolled) setHasScrolled(scrolled);
		},
		deps: [hasScrolled],
		useWindow: true,
		throttleInterval: 200,
	});

	return (
		<>
			<NavContainer
				applyBoxShadow={hasScrolled}
				isDark={isDark(mode)}
				bgColor={containerStyles.backgroundColor}
			>
				<Flex
					height="100%"
					flexDirection="row"
					alignItems="center"
					data-testid="nav-flex-container"
					{...containerStyles}
				>
					<Box {...(name ? {} : { mr: NAME_PLACEHOLDER_SIZE })}>
						<TrackedLink
							{...linkProps}
							tracking={{
								page: 'all',
								component: 'navigation',
								label: 'stash-logo',
							}}
						>
							<Logo height="14px" />
							<Text.Label16
								data-testid="name-tbd"
								mt={4}
								fontWeight="medium"
								color="textSecondary"
							>
								Atlas
							</Text.Label16>
						</TrackedLink>
					</Box>
					{name ? (
						<Flex
							px="10px"
							ml="23px"
							mr="10px"
							bg="shadowPrimary"
							height="100%"
							alignItems="center"
							flexShrink="0"
							data-testid="name-text"
						>
							<Text.Label14 color="textSecondary" pr="5px" pt="2px">
								{name}
							</Text.Label14>
						</Flex>
					) : null}
					{nav.map(({ url, title }) => (
						<NavigationLink
							key={url}
							path={url}
							title={title}
							environment={environment}
							onUserHub={onUserHub}
						/>
					))}
					{showSearchBox ? (
						<SearchForm
							environment={environment}
							onUserHub={onUserHub}
							clearResponse={clearResponse}
							clearSearchTerms={clearSearchTerms}
						/>
					) : null}
				</Flex>
			</NavContainer>
			{children}
		</>
	);
};
