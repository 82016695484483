import React, { FC } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { FeatureFlagWrapperProps } from './FeatureFlagWrapper.types';

// NOTE: Ensure the flag you want to request is set when creating
// the FeatureFlagProvider - the createFeatureFlagProvider function
// takes a flagsToRequest object which must contain the flag you want
// to evaluate.

export const FeatureFlagWrapperComponent: FC<
	React.PropsWithChildren<FeatureFlagWrapperProps>
> = ({ flags, flag, showIfFalsy = false, children }) => {
	const showFeature = showIfFalsy
		? !Boolean(flags && flags[flag])
		: Boolean(flags && flags[flag]);
	return <>{showFeature ? children : null}</>;
};

export const FeatureFlagWrapper = withLDConsumer()(FeatureFlagWrapperComponent);
