export enum ValidCurrency {
	'USD' = 'USD',
}

export interface FormattedCurrencyProps {
	/**
	 * Currency to render from ValidCurrency enum
	 * @default ValidCurrency.USD
	 */
	currency?: ValidCurrency;
	/**
	 * Enables font color for positive or negative values
	 */
	enableColors?: boolean;
	/**
	 * Amount, price, cost or value to render
	 */
	value?: string | number;
	/**
	 * What to pass when the timestamp is undefined
	 * @default "'Empty'"
	 */
	nullValue?: string;
	/**
	 * Sets the text.Label value
	 */
	textType?: string;
}
