import React, { FC, useState } from 'react';
import {
	ChoiceGroup,
	Box,
	ChoicePad,
	FormikChoicePadRadio,
	FormikCheckbox,
	ChoicePadRadio,
} from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { object as yobj, string as ystr, array as yarr } from 'yup';
import {
	QuestionForm,
	QUESTION_FIELD_IDENTIFIER,
	QUESTION_IDENTIFIER,
	ErrorMessage,
	QuestionTreeValuePrimitive,
} from '../QuestionForm';
import { QuestionTreeTypes } from '../../QuestionTree.types';
import { QuestionTreeContext } from '../../../../providers/QuestionTreeProvider/QuestionTreeProvider';
import { QuestionWithOptionsProps } from './QuestionWithOptions.types';
import { RadioPadWithNumber } from './RadioPadWithNumber';

const RadioPadSchema = yobj().shape({
	[QUESTION_IDENTIFIER]: ystr().required('Required'),
});

const RadioPadWithNumberSchema = yobj().shape({
	[QUESTION_IDENTIFIER]: ystr().required('Required'),
	[QUESTION_FIELD_IDENTIFIER]: ystr().required('Required'),
});

const CheckboxSchema = yobj().shape({
	[QUESTION_IDENTIFIER]: yarr().of(ystr()).min(1, 'Required').required('Required'),
});

export const QuestionWithOptions: FC<
	React.PropsWithChildren<QuestionWithOptionsProps>
> = ({
	variant = QuestionTreeTypes.CHOICE_PAD,
	fields,
	handleNext,
	activateCurrent,
	id,
	disabled,
	dynamicList,
	buttonLabel,
}) => {
	const [isTextOpen, setIsTextOpen] = useState(false);
	const isDisabled = Boolean(disabled);
	const canHandleNext = !dynamicList && handleNext;
	const hasRadioPadWithNumber = fields.find(
		(field) => field.type === QuestionTreeTypes.RADIO_PAD_WITH_NUMBER
	);

	if (variant === QuestionTreeTypes.CHOICE_PAD) {
		return (
			<Box width="100%" maxWidth="280px">
				<QuestionTreeContext.Consumer>
					{({ setLoading, setError }) => (
						<ChoiceGroup cols={1} hideLegend>
							{fields.map((option) => (
								<ChoicePad
									disabled={isDisabled}
									name={QUESTION_IDENTIFIER}
									key={option.id}
									label={option.label}
									inputPosition="left"
									checked={option.active}
									onClick={async () => {
										setLoading(true);
										activateCurrent(id);

										try {
											await option.callback({
												answerVal: option.value,
												questionId: id,
											});
											canHandleNext && handleNext!(id);
											setLoading(false);
										} catch (error) {
											setLoading(false);
											setError(true);
										}
									}}
									value={option.value || false}
								/>
							))}
						</ChoiceGroup>
					)}
				</QuestionTreeContext.Consumer>
			</Box>
		);
	}

	if (variant === QuestionTreeTypes.RADIO_PAD && !hasRadioPadWithNumber) {
		return (
			<Box maxWidth="500px">
				<ChoiceGroup cols={1} hideLegend>
					<QuestionForm
						disabled={isDisabled}
						schema={RadioPadSchema}
						callback={fields[0].callback}
						id={id}
						handleNext={handleNext}
						dynamicList={dynamicList}
						activateCurrent={activateCurrent}
						value={fields.find((f) => f.active)?.value || undefined}
						buttonLabel={buttonLabel}
					>
						{() => (
							<Box pb="15px">
								{fields.map((option) => (
									<FormikChoicePadRadio
										key={option.id}
										disabled={isDisabled}
										name={QUESTION_IDENTIFIER}
										inputPosition="left"
										label={<Text.Label18 pl="15px">{option.label}</Text.Label18>}
										value={option.value}
									/>
								))}
							</Box>
						)}
					</QuestionForm>
				</ChoiceGroup>
			</Box>
		);
	}

	if (variant === QuestionTreeTypes.RADIO_PAD && hasRadioPadWithNumber) {
		return (
			<Box maxWidth="500px">
				<ChoiceGroup cols={1} hideLegend>
					<QuestionForm
						disabled={isDisabled}
						schema={RadioPadWithNumberSchema}
						callback={fields[0].callback}
						id={id}
						handleNext={handleNext}
						dynamicList={dynamicList}
						activateCurrent={activateCurrent}
						value={fields[0].value}
						buttonLabel={buttonLabel}
					>
						{({ values, errors, setFieldValue }) => (
							<Box pb="15px">
								{fields.map((option) => {
									if (option.type === QuestionTreeTypes.RADIO_PAD_WITH_NUMBER) {
										return (
											<RadioPadWithNumber
												disabled={isDisabled}
												key={option.id}
												label={option.label}
												values={values}
												errors={errors}
												setFieldValue={setFieldValue}
												isOpen={isTextOpen}
												setIsOpen={setIsTextOpen}
											/>
										);
									}

									return (
										<ChoicePadRadio
											key={option.id}
											disabled={isDisabled}
											name={QUESTION_IDENTIFIER}
											label={<Text.Label18 pl="15px">{option.label}</Text.Label18>}
											value={option.value}
											checked={!isTextOpen}
											inputPosition="left"
											onChange={() => {
												setIsTextOpen(false);
												setFieldValue(QUESTION_IDENTIFIER, option.value);
												setFieldValue(QUESTION_FIELD_IDENTIFIER, option.value);
											}}
										/>
									);
								})}
								{fields[0].inlineError ? (
									<ErrorMessage>{fields[0].inlineError}</ErrorMessage>
								) : null}
							</Box>
						)}
					</QuestionForm>
				</ChoiceGroup>
			</Box>
		);
	}

	if (variant === QuestionTreeTypes.CHECKBOX) {
		return (
			<Box maxWidth="500px">
				<ChoiceGroup cols={1} hideLegend>
					<QuestionForm
						disabled={isDisabled}
						schema={CheckboxSchema}
						callback={fields[0].callback}
						id={id}
						handleNext={handleNext}
						dynamicList={dynamicList}
						activateCurrent={activateCurrent}
						value={fields.reduce(
							(val, f) => (f.active ? [...val, f.value] : val),
							[] as Array<QuestionTreeValuePrimitive>
						)}
						buttonLabel={buttonLabel}
					>
						{() => (
							<Box pb="15px">
								{fields.map((option) => (
									<FormikCheckbox
										key={option.id}
										name={QUESTION_IDENTIFIER}
										value={option.value}
										disabled={isDisabled}
									>
										{option.label}
									</FormikCheckbox>
								))}
							</Box>
						)}
					</QuestionForm>
				</ChoiceGroup>
			</Box>
		);
	}

	return null;
};
