export const investAccountStates: Record<string, { title: string; type: string }> = {
	UNKNOWN_STATE: {
		title: 'Unknown',
		type: 'danger',
	},
	INCOMPLETE: {
		title: 'Incomplete',
		type: 'danger',
	},
	QUEUED_FOR_APEX: {
		title: 'Queued for APEX',
		type: 'info',
	},
	ERRORED: {
		title: 'Errored',
		type: 'danger',
	},
	PENDING: {
		title: 'Pending',
		type: 'info',
	},
	BACK_OFFICE: {
		title: 'Back Office',
		type: 'info',
	},
	IMPEDED: {
		title: 'Impeded',
		type: 'danger',
	},
	INDETERMINATE: {
		title: 'Indeterminate',
		type: 'danger',
	},
	UNSUPPORTED: {
		title: 'Unsupported',
		type: 'danger',
	},
	COMPLETE: {
		title: 'Complete',
		type: 'success',
	},
	REJECTED: {
		title: 'Rejected',
		type: 'danger',
	},
	IN_CLOSING_PROCESS: {
		title: 'In Closing',
		type: 'info',
	},
	CLOSED: {
		title: 'Closed',
		type: 'danger',
	},
	INACTIVE: {
		title: 'Inactive',
		type: 'info',
	},
	IN_ACTIVATION_PROCESS: {
		title: 'In Activation',
		type: 'info',
	},
	IN_REOPEN_PROCESS: {
		title: 'In Reopen Process',
		type: 'info',
	},
};
