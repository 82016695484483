import { z } from 'zod';
import { dateStringToDate, luxonDateFormat } from '@stashinvest/shared-ui';
import { convertBoolStringToBool } from '../convertBoolStringToBool';

export const formErrorMessage = {
	required: 'This field is required',
	invalidInput: {
		default: 'This input does not match the expected format',
		phoneNumber: 'Please enter a valid phone number',
		email: 'Please enter a valid email',
		date: 'Please enter a valid date',
		zipCode: 'Please enter a valid zip code',
		name: 'Name must not contain numbers or special characters',
	},
} as const;

const commaDelimitedWordsRegex = /^(?:[a-zA-Z ]+,)*[a-zA-Z ]+$/;
const boolStringRegex = /^(yes)|(no)|(false)|(true)$/i;
const phoneNumberRegex = /^(\+?0?1\s?)?[\s.-]?\(?(\d{3})\)?[\s.-]?(\d{3})[\s.-]?(\d{4})$/;
const dateRegex = {
	'MM/DD/YYYY': /^\d{2}\/\d{2}\/\d{4}$/,
	'YYYY-MM-DD': /^\d{4}-\d{2}-\d{2}$/,
};
const zipCodeRegex = /^\d{5}(-\d{4})?$/;

const nameRegex = /^[A-Za-z\-\s]*$/;

export const regexes = {
	commaDelimitedWords: commaDelimitedWordsRegex,
	boolString: boolStringRegex,
	phoneNumber: phoneNumberRegex,
	name: nameRegex,
} as const;

export const stripInternationalCodeAndSpecialChars = (phoneNumber: string) => {
	const number = phoneNumber.match(phoneNumberRegex);
	return [number?.[2], number?.[3], number?.[4]].join('');
};

const commaDelimitedStringToArray = z
	.string()
	.regex(commaDelimitedWordsRegex, { message: formErrorMessage.invalidInput.default })
	.transform((value) =>
		value
			.split(',')
			.map((value) => value.trim())
			.filter((value) => value !== '')
	)
	.or(z.literal(''))
	.transform((value) => (value === '' ? undefined : value));

const boolStringToBool = z.coerce
	.string()
	.regex(boolStringRegex)
	.transform((value) => convertBoolStringToBool(value));

const phoneNumber = z
	.string({ required_error: formErrorMessage.required })
	.regex(phoneNumberRegex, { message: formErrorMessage.invalidInput.phoneNumber })
	.transform(stripInternationalCodeAndSpecialChars);

const email = z.string().email(formErrorMessage.invalidInput.email);

const emptyStringToUndefined = z.coerce
	.string()
	.transform((value) => (value === '' ? undefined : value));

const dateStringToUtcDate = z
	.string({ required_error: formErrorMessage.required })
	.regex(dateRegex['MM/DD/YYYY'], formErrorMessage.invalidInput.date)
	.transform((input) => dateStringToDate(input, luxonDateFormat['MM/DD/YYYY']));

const isoDateStringToUtcDate = z
	.string({ required_error: formErrorMessage.required })
	.regex(dateRegex['YYYY-MM-DD'], formErrorMessage.invalidInput.date)
	.transform((input) => dateStringToDate(input, luxonDateFormat['YYYY-MM-DD']));

const zipCode = z
	.string({ required_error: formErrorMessage.required })
	.regex(zipCodeRegex, formErrorMessage.invalidInput.zipCode);

const name = z
	.string({ required_error: formErrorMessage.required })
	.regex(nameRegex, formErrorMessage.invalidInput.name);

export const schemas = {
	commaDelimitedStringToArray,
	boolStringToBool,
	phoneNumber,
	emptyStringToUndefined,
	email,
	dateStringToUtcDate,
	isoDateStringToUtcDate,
	zipCode,
	name,
} as const;
