import styled from 'styled-components';
import { Flex, Box } from '@stashinvest/ui/dist/es/2';

export const ScrollContainer = styled(Flex)`
	overflow-y: scroll;
	position: relative;
`;

export const BackButton = styled(Box)`
	position: absolute;
	left: 20px;
	bottom: 20px;
`;

export const QuestionDivider = styled(Box)`
	${({ theme, index }) =>
		index !== 0 ? `border-top: 1px solid ${theme.colors.divider};` : ``}
`;

export const StateContainer = styled(Box)`
	position: sticky;
	top: 50%;
	left: 50%;
	width: 100%;
	z-index: 10;
	transform: translateY(-50%);
`;
