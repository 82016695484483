import { FC } from 'react';
import { FormikProps } from 'formik';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import { FormikTextField, FormikSelect, Flex, Box } from '@stashinvest/ui/dist/es/2';
import {
	dateObjectToFormat,
	getFurthestValidDOBForAge,
	getSoonestValidDOBForAge,
} from '@stashinvest/shared-ui';
import {
	PatchUserProfileSchema,
	UserProfile,
	PhoneNumberTypes,
} from '@stashinvest/shared-types/userProfile';

import { useUpdateUserProfile } from 'src/hooks/useUserProfileV2';
import {
	schemas,
	stripInternationalCodeAndSpecialChars,
} from 'src/utils/form/validation';
import { useToast } from 'src/hooks/useToast';
import { ModalForm } from 'src/components/ModalForm';

type UserPageParams = { userId: string };

const patchPersonalDetailsSchema = z.object({
	firstName: z.pipeline(schemas.name, PatchUserProfileSchema.shape.firstName),
	lastName: z.pipeline(schemas.name, PatchUserProfileSchema.shape.lastName),
	dateOfBirth: z.pipeline(
		schemas.dateStringToUtcDate.pipe(
			z
				.date()
				.min(getFurthestValidDOBForAge(113), 'The customers age cannot be above 113')
				.max(getSoonestValidDOBForAge(18), 'The customers age cannot be below 18')
		),
		PatchUserProfileSchema.shape.dateOfBirth
	),
	email: z.pipeline(schemas.email, PatchUserProfileSchema.shape.email),
	phoneNumber: z.pipeline(schemas.phoneNumber, PatchUserProfileSchema.shape.phoneNumber),
	phoneNumberType: z.pipeline(
		schemas.emptyStringToUndefined,
		PatchUserProfileSchema.shape.phoneNumberType
	),
});

type PatchUserProfileInfoPayload = z.infer<typeof patchPersonalDetailsSchema>;
type FormValues = Partial<z.input<typeof patchPersonalDetailsSchema>>;

const renderFormFields = (_formikProps: FormikProps<FormValues>) => {
	return (
		<>
			<Box width="100%">
				<FormikTextField label="First Name" name="firstName" />
			</Box>
			<Box width="100%">
				<FormikTextField label="Last Name" name="lastName" />
			</Box>
			<Box width="100%">
				<FormikTextField
					mask="date"
					label="DOB"
					name="dateOfBirth"
					placeholder="MM/DD/YYYY"
					caption="Enter the date in the MM/DD/YYYY format"
				/>
			</Box>
			<Box width="100%">
				<FormikTextField label="Email" name="email" />
			</Box>
			<Flex width="100%" justifyContent="space-between" alignItems="baseline">
				<Box width="65%">
					<FormikTextField label="Phone" name="phoneNumber" mask="phoneNumber" />
				</Box>
				<Box width="30%">
					<FormikSelect name="phoneNumberType" label="Phone type">
						{PhoneNumberTypes.options.map((phoneType) => (
							<option key={phoneType} label={phoneType} value={phoneType}>
								{phoneType}
							</option>
						))}
					</FormikSelect>
				</Box>
			</Flex>
		</>
	);
};

interface EditPersonalDetailsFormProps {
	userProfile: UserProfile;
}

export const EditPersonalDetailsForm: FC<EditPersonalDetailsFormProps> = ({
	userProfile,
}) => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	const { setToast } = useToast();
	const onUserProfileUpdateSuccess = () => {
		setToast({ message: 'Personal Details updated' });
	};
	const updateUserProfile = useUpdateUserProfile(userId, {
		onSuccess: onUserProfileUpdateSuccess,
	});

	const handleSubmit = async (values: Partial<PatchUserProfileInfoPayload>) => {
		await updateUserProfile(values);
	};

	const initialValues = {
		...userProfile.info,
		firstName: userProfile.profile.firstName,
		lastName: userProfile.profile.lastName,
		dateOfBirth: userProfile.profile.dateOfBirth
			? dateObjectToFormat(new Date(userProfile.profile.dateOfBirth))
			: undefined,
		phoneNumber: stripInternationalCodeAndSpecialChars(
			userProfile.info.phoneNumber ?? ''
		),
		phoneNumberType:
			// the placeholder/empty option in the select field has a value of "", convert null to "" to select the empty option
			userProfile.info.phoneNumberType ? userProfile.info.phoneNumberType : '',
	};

	return (
		<ModalForm
			title="Edit Personal Details"
			initialValues={initialValues}
			validationSchema={patchPersonalDetailsSchema}
			handleSubmit={handleSubmit}
			renderContent={renderFormFields}
		/>
	);
};
