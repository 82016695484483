import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const LeftArrowIcon: FC<React.PropsWithChildren<IconProps>> = (props) => (
	<IconContainer {...props}>
		<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.75 10.75l7-7L12 2 2 12l10 10 1.75-1.75-7-7H22v-2.5H6.75z"
				fill="currentColor"
			/>
		</svg>
	</IconContainer>
);
