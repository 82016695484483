import React, { FC, ReactNode } from 'react';

import { EditButton } from '../EditButton';
import { ModalOpener, OpenModalFn } from './ModalOpener';

const renderEditButton = (label?: string) => (openModal: OpenModalFn) => {
	return <EditButton onClick={openModal} label={label} />;
};

interface EditButtonModalOpenerProps {
	children: ReactNode;
	label?: string;
}

export const EditButtonModalOpener: FC<EditButtonModalOpenerProps> = ({
	children,
	label,
}) => {
	return <ModalOpener renderOpenButton={renderEditButton(label)}>{children}</ModalOpener>;
};
