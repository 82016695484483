import { OktaAuthOptions, OktaAuth } from '@okta/okta-auth-js';
import { config } from '../config';

export interface OktaRuntimeConfig {
	issuer: string;
	clientId: string | undefined;
}

export function getOktaConfig(): OktaAuthOptions {
	const runtimeConf = config.okta;

	return {
		...runtimeConf,
		scopes: ['openid', 'email', 'groups'],
		redirectUri: window.location.origin + '/user/auth/callback',
		pkce: true,
	};
}

export const oktaAuth = new OktaAuth(getOktaConfig());
