import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from '@stashinvest/ui/dist/es/2';

export const PageLink = styled.button`
	background: none;
	padding: 0 5px;

	:focus {
		text-decoration: underline;
	}
`;

interface PaginationItemProps {
	page: number;
	isActive: boolean;
	onClick(page: number): void;
}

export const PaginationItem: FC<React.PropsWithChildren<PaginationItemProps>> = ({
	page,
	isActive,
	onClick,
}) => (
	<PageLink onClick={() => onClick(page)}>
		<Text.Label14 fontWeight={isActive ? 'bold' : 'default'}>
			{isActive ? 'Page ' : ''}
			{page}
		</Text.Label14>
	</PageLink>
);
