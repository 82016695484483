import React, { FC, useRef } from 'react';
import { Box, Flex, SecondaryButton, Loader } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { useRotation } from '../../hooks';
import { WrapperTrackingProps } from '../../utils/analytics';
import { DownloadableButton } from '../DownloadableButton';
import { AnimatedImage, AnimatedButtonContainer } from './DocumentViewer.styles';
import { DocumentToView } from './DocumentViewerBody';
import { useOtherHeights } from './useOtherHeights';
import { useImageHeight } from './useImageHeight';

interface DocumentViewerItemProps extends DocumentToView {
	containerDimensions: { width: number; height: number };
	canDownloadDocs: boolean;
	tracking: WrapperTrackingProps;
	disableDownload: boolean;
}

export const warningText =
	'This image contains sensitive information, please do not screenshot, download or copy this image.';

export const DocumentViewerItem: FC<DocumentViewerItemProps> = ({
	id,
	title,
	type,
	image,
	unavailableReason,
	containerDimensions,
	canDownloadDocs,
	tracking,
	mimeType,
	disableDownload = true,
}) => {
	const { rotation, handleRotate } = useRotation();
	const imageRef = useRef<HTMLImageElement>(null);
	const { titleRef, titleHeight, footerRef, footerHeight } = useOtherHeights(
		containerDimensions.height
	);
	const isImageRotated = rotation % 180 !== 0;
	const { loadedImg, newHeight, newWidth } = useImageHeight({
		containerDimensions,
		titleHeight,
		footerHeight,
		image,
		isImageRotated,
		imageRef,
	});
	const imgProps = disableDownload
		? {
				onContextMenu: (event: React.MouseEvent) => {
					event.preventDefault();
				},
				onMouseDown: (event: React.MouseEvent) => {
					event.preventDefault();
				},
		  }
		: {};
	const imageLoadingError = Boolean(unavailableReason) && !image;
	const showDownloadButton = canDownloadDocs && image && mimeType;

	return (
		<Flex flexDirection="column" height="100%">
			<Box ref={titleRef} pb="20px" position="relative" zIndex="1">
				<Text.Label18 fontWeight="bold" pt="10px" align="center">
					{title}
				</Text.Label18>
				{type && <Text.Label16 align="center">({type})</Text.Label16>}
			</Box>
			<Flex
				justifyContent="center"
				alignItems="center"
				flexGrow="1"
				maxHeight={newHeight ?? newWidth}
				maxWidth={newWidth}
				margin="auto"
				data-testid="image-container"
			>
				{imageLoadingError ? (
					<Text.Body18 align="center">{unavailableReason}</Text.Body18>
				) : loadedImg ? (
					<AnimatedImage
						ref={imageRef}
						src={image}
						alt={title}
						rotate={rotation}
						{...imgProps}
					/>
				) : (
					<Flex alignItems="center" height="100%">
						<Loader />
					</Flex>
				)}
			</Flex>
			<AnimatedButtonContainer
				ref={footerRef}
				loaded={loadedImg && !imageLoadingError}
				justifyContent="space-between"
				pt="20px"
				pb="5px"
			>
				{showDownloadButton ? (
					<DownloadableButton
						buttonText="Download"
						fileName={id}
						fileURL={image}
						tracking={tracking}
						mimeType={mimeType}
					/>
				) : (
					<Box width="260px" word-break="break-word">
						<Text.Label12>{warningText} </Text.Label12>
					</Box>
				)}
				<SecondaryButton size="medium" width="200px" mr="10px" onClick={handleRotate}>
					Rotate
				</SecondaryButton>
			</AnimatedButtonContainer>
		</Flex>
	);
};
