import { FC } from 'react';
import styled from 'styled-components';
import { Box } from '@stashinvest/ui';
import { RobotIcon, UserCommentIcon } from '@stashinvest/shared-ui';

import { IsAuto } from '../Comments';

const IconContainer = styled.div`
	position: absolute;
	top: -5px;
	left: 0;
`;

interface CommentIconProps {
	isAuto: IsAuto;
}

export const CommentIcon: FC<CommentIconProps> = ({ isAuto }) => (
	<IconContainer>
		{isAuto ? (
			<Box mt="5px" ml="5px">
				<RobotIcon color="textTertiary" size="23px" data-testid="auto-comment-icon" />
			</Box>
		) : (
			<UserCommentIcon color="textTertiary" size="33px" data-testid="user-comment-icon" />
		)}
	</IconContainer>
);
