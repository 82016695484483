import React, { FunctionComponent } from 'react';
import { investAccountTypes } from 'src/constants/investAccountTypes';
import { AccountCard, BaseAccountCardProps } from 'src/components/AccountCard';

import { InvestAccountsResponseBody } from '@stashinvest/shared-types';
import { InvestAccountCardTitle } from '../InvestAccountCardTitle';

interface CustodialAccountCardProps extends BaseAccountCardProps {
	identity?: InvestAccountsResponseBody[0]['identity'];
	accountId: string;
	trackingTitle: string;
}

export const CustodialAccountCard: FunctionComponent<
	React.PropsWithChildren<CustodialAccountCardProps>
> = ({ identity, accountId, trackingTitle, children, ...otherProps }) => {
	const owner =
		identity === undefined ||
		identity === null ||
		(identity.first_name === '' && identity.last_name === '')
			? ''
			: `${identity.first_name} ${identity.last_name}'s `;

	return (
		<AccountCard
			title={
				<InvestAccountCardTitle accountId={accountId} trackingTitle={trackingTitle}>
					{`${owner}${investAccountTypes.CUSTODIAN}`}
				</InvestAccountCardTitle>
			}
			{...otherProps}
		>
			{children}
		</AccountCard>
	);
};
