import React, { FC } from 'react';
import { PrimaryButton } from '@stashinvest/ui';
import { Box } from '@stashinvest/ui/dist/es/2';

interface QuestionSubmitProps {
	isValid: boolean;
	isSubmitting: boolean;
}

export const QuestionSubmit: FC<React.PropsWithChildren<QuestionSubmitProps>> = ({
	children,
	isValid,
	isSubmitting,
}) => (
	<Box width="296px" mx="auto">
		<PrimaryButton mt="16px" type="submit" disabled={!isValid || isSubmitting}>
			{children}
		</PrimaryButton>
	</Box>
);
