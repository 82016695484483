import { TrackingProps } from '../../utils/analytics';

export enum TrackedLinkVariants {
	BUTTON = 1,
	SECONDARY_BUTTON = 2,
	LINK = 3,
}

export enum TrackedLinkColors {
	'bgActionPrimary' = 'bgActionPrimary',
	'textActionPrimary' = 'textActionPrimary',
	'textPlaceholder' = 'textPlaceholder',
	'textPrimary' = 'textPrimary',
	'inherit' = 'inherit',
}

export interface BaseTrackedLinkProps {
	/**
	 * Padding top and bottom
	 */
	py?: string;
	/**
	 * Padding left and right
	 */
	px?: string;
	/**
	 * margin left
	 */
	ml?: string;
	/**
	 * Custom styling
	 */
	style?: object;
	/**
	 * Custom hover styling
	 */
	hoverStyles?: { color?: TrackedLinkColors };
	/**
	 * Used to set the button type size
	 */
	size?: 'small' | 'medium' | 'large';
	/**
	 * Used to set a custom width
	 */
	width?: string;
	/**
	 * Used to set the color of the link
	 */
	color?: TrackedLinkColors;
	/**
	 * If the link should open in a new window or not
	 */
	target?: '_self' | '_blank';
	/**
	 * The type of component we want to show.
	 * From the TrackedLinkVariants type:
	 * BUTTON (1), SECONDARY_BUTTON (2) or LINK (3)
	 */
	type?: TrackedLinkVariants;
	/**
	 * The properties used for tracking
	 */
	tracking: TrackingProps;
}

export interface TrackedLinkProps extends BaseTrackedLinkProps {
	/**
	 * Used for external link paths
	 */
	href?: string;
	/**
	 * Used for internal link paths
	 */
	to?: string;
	/**
	 * If linking externally, this should be true
	 * so we use an anchor tag over a React Router link
	 */
	nativeLink?: boolean;
	/**
	 * Used where the link needs to trigger
	 * downloading a document or image
	 */
	download?: string;
}
