import React, { FC, useLayoutEffect, useState } from 'react';
import { Box } from '@stashinvest/ui';
import { usePopper } from 'react-popper';

import { TooltipBody } from './TooltipBody';
import { TooltipProps, TooltipComponentProps } from './Tooltip.types';
import { TooltipVisibilityProvider } from './TooltipVisibilityProvider';
import { TooltipButton } from './TooltipButton';

export const TooltipComponent: FC<React.PropsWithChildren<TooltipComponentProps>> = ({
	inverted = false,
	visible = false,
	description,
	bodyWidth,
	emoji,
	children,
	...otherProps
}) => {
	const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(
		null
	);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
	const popperProps = usePopper(referenceElement, popperElement, {
		placement: 'top',
		modifiers: [
			{
				name: 'preventOverflow',
			},
			{ name: 'arrow', options: { element: arrowElement } },
			{
				name: 'offset',
				options: {
					offset: [2, 5],
				},
			},
			{
				name: 'computeStyles',
				options: {
					gpuAcceleration: false,
				},
			},
		],
	});
	useLayoutEffect(() => {
		if (popperProps.update) popperProps.update(); // eslint-disable-next-line
	}, [visible, popperProps.update]);
	return (
		<>
			<TooltipButton
				{...otherProps}
				inverted={inverted}
				emoji={emoji}
				ref={setReferenceElement}
			>
				{children}
			</TooltipButton>

			<TooltipBody
				visible={visible}
				bodyWidth={bodyWidth}
				ref={setPopperElement}
				popperProps={{ ...popperProps, ...{ setArrowElement } }}
			>
				{description}
			</TooltipBody>
		</>
	);
};

export const Tooltip: FC<React.PropsWithChildren<TooltipProps>> = (props) => (
	<TooltipVisibilityProvider>
		{({ visible, toggleVisibility }) => (
			<Box
				width="30px"
				onMouseEnter={() => toggleVisibility(true)}
				onMouseLeave={() => toggleVisibility(false)}
				onFocus={() => toggleVisibility(true)}
				onBlur={() => toggleVisibility(false)}
			>
				<TooltipComponent {...props} visible={visible} />
			</Box>
		)}
	</TooltipVisibilityProvider>
);
