import React from 'react';
import styled from 'styled-components';
import { Box } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { usePopper } from 'react-popper';

const TooltipBodyContainer = styled(Box).attrs(({ visible }) => ({
	display: visible ? 'block' : 'none',
	bg: 'bgHoverSecondary',
	p: '15px',
	borderRadius: '10px',
}))`
	z-index: 1;
	text-transform: none;
	white-space: normal;
	min-width: min-content;
	width: max-content;
	max-width: ${({ bodyWidth }) => bodyWidth || '500px'};
`;

const TooltipBodyPoint = styled(Box).attrs(() => ({
	display: 'block',
	borderTopColor: 'bgHoverSecondary',
}))`
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top-width: 10px;
	border-top-style: solid;

	[data-popper-placement^='top'] & {
	}

	[data-popper-placement^='bottom'] & {
		transform: rotate(180deg);
	}

	[data-popper-placement^='left'] & {
		transform: rotate(-90deg);
	}

	[data-popper-placement^='right'] & {
		transform: rotate(90deg);
	}
`;

const TooltipBodyPointContainer = styled(Box)`
	[data-popper-placement^='top'] & {
		bottom: -10px;
	}

	[data-popper-placement^='bottom'] & {
		top: -10px;
	}

	[data-popper-placement^='left'] & {
		right: -15px;
	}

	[data-popper-placement^='right'] & {
		left: -15px;
	}
`;
interface TooltipBodyProps {
	visible: boolean;
	bodyWidth?: string;
	popperProps: ReturnType<typeof usePopper> & {
		setArrowElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
	};
}

export const TooltipBody = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<TooltipBodyProps>
>(({ bodyWidth, visible, children, popperProps }, ref) => (
	<TooltipBodyContainer
		visible={visible}
		bodyWidth={bodyWidth}
		ref={ref}
		popperProps={popperProps}
		style={popperProps.styles.popper}
		{...popperProps.attributes.popper}
	>
		<Text.Label14 fontWeight="bold" as="span" color="textPrimary">
			{children}
		</Text.Label14>
		<TooltipBodyPointContainer
			ref={popperProps.setArrowElement}
			style={popperProps.styles.arrow}
		>
			<TooltipBodyPoint as="span" />
		</TooltipBodyPointContainer>
	</TooltipBodyContainer>
));
