import { PageContainer } from 'src/components/PageContainer';
import { useParams } from 'react-router-dom';
import { Box } from '@stashinvest/ui';
import { AlertBanner, DocumentViewerFixedModal } from '@stashinvest/shared-ui';
import type { DocumentToView } from '@stashinvest/shared-ui';
import {
	DocumentRequest,
	GetDocumentRequestResponse,
} from '@stashinvest/shared-types/schemas/papyrus';

import { useIdentityDocuments } from 'src/hooks/useIdentityDocuments';
import { LoadingErrorWrapper } from '../../components/LoadingErrorWrapper';

const getValidationFailureReason = (failureType: string) => {
	switch (failureType) {
		case 'UnsupportedImageType':
			return 'Sorry, the file uploaded isn’t an image. Please ask the customer to upload an image. We support JPEG, PNG, HEIF and HEIC files.';
		case 'UnsupportedMimeType':
			return 'Sorry, the file uploaded is invalid. Please ask the customer to upload another image. We support JPEG, PNG, HEIF and HEIC files.';
		case 'InvalidImageMetadata':
			return 'Sorry, the image uploaded has invalid metadata (missing width or height). Please ask the customer to upload another image. We support JPEG, PNG, HEIF and HEIC files.';
		case 'FileIsEmpty':
			return 'Sorry, the file uploaded is empty or its size cannot be determined. Please ask the customer to upload another image. We support JPEG, PNG, HEIF and HEIC files.';
		case 'FileSizeExceedsLimit':
			return 'Sorry, the file uploaded is too big. Please ask the customer to upload another image. We support JPEG, PNG, HEIF and HEIC files up to 20MB.';
		case 'PendingValidation':
			return 'Sorry, we’re still trying to validate this image. Please check back in a moment';
		default:
			return 'Sorry, the file uploaded isn’t valid. Please ask the customer to upload another image. We support JPEG, PNG, HEIF and HEIC files.';
	}
};

const getImages = (response: GetDocumentRequestResponse): DocumentToView[] => {
	// function copied from Bank Portal
	const { documentRequest } = response.data;
	switch (documentRequest.__typename) {
		case 'DocumentRequest':
			const docRequest = documentRequest as DocumentRequest;
			const documents = docRequest.documents;
			switch (documents.__typename) {
				case 'DocumentList':
					return documents.documents.map((document, index) => {
						const url = document.presignedURL;
						const doc: DocumentToView = {
							id: document.id,
							title: `Evidence ${index + 1} of ${documents.documents.length}`,
							type: 'Evidence',
							updatedAt: document.updatedAt,
							mimeType: 'image/jpeg',
						};

						let unavailableReason: string | undefined;
						const validationResult = document.validationResult;
						if (!validationResult) {
							unavailableReason = getValidationFailureReason('PendingValidation');
						} else if (!validationResult.isValid) {
							unavailableReason = getValidationFailureReason(
								validationResult.failureType || ''
							);
						}

						if (unavailableReason) {
							doc.unavailableReason = unavailableReason;
							return doc;
						}

						switch (url.__typename) {
							case 'PresignedURL':
								doc.image = url.url || '';
								return doc;
							case 'PresignedURLError':
								throw Error('URL Error');
							default:
								throw Error('Error handling document request typename');
						}
					});
				case 'DocumentListError':
					throw Error('Error retrieving documents Error');
			}
			break;
		case 'DocumentRequestNotFoundError':
			throw Error('Document Request Not Found Error');
		default:
			throw Error('Request Error');
	}
};

type IdentityDocumentParams = {
	userId: string;
	documentRequestId: string;
};

export const IdentityDocument = () => {
	const { userId, documentRequestId } =
		useParams<IdentityDocumentParams>() as IdentityDocumentParams;

	const { data, isLoading, error } = useIdentityDocuments({ userId, documentRequestId });

	const images = data && getImages(data);
	return (
		<PageContainer disableActions={true}>
			<Box mt="l">
				<LoadingErrorWrapper loading={isLoading} error={error as Error} />
				{data &&
					(images && images.length > 0 ? (
						<DocumentViewerFixedModal
							items={images}
							title={'Identity Document Viewer'}
							canDownloadDocs={false}
							tracking={{
								component: 'Identity Document',
								page: 'Identity Document',
							}}
						/>
					) : (
						<AlertBanner
							title={
								images && images.length === 0
									? 'No document image was found for this requestID, please contact the System Administrator'
									: 'Unexpected error processing image response'
							}
						/>
					))}
			</Box>
		</PageContainer>
	);
};
