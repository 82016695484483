import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Routes } from 'src/constants/routes';

export const AppRoutes: FC = () => {
	return Routes ? (
		<RouterProvider
			router={createBrowserRouter(Routes, { basename: process.env.PUBLIC_URL })}
		/>
	) : null;
};
