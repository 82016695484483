import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const WarningIcon: FC<React.PropsWithChildren<IconProps>> = (props) => (
	<IconContainer {...props}>
		<svg width="100%" height="100%" viewBox="0 0 22 20" fill="none">
			<path
				d="M21.798 17.03L12.312.746c-.58-.996-2.043-.997-2.624 0L.202 17.017c-.576.989.15 2.22 1.31 2.22l18.973.013c1.162 0 1.89-1.23 1.313-2.22zM11 16.5a1.375 1.375 0 11.001-2.75A1.375 1.375 0 0111 16.5zm1.375-5.5a1.376 1.376 0 01-2.75 0V6.875a1.375 1.375 0 012.75 0V11z"
				fill="currentColor"
				fillRule="evenodd"
				clipRule="evenodd"
			/>
		</svg>
	</IconContainer>
);
