import React, { FC } from 'react';

import {
	EnvironmentContextProps,
	EnvironmentProviderProps,
	Environments,
	StashApps,
} from './EnvironmentProvider.types';

export const defaultBaseUrlConfig = {
	[StashApps.ACTIVE_ADMIN]: {
		[Environments.EDGE]: 'https://edge.stsh.io',
		[Environments.STAGING]: 'https://staging.stsh.io',
		[Environments.PROD]: 'https://prod.stsh.io',
	},
	[StashApps.BANK_PORTAL]: {
		[Environments.EDGE]: 'https://bankportal-edge.stsh.io/v2',
		[Environments.STAGING]: 'https://bankportal-staging.stsh.io/v2',
		[Environments.PROD]: 'https://bankportal.stsh.io/v2',
	},
	[StashApps.USER_HUB]: {
		[Environments.EDGE]: 'https://cx-edge.stsh.io',
		[Environments.STAGING]: 'https://cx-staging.stsh.io',
		[Environments.PROD]: 'https://cx.stsh.io',
	},
	[StashApps.LEGACY_BANK_PORTAL]: {
		[Environments.EDGE]: 'https://bankportal-edge.stsh.io',
		[Environments.STAGING]: 'https://bankportal-staging.stsh.io',
		[Environments.PROD]: 'https://bankportal.stsh.io',
	},
};

export const EnvironmentContext = React.createContext<EnvironmentContextProps>({
	environment: Environments.EDGE,
	baseUrlConfig: defaultBaseUrlConfig,
});

export const EnvironmentProvider: FC<
	React.PropsWithChildren<EnvironmentProviderProps>
> = ({
	environmentToSet = Environments.EDGE,
	baseUrlConfig = defaultBaseUrlConfig,
	children,
}) => (
	<EnvironmentContext.Provider
		value={{
			environment: environmentToSet,
			baseUrlConfig,
		}}
	>
		{children}
	</EnvironmentContext.Provider>
);
