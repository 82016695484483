import React, { FC, useContext } from 'react';

import { VisibilityContext } from '../../providers/VisibilityProvider';
import { VisibilityOpenerProps } from './VisibilityOpener.types';

export const VisibilityOpener: FC<VisibilityOpenerProps> = ({ children }) => {
	const OverlayContext = useContext(VisibilityContext);

	return <>{children(OverlayContext)}</>;
};
