import { FC } from 'react';
import { TooltipWithTag } from '@stashinvest/shared-ui';

interface EitherErrLockListProps {
	value: string;
	error: string;
}

// While previously used mapping is nice to have, Winchester never actually requested any other locks except for Invest ones from NW
// So until we actually add this logic we can hardcode the values
export const EitherErrLockList: FC<EitherErrLockListProps> = ({
	value,
	error,
}: EitherErrLockListProps) => {
	if (error) {
		return (
			<TooltipWithTag
				description={'An error occured while loading the locks: ' + error}
				variant={''}
				data-testid="error-text"
			>
				{'Unknown'}
			</TooltipWithTag>
		);
	}
	return value ? (
		<TooltipWithTag
			key={`key-${'Invest'}-${value}-${0}`}
			description={'Invest account has been locked for the following reason: ' + value}
			bodyWidth="320px"
			variant="info"
		>
			{'Invest'}
		</TooltipWithTag>
	) : null;
};
