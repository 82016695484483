import { useParams } from 'react-router-dom';
import { TrackedLinkVariants } from '@stashinvest/shared-ui';
import { ActiveAdminLink } from 'src/components/ActiveAdminLink';

type UserPageParams = { userId: string };

export const UploadedDocumentsLink = () => {
	const { userId } = useParams<UserPageParams>();

	return (
		<ActiveAdminLink
			type={TrackedLinkVariants.BUTTON}
			path={`admin/users/${userId}/uploaded_documents`}
			tracking={{
				page: 'user',
				component: 'lock-items',
			}}
		>
			View Documents
		</ActiveAdminLink>
	);
};
