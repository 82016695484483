import { useState, useRef, useEffect } from 'react';

import { RefPropType } from '../../utils';

export const useScrollPosition = (
	/** used to trigger scrolling to the correct container */
	activeIndex: number,
	numberOfItems: number
) => {
	const containerRef = useRef<RefPropType>(null);
	const scrollRef = useRef<RefPropType>(null);
	const [scrollWidth, setScrollWidth] = useState(0);
	const [itemWidth, setItemWidth] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);

	useEffect(() => {
		const containerWidth = containerRef.current?.clientWidth || 0;
		const newScrollWidth = containerWidth * numberOfItems;
		newScrollWidth > 0 && setScrollWidth(newScrollWidth);
		containerWidth > 0 && setItemWidth(containerWidth);

		return () => {
			setScrollWidth(0);
			setItemWidth(0);
		};
	}, [numberOfItems]);

	useEffect(() => {
		setScrollLeft(itemWidth * activeIndex);

		return () => {
			setScrollLeft(0);
		};
	}, [itemWidth, activeIndex]);

	return {
		containerRef,
		scrollRef,
		scrollWidth,
		scrollLeft,
		itemWidth,
	};
};
