import React, { FC, useContext } from 'react';

import { TrackedLink } from '../TrackedLink';
import {
	EnvironmentContext,
	StashApps as StashAppVariants,
} from '../../providers/EnvironmentProvider';
import { LinkVariantProps, BasicVariants, GetBasePathProps } from './LinkVariant.types';

export const LinkVariants = { ...BasicVariants, ...StashAppVariants };

const stripSlashes = (str: string) => str.replace(/^\/+|\/+$/g, '');

const getPath = ({
	path,
	variant = LinkVariants.INTERNAL,
	environment,
	baseUrlConfig,
}: GetBasePathProps) => {
	if (variant === LinkVariants.INTERNAL || variant === LinkVariants.EXTERNAL) {
		return path;
	}

	const basePath = baseUrlConfig[variant][environment];
	return `${stripSlashes(basePath)}/${stripSlashes(path)}`;
};

export const LinkVariant: FC<React.PropsWithChildren<LinkVariantProps>> = ({
	children,
	path,
	variant,
	...otherProps
}) => {
	const { environment, baseUrlConfig } = useContext(EnvironmentContext);
	const pathWithBase = getPath({
		path,
		variant,
		environment,
		baseUrlConfig,
	});

	return (
		<TrackedLink to={pathWithBase} {...otherProps}>
			{children}
		</TrackedLink>
	);
};
