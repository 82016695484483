import { FC, useContext } from 'react';
import { PageContainer as StashPageContainer } from '@stashinvest/shared-ui';
import { useParams } from 'react-router-dom';
import { SearchResponseContext } from 'src/providers/SearchResponseProvider';
import { SearchTermContext } from 'src/providers/SearchTermProvider';

export interface PageContainerProps {
	showSearchBox?: boolean;
	disableActions?: boolean;
	name?: string;
	children: React.ReactNode;
}

type UserPageParams = { userId: string };

export const PageContainer: FC<React.PropsWithChildren<PageContainerProps>> = ({
	name,
	showSearchBox = false,
	children,
}) => {
	const { userId } = useParams<UserPageParams>();
	const { clearSearchTerms } = useContext(SearchTermContext);
	const { clearResponse } = useContext(SearchResponseContext);

	return (
		<StashPageContainer
			name={name}
			showSearchBox={showSearchBox}
			userId={userId}
			onUserHub
			clearResponse={clearResponse}
			clearSearchTerms={clearSearchTerms}
		>
			{children}
		</StashPageContainer>
	);
};
