import { z } from 'zod';
import { StashGenericErrorResponse } from '../errors';

export const SandfordAccount = z.object({
	id: z.string().uuid(),
	type: z.string(),
	state: z.string(),
	eSigAgreedAt: z.coerce
		.date()
		.transform((timestamp) => timestamp.toISOString())
		.optional()
		.nullable(),
	atlasId: z.string().optional().nullable(),
});

export const AccountsSchema = z.object({
	accounts: z.array(SandfordAccount),
});

//TODO: improve naming
export const AccountsResponseSchema = AccountsSchema.or(StashGenericErrorResponse);
export type AccountsResponse = z.infer<typeof AccountsResponseSchema>;
export type AccountsType = z.infer<typeof AccountsSchema>;
