import { useConfig } from 'src/hooks/useConfig';
import { SearchResponseProvider } from 'src/providers/SearchResponseProvider';
import { SearchTermProvider } from 'src/providers/SearchTermProvider';
import { PageHistoryProvider } from 'src/providers/PageHistoryProvider';
import {
	ReferralProvider,
	TrackingProvider,
	TrackingHosts,
} from '@stashinvest/shared-ui';

import { Outlet } from 'react-router-dom';

export const AppProviders = () => {
	const config = useConfig();
	return (
		<PageHistoryProvider>
			<SearchResponseProvider>
				<SearchTermProvider>
					<ReferralProvider>
						<TrackingProvider
							host={TrackingHosts.USER_HUB}
							googleAnalyticsTag={config.googleAnalyticsTag}
						>
							<Outlet />
						</TrackingProvider>
					</ReferralProvider>
				</SearchTermProvider>
			</SearchResponseProvider>
		</PageHistoryProvider>
	);
};
