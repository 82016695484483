import React, { FC } from 'react';

import { FalseyText } from '../FalseyText';
import { NameProps } from './Name.types';

export const Name: FC<React.PropsWithChildren<NameProps>> = ({
	firstName,
	lastName,
}: NameProps) => {
	if (!firstName && !lastName) {
		return <FalseyText text={undefined} />;
	}

	return (
		<span data-testid="name-text">
			<FalseyText text={firstName} /> <FalseyText text={lastName} />
		</span>
	);
};
