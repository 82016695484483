import { FC, ReactNode } from 'react';
import { Flex } from '@stashinvest/ui';

import { StashApps, Card } from '@stashinvest/shared-ui';
import type { TagVariant, CardProps } from '@stashinvest/shared-ui';

export interface BaseAccountCardProps {
	tag: {
		type: TagVariant;
		title: string;
	};
	link?: CardProps['link'];
	links?: AccountLink[];
	tracking?: CardProps['tracking'];
}

type AccountLink = {
	id: string;
	path: string;
	trackingId: string;
	label: string;
	type: StashApps;
};

export interface AccountCardProps extends BaseAccountCardProps {
	title: ReactNode;
}

export const AccountCard: FC<React.PropsWithChildren<AccountCardProps>> = ({
	title,
	children,
	tag,
	link,
	tracking,
}) => {
	return (
		<Flex
			maxWidth={{ mobile: '100%', desktop: '48.5%' }}
			minWidth="529px"
			width="100%"
			data-testid="account-card"
		>
			<Card
				title={title}
				tag={{ variant: tag.type, label: tag.title }}
				tracking={tracking}
				link={link}
			>
				{children}
			</Card>
		</Flex>
	);
};
