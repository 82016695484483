import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { SortArrowIcon } from '../../icons';
import { UnstyledButton } from '../UnstyledButton';
import { TableHeader } from './DataTable.styles';
import { DataTableHeaderProps, DataTableHeading, SortOrders } from './DataTable.types';

const IconContainer = styled(Box).attrs(() => ({
	ml: '7px',
	as: 'span',
}))`
	> span {
		transform: ${({ sortOrder }) =>
			sortOrder === SortOrders.ASC
				? `translateY(16px) rotate(180deg)`
				: `translateY(-1px) rotate(0deg)`};
	}
`;

const UppercaseLabel = styled(Text.Label12)`
	text-transform: uppercase;
`;

export const DataTableHeader: FC<React.PropsWithChildren<DataTableHeaderProps>> = ({
	cols,
}) => {
	const [sortCol, setSortCol] = useState<string>('');
	const [sortOrder, setSortOrder] = useState<SortOrders>(SortOrders.DESC);
	const [callbackFn, setCallbackFn] = useState<DataTableHeading['callback']>();

	useEffect(() => {
		callbackFn && callbackFn(sortOrder);
	}, [sortOrder, callbackFn]);

	const handleColHeaderClick = (
		label: string,
		callback: DataTableHeading['callback']
	) => {
		if (label === sortCol) {
			const order = sortOrder === SortOrders.DESC ? SortOrders.ASC : SortOrders.DESC;
			setSortOrder(order);
		} else {
			setSortOrder(SortOrders.DESC);
		}

		setSortCol(label);
		setCallbackFn(() => callback);
	};

	const column = (
		label: string,
		index: number,
		callback: DataTableHeading['callback']
	) => {
		const order = sortCol === label ? sortOrder : SortOrders.DESC;
		const textColor = sortCol === label ? 'textActionPrimary' : 'textPrimary';

		return (
			<>
				{callback ? (
					<UnstyledButton
						data-testid={`datatable-header-btn${index}`}
						onClick={() => handleColHeaderClick(label, callback)}
					>
						<UppercaseLabel color={textColor}>
							{label}
							<IconContainer sortOrder={order}>
								<SortArrowIcon
									data-testid={`sort-arrow-icon${index}`}
									size="10px"
									as="span"
									color={textColor}
								/>
							</IconContainer>
						</UppercaseLabel>
					</UnstyledButton>
				) : (
					<UppercaseLabel>{label}</UppercaseLabel>
				)}
			</>
		);
	};

	return (
		<>
			{cols.map((header: DataTableHeading, i: number) => (
				<TableHeader
					key={header.label}
					width={header.width}
					headerAlignment={header.headerAlignment}
				>
					{column(header.label, i, header.callback)}
				</TableHeader>
			))}
		</>
	);
};
