import React, { FC, useState } from 'react';

import { MODE_DARK, ThemeModes } from '../ThemeModeProvider';

export enum StorageKeys {
	THEME_MODE = 'themeMode',
	TEST_KEY = 'testKey',
}

export interface SetStorageProps {
	key: StorageKeys;
	value: string;
}

interface StorageProps {
	[StorageKeys.THEME_MODE]: ThemeModes;
	[StorageKeys.TEST_KEY]?: string;
}

interface LocalStorageContextProps {
	storage: StorageProps;
	setLocalStorage({ key, value }: SetStorageProps): void;
}

export const LocalStorageContext = React.createContext<LocalStorageContextProps>({
	storage: {
		[StorageKeys.THEME_MODE]: MODE_DARK,
	},
	setLocalStorage: () => {},
});

export const LocalStorageProvider: FC<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const initialStorageObject = {
		[StorageKeys.THEME_MODE]: localStorage.getItem(StorageKeys.THEME_MODE) as ThemeModes,
		[StorageKeys.TEST_KEY]: localStorage.getItem(StorageKeys.TEST_KEY) || undefined,
	};
	const [storage, setStorage] = useState(initialStorageObject);
	const setLocalStorage = ({ key, value }: SetStorageProps) => {
		setStorage({
			...storage,
			[key]: value,
		});
		localStorage.setItem(key, value);
	};

	return (
		<LocalStorageContext.Provider
			value={{
				storage,
				setLocalStorage,
			}}
		>
			{children}
		</LocalStorageContext.Provider>
	);
};
