import { z } from 'zod';
import { ErrorResponseSchema } from '../userProfile';
import { PartialErrorsSchema, StashGenericErrorResponse } from '../errors';

export const UserInfoSchema = z.object({
	user_id: z.string().uuid(),
	username: z.string(),
	email: z.string(),
	email_verified: z.boolean().nullable(),
	phone_number: z.string().nullable(),
	phone_verified: z.boolean().nullable(),
	legacy_id: z.number(),
	created_at: z.string().datetime(),
});

export const UserProfileSchema = z.object({
	first_name: z.string().nullable(),
	last_name: z.string().nullable(),
	date_of_birth: z.string().nullable(),
	ssn_ciphertext_blob: z.string().nullable(),
});

export const AddressSchema = z.object({
	is_primary: z.boolean().nullable(),
	street_address: z.string().nullable(),
	street_address2: z.string().nullable(),
	city: z.string().nullable(),
	state: z.string().nullable(),
	postal_code: z.string().nullable(),
});

export const UserSchema = z.object({
	info: UserInfoSchema,
	profile: UserProfileSchema.nullable(),
	addresses: z.array(AddressSchema),
});

export const UserSearchResponse = z.object({
	users: z.array(UserSchema),
	total_count: z.number(),
});

export const UserSearchResponseSchema = UserSearchResponse.or(ErrorResponseSchema);
export type UserSearchResponseType = z.infer<typeof UserSearchResponse>;
export type UserSearchResponseSchemaType = z.infer<typeof UserSearchResponseSchema>;

export const UserSearchParams = z.object({
	page: z.coerce.number().transform((page) => String(page)),
	per_page: z.coerce.number().transform((per_page) => String(per_page)),
	email: z.string().optional(),
	first_name: z.string().optional(),
	last_name: z.string().optional(),
	phone_number: z.string().optional(),
});

export const UserSearchModified = z.object({
	userId: z.string(),
	email: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	isSsnPresent: z.boolean(),
	phoneNumber: z.string(),
	lockReason: z.string(),
});

export const SearchResponse = z.object({
	users: z.array(UserSearchModified),
	totalCount: z.number(),
	_errors: PartialErrorsSchema.optional(),
});

const SearchResponseSchema = SearchResponse.or(ErrorResponseSchema);

export type SearchResponseSchemaType = z.infer<typeof SearchResponseSchema>;
export type SearchResponseType = z.infer<typeof SearchResponse>;
export type UserSearchParamsType = z.infer<typeof UserSearchParams>;

const BankAccountStatus = z.enum([
	'pending',
	'restricted',
	'normal',
	'closed',
	'closing',
	'locked',
	'inactive',
	'unknown',
]);

const BankAccountStatusReasons = z.array(
	z.enum([
		'accountEscheated',
		'bankInitiated',
		'bankInitiatedClosed',
		'chargedOff',
		'closedWithRefund',
		'confirmedAccountTakeover',
		'confirmedFirstPartyFraud',
		'confirmedFraud',
		'confirmedIdentityTheft',
		'confirmedOtherFraud',
		'confirmedThirdPartyFraud',
		'customerInitiated',
		'customerInitiatedClose',
		'customerInitiatedClosed',
		'customerInitiatedSpendDown',
		'customerReportedIdentityTheft',
		'deceased',
		'deceasedPendingDocs',
		'duplicateAccount',
		'errored',
		'escheat',
		'fraud',
		'fraudEscheat',
		'gdClosedDeceased',
		'healthy',
		'identityTheft',
		'inactive',
		'inactiveDormant',
		'inactiveZeroBalance',
		'legalRestriction',
		'lostStolen',
		'manualLock',
		'monitor',
		'negativeBalance',
		'nonSpecificLevy',
		'OFAC',
		'overdraft',
		'overdrawn',
		'partnerConfirmedAccountTakeover',
		'partnerPotentialAccountTakeover',
		'potentialAccountTakeover',
		'potentialFirstPartyFraud',
		'potentialFraud',
		'potentialIdentityTheft',
		'potentialOtherFraud',
		'potentialThirdPartyFraud',
		'registrationFailed',
		'registrationNotComplete',
		'returnedMail',
		'spendDown',
		'statusChangedByCsr',
		'underReview',
		'unknown',
		'unspecifiedClose',
		'verificationNeeded',
		'writeOff',
	])
);

export const BankAccount = z.object({
	id: z.string().uuid(),
	accountState: z.object({
		status: BankAccountStatus,
		statusReasons: BankAccountStatusReasons,
	}),
	partner: z.string(),
	accountStatusChangedDateTime: z.coerce
		.date()
		.transform((timestamp) => timestamp.toISOString()),
	// To simplify the usage this model doesn't contain fields that aren't used currently (such as address etc)
	// If needed in the future it should be expanded
});

export const BankAccountsResponse = z.object({
	accounts: z.array(BankAccount),
});

export type BankAccountsResponseType = z.infer<typeof BankAccountsResponse>;
export const BankAccountsResponseSchema = BankAccountsResponse.or(
	StashGenericErrorResponse
);
export type BankAccountsResponseSchemaType = z.infer<typeof BankAccountsResponseSchema>;

export const BankAccountBalance = z.object({
	accountBalance: z.object({
		availableBalance: z.number(),
		ledgerBalance: z.number(),
	}),
});

export type BankAccountBalanceType = z.infer<typeof BankAccountBalance>;
export const BankAccountBalanceResponseSchema = BankAccountBalance.or(
	StashGenericErrorResponse
);
export type BankAccountBalanceResponseSchemaType = z.infer<
	typeof BankAccountBalanceResponseSchema
>;

export const BankAccountsWithBalance = BankAccount.extend({
	availableBalance: z.string(),
	_errors: PartialErrorsSchema.optional(),
});

export type BankAccountsWithBalance = z.infer<typeof BankAccountsWithBalance>;

export const GetUserBankAccountsResponse = z.object({
	accounts: z.array(BankAccountsWithBalance),
});

export type GetUserBankAccountsResponseType = z.infer<typeof GetUserBankAccountsResponse>;

// EXR
export const ExrExternalAccountStatus = z.enum([
	'activated',
	'deactivated',
	'deregistered',
	'registered',
	'rejected',
	'severed',
	'severing',
]);
export type ExrExternalAccountStatusType = z.infer<typeof ExrExternalAccountStatus>;

const ExrLinkType = z.enum(['legacyMicroDeposit', 'legacyPlaid', 'plaid', 'quovo']);
export type ExrLinkTypeType = z.infer<typeof ExrLinkType>;

const ExrAccountType = z.enum(['savings', 'checking']);
export type ExrAccountTypeType = z.infer<typeof ExrAccountType>;

export const ExrAccountDataSchema = z.object({
	maskedAccountNumber: z
		.string()
		.transform((maskedAccountNumber) => maskedAccountNumber.slice(-4)),
	routingNumber: z.string(),
	externalAccountId: z.string(),
	accountType: ExrAccountType,
	institutionName: z.string(),
	externalAccountStatus: ExrExternalAccountStatus,
	linkType: ExrLinkType,
});
export const ExrAccountSchema = z.object({ accounts: z.array(ExrAccountDataSchema) });

const ExrAccountResponseSchema = ExrAccountSchema.or(StashGenericErrorResponse);

export type ExrAccountData = z.infer<typeof ExrAccountDataSchema>;
export type ExrAccount = z.infer<typeof ExrAccountSchema>;
export type ExrAccountResponse = z.infer<typeof ExrAccountResponseSchema>;

const ExrCurrentAccountResponseSchema = ExrAccountDataSchema.or(
	StashGenericErrorResponse
);
export type ExrCurrentAccountResponse = z.infer<typeof ExrCurrentAccountResponseSchema>;
