export class InvalidLockReasonError extends Error {
	constructor(reason: number | string, message: string, ...params: any) {
		super(...params);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, InvalidLockReasonError);
		}

		this.name = 'InvalidLockReasonError';
		this.message = `${message} (ReasonCode: ${reason})`;
	}
}
