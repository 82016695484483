import styled from 'styled-components';
import { Link } from 'react-router-dom';

const commonLinkStyles = `
	background: none;
	text-decoration: none;
	color: inherit;
`;

export const UnstyledLink = styled(Link)`
	${commonLinkStyles}
`;

export const UnstyledNativeLink = styled.a`
	${commonLinkStyles}
	cursor: pointer;
`;
