import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const PencilIcon: FC<React.PropsWithChildren<IconProps>> = (props) => (
	<IconContainer {...props}>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M28.7675 8.8175L23.1825 3.23125C22.9504 2.99899 22.6747 2.81476 22.3713 2.68906C22.0679 2.56336 21.7428 2.49866 21.4144 2.49866C21.086 2.49866 20.7608 2.56336 20.4574 2.68906C20.1541 2.81476 19.8784 2.99899 19.6463 3.23125L4.23251 18.6462C3.99951 18.8777 3.81479 19.1531 3.68905 19.4566C3.56331 19.76 3.49905 20.0853 3.50001 20.4137V26C3.50001 26.663 3.7634 27.2989 4.23224 27.7678C4.70108 28.2366 5.33697 28.5 6.00001 28.5H11.5863C11.9147 28.5009 12.24 28.4366 12.5434 28.3109C12.8468 28.1851 13.1222 28.0004 13.3538 27.7675L28.7675 12.3525C29.2362 11.8837 29.4995 11.2479 29.4995 10.585C29.4995 9.92209 29.2362 9.28632 28.7675 8.8175ZM11.625 22.5L20.5 13.625L21.875 15L13 23.875L11.625 22.5ZM9.50001 20.375L8.12501 19L17 10.125L18.375 11.5L9.50001 20.375ZM6.50001 21.625L8.43876 23.5637L10.375 25.5H6.50001V21.625ZM24 12.875L19.125 8L21.4175 5.7075L26.2925 10.5825L24 12.875Z"
				fill="currentColor"
			/>
		</svg>
	</IconContainer>
);
