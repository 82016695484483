import { DateTime } from 'luxon';

export type SupportedZones =
	| 'America/New_York' // Eastern
	| 'America/Los_Angeles' // Pacific
	| 'America/Chicago' // Central
	| 'America/Denver' // Mountain
	| 'Etc/UTC'; // No Offset Timezone

export interface GetDateInTimezoneProps {
	timestamp: string;
	timezone?: SupportedZones;
}

export const getDateForTimezone = ({
	timestamp,
	timezone = 'America/New_York',
}: GetDateInTimezoneProps) => {
	const dateTime = isValidDateTime(timestamp);
	if (dateTime) {
		return DateTime.fromISO(dateTime.toISOString(), { zone: timezone });
	}
};

export const isValidDateTime = (timestamp: string) => {
	if (isNaN(Date.parse(timestamp))) {
		// eslint-disable-next-line
		console.warn(
			`The date must be passed as a valid ISO date string: (YYYY-MM-DD | YYYY-MM-DDTHH:MN:SS.MSSZ)\n
			Received "${timestamp}"`
		);
		return false;
	}

	return new Date(timestamp);
};

export const dateObjectToFormat = (day: Date) => {
	return DateTime.fromJSDate(day, { zone: 'Etc/UTC' }).toFormat(
		luxonDateFormat['MM/DD/YYYY']
	);
};

export const dateStringToDate = (
	dateString: string,
	format: string = luxonDateFormat['YYYY-MM-DD'],
	zone = 'utc'
) => {
	return DateTime.fromFormat(dateString, format, { zone }).toJSDate();
};

/** Furthest date where person is still age X, assumes the next day user will turn X + 1 */
export const getFurthestValidDOBForAge = (age: number) => {
	return DateTime.now()
		.minus({ years: age + 1 })
		.plus({ day: 1 })
		.toJSDate();
};

/** Soonest date where person can be age X */
export const getSoonestValidDOBForAge = (age: number) => {
	return DateTime.now().minus({ years: age }).toJSDate();
};

export const luxonDateFormat = {
	'MM/DD/YYYY': 'LL/dd/yyyy',
	'YYYY-MM-DD': 'yyyy-LL-dd',
} as const;
