import { FC } from 'react';
import { Text } from '@stashinvest/ui/dist/es/2';
import { AlertBanner, FormattedDate } from '@stashinvest/shared-ui';
import { ReasonTag } from '@stashinvest/shared-types/grpc/fraud_service_v1';
import { LockItems } from './LockItems';

export interface LockedAlertBannerProps {
	id: string;
	title: string;
	reasons: number[];
	comment?: string;
	dateOfAction?: string;
	adminUser?: string;
	fraudTags?: ReasonTag[];
}

export const LockedAlertBanner: FC<LockedAlertBannerProps> = ({
	id,
	title,
	reasons,
	comment,
	dateOfAction,
	adminUser,
	fraudTags,
}) => {
	const connectingCopy = dateOfAction ? ' by' : 'By';

	return (
		<AlertBanner title="">
			<Text.Title20 pt="7px" color="textNegativeSecondary" fontWeight="bold" pb="20px">
				{title}
			</Text.Title20>
			{fraudTags
				? fraudTags.map((tag) => (
						<LockItems fraudTag={tag.reasonTag} fraudTagAppliedAt={tag.taggedAt} />
				  ))
				: null}
			{reasons.map((reason) => (
				<LockItems reason={reason} key={`${id}-${reason}`} />
			))}
			{comment ? (
				<Text.Label16 pt="7px" color="textNegativeSecondary">
					<Text.Label16 as="span" fontWeight="bold" color="textNegativeSecondary">
						Lock Comment:
					</Text.Label16>
					{` ${comment}`}
				</Text.Label16>
			) : null}
			<Text.Label16 pt="7px" color="textNegativeSecondary" pb="10px">
				{dateOfAction ? `Locked on` : null}
				{dateOfAction ? (
					<FormattedDate timestamp={`${dateOfAction}`} showTimezone />
				) : null}
			</Text.Label16>
			<Text.Label16 pt="7px" color="textNegativeSecondary" pb="10px">
				{adminUser ? `${connectingCopy} ` : null}
				{adminUser ? (
					<Text.Label16 as="span" color="textNegativeSecondary">
						{adminUser}
					</Text.Label16>
				) : null}
			</Text.Label16>
		</AlertBanner>
	);
};
