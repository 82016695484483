import { FC } from 'react';

import { investAccountTypes, InvestAccountType } from 'src/constants/investAccountTypes';
import { investAccountStates } from 'src/constants/investAccountStates';
import { AccountCard } from 'src/components/AccountCard';
import { formatForTracking } from 'src/utils';

import { InvestAccountsResponseBodyV2 } from '@stashinvest/shared-types';
import { CustodialAccountCard } from '../CustodialAccountCard';
import { InvestAccountBody } from '../InvestAccountBody';
import { InvestAccountCardTitle } from '../InvestAccountCardTitle';

type InvestAccountsListV2Props = {
	accounts: InvestAccountsResponseBodyV2;
};

const InvestAccountsListV2: FC<InvestAccountsListV2Props> = ({
	accounts,
}: InvestAccountsListV2Props) => {
	return (
		<>
			{accounts.accounts.map((investAccount) => {
				const accountId = investAccount.id;
				const tag = investAccountStates[investAccount.state.toUpperCase()];
				const title = investAccountTypes[investAccount.type as InvestAccountType];
				const portfolio = investAccount.portfolio;
				const identity = investAccount.identity;
				const accountType = investAccount.type as InvestAccountType;
				const eSig = investAccount.e_signature_agreed_at;
				const atlasId = investAccount.atlas_id;
				const trackingTitle = formatForTracking(title);
				if (accountType === 'CUSTODIAN') {
					return (
						<CustodialAccountCard
							key={accountId}
							tag={tag}
							identity={identity}
							accountId={accountId}
							trackingTitle={trackingTitle}
						>
							<InvestAccountBody
								portfolio={portfolio!}
								eSig={eSig!}
								atlasId={atlasId}
								accountId={accountId}
								trackingTitle={trackingTitle}
							/>
						</CustodialAccountCard>
					);
				} else {
					return (
						<AccountCard
							key={accountId}
							title={
								<InvestAccountCardTitle
									accountId={accountId}
									trackingTitle={trackingTitle}
								>
									{title}
								</InvestAccountCardTitle>
							}
							tag={tag}
						>
							<InvestAccountBody
								portfolio={portfolio!}
								eSig={eSig!}
								atlasId={atlasId}
								accountId={accountId}
								trackingTitle={trackingTitle}
							/>
						</AccountCard>
					);
				}
			})}
		</>
	);
};

type InvestAccountsProps = {
	investAccountsV2: InvestAccountsResponseBodyV2;
};
export const InvestAccounts: FC<InvestAccountsProps> = ({ investAccountsV2 }) => {
	const hasAccounts = investAccountsV2 && investAccountsV2.accounts.length > 0;

	if (!hasAccounts) {
		return null;
	}

	return <InvestAccountsListV2 accounts={investAccountsV2} />;
};
