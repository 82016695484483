import { FC } from 'react';
import { Tile, TertiaryButton, SmartLink, Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import {
	Pagination,
	FalseyText,
	Name,
	UnstyledLink,
	valueToEmoji,
} from '@stashinvest/shared-ui';
import styled from 'styled-components';

import { SearchResponseType } from '@stashinvest/shared-types/schemas/user';
import { EitherErrLockList } from 'src/components/EitherErrVal';

export const Table = styled.table`
	width: 100%;
`;

export const TableHeader = styled.thead`
	border-bottom: 1px solid ${({ theme }) => theme.colors.bgPrimary};
	display: table-header-group;
	padding: 10px;
`;

interface ThProps {
	width: string;
}

export const Th = styled.th<ThProps>`
	padding: 10px 0;
	width: ${({ width }) => (width ? width : 'auto')};
`;

export const TableData = styled.td`
	border-bottom: 1px solid ${({ theme }) => theme.colors.bgPrimary};
	text-align: center;
	padding: 10px;
`;

export const TableRow = styled.tr`
	background-color: ${({ theme }) => theme.colors.bgPrimary};

	:nth-child(even) {
		background-color: ${({ theme }) => theme.colors.bgActionSecondary};
	}

	:hover {
		background-color: ${({ theme }) => theme.colors.bgActionTertiary};
	}
`;

export type UserSearchTableProps = {
	searchResults: SearchResponseType;
	onChangePage: (page: number) => void;
	page: number;
	perPage: number;
};

export function getNumberOfPages(totalCount: number, perPage: number): number {
	return Math.ceil(totalCount / perPage);
}

export const UserSearchTable: FC<UserSearchTableProps> = ({
	searchResults,
	onChangePage,
	page,
	perPage,
}: UserSearchTableProps) => {
	const numberOfPages = getNumberOfPages(searchResults.totalCount, perPage);
	const pageConfig = {
		onPageClick: onChangePage,
		currentPage: page,
		pageCount: numberOfPages,
	};

	return (
		<Tile mx="40px" mb="20px" style={{ overflow: 'visible' }}>
			<Table>
				<TableHeader>
					<tr>
						<Th width="22%">
							<Text.Label14 fontWeight="bold">Name</Text.Label14>
						</Th>
						<Th width="23%">
							<Text.Label14 fontWeight="bold">Email</Text.Label14>
						</Th>

						<Th width="14%">
							<Text.Label14 fontWeight="bold">Phone</Text.Label14>
						</Th>

						<Th width="21%">
							<Text.Label14 fontWeight="bold">Restrictions</Text.Label14>
						</Th>

						<Th width="6%">
							<Text.Label14 fontWeight="bold">Has SSN?</Text.Label14>
						</Th>
					</tr>
				</TableHeader>
				<tbody>
					{searchResults.users.map((user) => {
						const formattedPhoneLastFour =
							user.phoneNumber === '' ? '' : `(***) *** ${user.phoneNumber}`;

						return (
							<TableRow key={user.userId} data-testid={`search-row-${user.userId}`}>
								<TableData>
									<UnstyledLink to={`/id/${user.userId}`}>
										<Text.Label14 p="10px">
											<Name firstName={user.firstName} lastName={user.lastName} />
										</Text.Label14>
									</UnstyledLink>
								</TableData>
								<TableData>
									<UnstyledLink to={`/id/${user.userId}`}>
										<Text.Label14 p="10px">{user.email}</Text.Label14>
									</UnstyledLink>
								</TableData>

								<TableData>
									<UnstyledLink to={`/id/${user.userId}`}>
										<Text.Label14 p="10px">
											<FalseyText text={formattedPhoneLastFour} />
										</Text.Label14>
									</UnstyledLink>
								</TableData>

								<TableData>
									<UnstyledLink to={`/id/${user.userId}`}>
										<Text.Label14 p="10px">
											<EitherErrLockList
												value={user.lockReason}
												error={
													searchResults._errors?.locks.message
														? searchResults._errors?.locks.message
														: ''
												}
											/>
										</Text.Label14>
									</UnstyledLink>
								</TableData>
								<TableData>
									<UnstyledLink to={`/id/${user.userId}`}>
										<Text.Label14 p="10px">
											{valueToEmoji(user.isSsnPresent ? 'TRUE' : 'FALSE')}
										</Text.Label14>
									</UnstyledLink>
								</TableData>

								<TableData>
									<TertiaryButton
										arrow
										to={`/id/${user.userId}`}
										data-testid={`view-profile-button-${user.userId}`}
										as={SmartLink}
										size="small"
										my="0"
									>
										View
									</TertiaryButton>
								</TableData>
							</TableRow>
						);
					})}
				</tbody>
			</Table>
			<Flex justifyContent="center" py="20px">
				<Pagination {...pageConfig} />
			</Flex>
		</Tile>
	);
};
