import { z } from 'zod';
import { StashGenericErrorResponse, PartialErrorsSchema } from '../errors';

const Lock = z.object({
	locked: z.boolean(),
	applied_at: z.coerce
		.date()
		.transform((timestamp) => timestamp.toISOString())
		.optional()
		.nullable(),
	reason: z.string().optional().nullable(),
	admin_email: z.string().optional().nullable(),
	comment: z.string().optional().nullable(),
});

export const BatchLocksSchema = z.object({
	locks: z.record(z.string(), Lock),
});

export type BatchLockSchemaType = z.infer<typeof BatchLocksSchema>;
export const BatchLocksResponse = BatchLocksSchema.or(StashGenericErrorResponse);
export type BatchLocksResponseType = z.infer<typeof BatchLocksResponse>;

export type LockSchemaType = z.infer<typeof Lock>;
export const LocksResponse = Lock.or(StashGenericErrorResponse);
export type LocksResponseType = z.infer<typeof LocksResponse>;

const BankLock = z.object({
	locked: z.boolean(),
	reasons: z.array(z.string()),
});

const UserLocksSchema = z.object({
	userLocks: Lock,
	bankLocks: BankLock,
	_errors: PartialErrorsSchema.optional(),
});

export type UserLocksSchemaType = z.infer<typeof UserLocksSchema>;

export const UserLockReasonMap = {
	noLockReason: 0,
	verificationNeeded: 1,
	registrationNotComplete: 2,
	healthy: 3,
	closedWithRefund: 4,
	underReview: 5,
	bankInitiatedClosed: 6,
	customerInitiatedSpendDown: 7,
	confirmedFraud: 8,
	lostStolen: 9,
	negativeBalance: 10,
	unspecifiedClose: 11,
	potentialAccountTakeover: 12,
	partnerPotentialAccountTakeover: 12,
	potentialFraud: 13,
	customerReportedIdentityTheft: 14,
	spendDown: 15,
	writeOff: 16,
	returnedMail: 17,
	customerInitiatedClose: 18,
	registrationFailed: 19,
	statusChangedByCsr: 20,
	accountEscheated: 21,
	identityTheft: 35,
	BANK_LOCK_REASON_ACCOUNT_BLOCK: 36, // not present anymore in AMA
	potentialFirstPartyFraud: 37,
	confirmedFirstPartyFraud: 38,
	potentialThirdPartyFraud: 39,
	confirmedThirdPartyFraud: 40,
	confirmedAccountTakeover: 41,
	partnerConfirmedAccountTakeover: 41,
	potentialIdentityTheft: 42,
	confirmedIdentityTheft: 43,
	potentialOtherFraud: 44,
	confirmedOtherFraud: 45,
	chargedOff: 46,
	customerInitiatedClosed: 47,
	deceased: 48,
	escheat: 49,
	inactive: 50,
	inactiveDormant: 51,
	inactiveZeroBalance: 52,
	legalRestriction: 53,
	manualLock: 54,
	nonSpecificLevy: 55,
	OFAC: 56,
	overdrawn: 57,
	errored: 58,
	customerInitiated: 59,
	bankInitiated: 60,
	gdClosedDeceased: 63,
	unknown: 32,
	investUSER_LOCKED: 22,
	investRETURNED_DEPOSIT: 23,
	investRETURNED_MAIL: 24,
	investOFAC_COUNTRY: 25,
	investPENDING_ACAT: 26,
	investCHILD_SUPPORT_LEVY: 27,
	investNONSPECIFIC_LEVY: 28,
	investLEGAL_RESTRICTION: 29,
	investBACKUP_WITHHOLDING: 30,
	investMIGRATE_ONTO_TIER_REQUIRED: 31,
	investADDITIONAL_DOCUMENTS_REQUESTED: 33,
	investDUPLICATE_ACCOUNT: 34,
	investUnknown: 61,
	investESCHEATMENT_RESTRICTION: 62,
} as const;

export type UserLockReasonMapType = typeof UserLockReasonMap;
export type UserLockReasonKey = keyof UserLockReasonMapType;
