import styled from 'styled-components';

import { VisibilityContextProps } from '../../providers/VisibilityProvider';
import { OverlayProps } from '.';

type OverlayWrapperProps = Pick<VisibilityContextProps, 'open'> &
	Pick<OverlayProps, 'topOffset'>;

export const OverlayWrapper = styled.div<OverlayWrapperProps>`
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 10;
	left: 0;
	top: ${({ topOffset }) => topOffset};
	background-color: ${({ theme }) => theme.colors.bgPrimary};
	transform: ${({ open }) => (open ? 'translateX(0%)' : 'translateX(100%)')};
	transition-property: transform;
	transition-duration: 0.4s;
	transition-timing-function: ease-out;
`;
