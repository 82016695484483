import z from 'zod';
import { Response } from 'express';

export type ErrorCode =
	| 'BAD_USER_INPUT'
	| 'NOT_FOUND'
	| 'INTERNAL_SERVER_ERROR'
	| 'FORBIDDEN'
	| 'UNAUTHORIZED'
	| 'STASH_CORE_ERROR';

export type ErrorDetail = { '@type': `biz-ops.api.${string}` };

type GenericErrorDetails =
	| { '@type': 'biz-ops.api.v1.SimpleErrorMessage'; message: string }
	| { '@type': 'biz-ops.api.v1.ZodError'; zodError: z.ZodError }
	| { '@type': 'biz-ops.api.v1.ValidationErrorMessage'; message: string };

export type ErrorResponseBody<D extends ErrorDetail = GenericErrorDetails> = {
	error: { code: ErrorCode; message: string; details?: D };
};

export type ErrorResponse = Response<ErrorResponseBody>;

export function isErrorResponse(response: unknown): response is ErrorResponseBody {
	return Boolean(
		response &&
			typeof response === 'object' &&
			'error' in response &&
			typeof response.error === 'object' &&
			response.error !== null &&
			'code' in response.error &&
			'message' in response.error
	);
}

//TODO: Check which errors can be aggreagated into a single shared error type
export const StashGenericErrorResponse = z.object({
	errors: z.array(
		z.object({
			namespace: z.string(),
			code: z.number(),
			message: z.string(),
			description: z.string().optional(),
		})
	),
});
// get Accounts from Monolith returns simplified error response for not found
export const StashSimplifiedErrorResponse = z.object({
	errors: z.array(z.string()),
});
export type StashSimplifiedErrorResponseType = z.infer<
	typeof StashSimplifiedErrorResponse
>;
export type StashGenericErrorResponseType = z.infer<typeof StashGenericErrorResponse>;

export const ErrorType = z.enum(['ERROR', 'WARNING', 'INFO', 'REDACTED']);

const PartialErrorSchema = z.object({
	message: z.string(),
	description: z.string(),
	type: ErrorType,
});
export type PartialError = z.infer<typeof PartialErrorSchema>;

export const PartialErrorsSchema = z.record(z.string(), PartialErrorSchema);
export type PartialErrors = z.infer<typeof PartialErrorsSchema>;

/** Transforms unhandled errors to `ErrorResponseBody` shape */
export const UnhandledErrorSchema: z.Schema<ErrorResponseBody, z.ZodTypeDef, unknown> = z
	.object({ status: z.number(), statusText: z.string() })
	.transform((error) => ({
		error: {
			code: 'INTERNAL_SERVER_ERROR',
			message: `${error.status} - ${error.statusText}`,
		},
	}));

/** transforms `StashGenericErrorResponse` and unhandled errors to `ErrorResponseBody` shape */
export const ErrorResponseBodySchema: z.Schema<ErrorResponseBody, z.ZodTypeDef, unknown> =
	StashGenericErrorResponse.transform((errors) => {
		const message = errors.errors[0].message;
		const description = errors.errors[0].description;
		const code = errors.errors[0].code;
		const namespace = errors.errors[0].namespace;
		return {
			error: {
				code: 'INTERNAL_SERVER_ERROR' as const, // TODO: map external error codes to internal `ErrorCode`
				message: `${namespace} - ${code} - ${message}`,
				...(description && {
					details: {
						'@type': 'biz-ops.api.v1.SimpleErrorMessage' as const,
						message: description,
					},
				}),
			},
		};
	}).or(UnhandledErrorSchema);
