import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { UserDetailsProvider } from 'src/providers/UserDetailsProvider';

type UserPageParams = { userId: string };

export const UserPageContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;

	return <UserDetailsProvider userId={userId}>{children}</UserDetailsProvider>;
};
