import React, { FC } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FeatureFlagIdentifierProps } from './FeatureFlagIdentifier.types';

export const FeatureFlagIdentifier: FC<
	React.PropsWithChildren<FeatureFlagIdentifierProps>
> = ({ oktaProfile, children }) => {
	const ldClient = useLDClient();
	const oktaId = oktaProfile?.sub || '';

	if (ldClient && oktaProfile?.sub) {
		ldClient.identify({
			key: oktaId,
			email: oktaProfile.email,
			custom: {
				groups: oktaProfile.groups || [],
			},
		});
	}

	return <>{children}</>;
};
