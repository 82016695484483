import { StylesConfig, ThemeConfig, Colors } from 'react-select';

// stash-react-ui doesn't export types.
// TODO: create a minimal definition of this type
export type StashTheme = any;

export interface ComboboxOption {
	value: string;
	label: string;
}

export const menuBgColor = '#E1E1E2';
export const menuTextColor = '#000000';

export function getStyles(theme: StashTheme): StylesConfig<ComboboxOption> {
	if (!theme) {
		return {};
	}
	const stashColors = theme.colors;
	const { body16 } = theme.fonts;
	return {
		container: (base) => ({
			...base,
			height: '56px',
			background: stashColors.bgActionSecondary,
			borderRadius: theme.borderRadius.radius8,
		}),
		control: (base, state) => ({
			...base,
			borderColor: !state.isFocused ? stashColors.bgElevated : base.borderColor,
			'&:hover': {
				background: !state.isFocused ? stashColors.bgElevated : base.background,
			},
			background: state.isFocused ? stashColors.bgPrimary : stashColors.bgActionSecondary,
			height: '56px',
			cursor: 'text',
		}),
		singleValue: (base) => ({
			...base,
			...body16,
			color: stashColors.textActionSecondary,
		}),
		menu: (base) => ({
			...base,
			backgroundColor: menuBgColor,
			padding: '10px 20px',
		}),
		menuPortal: (base) => ({
			...base,
			zIndex: 999,
		}),
		option: (base, state) => ({
			...base,
			...body16,
			color: menuTextColor,
			backgroundColor: state.isSelected
				? state.isFocused
					? theme.colors.bgHoverPrimary
					: menuBgColor
				: base.backgroundColor,
		}),
		placeholder: (base) => ({
			...base,
			...body16,
		}),
	};
}

export function getTheme(theme: StashTheme): ThemeConfig {
	return function (baseTheme) {
		if (!theme) {
			return baseTheme;
		}

		return {
			...baseTheme,
			borderRadius: 8,
			colors: {
				...baseTheme.colors,
				...getColors(theme),
			},
		};
	};
}

function getColors(theme: StashTheme): Partial<Colors> {
	const colors = theme.colors;
	if (!colors) {
		return {};
	}

	//color mapping taken from: https://github.com/JedWatson/react-select/issues/3692#issuecomment-523425096
	return {
		/*
		 * control/backgroundColor
		 * menu/backgroundColor
		 * option/color(selected)
		 */
		neutral0: colors.bgActionSecondary,

		/*
		 * control/backgroundColor(disabled)
		 */
		neutral5: colors.textDisabled,

		/*
		 * control/borderColor(disabled)
		 * multiValue/backgroundColor
		 * indicators(separator)/backgroundColor(disabled)
		 */
		neutral10: colors.iconDisabled,

		/*
		 * control/borderColor
		 * option/color(disabled)
		 * indicators/color
		 * indicators(separator)/backgroundColor
		 * indicators(loading)/color
		 */
		neutral20: colors.TextPrimary,

		/*
		 * control/borderColor(focused)
		 * control/borderColor:hover
		 */
		neutral30: colors.bgActionSecondary,

		/*
		 * menu(notice)/color
		 * singleValue/color(disabled)
		 * indicators/color:hover
		 */
		neutral40: colors.textDisabled,

		/*
		 * placeholder/color
		 */
		neutral50: colors.textPlaceholder,

		/*
		 * indicators/color(focused)
		 * indicators(loading)/color(focused)
		 */
		neutral60: colors.textPrimary,

		neutral70: colors.textPrimary,

		/*
		 * input/color
		 * multiValue(label)/color
		 * singleValue/color
		 * indicators/color(focused)
		 * indicators/color:hover(focused)
		 */
		neutral80: colors.textPrimary,

		neutral90: colors.textPrimary,

		/*
		 * control/boxShadow(focused)
		 * control/borderColor(focused)
		 * control/borderColor:hover(focused)
		 * option/backgroundColor(selected)
		 * option/backgroundColor:active(selected)
		 */
		primary: colors.bgActionPrimary,

		/*
		 * option/backgroundColor(focused)
		 */
		primary25: colors.bgHoverPrimary,

		/*
		 * option/backgroundColor:active
		 */
		primary50: colors.shadowPrimary,

		primary75: colors.shadowPrimary,
	};
}
