import { FC } from 'react';
import { TrackedLinkColors } from '@stashinvest/shared-ui';
import { ActiveAdminLink } from 'src/components/ActiveAdminLink';

interface InvestAccountCardTitleProps {
	accountId: string;
	trackingTitle: string;
}

export const InvestAccountCardTitle: FC<
	React.PropsWithChildren<InvestAccountCardTitleProps>
> = ({ accountId, children, trackingTitle }) => (
	<ActiveAdminLink
		color={TrackedLinkColors.textPrimary}
		path={`admin/accounts/${accountId}`}
		tracking={{
			page: 'user',
			component: `${trackingTitle}-account-card-title-link`,
		}}
	>
		{children}
	</ActiveAdminLink>
);
