import React, { FC, ReactNode } from 'react';
import { Text, Flex } from '@stashinvest/ui/dist/es/2';

interface CardTitleProps {
	title: string;
	children: ReactNode;
}

export const CardTitle: FC<CardTitleProps> = ({ title, children }) => {
	return (
		<Text.Label18 fontWeight="bold" as="span" color="textPrimary" mb="5">
			<Flex justifyContent="space-between" alignItems="center">
				{title}
				{children}
			</Flex>
		</Text.Label18>
	);
};
