import React, { FC } from 'react';

import { TooltipVisibilityProvider, TooltipComponent } from '../Tooltip';
import { TooltipEmojiProps } from './TooltipEmoji.types';

export const TooltipEmoji: FC<React.PropsWithChildren<TooltipEmojiProps>> = ({
	bodyWidth,
	emojiLabel,
	children,
	style,
	...props
}) => {
	return (
		<TooltipVisibilityProvider>
			{({ visible, toggleVisibility }) => (
				<span
					style={{ ...style, cursor: 'pointer' }}
					onMouseEnter={() => toggleVisibility(true)}
					onMouseLeave={() => toggleVisibility(false)}
					onFocus={() => toggleVisibility(true)}
					onBlur={() => toggleVisibility(false)}
					role="img"
					aria-label={emojiLabel}
				>
					<TooltipComponent {...props} visible={visible}>
						{children}
					</TooltipComponent>
				</span>
			)}
		</TooltipVisibilityProvider>
	);
};
