import React, { FC } from 'react';
import { Flex, Box, Text } from '@stashinvest/ui/dist/es/2';
import { ProgressBar } from '@stashinvest/ui';
import styled from 'styled-components';
import { QuestionTreeTopBarProps } from './QuestionTreeTopBar.types';

const TopBar = styled(Box)`
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`;

const ShortProgressBar = styled(ProgressBar)`
	height: 2px;
	margin: 0;
`;

export const QuestionTreeTopBar: FC<React.PropsWithChildren<QuestionTreeTopBarProps>> = ({
	label,
	title,
	progress,
}) => (
	<TopBar bg="bgTargetSecondary">
		<Flex
			justifyContent="space-between"
			flexDirection="row"
			py="s"
			px="s"
			alignItems="center"
		>
			<Box flexGrow="1" flexShrink="1" flexBasis="0">
				<Text.Body14>{label}</Text.Body14>
			</Box>
			<Box>
				<Text.Label16>{title}</Text.Label16>
			</Box>
			<Box flexGrow="1" flexShrink="1" flexBasis="0" />
		</Flex>
		{!progress && progress !== 0 ? null : (
			<ShortProgressBar data-testid="progress-bar" progress={progress} />
		)}
	</TopBar>
);
