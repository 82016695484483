import { FC } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import { TextInput, PrimaryButton, TertiaryButton, Flex } from '@stashinvest/ui';
import {
	validateEmail,
	validatePhoneNumber,
	validateStrideAccountNumber,
	validateUUID,
} from 'src/utils';
import { isEqual } from 'lodash';
import { Box } from '@stashinvest/ui/dist/es/2';

import {
	defaultState,
	SearchTermProps,
	FIRST_NAME_TERM as FIRST_NAME_FIELD,
	LAST_NAME_TERM as LAST_NAME_FIELD,
	PHONE_TERM as PHONE_FIELD,
	EMAIL_TERM as EMAIL_FIELD,
	ID_TERM as ID_FIELD,
	STRIDE_ACCOUNT_NUMBER_TERM as STRIDE_ACCOUNT_NUMBER_FIELD,
} from 'src/providers/SearchTermProvider';
import { useStrideAccountSearch } from 'src/hooks/useStrideAccountSearch';
import { AlertBanner } from '@stashinvest/shared-ui';

export const validate = (values: SearchTermProps) => {
	values[FIRST_NAME_FIELD] = values[FIRST_NAME_FIELD]?.trim();
	values[LAST_NAME_FIELD] = values[LAST_NAME_FIELD]?.trim();

	const errors = {} as SearchTermProps;
	const { firstName, ...valuesMinusFirst } = values;
	const { lastName, ...valuesMinusLast } = values;
	const valueIsTruthy = (value: any) => Boolean(value);
	const firstAndOtherValue = Object.values(valuesMinusFirst).some(valueIsTruthy);
	const lastAndOtherValue = Object.values(valuesMinusLast).some(valueIsTruthy);

	if (values[FIRST_NAME_FIELD] && !firstAndOtherValue) {
		errors[FIRST_NAME_FIELD] = 'Cannot search on First Name only';
	}

	if (values[LAST_NAME_FIELD] && !lastAndOtherValue) {
		errors[LAST_NAME_FIELD] = 'Cannot search on Last Name only';
	}

	if (values[PHONE_FIELD]) {
		const phoneError = validatePhoneNumber(values[PHONE_FIELD] || '');
		if (phoneError) {
			errors[PHONE_FIELD] = phoneError;
		}
	}

	if (values[EMAIL_FIELD]) {
		const emailError = validateEmail(values[EMAIL_FIELD] || '');
		if (emailError) {
			errors[EMAIL_FIELD] = emailError;
		}
	}

	if (values[ID_FIELD]) {
		const idError = validateUUID(values[ID_FIELD] || '');
		if (idError) {
			errors[ID_FIELD] = idError;
		}
	}
	if (values[STRIDE_ACCOUNT_NUMBER_FIELD]) {
		const strideAccountNumberError = validateStrideAccountNumber(
			values[STRIDE_ACCOUNT_NUMBER_FIELD] || ''
		);
		if (strideAccountNumberError) {
			errors[STRIDE_ACCOUNT_NUMBER_FIELD] = strideAccountNumberError;
		}
	}

	return errors;
};

interface AdvancedSearchFormProps {
	initialValues: SearchTermProps;
	setSearchTerms(value: SearchTermProps): void;
	clearSearchTerms(): void;
}

export const AdvancedSearchForm: FC<AdvancedSearchFormProps> = ({
	initialValues,
	setSearchTerms,
	clearSearchTerms,
}) => {
	const navigate = useNavigate();
	const staticInputProps = {
		height: '54px',
		minWidth: '370px',
		mb: '28px',
	};
	const {
		strideAccountSearch,
		errorMessage: strideErrorMessage,
		isLoading: isStrideSearchLoading,
	} = useStrideAccountSearch();

	const onSubmit = (props: SearchTermProps, actions: FormikHelpers<SearchTermProps>) => {
		actions.setSubmitting(false);
		setSearchTerms(props);
		if (props[STRIDE_ACCOUNT_NUMBER_FIELD]) {
			strideAccountSearch(props[STRIDE_ACCOUNT_NUMBER_FIELD]);
		} else {
			navigate(
				queryString.stringifyUrl({
					url: '/search',
					query: {
						[FIRST_NAME_FIELD]: props[FIRST_NAME_FIELD] || undefined,
						[LAST_NAME_FIELD]: props[LAST_NAME_FIELD] || undefined,
						[PHONE_FIELD]: props[PHONE_FIELD] || undefined,
						[EMAIL_FIELD]: props[EMAIL_FIELD] || undefined,
						[ID_FIELD]: props[ID_FIELD] || undefined,
					},
				})
			);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validate={validate}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({
				isValid,
				isSubmitting,
				dirty,
				values,
				errors,
				handleChange,
				resetForm,
				setValues,
			}) => (
				<Form>
					<Flex data-testid="form-wrapper" flexDirection="column" alignItems="center">
						{strideErrorMessage && (
							<Box mb="s" width="100%">
								<AlertBanner title={strideErrorMessage as string} type="ERROR" />
							</Box>
						)}
						<TextInput
							name={FIRST_NAME_FIELD}
							label="First Name"
							value={values[FIRST_NAME_FIELD]}
							error={errors[FIRST_NAME_FIELD]}
							onChange={handleChange}
							{...staticInputProps}
						/>
						<TextInput
							name={LAST_NAME_FIELD}
							label="Last Name"
							value={values[LAST_NAME_FIELD]}
							error={errors[LAST_NAME_FIELD]}
							onChange={handleChange}
							{...staticInputProps}
						/>
						<TextInput
							name={PHONE_FIELD}
							label="Phone Number"
							value={values[PHONE_FIELD]}
							error={errors[PHONE_FIELD]}
							onChange={handleChange}
							{...staticInputProps}
						/>
						<TextInput
							name={EMAIL_FIELD}
							label="Email"
							value={values[EMAIL_FIELD]}
							error={errors[EMAIL_FIELD]}
							onChange={handleChange}
							type="email"
							{...staticInputProps}
						/>
						<TextInput
							name={ID_FIELD}
							label="User ID"
							value={values[ID_FIELD]}
							error={errors[ID_FIELD]}
							onChange={handleChange}
							{...staticInputProps}
						/>

						<TextInput
							name={STRIDE_ACCOUNT_NUMBER_FIELD}
							label="Stride Bank Account Number"
							assistiveText={
								values[STRIDE_ACCOUNT_NUMBER_FIELD]
									? 'Account number search will ignore all other search criteria'
									: ''
							}
							value={values[STRIDE_ACCOUNT_NUMBER_FIELD]}
							error={errors[STRIDE_ACCOUNT_NUMBER_FIELD]}
							onChange={handleChange}
							{...staticInputProps}
						/>

						<PrimaryButton
							type="submit"
							disabled={
								!isValid ||
								isSubmitting ||
								(!dirty && isEqual(values, defaultState)) ||
								isStrideSearchLoading
							}
							mt="10px"
							// default form submit performs a navigation, so we only need to
							// show loading state for stride search
							loading={isStrideSearchLoading}
						>
							Find user
						</PrimaryButton>
						<TertiaryButton
							type="button"
							mt="20px"
							onClick={() => {
								clearSearchTerms();
								resetForm();
								setValues(defaultState);
							}}
						>
							Clear form
						</TertiaryButton>
					</Flex>
				</Form>
			)}
		</Formik>
	);
};
