import React from 'react';
import { Text } from '@stashinvest/ui/dist/es/2';
import { PageContainer } from '../PageContainer';

export const NotFoundComponent = () => {
	return (
		<PageContainer>
			<header>
				<Text.Label16 p="50px">{"Oh no! That page doesn't exist"}</Text.Label16>
			</header>
		</PageContainer>
	);
};
