import React, { Component, FC } from 'react';
import ReactDOM from 'react-dom';
import { Flex, Box, Divider } from '@stashinvest/ui';
import { Text, CloseIcon } from '@stashinvest/ui/dist/es/2';

import { DrawerVisibilityContext } from '../../providers/DrawerVisibilityProvider';
import { UnstyledButton } from '../UnstyledButton';
import { FullPageContainer, FullPageOverlay } from '../Modal';
import { DrawerProps, DrawerComponentProps, PanelProps } from './Drawer.types';
import { DrawerBody, DrawerPanel } from './Drawer.styles';

let modalRoot = document.getElementById('modal-root');
if (!modalRoot) {
	modalRoot = document.createElement('div');
	modalRoot.setAttribute('id', 'modal-root');
	document.body.appendChild(modalRoot);
}

export class Drawer extends Component<DrawerProps> {
	el: HTMLElement = document.createElement('div');

	componentDidMount() {
		modalRoot?.appendChild(this.el);
	}

	componentWillUnmount() {
		modalRoot?.removeChild(this.el);
	}

	render(): React.ReactPortal {
		const { title, secondaryPanel, children } = this.props;
		return ReactDOM.createPortal(
			<DrawerVisibilityContext.Consumer>
				{(visibilityProps) => (
					<DrawerComponent
						title={title}
						secondaryPanel={secondaryPanel}
						{...visibilityProps}
					>
						{children}
					</DrawerComponent>
				)}
			</DrawerVisibilityContext.Consumer>,
			this.el
		);
	}
}

const DrawerComponent: FC<DrawerComponentProps> = ({
	title,
	secondaryPanel,
	children,
	primaryDrawOpen,
	secondaryDrawOpen,
	setPrimaryDrawOpen,
	setSecondaryDrawOpen,
}) => (
	<FullPageContainer isOpen={primaryDrawOpen} data-testid="drawer-container">
		<FullPageOverlay
			data-testid="drawer-overlay"
			isOpen={primaryDrawOpen}
			onClick={() => {
				setPrimaryDrawOpen(false);
				setSecondaryDrawOpen(false);
			}}
		/>
		<DrawerBody
			isPrimaryOpen={primaryDrawOpen}
			isSecondaryOpen={secondaryDrawOpen}
			data-testid="drawer-body"
		>
			<Flex>
				<Panel
					title={title}
					isPrimary
					open={primaryDrawOpen}
					setOpen={setPrimaryDrawOpen}
				>
					{children({
						toggle: () => setPrimaryDrawOpen(!primaryDrawOpen),
						setOpen: setPrimaryDrawOpen,
						open: primaryDrawOpen,
					})}
				</Panel>
				{secondaryPanel ? (
					<Panel
						title={secondaryPanel.title}
						open={secondaryDrawOpen && primaryDrawOpen}
						setOpen={setSecondaryDrawOpen}
					>
						{secondaryPanel.body
							? secondaryPanel.body({
									toggle: () => setSecondaryDrawOpen(!secondaryDrawOpen),
									setOpen: setSecondaryDrawOpen,
									open: secondaryDrawOpen && primaryDrawOpen,
							  })
							: null}
					</Panel>
				) : null}
			</Flex>
		</DrawerBody>
	</FullPageContainer>
);

const Panel: FC<React.PropsWithChildren<PanelProps>> = ({
	title,
	isPrimary = false,
	setOpen,
	children,
}) => (
	<DrawerPanel isPrimary={isPrimary}>
		<Flex pt="20px" px="20px" pb="10px" height="55px">
			{isPrimary ? (
				<UnstyledButton onClick={() => setOpen(false)} data-testid="close-button">
					<CloseIcon size="1em" mt="3px" ml="-3px" mr="10px" />
				</UnstyledButton>
			) : null}
			{title ? <Text.Label16>{title}</Text.Label16> : null}
		</Flex>
		<Divider width="100%" />
		<Box px="20px" pt="15px">
			{children}
		</Box>
	</DrawerPanel>
);
