import { ReactNode } from 'react';
import { TagVariant } from '../../types';

import { WrapperTrackingProps } from '../../utils';
import { LinkVariantsType } from '../LinkVariant';
import { PaginationProps } from '../Pagination';

import { TableHeaderProps } from './DataTable.styles';

type DataTableCellVal = string | number | ReactNode;

export enum DataTableCellVariant {
	'TAG' = 1,
	'TOOLTIP' = 2,
	'CURRENCY' = 3,
	'DATE' = 4,
	'DATETIME' = 5,
	'EMOJI' = 6,
}

export enum SortOrders {
	'ASC' = 'asc',
	'DESC' = 'desc',
}

export interface DataTableCell extends DataTableCellProps {
	/**
	 * Used to identify what column the field should belong to
	 */
	col: string;
}

export interface DataTableCellProps {
	/**
	 * Used to supply a value to any of the variants, or simply render a string or React Node
	 */
	value?: DataTableCellVal;
	/**
	 * Variant used to choose to render a Tag, Tooltip, Currency, Date or DateTime. See the DataTableCellVariant Type!
	 */
	variant?: DataTableCellVariant;
	/**
	 * Description for tooltip if supplied
	 */
	tooltipBody?: ReactNode;
	/**
	 * Used to give the Tag it's colour
	 */
	tagVariant?: TagVariant;
}

export interface DataTableHeading extends TableHeaderProps {
	/**
	 * Heading title
	 */
	label: string;
	/**
	 * Callback function for column sorting
	 * @param sortOrder specifies order for sorting.  This value is injected by the DataTable component upon trigger.
	 **/
	callback?(sortOrder: SortOrders): void;
}

export interface DataTableRowItem {
	/**
	 * Unique identifier used to ensure React renders dynamically updating rows
	 */
	id: string;
	/**
	 * List of cells to render in each row. Can render a string value or a React Node, along with a Tag, Tooltip, Currency, Date or DateTime
	 */
	cells: DataTableCell[];
	/**
	 * For rendering an action at the end of the row, can be a link or a button
	 */
	action?: {
		label: string;
		path?: string;
		onClick?(): void;
		variant?: LinkVariantsType;
	};
}

export interface DataTableRowProps extends DataTableRowItem {
	/**
	 * List of columns we are able to render fields against
	 */
	cols: DataTableHeading[];
	/**
	 * Wrapper Tracking properties for "page" and "component" field for links to use
	 */
	tracking: WrapperTrackingProps;
}

export interface DataTableProps {
	/**
	 * List of columns we are able to render fields against, constructs the table headers and allows for custom width overrides
	 */
	cols: DataTableHeading[];
	/**
	 * List of rows for our table to render, with a sublist of table cells
	 */
	rows: DataTableRowItem[];
	/**
	 * Properties used to provide pagination capability to the table
	 */
	pagination?: PaginationProps;
	/**
	 * Wrapper Tracking properties for "page" and "component" field for links to use
	 */
	tracking: WrapperTrackingProps;
	/**
	 * Title displayed at top of table
	 */
	title?: ReactNode | string;
	/**
	 * Component to be inserted between the title and table contents, e.g. a filter set
	 */
	beforeTable?: ReactNode;
	/**
	 * Message displayed when table has no rows
	 */
	emptyTableMessage?: string;
	/**
	 * Optional table loading state
	 */
	isLoading?: boolean;
	/**
	 * Optional test id
	 */
	testId?: string;
}

export interface DataTableHeaderProps {
	/**
	 * List of columns we are able to render fields against, constructs the table headers and allows for custom width overrides
	 */
	cols: DataTableHeading[];
}
