import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { LocalStorageProvider } from '@stashinvest/shared-ui';
import { FeatureFlagIdentifier } from 'src/components/FeatureFlagIdentifier';
import { AppRoutes } from './components/AppRoutes/AppRoutes';

interface AppProps {
	oktaAuth: OktaAuth;
}

const basepath = process.env.PUBLIC_URL;

export const App = ({ oktaAuth }: AppProps) => {
	const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string = '') => {
		const originalUriWithoutBasepath = originalUri.replace(basepath, '');
		window.location.assign(
			toRelativeUrl(originalUriWithoutBasepath, window.location.origin) ?? '/'
		);
	};
	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<FeatureFlagIdentifier>
				<LocalStorageProvider>
					<AppRoutes />
				</LocalStorageProvider>
			</FeatureFlagIdentifier>
		</Security>
	);
};
