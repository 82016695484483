import React, { FC } from 'react';
import { Flex } from '@stashinvest/ui';

import { ContentSlider } from '../ContentSlider';
import { DocumentViewerBodyProps, DocumentToView } from './DocumentViewerBody';
import { DocumentViewerItem } from './DocumentViewerItem';
import { useContainerDimensions } from './useContainerDimensions';

type DocumentViewerListProps = Pick<
	DocumentViewerBodyProps,
	'items' | 'emptyText' | 'tracking' | 'canDownloadDocs'
> & { fullWidth?: boolean; rowsDataUpdate(value: number): void };

export const DocumentViewerList: FC<DocumentViewerListProps> = ({
	items,
	emptyText = "Uh oh! We couldn't find this document",
	canDownloadDocs,
	tracking,
	fullWidth = false,
	rowsDataUpdate,
}) => {
	const { containerRef, containerDimensions } = useContainerDimensions();

	return (
		<Flex
			ref={containerRef}
			flexDirection="column"
			position="relative"
			width={fullWidth ? '706px' : '427px'}
			height="100%"
			mx="20px"
		>
			<ContentSlider
				items={items.map((item: DocumentToView) => ({
					id: item.id,
					body: (
						<DocumentViewerItem
							id={item.id}
							title={item.title}
							type={item.type}
							image={item.image}
							mimeType={item.mimeType}
							unavailableReason={item.unavailableReason}
							containerDimensions={containerDimensions}
							canDownloadDocs={canDownloadDocs}
							tracking={tracking}
							disableDownload
						/>
					),
				}))}
				onSlideChange={rowsDataUpdate}
			/>
		</Flex>
	);
};
