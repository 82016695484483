import React, { FC } from 'react';
import { Box } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { valueToEmoji } from '../../utils';
import { JsonItem } from './JsonItem';

interface KeyValueObj {
	[key: string]: string | number | KeyValueObj | Array<KeyValueObj>;
}

export interface JsonItemsProps {
	id: number;
	items: KeyValueObj;
}

export const JsonItems: FC<React.PropsWithChildren<JsonItemsProps>> = ({ id, items }) => (
	<Box as="ul" pt="5px" pl="10px" data-testid="json-list">
		{Object.keys(items).map((key, index) => {
			const value = items[key];
			const valueType = typeof value;
			const mapKey = `${id}-${key}-${value}-${index}`;

			if (valueType === 'string') {
				const emoji = valueToEmoji(value as string);
				return (
					<JsonItem key={mapKey} label={key}>
						<Text.Label14 as="span">{emoji ? emoji : (value as string)}</Text.Label14>
					</JsonItem>
				);
			}

			if (valueType === 'number') {
				return (
					<JsonItem key={mapKey} label={key}>
						<Text.Label14 as="span">{value as number}</Text.Label14>
					</JsonItem>
				);
			}

			if (value === null) {
				return (
					<JsonItem key={mapKey} label={key}>
						<Text.Label14 as="span">null</Text.Label14>
					</JsonItem>
				);
			}

			return (
				<JsonItem key={mapKey} label={key}>
					<JsonItems id={id} items={value as KeyValueObj} />
				</JsonItem>
			);
		})}
	</Box>
);
