import React, { FC, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { FormikTextInput, ChoicePadRadio, Collapse, Box } from '@stashinvest/ui';
import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import styled from 'styled-components';
import {
	QUESTION_IDENTIFIER,
	QUESTION_FIELD_IDENTIFIER,
	QuestionField,
	QuestionFormProps,
} from '../QuestionForm';

interface ExpanderBoxProps {
	isOpened: boolean;
}

const ExpanderBox = styled.div<ExpanderBoxProps>`
	background-color: ${({ theme, isOpened }) =>
		isOpened ? theme.colors.bgTargetSecondary : theme.colors.bgActionSecondary};
	position: relative;
	top: -21px;
	margin: 0 0.5em;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
`;

interface RadioPadNumber {
	disabled: boolean;
	label: QuestionField['label'];
	values: QuestionFormProps;
	errors: FormikErrors<QuestionFormProps>;
	setFieldValue: FormikHelpers<QuestionFormProps>['setFieldValue'];
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const RadioPadWithNumber: FC<React.PropsWithChildren<RadioPadNumber>> = ({
	label,
	setFieldValue,
	values,
	errors,
	isOpen,
	setIsOpen,
	disabled = false,
}) => (
	<Box mb={isOpen ? '-20px' : 0}>
		<ChoicePadRadio
			name={values[QUESTION_IDENTIFIER]}
			inputPosition="left"
			checked={isOpen}
			label={label}
			onChange={() => {
				setIsOpen(true);
				setFieldValue(QUESTION_IDENTIFIER, values[QUESTION_FIELD_IDENTIFIER]);
			}}
		/>

		<ExpanderBox isOpened={isOpen}>
			<Collapse isOpened={isOpen}>
				<Box px="20px" pt="10px">
					<FormikTextInput
						disabled={disabled}
						label="Amount"
						name={QUESTION_FIELD_IDENTIFIER}
						mb="none"
						maxWidth="25rem"
						py="none"
						mx="auto"
						mask="currency"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setFieldValue(QUESTION_IDENTIFIER, e.target.value);
						}}
						error={errors[QUESTION_IDENTIFIER]}
						hideLabel
					/>
				</Box>
			</Collapse>
		</ExpanderBox>
	</Box>
);
