import { useContext } from 'react';
import { Box, FormWrapper } from '@stashinvest/ui';
import { useLocation } from 'react-router-dom';
import { Flex } from '@stashinvest/ui/dist/es/2';
import { TrackedLinkColors } from '@stashinvest/shared-ui';

import { ActiveAdminLink } from 'src/components/ActiveAdminLink';
import { PageContainer } from 'src/components/PageContainer';
import { NO_RESULTS_ROUTE } from 'src/constants/routes';
import { SearchTermContext } from 'src/providers/SearchTermProvider';

import { AdvancedSearchForm } from './components/AdvancedSearchForm';
import { NoResults } from './components/NoResults';

export const Home = () => {
	const { pathname } = useLocation();
	const { searchTerms, setSearchTerms, clearSearchTerms } = useContext(SearchTermContext);
	const noResultsFound = pathname.includes(NO_RESULTS_ROUTE);
	const definedTerms = Object.values(searchTerms).filter((term) => Boolean(term));

	return (
		<PageContainer>
			<FormWrapper mx="auto">
				<Box maxWidth="100%" p="l" mx="auto">
					{noResultsFound && definedTerms.length > 0 ? (
						<NoResults searchTerms={searchTerms} />
					) : null}
					<AdvancedSearchForm
						initialValues={searchTerms}
						setSearchTerms={setSearchTerms}
						clearSearchTerms={clearSearchTerms}
					/>
					<Flex mt="20px" justifyContent="space-between">
						<ActiveAdminLink
							color={TrackedLinkColors.textPrimary}
							path="/ssn_check"
							tracking={{
								page: 'search',
								component: 'ssn-checker',
							}}
						>
							SSN Checker
						</ActiveAdminLink>
						<ActiveAdminLink
							path="/admin"
							color={TrackedLinkColors.textPrimary}
							tracking={{
								page: 'search',
								component: 'search-in-aa',
							}}
						>
							Search in AA
						</ActiveAdminLink>
					</Flex>
				</Box>
			</FormWrapper>
		</PageContainer>
	);
};
