import { ReactNode } from 'react';
import { TagVariant } from '../../types';
import { BaseLinkVariantProps } from '../LinkVariant';
import { WrapperTrackingProps } from '../../utils/analytics';

type CardWidth = '100%' | '50%' | '33.333%' | '25%' | string;

export interface CardLinkProps extends BaseLinkVariantProps {
	label: string;
}

/**
 * Type guard to determine if link is a CardLink or ReactNode
 * @param link
 */
export const isCardLink = (link: CardLinkProps | ReactNode): link is CardLinkProps => {
	return (
		(link as CardLinkProps).label !== undefined &&
		(link as CardLinkProps).path !== undefined
	);
};

export interface CardProps {
	/**
	 * Title for our Card
	 */
	title: string | ReactNode;
	/**
	 * Useful if you want to set a smaller width than Flexbox supplies,
	 * or if you have strict Flex Wrap properties
	 */
	maxWidth?: {
		mobile: CardWidth;
		tablet: CardWidth;
		desktop: CardWidth;
	};
	/**
	 * If you want the Card to link to another location,
	 * this will add a link to the bottom of the card
	 */
	link?: CardLinkProps | ReactNode;
	/**
	 * This will render a Tag on the bottom right
	 * of the card — the variant property will take either
	 * `'info' | 'success' | 'warning' | 'danger'`
	 * and render an appropriate color
	 */
	tag?: {
		variant: TagVariant;
		label: string;
	};
	/**
	 * The properties used for tracking
	 */
	tracking?: WrapperTrackingProps;
	/**
	 * Used to add a flat style to the cards
	 */
	flat?: boolean;
	/**
	 * slot for adding content below the tag, typically used for showing multiple useful links
	 */
	footer?: ReactNode;
	/**
	 * X-Axis padding. Sets the padding (in pixels) of the left and right of the card.
	 * Optional, default 15
	 */
	px?: number;
	/**
	 * Top padding. Sets the padding (in pixels) of the top of the card.
	 * Optiona, default 30
	 */
	pt?: number;
}
