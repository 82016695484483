export function isObjEqualShallow(
	objA: Record<string, unknown>,
	objB: Record<string, unknown>,
	options: { caseInsensitive: boolean } = {
		caseInsensitive: false,
	}
): boolean {
	for (let [key, valueA] of Object.entries(objA)) {
		let valueB = objB[key];

		if (
			options.caseInsensitive &&
			typeof valueA === 'string' &&
			typeof valueB === 'string'
		) {
			valueA = valueA.toLowerCase();
			valueB = valueB.toLowerCase();
		}

		if (valueA !== valueB) {
			return false;
		}
	}

	return true;
}
