import { useEffect } from 'react';

export type OnKeyPressOperator = 1 | -1;

type OnKeyPress = (value: OnKeyPressOperator) => void;

export const useArrowKeys = (onKeyPress: OnKeyPress) => {
	useEffect(() => {
		const handleArrow = (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft') {
				onKeyPress(-1);
			}

			if (event.key === 'ArrowRight') {
				onKeyPress(1);
			}
		};

		window.addEventListener('keydown', handleArrow);

		return () => {
			window.removeEventListener('keydown', handleArrow);
		};
	}, [onKeyPress]);
};
