import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const SearchIcon: FC<React.PropsWithChildren<IconProps>> = ({
	inverted,
	...props
}) => {
	const bgColor = Boolean(inverted) ? '#000' : '#FFF';
	const iconColor = Boolean(inverted) ? '#FFF' : '#000';

	return (
		<IconContainer {...props}>
			<svg width="100%" height="100%" viewBox="0 0 22 22" fill="none">
				<circle cx="9" cy="9" r="7" fill={bgColor} data-testid="search-icon-bg" />
				<path
					d="M13 9.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
					fill={iconColor}
					data-testid="search-icon"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 11C0 4.925 4.925 0 11 0s11 4.925 11 11-4.925 11-11 11S0 17.075 0 11zm10.5 3c.584 0 1.142-.111 1.655-.314a.787.787 0 00.095.139l1.85 2.164c.34.398 1.008.386 1.491-.027.483-.413.6-1.071.259-1.47L14 12.33A4.5 4.5 0 1010.5 14z"
					fill={iconColor}
				/>
			</svg>
		</IconContainer>
	);
};
