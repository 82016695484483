import React, { FC, useEffect, useState } from 'react';
import { WrapperTrackingProps } from '../../utils/analytics';
import { TrackedLink, TrackedLinkVariants } from '../TrackedLink';

export const downloadFileOnSameDomain = async (url: string, mimeType: string) => {
	try {
		const response = await fetch(url, {
			method: 'GET',
		});
		const blob = await response.blob();

		if (!blob) {
			throw new Error('No blob for file');
		}

		return window.URL.createObjectURL(new Blob([blob], { type: mimeType }));
	} catch (error) {
		throw error;
	}
};

export interface DownloadableButtonProps {
	buttonText: string;
	fileURL: string;
	tracking: WrapperTrackingProps;
	fileName: string;
	mimeType: string;
}

export const DownloadableButton: FC<React.PropsWithChildren<DownloadableButtonProps>> = ({
	fileName,
	buttonText,
	fileURL,
	tracking,
	mimeType,
}) => {
	const [downloadableFile, setFile] = useState<string | null>(null);

	useEffect(() => {
		(async () => {
			try {
				const response = await downloadFileOnSameDomain(fileURL, mimeType);
				setFile(response);
			} catch (error) {
				setFile(null);
			}
		})();
	}, [fileURL, mimeType]);

	return downloadableFile ? (
		<TrackedLink
			href={downloadableFile}
			type={TrackedLinkVariants.BUTTON}
			download={fileName}
			size="medium"
			width="200px"
			ml="10px"
			tracking={{
				...tracking,
				label: 'download-button',
			}}
		>
			{buttonText}
		</TrackedLink>
	) : null;
};
