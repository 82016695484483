import { useQuery } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';

import { IsContentOnly } from '@stashinvest/shared-types/gateway/api';
import { config } from 'src/config/config';

export const getUserIsContentOnly = async (
	userId: string,
	accessToken: string
): Promise<IsContentOnly> => {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/${userId}/isContentOnly`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		}
	);

	const responseBody = await response.json();

	if (!response.ok) {
		switch (response.status) {
			case 404:
				throw new Error("This user's profile was not found");
			case 401:
				throw new Error('Unauthorized');
			default:
				// TODO: export the gateway error type from shared-types and passthrough the actual error message
				throw new Error(
					'Something went wrong while fetching content only status of the user'
				);
		}
	}

	return responseBody;
};

export const useIsUserContentOnly = (userId: string) => {
	const { oktaAuth } = useOktaAuth();

	return useQuery<IsContentOnly>(['isContentOnly', userId], () =>
		getUserIsContentOnly(userId, oktaAuth.getAccessToken())
	);
};
