import { useQuery } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';
import { config } from 'src/config/config';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { AccountSearchResponse } from '@stashinvest/shared-types/schemas/account';

export const getStrideAccount = async (
	accessToken: string,
	strideBankAccountNumber?: string
) => {
	if (!strideBankAccountNumber) {
		throw new Error('Stride Bank Account missing');
	}
	const response = await fetch(`${config.expGatewayUrl}/api/v1/accounts/search`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			accountNumber: strideBankAccountNumber,
		}),
	});

	const responseBody = (await response.json()) as AccountSearchResponse;

	if (!response.ok) {
		switch (response.status) {
			case 401:
				throw new Error('Unauthorized');
			case 404:
				throw new Error('Account not found');
			default:
				throw new Error('Something went wrong while searching for stride accounts');
		}
	}

	if (!responseBody.account) {
		throw new Error('Account not found');
	}

	return responseBody.account;
};

export const useStrideAccountSearch = (): {
	strideAccountSearch: (accountNumber: string) => void;
	errorMessage: string | undefined;
	isLoading: boolean;
} => {
	const navigate = useNavigate();
	const { oktaAuth } = useOktaAuth();
	const [accountNumber, setAccountNumber] = useState<string>();
	const query = useQuery(
		['strideAccountSearch', accountNumber],
		() => getStrideAccount(oktaAuth.getAccessToken(), accountNumber),
		{
			enabled: Boolean(accountNumber),
			retry: false,
			onSuccess: (account) => {
				const userId = account?.userId;
				navigate(`/id/${userId}`);
			},
		}
	);
	const errorMessage = query.error
		? query.error instanceof Error
			? query.error.message
			: typeof query.error === 'string'
			? query.error
			: 'Something went wrong'
		: undefined;

	return {
		strideAccountSearch: setAccountNumber,
		errorMessage,
		isLoading: query.isFetching,
	};
};
