import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { AlertBannerProps } from './AlertBanner.types';

interface BannerContainerProps {
	color: string;
}

const BannerContainer = styled(Box)<BannerContainerProps>`
	border-radius: 10px;
	max-width: 948px;
	width: 100%;
	margin: 0 auto;
	svg {
		color: ${({ theme, color }) => theme.colors[color]};
	}
`;

const colorMap = {
	ERROR: {
		bg: 'bgNegativeSecondary',
		color: 'textNegativeSecondary',
	},
	WARNING: {
		bg: 'bgWarningSecondary',
		color: 'textWarningSecondary',
	},
	SUCCESS: {
		bg: 'bgPositiveSecondary',
		color: 'textPositiveSecondary',
	},
};

export const AlertBanner: FC<React.PropsWithChildren<AlertBannerProps>> = ({
	title,
	type = 'ERROR',
	icon,
	body,
	children,
}) => {
	const { bg, color } = colorMap[type];
	return (
		<BannerContainer
			bg={bg}
			py="16px"
			px="28px"
			data-testid="alert-banner-container"
			color={color}
		>
			<Flex>
				{icon ? (
					<Box
						pr="10px"
						display={{ mobile: 'none', tablet: 'flex' }}
						justifyContent="center"
						mt="3px"
					>
						{icon}
					</Box>
				) : null}
				<Flex flexDirection="column" flexGrow={1}>
					<Text.Body16 fontWeight="bold" color={color}>
						{title}
					</Text.Body16>
					{body ? (
						<Text.Body16 mt="8px" color={color}>
							{body}
						</Text.Body16>
					) : null}
					{children}
				</Flex>
			</Flex>
		</BannerContainer>
	);
};
