import { FC } from 'react';
import styled from 'styled-components';

import { Flex, Box, Divider } from '@stashinvest/ui';
import { Text, Link } from '@stashinvest/ui/dist/es/2';
import { JsonList, FormattedDate } from '@stashinvest/shared-ui';
import { CommentBodyType } from '@stashinvest/shared-types/comments';

import { CommentProps, CommentBodySectionProps } from '../Comments';
import { CommentIcon } from './CommentIcon';
import { CommentTitle } from './CommentTitle';

const CommentContent = styled(Text.Label16)`
	word-wrap: break-word;
	white-space: pre-line;
	color: ${({ theme }) => theme.colors.textPrimary};
	margin-bottom: 1.5em;
`;

export const CommentBodySection: FC<CommentBodySectionProps & { id: number }> = ({
	id,
	type,
	content,
	link,
}) => {
	switch (type) {
		case CommentBodyType.JSON:
			return (
				<Box pt="15px" key={`${id}`}>
					<JsonList id={id} json={content} />
				</Box>
			);

		case CommentBodyType.TEXT:
			const lines = content.split('\n');
			return (
				<>
					{lines.map((line, index) => (
						<CommentContent
							key={`${id}-${index}`}
							as={index === lines.length - 1 ? 'span' : 'p'}
						>
							{line}
						</CommentContent>
					))}
				</>
			);

		case CommentBodyType.LINK:
			return (
				<Link display="inline" href={link} target="_blank">
					{content}
				</Link>
			);
	}
};

export const Comment: FC<CommentProps> = ({
	id,
	isAuto,
	authorEmail,
	body,
	createdAt,
}) => (
	<Box mb="20px" position="relative" data-testid="user-comment">
		<CommentIcon isAuto={isAuto} />
		<Flex flexDirection="column" pl="50px" width="100%">
			<CommentTitle isAuto={isAuto} authorEmail={authorEmail} />
			<Box pt="15px">
				{body.map((comment) => (
					<CommentBodySection
						key={id}
						id={id}
						type={comment.type}
						content={comment.content}
						link={comment.link}
					/>
				))}
			</Box>
			<Box p="15px 0 20px 0">
				<Text.Label16>
					<FormattedDate
						timestamp={createdAt}
						showTime
						showTimezone
						nullValue="Unknown datetime"
					/>
				</Text.Label16>
			</Box>
			<Divider m="0" />
		</Flex>
	</Box>
);
