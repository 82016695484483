import React, { FC, KeyboardEvent, ClipboardEvent } from 'react';
import styled from 'styled-components';
import { Formik, Form, FormikHelpers } from 'formik';
import { FormikTextInput, Flex } from '@stashinvest/ui';
import { useNavigate } from 'react-router-dom';
import {
	validateSearchTerm,
	hasValidEmail,
	hasValidUUID,
	hasValidPhoneNumber,
} from '../../utils';
import {
	Environments,
	StashApps,
	defaultBaseUrlConfig,
} from '../../providers/EnvironmentProvider';

const SEARCH_FIELD = 'search';

interface SearchFormValueProps {
	[SEARCH_FIELD]: string;
}
export interface ExtendedSearchFormProps extends SearchFormProps {
	searchResponse?: string;
	navigate(path: string, state?: unknown): void;
}

const searchFormValues = {
	[SEARCH_FIELD]: '',
} as SearchFormValueProps;

const StyledForm = styled(Form)`
	margin-top: 5px;
	margin-left: auto;
	input {
		font-size: ${({ theme }) => theme.fonts.body14.fontSize};
	}
`;

interface KeyDownEnterProps {
	event: KeyboardEvent<HTMLInputElement>;
	submitForm(): void;
}

const keyDownEnter = ({ event, submitForm }: KeyDownEnterProps) => {
	if (event.key === 'Enter') {
		const target = event.target as HTMLInputElement;
		target.blur();
		submitForm();
	}
};

interface PasteHandler
	extends Pick<FormikHelpers<SearchFormValueProps>, 'setFieldValue'> {
	event: ClipboardEvent<HTMLInputElement>;
	fieldName: string;
}

const pasteHandler = ({ event, setFieldValue, fieldName }: PasteHandler) => {
	if (
		event.clipboardData &&
		event.clipboardData.getData &&
		event.clipboardData.getData('Text')
	) {
		event.preventDefault();
		const trimmedValue = event.clipboardData.getData('Text').trim();
		setFieldValue(fieldName, trimmedValue, true);
	}
};

export const SearchFormComponent: FC<
	React.PropsWithChildren<ExtendedSearchFormProps>
> = ({ onUserHub, environment, navigate, clearResponse, clearSearchTerms }) => {
	const onSubmit = (
		{ search }: SearchFormValueProps,
		actions: FormikHelpers<SearchFormValueProps>
	) => {
		clearSearchTerms?.();
		clearResponse?.();
		actions.setSubmitting(false);
		actions.resetForm();
		const userHubURL = `${defaultBaseUrlConfig[StashApps.USER_HUB][environment]}/user`;

		if (hasValidUUID(search)) {
			const idSearchRoute = '/id/' + search;
			if (onUserHub) {
				navigate(idSearchRoute);
			} else {
				window.location.assign(`${userHubURL}${idSearchRoute}`);
			}
		}

		if (hasValidEmail(search)) {
			const emailSearchPath = `/search?email=${encodeURIComponent(
				search
			)}&autoRedirect=true`;
			if (onUserHub) {
				navigate(emailSearchPath);
			} else {
				window.location.assign(`${userHubURL}${emailSearchPath}`);
			}
		}

		if (hasValidPhoneNumber(search)) {
			const phoneSearchPath = `/search?phone=${encodeURIComponent(
				search
			)}&autoRedirect=true`;
			if (onUserHub) {
				navigate(phoneSearchPath);
			} else {
				window.location.assign(`${userHubURL}${phoneSearchPath}`);
			}
		}
	};

	return (
		<Formik initialValues={searchFormValues} onSubmit={onSubmit}>
			{({ submitForm, setFieldValue }) => (
				<StyledForm>
					<Flex data-testid="form-wrapper" alignItems="flex-start">
						<FormikTextInput
							data-testid="input"
							name={SEARCH_FIELD}
							placeholder="Email, phone number, or ID"
							validate={validateSearchTerm}
							label="Search"
							hideLabel
							px="s12"
							onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
								keyDownEnter({ event, submitForm })
							}
							onPaste={(event: ClipboardEvent<HTMLInputElement>) => {
								pasteHandler({
									event,
									setFieldValue,
									fieldName: SEARCH_FIELD,
								});
							}}
							height="36px"
							width="260px"
						/>
					</Flex>
				</StyledForm>
			)}
		</Formik>
	);
};

export interface SearchFormProps {
	/**
	 * if you are on UserHub render a relative link
	 */
	onUserHub?: boolean;
	/**
	 * Current Environment for rendering links
	 */
	environment: Environments;
	/**
	 * Optional: Clear search response
	 */
	clearResponse?(): void;
	/**
	 * Optional: Clear search terms
	 */
	clearSearchTerms?(): void;
}

export const SearchForm: FC<React.PropsWithChildren<SearchFormProps>> = ({
	onUserHub = false,
	environment,
	clearResponse,
	clearSearchTerms,
}) => {
	const navigate = useNavigate();

	return (
		<SearchFormComponent
			navigate={navigate}
			environment={environment}
			clearResponse={clearResponse}
			clearSearchTerms={clearSearchTerms}
			onUserHub={onUserHub}
		/>
	);
};
