import React, { FC } from 'react';
import { Box } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

interface JsonItemProps {
	label: string;
}

export const JsonItem: FC<React.PropsWithChildren<JsonItemProps>> = ({
	children,
	label,
}) => (
	<Box as="li" pb="5px">
		<Text.Label14 fontWeight="bold" as="span">
			{label}:
		</Text.Label14>{' '}
		{children}
	</Box>
);
