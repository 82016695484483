import styled from 'styled-components';
import { Flex } from '@stashinvest/ui';

export const DocViewerDivider = styled.hr`
	height: 1px;
	background-color: ${({ theme }) => theme.colors.textTertiary};
	opacity: 0.5;
	border: 0;
	width: 100%;
	margin: 9px 0 0 0;
`;

export const DocViewerVerticalDivider = styled.div`
	height: 100%;
	max-width: 1px;
	background-color: ${({ theme }) => theme.colors.textTertiary};
	opacity: 0.5;
	border: 0;
	width: 100%;
	margin: 0px 10px;
`;

interface AnimatedImageProps {
	rotate: number;
}

export const AnimatedImage = styled.img<AnimatedImageProps>`
	width: 100%;
	height: 100%;
	object-fit: contain;
	transition-property: object-fit, transform;
	transition-duration: 0.2s;
	transition-timing-function: ease-in;
	transform: rotate(${({ rotate = 0 }) => rotate}deg);
`;

export const AnimatedButtonContainer = styled(Flex)`
	opacity: ${({ loaded }) => (loaded ? '1' : '0')};
	transition-property: opacity;
	transition-duration: 0.1s;
	transition-timing-function: linear;
`;
