import React, { FunctionComponent } from 'react';
import { FormattedDate } from '@stashinvest/shared-ui';
import type { FormattedDateProps } from '@stashinvest/shared-ui';
import { DataField } from '../../DataField';

interface FormattedDateDataFieldProps extends FormattedDateProps {
	partialObject: any;
	propertyName: string;
	nullValue?: string;
}

export const FormattedDateDataField: FunctionComponent<FormattedDateDataFieldProps> = ({
	partialObject,
	propertyName,
	nullValue = 'Unknown',
	...otherProps
}: FormattedDateDataFieldProps) => {
	const fieldValue = partialObject[propertyName] as Date;

	return (
		<DataField partialObject={partialObject} propertyName={propertyName}>
			{fieldValue ? (
				<FormattedDate timestamp={fieldValue.toISOString()} {...otherProps} />
			) : (
				nullValue
			)}
		</DataField>
	);
};
