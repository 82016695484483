export const investAccountTypes = {
	UNKNOWN_TYPE: 'Unknown',
	PERSONAL_BROKERAGE: 'Personal Brokerage',
	CUSTODIAN: 'Custodial',
	ROTH_IRA: 'Roth IRA',
	TRADITIONAL_IRA: 'Traditional IRA',
	ROBO_PERSONAL_BROKERAGE: 'Smart Portfolio',
} as const;

export type InvestAccountType = keyof typeof investAccountTypes;
