import React, { FC } from 'react';
import { Flex } from '@stashinvest/ui';
import { string as ystr, object as yobj } from 'yup';

import { QuestionFieldAndFormProps } from '../QuestionTreeItem.types';
import { QuestionForm, QUESTION_IDENTIFIER } from '../QuestionForm';
import { SizeButtons, NumberTextInput } from './QuestionWithNumber.styles';

const Schema = yobj().shape({
	[QUESTION_IDENTIFIER]: ystr().required('Required'),
});

export const QuestionWithNumber: FC<
	React.PropsWithChildren<QuestionFieldAndFormProps>
> = ({ label, value = 1, disabled = false, ...formProps }) => (
	<QuestionForm schema={Schema} value={value} disabled={disabled} {...formProps}>
		{({ values, errors, handleChange, setFieldValue }) => (
			<Flex mb="24px" justifyContent="center">
				<SizeButtons
					type="button"
					disabled={values[QUESTION_IDENTIFIER]! === 1 || disabled}
					onClick={() => {
						if (values[QUESTION_IDENTIFIER]! > 1) {
							setFieldValue(QUESTION_IDENTIFIER, Number(values.question) - 1);
						}
					}}
				>
					-
				</SizeButtons>
				<NumberTextInput
					disabled={disabled}
					name={QUESTION_IDENTIFIER}
					label={label}
					width="80px"
					onChange={handleChange}
					value={values[QUESTION_IDENTIFIER]}
					error={errors[QUESTION_IDENTIFIER]}
					hideLabel
					type="number"
				/>
				<SizeButtons
					type="button"
					disabled={disabled}
					onClick={() => {
						setFieldValue(QUESTION_IDENTIFIER, Number(values[QUESTION_IDENTIFIER]) + 1);
					}}
				>
					+
				</SizeButtons>
			</Flex>
		)}
	</QuestionForm>
);
