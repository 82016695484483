import React, { FC } from 'react';
import { TextInput, Textarea } from '@stashinvest/ui';
import { string as ystr, object as yobj } from 'yup';

import { QuestionTreeTypes } from '../../QuestionTree.types';
import { QuestionWithNumber } from '../QuestionWithNumber';
import { QuestionWithDatePicker } from '../QuestionWithDatePicker';
import { QuestionForm, ErrorMessage, QUESTION_IDENTIFIER } from '../QuestionForm';
import { QuestionWithInputProps } from './QuestionWithInput.types';
import { QuestionWithText } from './QuestionWithText';

const CurrencySchema = yobj().shape({
	[QUESTION_IDENTIFIER]: ystr().required('Required'),
});

const TextSchema = yobj().shape({
	[QUESTION_IDENTIFIER]: ystr().required('Required'),
});

export const QuestionWithInput: FC<React.PropsWithChildren<QuestionWithInputProps>> = ({
	variant = QuestionTreeTypes.TEXT,
	disabled,
	field,
	...otherProps
}) => {
	const formProps = {
		value: field.value,
		callback: field.callback,
		disabled: Boolean(disabled),
	};

	if (variant === QuestionTreeTypes.CURRENCY) {
		return (
			<QuestionForm schema={CurrencySchema} {...formProps} {...otherProps}>
				{({ values, errors, handleChange }) => (
					<>
						<TextInput
							disabled={formProps.disabled}
							name={QUESTION_IDENTIFIER}
							label={field.label}
							maxWidth="200px"
							mx="auto"
							mask="currency"
							onChange={handleChange}
							value={values[QUESTION_IDENTIFIER]}
							error={errors[QUESTION_IDENTIFIER]}
							hideLabel
							placeholder="$0.00"
						/>{' '}
						{field.inlineError ? <ErrorMessage>{field.inlineError}</ErrorMessage> : null}
					</>
				)}
			</QuestionForm>
		);
	}

	if (variant === QuestionTreeTypes.DATE) {
		return (
			<>
				<QuestionWithDatePicker
					disabled={formProps.disabled}
					{...field}
					{...otherProps}
				/>
				{field.inlineError ? <ErrorMessage>{field.inlineError}</ErrorMessage> : null}
			</>
		);
	}

	if (variant === QuestionTreeTypes.NUMBER) {
		return (
			<>
				<QuestionWithNumber disabled={formProps.disabled} {...field} {...otherProps} />
				{field.inlineError ? <ErrorMessage>{field.inlineError}</ErrorMessage> : null}
			</>
		);
	}

	if (variant === QuestionTreeTypes.MULTI_TEXT) {
		return (
			<QuestionForm schema={TextSchema} {...formProps} {...otherProps}>
				{({ values, errors, handleChange }) => (
					<>
						<Textarea
							disabled={formProps.disabled}
							name={QUESTION_IDENTIFIER}
							maxWidth="400px"
							mx="auto"
							label={field.label}
							onChange={handleChange}
							value={values[QUESTION_IDENTIFIER]}
							error={errors[QUESTION_IDENTIFIER]}
							mb="36px"
						/>
						{field.inlineError ? <ErrorMessage>{field.inlineError}</ErrorMessage> : null}
					</>
				)}
			</QuestionForm>
		);
	}

	if (variant === QuestionTreeTypes.TEXT) {
		return (
			<QuestionForm schema={TextSchema} {...formProps} {...otherProps}>
				{({ values, errors, handleChange }) => (
					<>
						<TextInput
							disabled={formProps.disabled}
							name={QUESTION_IDENTIFIER}
							maxWidth="400px"
							mx="auto"
							label={field.label}
							onChange={handleChange}
							value={values[QUESTION_IDENTIFIER]}
							error={errors[QUESTION_IDENTIFIER]}
						/>
						{field.inlineError ? <ErrorMessage>{field.inlineError}</ErrorMessage> : null}
					</>
				)}
			</QuestionForm>
		);
	}

	if (variant === QuestionTreeTypes.INFORMATIVE) {
		return (
			<QuestionForm
				schema={TextSchema}
				{...formProps}
				{...otherProps}
				buttonLabel={otherProps.buttonLabel ?? field.value?.toString()}
			>
				{() => (
					<>
						<QuestionWithText label={field.label} />
						{field.inlineError ? <ErrorMessage>{field.inlineError}</ErrorMessage> : null}
					</>
				)}
			</QuestionForm>
		);
	}

	return null;
};
