import React, { FC } from 'react';
import { Box } from '@stashinvest/ui';

export interface IconProps {
	size?: string;
	color?: string;
	display?: string;
	mb?: string;
	inverted?: boolean;
	as?: string;
}

export const IconContainer: FC<React.PropsWithChildren<IconProps>> = ({
	size = '16px',
	color = 'black',
	display = 'inline-block',
	children,
	...rest
}) => (
	<Box
		height={size}
		width={size}
		color={color}
		aria-hidden="true"
		display={display}
		{...rest}
	>
		{children}
	</Box>
);
