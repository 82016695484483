import styled from 'styled-components/macro';
import { Flex, Box, Tile } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { Pagination, QuickPagination } from '@stashinvest/shared-ui';

import { Comments, CommentsError, EmptyComments } from 'src/components/Comments';
import { LoadingErrorWrapper } from 'src/components/LoadingErrorWrapper';
import { PaginatedComment } from '@stashinvest/shared-types/comments';

const CommentsTile = styled(Tile)`
	width: 100%;
	position: relative;
`;

const QuickNavContainer = styled(Box)`
	position: absolute;
	right: 20px;
	top: 30px;
`;

export type UserCommentsProps = {
	comments?: PaginatedComment;
	setPage(page: number): void;
	error?: unknown;
	loading: boolean;
};

export const UserComments = ({
	comments,
	setPage,
	error,
	loading,
}: UserCommentsProps) => {
	const commentsList = comments?.comments || [];
	const total = comments?.total || 0;
	const perPage = comments?.perPage || 1;
	const page = comments?.page || 1;
	const pageCount = total && perPage ? Math.ceil(total / perPage) : 0;
	const pageConfig = {
		onPageClick: setPage,
		currentPage: page,
		pageCount,
	};

	return (
		<Box mt="30px" px="15px" width="100%" data-testid="user-comment-list">
			{!error ? (
				<CommentsTile>
					<Text.Label18 fontWeight="bold">Comments</Text.Label18>
					<QuickNavContainer>
						<QuickPagination {...pageConfig} />
					</QuickNavContainer>
					<LoadingErrorWrapper loading={loading}>
						{commentsList?.length >= 1 ? (
							<Comments comments={commentsList} />
						) : (
							<EmptyComments />
						)}
					</LoadingErrorWrapper>
					<Flex justifyContent="center">
						<Pagination {...pageConfig} />
					</Flex>
				</CommentsTile>
			) : (
				<CommentsError />
			)}
		</Box>
	);
};
