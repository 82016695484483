import { FC } from 'react';
import * as Sentry from '@sentry/react';
import { ThemeProvider, GlobalStyle, Text, Box } from '@stashinvest/ui';

export const ErrorBoundary: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
	<Sentry.ErrorBoundary
		fallback={({ error, eventId, resetError }) => (
			<ThemeProvider>
				<GlobalStyle withFonts />
				<Box maxWidth="1200px" margin="30px auto" p="0 20px 100px">
					<Text.H3 size="3" pb="20px">
						Oh no! Something went wrong
					</Text.H3>
					<Text pb="20px">Error ID: {eventId}</Text>
					<Text pb="20px">{error.toString()}</Text>
				</Box>
			</ThemeProvider>
		)}
		showDialog
	>
		{children}
	</Sentry.ErrorBoundary>
);
