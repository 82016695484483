import styled from 'styled-components';
import { Flex } from '@stashinvest/ui';

import { UnstyledButton } from '../UnstyledButton';
import { ModalProps } from './Modal';

interface DrawerOpenStateProps {
	isOpen: boolean;
}

export const FullPageContainer = styled.div<DrawerOpenStateProps>`
	z-index: 6;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
`;

export const FullPageOverlay = styled.div<DrawerOpenStateProps>`
	background: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
	transition-property: opacity;
	transition-duration: 0.2s;
	transition-timing-function: ease-in;
`;

export const ModalBody = styled(Flex)<ModalProps>`
	position: relative;
	opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
	color: ${({ theme }) => theme.colors.textPrimary};
	padding: ${({ theme }) => theme.space.m};
	width: ${({ width, isFullscreen }) => (isFullscreen ? '100%' : width || '90%')};
	height: ${({ height, isFullscreen }) => (isFullscreen ? '100%' : height || '90%')};
	box-shadow: ${({ theme }) => theme.shadows.shadow16};
	background-color: ${({ theme, isFullscreen }) =>
		isFullscreen ? theme.colors.bgPrimary : theme.colors.bgSecondary};
	margin-left: auto;
	margin-right: auto;
	margin-bottom: ${({ theme }) => theme.space.s};
	border-radius: 0.5rem;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	transform: ${({ isOpen, isFullscreen }) =>
		isOpen && !isFullscreen ? 'translateY(20px)' : 'translateY(0)'};
	transition-property: transform;
	transition-duration: 0.2s;
	transition-timing-function: ease-in;
`;

export const ModalCloseBtn = styled(UnstyledButton)`
	position: absolute;
	top: 30px;
	right: 15px;
`;
