import { Fragment } from 'react';
import { Text } from '@stashinvest/ui/dist/es/2';

export function textComponentType(textType: string | undefined) {
	switch (textType) {
		case 'Label16':
			return Text.Label16;
		case 'Label14':
			return Text.Label14;
		case 'Label12':
			return Text.Label12;
		default:
			return Fragment;
	}
}
