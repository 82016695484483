import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Flex, Tile, Tag } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { LinkVariant } from '../LinkVariant';
import { CardLinkProps, CardProps, isCardLink } from './Card.types';

const CardTag = styled(Tag)`
	position: absolute;
	bottom: 0px;
	right: 0px;
`;
const Link: FC<
	React.PropsWithChildren<{
		tracking: CardProps['tracking'];
		link?: CardLinkProps | React.ReactNode;
	}>
> = ({ link, tracking }) => {
	if (typeof link === 'undefined') return null;
	return (
		<Box mt="10px">
			{isCardLink(link) ? (
				<LinkVariant
					{...link}
					tracking={{
						page: tracking?.page || 'undefined',
						component: tracking?.component || 'card',
						label: 'card-link',
					}}
				>
					{link.label}
				</LinkVariant>
			) : (
				<>{link}</>
			)}
		</Box>
	);
};
export const Card: FC<React.PropsWithChildren<CardProps>> = ({
	title,
	maxWidth = { mobile: '100%', tablet: '100%', desktop: '100%' },
	link,
	tag,
	children,
	tracking,
	flat = false,
	footer,
	px = 15,
	pt = 30,
}) => (
	<Flex
		px={px}
		pt={pt}
		flexDirection="row"
		maxWidth={maxWidth}
		minWidth="300px"
		minHeight="162px"
		width="100%"
	>
		<Tile
			mb={0}
			style={{ width: '100%', overflow: 'visible', positon: 'relative' }}
			backgroundColor={flat ? 'inherit' : 'bgSecondary'}
			data-testid="card"
		>
			{typeof title === 'string' ? (
				<Text.Label18 fontWeight="bold" as="span" color="textPrimary">
					{title}
				</Text.Label18>
			) : (
				title
			)}
			<Flex
				flexDirection="column"
				alignItems="flex-start"
				pt="10px"
				style={{ position: 'relative' }}
			>
				{tag ? <CardTag variant={tag.variant}>{tag.label}</CardTag> : null}
				{children}
				<Link link={link} tracking={tracking} />
			</Flex>
			{footer}
		</Tile>
	</Flex>
);
