import styled from 'styled-components';

interface GridProps {
	rows?: number;
	columns?: number;
}

export const Grid = styled.div<GridProps>`
	display: grid;
	grid-template-columns: repeat(${({ columns }) => columns ?? 2}, 1fr);
	grid-template-rows: repeat(${({ rows }) => rows ?? 2}, max-content);
`;
