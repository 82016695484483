import { ReactElement } from 'react';
import { BankAccountStatusReason } from 'src/types/global';
import { UploadedDocumentsLink } from 'src/components/UploadedDocumentsLink';
import { UserSearchLink } from 'src/components/UserSearchLink';
import {
	UserLockReasonMap,
	UserLockReasonMapType,
	UserLockReasonKey,
} from '@stashinvest/shared-types/schemas/userLocks';

type LockReasonPropsMap = Record<
	UserLockReasonMapType[UserLockReasonKey],
	LockReasonProps
>;

export type LockReasonKey = keyof LockReasonPropsMap;

export type LockReasonProps = {
	title: string;
	description?: string;
	knowledgeBaseLink?: string;
	nextStepLink?: ReactElement;
};

export const lockReasons: LockReasonPropsMap = {
	[UserLockReasonMap.noLockReason]: {
		title: 'NO_LOCK_REASON',
		description:
			'Due to a technical error, we cannot display the lock reason, please refer to Active Admin or Debit Portal',
	},
	[UserLockReasonMap.unknown]: {
		title: 'LOCK_REASON_UNKNOWN',
		description:
			'Due to a technical error, we cannot display the lock reason, please refer to Active Admin or Debit Portal',
	},
	[UserLockReasonMap.investUSER_LOCKED]: {
		title: 'USER_LOCKED',
		description:
			"Customer's account is locked. Please check the comments for more details and refer to the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/6586369038740-Quick-Guide-Why-is-My-Account-Locked-',
	},
	[UserLockReasonMap.investRETURNED_DEPOSIT]: {
		title: 'RETURNED_DEPOSIT',
		description:
			"Customer's account is locked due to Returned Deposits, meaning an attempted money transfer into their Stash Invest account was returned by their external bank. Please see the KB for next steps on each type of Return.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360052470132-Return-Deposit-Locked-Accounts%C2%A0',
		nextStepLink: <UploadedDocumentsLink />,
	},
	[UserLockReasonMap.investRETURNED_MAIL]: {
		title: 'RETURNED_MAIL',
		description:
			"Customer's account is locked due to too many failed attempts by our Custodian to deliver physical mail and email statements to the customer. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360000822466-Return-Mail-',
		nextStepLink: <UploadedDocumentsLink />,
	},
	[UserLockReasonMap.investOFAC_COUNTRY]: {
		title: 'OFAC_COUNTRY',
		description:
			"Customer's account was locked due to applying for Stash from a restricted country. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360042789151-Onboarding-Accounts-Unsupported',
	},
	[UserLockReasonMap.investPENDING_ACAT]: {
		title: 'PENDING_ACAT',
		description:
			"Customer's account is locked as there is a pending ACAT on their account, meaning they have initiated a transfer of all or some of their shares to an outside broker. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360000822626-ACAT-Overview',
	},
	[UserLockReasonMap.investCHILD_SUPPORT_LEVY]: {
		title: 'CHILD_SUPPORT_LEVY',
		description:
			"Customer's account is locked by our Custodian. The state they live in has requested that our Custodian lock their account until funds are provided for outstanding child support. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360043470971-Levies',
	},
	[UserLockReasonMap.investNONSPECIFIC_LEVY]: {
		title: 'NONSPECIFIC_LEVY',
		description:
			"Customer's account is locked by our Custodian. The state they live in requested that our Custodian lock their account until funds are provided for outstanding money they owe to the Government. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360043470971-Levies',
	},
	[UserLockReasonMap.investLEGAL_RESTRICTION]: {
		title: 'LEGAL_RESTRICTION',
		description:
			"Customer's account is locked for operations review. Please let the customer know we are unable to service their account at this time, and escalate the ticket to NYC.",
	},
	[UserLockReasonMap.investBACKUP_WITHHOLDING]: {
		title: 'BACKUP_WITHHOLDING',
		description:
			"Customer's account is locked as there is some information mismatch such as name or social, and the IRS is requiring them to fill out a W9 to confirm their info. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360040262711-Back-Up-Withholdings',
	},
	[UserLockReasonMap.investMIGRATE_ONTO_TIER_REQUIRED]: {
		title: 'MIGRATE_ONTO_TIER_REQUIRED',
		description:
			"Customer's account is locked as they are not on a subscription plan. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360038084652-Locked-for-no-subscription-',
	},
	[UserLockReasonMap.investADDITIONAL_DOCUMENTS_REQUESTED]: {
		title: 'ADDITIONAL_DOCUMENTS_REQUESTED',
		description:
			"Customer's account is locked for a high sigma score during onboarding. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360055858012-Onboarding-Queued-for-Apex-Restricted',
		nextStepLink: <UploadedDocumentsLink />,
	},
	[UserLockReasonMap.investDUPLICATE_ACCOUNT]: {
		title: 'DUPLICATE_ACCOUNT',
		description:
			"Customer's account is locked as it is a duplicate account, created by the customer in error. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360033682391-Duplicate-Account-Process',
		nextStepLink: <UserSearchLink />,
	},
	[UserLockReasonMap.investUnknown]: {
		title: 'USER_LOCKED',
		description:
			"Customer's account is locked. Please check the comments for more details and refer to the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151',
	},
	[UserLockReasonMap.investESCHEATMENT_RESTRICTION]: {
		title: 'ESCHEATMENT_RESTRICTION',
		description:
			"The customer's account has entered the escheatment process due to inactivity.  Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/4481983968404',
	},
	[UserLockReasonMap.verificationNeeded]: {
		title: BankAccountStatusReason.VERIFICATION_NEEDED,
		description:
			'Customer needs to follow the instructions on their device to submit their ID and complete registration. Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.registrationNotComplete]: {
		title: BankAccountStatusReason.REGISTRATION_NOT_COMPLETE,
		description:
			"Customer's bank account requires manual review in order to be completed. Follow escalation procedure.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.healthy]: {
		title: BankAccountStatusReason.HEALTHY,
	},
	[UserLockReasonMap.closedWithRefund]: {
		title: BankAccountStatusReason.CLOSED_WITH_REFUND,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.underReview]: {
		title: BankAccountStatusReason.UNDER_REVIEW,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.bankInitiatedClosed]: {
		title: BankAccountStatusReason.BANK_INITIATED_CLOSED,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.customerInitiatedSpendDown]: {
		title: BankAccountStatusReason.CUSTOMER_INITIATED_SPEND_DOWN,
		description:
			'Customer initiated the close of their Stash Banking Account and are currently in a Spend Down state, meaning once they spend the remaining funds on their debit card their account will be closed.',
	},
	[UserLockReasonMap.confirmedFraud]: {
		title: BankAccountStatusReason.CONFIRMED_FRAUD,
		description:
			"Customer's account is locked for fraud. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.lostStolen]: {
		title: BankAccountStatusReason.LOST_STOLEN,
		description:
			'Customer reported their debit card as Lost or Stolen from within the app. Please see the KB for next steps.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/knowledge/articles/360022010031/en-us?brand_id=360000188374',
	},
	[UserLockReasonMap.negativeBalance]: {
		title: BankAccountStatusReason.NEGATIVE_BALANCE,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.unspecifiedClose]: {
		title: BankAccountStatusReason.UNSPECIFIED_CLOSE,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.potentialAccountTakeover]: {
		title: BankAccountStatusReason.POTENTIAL_ACCOUNT_TAKEOVER,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.potentialFraud]: {
		title: BankAccountStatusReason.POTENTIAL_FRAUD,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.customerReportedIdentityTheft]: {
		title: BankAccountStatusReason.CUSTOMER_REPORTED_IDENTITY_THEFT,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.spendDown]: {
		title: BankAccountStatusReason.SPEND_DOWN,
		description:
			"Customer's bank account is in a spend down state, meaning their Stash Banking Account was put in the closing process and once they spend the remaining funds on their debit card, their account will be closed.",
	},
	[UserLockReasonMap.writeOff]: {
		title: BankAccountStatusReason.WRITE_OFF,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.returnedMail]: {
		title: BankAccountStatusReason.RETURNED_MAIL,
		description:
			"Customer's bank account is locked due to too many failed attempts to deliver physical mail and email statements to the customer. Please see the KB for next steps.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360000822466-Return-Mail',
		nextStepLink: <UploadedDocumentsLink />,
	},
	[UserLockReasonMap.customerInitiatedClose]: {
		title: BankAccountStatusReason.CUSTOMER_INITIATED_CLOSE,
		description:
			'Customer initiated the close of their Stash Banking Account and are currently in a Spend Down state, meaning once they spend the remaining funds on their debit card their account will be closed.',
	},
	[UserLockReasonMap.registrationFailed]: {
		title: BankAccountStatusReason.REGISTRATION_FAILED,
		description:
			"Customer's bank account is locked as they failed GreenDot's registration. Advice the customer we are sorry but we are unable to verify their identity and cannot open a Stash Debit Account at this time.",
	},
	[UserLockReasonMap.statusChangedByCsr]: {
		title: BankAccountStatusReason.STATUS_CHANGED_BY_CSR,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.accountEscheated]: {
		title: BankAccountStatusReason.ACCOUNT_ESCHEATED,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360058200012-Escalation-Macros',
	},
	[UserLockReasonMap.identityTheft]: {
		title: BankAccountStatusReason.IDENTITY_THEFT,
		description:
			"Possible identity theft on customer's bank account, please escalate to Fraud Operations.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.BANK_LOCK_REASON_ACCOUNT_BLOCK]: {
		title: BankAccountStatusReason.ACCOUNT_BLOCK,
		description:
			"Customer's bank account is blocked. Advise the customer we are sorry but they cannot open a Stash Debit Account at this time. See KB for further details.",
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360021441011-Stash-Bank-Registration-Outcome-Troubleshooting',
	},
	[UserLockReasonMap.potentialFirstPartyFraud]: {
		title: BankAccountStatusReason.POTENTIAL_FIRST_PARTY_FRAUD,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.confirmedFirstPartyFraud]: {
		title: BankAccountStatusReason.CONFIRMED_FIRST_PARTY_FRAUD,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.potentialThirdPartyFraud]: {
		title: BankAccountStatusReason.POTENTIAL_THIRD_PARTY_FRAUD,
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.confirmedThirdPartyFraud]: {
		title: BankAccountStatusReason.CONFIRMED_THIRD_PARTY_FRAUD,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.confirmedAccountTakeover]: {
		title: BankAccountStatusReason.CONFIRMED_ACCOUNT_TAKEOVER,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.potentialIdentityTheft]: {
		title: BankAccountStatusReason.POTENTIAL_IDENTITY_THEFT,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.confirmedIdentityTheft]: {
		title: BankAccountStatusReason.CONFIRMED_IDENTITY_THEFT,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.potentialOtherFraud]: {
		title: BankAccountStatusReason.POTENTIAL_OTHER_FRAUD,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.confirmedOtherFraud]: {
		title: BankAccountStatusReason.CONFIRMED_OTHER_FRAUD,
		description: 'Follow escalation procedure.',
		knowledgeBaseLink:
			'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151-Fraud-Procedure-Locked-for-Fraud-Reasons-Overview',
	},
	[UserLockReasonMap.chargedOff]: {
		title: BankAccountStatusReason.CHARGED_OFF,
		description: '',
	},
	[UserLockReasonMap.customerInitiatedClosed]: {
		title: BankAccountStatusReason.CUSTOMER_INITIATED_CLOSED,
		description: '',
	},
	[UserLockReasonMap.deceased]: {
		title: BankAccountStatusReason.DECEASED,
		description: '',
	},
	[UserLockReasonMap.escheat]: {
		title: BankAccountStatusReason.ESCHEATED,
		description: '',
	},
	[UserLockReasonMap.inactive]: {
		title: BankAccountStatusReason.INACTIVE,
		description: '',
	},
	[UserLockReasonMap.inactiveDormant]: {
		title: BankAccountStatusReason.INACTIVE_DORMANT,
		description: '',
	},
	[UserLockReasonMap.inactiveZeroBalance]: {
		title: BankAccountStatusReason.INACTIVE_ZERO_BALANCE,
		description: '',
	},
	[UserLockReasonMap.legalRestriction]: {
		title: BankAccountStatusReason.LEGAL_RESTRICTION,
		description: '',
	},
	[UserLockReasonMap.manualLock]: {
		title: BankAccountStatusReason.MANUAL_LOCK,
		description: '',
	},
	[UserLockReasonMap.nonSpecificLevy]: {
		title: BankAccountStatusReason.NON_SPECIFIC_LEVY,
		description: '',
	},
	[UserLockReasonMap.OFAC]: {
		title: BankAccountStatusReason.OFAC,
		description: '',
	},
	[UserLockReasonMap.overdrawn]: {
		title: BankAccountStatusReason.OVERDRAWN,
		description: '',
	},
	[UserLockReasonMap.errored]: {
		title: BankAccountStatusReason.ERRORED,
		description: '',
	},
	[UserLockReasonMap.customerInitiated]: {
		title: BankAccountStatusReason.CUSTOMER_INITIATED,
		description: '',
	},
	[UserLockReasonMap.bankInitiated]: {
		title: BankAccountStatusReason.BANK_INITIATED,
		description: '',
	},
	[UserLockReasonMap.gdClosedDeceased]: {
		title: BankAccountStatusReason.GD_CLOSED_DECEASED,
		description: '',
	},
};
