import { createContext, useState, useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';

type PageHistoryArray = string[];

interface PageHistoryContextProps {
	history: PageHistoryArray;
}

export const PageHistoryContext = createContext<PageHistoryContextProps>({
	history: [],
});

export const PageHistoryProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const { pathname } = useLocation();
	const [state, setState] = useState<PageHistoryArray>([]);

	useEffect(() => {
		setState([...state, pathname]);

		// eslint-disable-next-line
	}, [pathname, setState]);

	return (
		<PageHistoryContext.Provider
			value={{
				history: state,
			}}
		>
			{children}
		</PageHistoryContext.Provider>
	);
};
