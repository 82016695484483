interface FormatReferralQueryProps {
	path: string;
	items: {
		[key: string]: string | null;
	};
}

export const formatReferralQuery = ({ path, items }: FormatReferralQueryProps) => {
	const query = new URLSearchParams('');
	const protocol = window.location.protocol;
	const host = window.location.host;
	const url = new URL(`${protocol}//${host}/${path}`);
	const pathHasSearch = Boolean(url.search);
	const firstChar = pathHasSearch ? '&' : '?';

	Object.keys(items).forEach((key) => {
		const value = items[key];
		if (value) {
			query.set(key, value);
		}
	});

	const result = query.toString();

	return result.length > 0 ? `${firstChar}${result}` : '';
};
