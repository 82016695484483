import { FC } from 'react';
import { LoginCallback as OktaLoginCallback } from '@okta/okta-react';
import { Tile, Flex, Loader } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { PageContainer } from 'src/components/PageContainer';

interface OktaErrorProps {
	error: Error;
}

// The ErrorMessage implementation copies the OktaError component
// this is shown if a custom component is not provided
// see: https://github.com/okta/okta-react/blob/master/src/OktaError.tsx
const ErrorMessage: FC<OktaErrorProps> = ({ error }) => {
	if (error.name && error.message) {
		return (
			<p>
				{error.name}: {error.message}
			</p>
		);
	}
	return <p>Error: {error.toString()}</p>;
};

export const OktaError = ({ error }: OktaErrorProps) => (
	<PageContainer disableActions>
		<Tile my="30px" mx="auto" style={{ width: '500px' }}>
			<Text.Label16 fontWeight="bold" color="textPrimary" align="center" pb="20px">
				Please request access to User Hub via. Spacebar
			</Text.Label16>
			<Text.Label14 color="textPrimary" as="span" align="center">
				<ErrorMessage error={error} />
			</Text.Label14>
		</Tile>
	</PageContainer>
);

const Loading = () => (
	<PageContainer disableActions>
		<Tile my="30px" mx="auto" style={{ width: '500px' }}>
			<Flex
				data-testid="loading"
				flexDirection={{ mobile: 'row' }}
				flexGrow="1"
				justifyContent="center"
				my="25px"
			>
				<Loader center />
			</Flex>
		</Tile>
	</PageContainer>
);

export const LoginCallback = () => (
	<OktaLoginCallback errorComponent={OktaError} loadingComponent={Loading} />
);
