import { z } from 'zod';
import { APISchema } from '../api';

const questions = [
	{
		questionId: 'employment_status',
		groupId: 'employment',
		label: 'Employment status',
		dependentQuestions: [
			{ id: 'employer', value: 'EMPLOYED' },
			{ id: 'position_employed', value: 'EMPLOYED' },
		],
		readOnly: false,
		type: z.enum([
			'STUDENT',
			'UNEMPLOYED',
			'EMPLOYED',
			'RETIRED',
			'PART_TIME_EMPLOYED',
			'FREELANCER',
		]),
	},
	{
		questionId: 'employer',
		groupId: 'employment',
		label: 'Employer',
		dependentQuestions: [],
		readOnly: false,
		type: z.string(),
	},
	{
		questionId: 'position_employed',
		groupId: 'employment',
		label: 'Position',
		dependentQuestions: [],
		readOnly: false,
		type: z.string(),
	},
	{
		questionId: 'annual_income_range',
		groupId: 'financial',
		label: 'Annual income range',
		dependentQuestions: [],
		readOnly: true,
		type: z.enum(['UNDER_25K', '25K_TO_50K', '50K_TO_100K', 'OVER_100K']),
	},
	{
		questionId: 'total_net_worth_range',
		groupId: 'financial',
		label: 'Total net worth range',
		dependentQuestions: [],
		readOnly: false,
		type: z.enum(['UNDER_5K', '5K_TO_25K', '25K_TO_50K', '50K_TO_100K', 'OVER_100K']),
	},
	{
		questionId: 'federal_tax_bracket_percent',
		groupId: 'financial',
		label: 'Federal tax bracket percent',
		dependentQuestions: [],
		readOnly: true,
		type: z.number(),
	},
	{
		questionId: 'household_income_bracket',
		groupId: 'financial',
		label: 'Household income bracket',
		dependentQuestions: [],
		readOnly: true,
		type: z.enum([
			'UNDER_5_5K',
			'5_5K_TO_25K',
			'5_5K_TO_50K',
			'5_5K_TO_118K',
			'5_5K_TO_120K',
			'5_5K_TO_186K',
			'5_5K_TO_189K',
			'UNDER_6K',
			'6K_TO_25K',
			'UNDER_6_5K',
			'6_5K_TO_25K',
			'6_5K_TO_50K',
			'6_5K_TO_118K',
			'6_5K_TO_186K',
			'UNDER_7K',
			'UNDER_8K',
			'UNDER_7_5K',
			'7K_TO_25K',
			'8K_TO_25K',
			'7_5K_TO_25K',
			'25K_TO_50K',
			'50K_TO_100K',
			'100K_TO_118K',
			'100K_TO_120K',
			'100K_TO_122K',
			'100K_TO_124K',
			'100K_TO_129K',
			'100K_TO_138K',
			'100K_TO_146K',
			'124K_TO_196K',
			'138K_TO_218K',
			'146K_TO_230K',
			'100K_TO_150K',
			'150K_TO_186K',
			'150K_TO_189K',
			'150K_TO_193K',
			'150K_TO_196K',
			'150K_TO_204K',
			'150K_TO_218K',
			'150K_TO_230K',
			'OVER_118K',
			'OVER_120K',
			'OVER_122K',
			'OVER_124K',
			'OVER_129K',
			'OVER_138K',
			'OVER_146K',
			'OVER_186K',
			'OVER_189K',
			'OVER_193K',
			'OVER_196K',
			'OVER_204K',
			'OVER_218K',
			'OVER_230K',
		]),
	},
	{
		questionId: 'tax_withholding_percent',
		groupId: 'financial',
		label: 'Tax withholding percent',
		dependentQuestions: [],
		readOnly: true,
		type: z.number(),
	},
	{
		questionId: 'risk_tolerance',
		groupId: 'investment',
		label: 'Risk tolerance',
		dependentQuestions: [],
		readOnly: false,
		type: z.enum(['LOW', 'MEDIUM', 'HIGH']),
	},
	{
		questionId: 'time_horizon',
		groupId: 'investment',
		label: 'Time horizon',
		dependentQuestions: [],
		readOnly: false,
		type: z.enum(['LESS_THAN_ONE_YEAR', 'SHORT_TERM', 'MID_TERM', 'LONG_TERM']),
	},
	{
		questionId: 'liquidity_needs',
		groupId: 'investment',
		label: 'Liquidity needs',
		dependentQuestions: [],
		readOnly: true,
		type: z.enum(['VERY_IMPORTANT', 'SOMEWHAT_IMPORTANT', 'NOT_IMPORTANT']),
	},
	{
		questionId: 'investment_objective',
		groupId: 'investment',
		label: 'Investment objective',
		dependentQuestions: [],
		readOnly: true,
		type: z.enum([
			'GROWTH_INCOME',
			'GROWTH',
			'INCOME',
			'CAPITAL_PRESERVATION',
			'SPECULATION',
			'MAXIMUM_GROWTH',
			'BALANCED',
			'OTHER',
		]),
	},
	{
		questionId: 'investing_experience',
		groupId: 'investment',
		label: 'Investing experience',
		dependentQuestions: [],
		readOnly: false,
		type: z.enum(['NO_EXPERIENCE', 'LITTLE_BIT', 'FAIR_SHARE', 'EXTENSIVE']),
	},
	{
		questionId: 'risk_level',
		groupId: 'investment',
		label: 'Risk level',
		dependentQuestions: [],
		readOnly: true,
		type: z.enum(['CONSERVATIVE', 'MODERATE', 'AGGRESSIVE']),
	},
	{
		questionId: 'is_control_person',
		groupId: 'special',
		label: 'Control person?',
		dependentQuestions: [{ id: 'company_symbols', value: true }],
		readOnly: false,
		type: z.boolean(),
	},
	{
		questionId: 'company_symbols',
		groupId: 'special',
		label: 'Company name(s)',
		dependentQuestions: [],
		readOnly: false,
		type: z.array(z.string()),
	},
	{
		questionId: 'is_politically_exposed',
		groupId: 'special',
		label: 'Politically exposed?',
		dependentQuestions: [
			{ id: 'immediate_family', value: true },
			{ id: 'political_organization', value: true },
		],
		readOnly: false,
		type: z.boolean(),
	},
	{
		questionId: 'immediate_family',
		groupId: 'special',
		label: 'Name(s) of politically exposed',
		dependentQuestions: [],
		readOnly: false,
		type: z.array(z.string()),
	},
	{
		questionId: 'political_organization',
		groupId: 'special',
		label: 'Political organization',
		dependentQuestions: [],
		readOnly: false,
		type: z.string(),
	},
	{
		questionId: 'is_affiliated_exchange_or_finra',
		groupId: 'special',
		label: 'Affiliated exchange or FINRA?',
		dependentQuestions: [{ id: 'firm_name', value: true }],
		readOnly: false,
		type: z.boolean(),
	},
	{
		questionId: 'firm_name',
		groupId: 'special',
		label: 'Firm name(s)',
		dependentQuestions: [],
		readOnly: false,
		type: z.string(),
	},
] as const;

type Questions = (typeof questions)[number];
type WritableQuestions = Questions & { readOnly: false };

const writableQuestions = questions.filter(
	(question) => !question.readOnly
) as WritableQuestions[];
type WriteableQuestionsMap = {
	[K in WritableQuestions['questionId']]: z.ZodOptional<
		Extract<WritableQuestions, { questionId: K }>['type']
	>;
};
export type QuestionsMap = {
	[K in Questions['questionId']]: z.ZodOptional<
		Extract<Questions, { questionId: K }>['type']
	>;
};
const writableQuestionsMap = writableQuestions.reduce((acc, currValue) => {
	return { ...acc, [currValue.questionId]: currValue.type.optional() };
}, {} as WriteableQuestionsMap);
const questionsMap = questions.reduce((acc, question) => {
	return { ...acc, [question.questionId]: question.type.optional() };
}, {} as QuestionsMap);

const labels = {
	STUDENT: 'Student',
	UNEMPLOYED: 'Unemployed',
	EMPLOYED: 'Employed',
	RETIRED: 'Retired',
	PART_TIME_EMPLOYED: 'Part-time employed',
	FREELANCER: 'Freelancer',

	UNDER_5K: 'Under $5k',
	UNDER_25K: 'Under $25k',
	'5K_TO_25K': '$5k - $25k',
	'25K_TO_50K': '$25k - $50k',
	'50K_TO_100K': '$50k - $100k',
	OVER_100K: 'Over $100k',

	UNDER_5_5K: 'Under $5,5k',
	'5_5K_TO_25K': '$5.5k - $25k',
	'5_5K_TO_50K': '$5.5k - $5k',
	'5_5K_TO_118K': '$5.5k - $118k',
	'5_5K_TO_120K': '$5.5k - $120k',
	'5_5K_TO_186K': '$5.5k - $186k',
	'5_5K_TO_189K': '$5.5k - $189k',
	UNDER_6K: 'Under $6k',
	'6K_TO_25K': '$6k - $25k',
	UNDER_6_5K: 'Under $6.5k',
	'6_5K_TO_25K': '$6.5k - $25k',
	'6_5K_TO_50K': '$6.5k - $50k',
	'6_5K_TO_118K': '$6.5k - $118k',
	'6_5K_TO_186K': '$6.5k - $186k',
	UNDER_7K: 'Under $7k',
	UNDER_8K: 'Under $8k',
	UNDER_7_5K: 'Under $7.5k',
	'7K_TO_25K': '$7.5k - $25k',
	'8K_TO_25K': '$8k - $25k',
	'7_5K_TO_25K': '$7.5k - $25k',
	'100K_TO_118K': '$100k - $118k',
	'100K_TO_120K': '$100k - $120k',
	'100K_TO_122K': '$100k - $122k',
	'100K_TO_124K': '$100k - $124k',
	'100K_TO_129K': '$100k - $129k',
	'100K_TO_138K': '$100k - $138k',
	'100K_TO_146K': '$100k - $146k',
	'124K_TO_196K': '$124k - $196k',
	'138K_TO_218K': '$138k - $218k',
	'146K_TO_230K': '$146k - $230k',
	'100K_TO_150K': '$100k - $150k',
	'150K_TO_186K': '$150k - $186k',
	'150K_TO_189K': '$150k - $189k',
	'150K_TO_193K': '$150k - $193k',
	'150K_TO_196K': '$150k - $196k',
	'150K_TO_204K': '$150k - $204k',
	'150K_TO_218K': '$150k - $218k',
	'150K_TO_230K': '$150k - $230k',
	OVER_118K: 'Over $118k',
	OVER_120K: 'Over $120k',
	OVER_122K: 'Over $122k',
	OVER_124K: 'Over $124k',
	OVER_129K: 'Over $129k',
	OVER_138K: 'Over $138k',
	OVER_146K: 'Over $146k',
	OVER_186K: 'Over $186k',
	OVER_189K: 'Over $189k',
	OVER_193K: 'Over $193k',
	OVER_196K: 'Over $196k',
	OVER_204K: 'Over $204k',
	OVER_218K: 'Over $218k',
	OVER_230K: 'Over $230k',

	LOW: 'Low',
	MEDIUM: 'Medium',
	HIGH: 'High',

	LESS_THAN_ONE_YEAR: 'Less than one year',
	SHORT_TERM: 'Short-term',
	MID_TERM: 'Mid-term',
	LONG_TERM: 'Long-term',

	VERY_IMPORTANT: 'Very important',
	SOMEWHAT_IMPORTANT: 'Somewhat important',
	NOT_IMPORTANT: 'Not important',

	GROWTH_INCOME: 'Growth income',
	GROWTH: 'Growth',
	INCOME: 'Income',
	CAPITAL_PRESERVATION: 'Capital preservation',
	SPECULATION: 'Speculation',
	MAXIMUM_GROWTH: 'Maximum growth',
	BALANCED: 'Balanced',
	OTHER: 'Other',

	NO_EXPERIENCE: 'No experience',
	LITTLE_BIT: 'Limited experience',
	FAIR_SHARE: 'Good experience',
	EXTENSIVE: 'Extensive experience',

	CONSERVATIVE: 'Conservative',
	MODERATE: 'Moderate',
	AGGRESSIVE: 'Aggressive',
} as const;

const zodSchemaToLabel = {
	ZodNumber: 'Number',
	ZodString: 'String',
	ZodBoolean: 'Boolean',
	ZodArray: 'Array',
	ZodEnum: 'Enum',
} as const;

// This is an intermediate step to convert "legacy" values to new values
const LegacyInvestorApplicationSchema = z
	.object({
		time_horizon: z
			.string()
			.optional()
			.transform((value) => {
				switch (value) {
					case 'SHORT':
						return 'SHORT_TERM';
					case 'AVERAGE':
						return 'MID_TERM';
					case 'LONGEST':
						return 'LONG_TERM';
					default:
						return value;
				}
			}),
	})
	.passthrough();

export const InvestorApplicationFlatSchema = z.object(questionsMap);
export const InvestorApplicationInputSchema = z.object(writableQuestionsMap);
export const investorApplicationGroups = [
	'employment',
	'financial',
	'investment',
	'special',
] as const;

export const InvestorApplicationSchema = LegacyInvestorApplicationSchema.pipe(
	InvestorApplicationFlatSchema.transform((raw) => {
		return {
			investorApplication: investorApplicationGroups.map((groupId) => ({
				group: groupId,
				questions: questions
					.filter((q) => q.groupId === groupId)
					.map((q) => ({
						id: q.questionId,
						label: q.label,
						readOnly: q.readOnly,
						dependentQuestions: q.dependentQuestions,
						answer: {
							type: zodSchemaToLabel[q.type._def.typeName],
							value: raw[q.questionId] === 'Refused to answer' ? null : raw[q.questionId],
							...(q.type._def.typeName === z.ZodFirstPartyTypeKind.ZodEnum && {
								options: q.type._def.values.map((value) => ({
									value,
									label: labels[value],
								})),
							}),
						},
					})),
			})),
		};
	})
);

export type InvestorApplicationInput = z.infer<typeof InvestorApplicationInputSchema>;

export type InvestorApplication = z.infer<typeof InvestorApplicationSchema>;
export type InvestorApplicationGroup = (typeof investorApplicationGroups)[number];
export type InvestorApplicationQuestionGroups =
	InvestorApplication['investorApplication'][number]['questions'];
export type InvestorApplicationQuestionId =
	InvestorApplicationQuestionGroups[number]['id'];
export type InvestorApplicationQuestionAnswer =
	InvestorApplicationQuestionGroups[number]['answer'];
export type InvestorApplicationQuestionAnswerValue =
	InvestorApplicationQuestionAnswer['value'];
export type InvestorApplicationQuestionAnswerType =
	InvestorApplicationQuestionAnswer['type'];

export const investorApplicationApiSchema = {
	getInvestorApplication: {},
} as const satisfies Record<string, APISchema>;
