import React, { FC } from 'react';
import { PrimaryButton, SecondaryButton, SmartLink } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { gaEvent } from '../../utils';
import { StyledLink } from '../../primitives';
import {
	TrackedLinkProps,
	TrackedLinkVariants,
	TrackedLinkColors,
} from './TrackedLink.types';

export const TrackedLink: FC<React.PropsWithChildren<TrackedLinkProps>> = ({
	href,
	to,
	target = '_self',
	type = TrackedLinkVariants.LINK,
	size = 'small',
	color = TrackedLinkColors.bgActionPrimary,
	nativeLink = false,
	tracking,
	py = '0',
	px = '0',
	ml = '0',
	width,
	style,
	hoverStyles,
	download,
	children,
}) => {
	const { page, component, label } = tracking;
	const trackingEvent = () => gaEvent({ event: 'link', page, component, label });

	const commonProps = {
		to,
		href,
		target,
		as: nativeLink ? 'a' : SmartLink,
		onClick: trackingEvent,
		py,
		px,
		ml,
		style,
		color,
		width,
		download,
		rel: 'noopener noreferrer',
	};

	if (type === TrackedLinkVariants.BUTTON) {
		return (
			<PrimaryButton {...commonProps} size={size} data-testid="button-style">
				{children}
			</PrimaryButton>
		);
	}

	if (type === TrackedLinkVariants.SECONDARY_BUTTON) {
		return (
			<SecondaryButton {...commonProps} size={size} data-testid="secondary-button-style">
				{children}
			</SecondaryButton>
		);
	}

	let linkText = (
		<Text.Label16 fontWeight="bold" as="span" size={size} color="inherit">
			{children}
		</Text.Label16>
	);
	if (size === 'small') {
		linkText = (
			<Text.Label14 fontWeight="bold" as="span" size={size} color="inherit">
				{children}
			</Text.Label14>
		);
	} else if (size === 'large') {
		linkText = (
			<Text.Label18 as="span" size={size} color="inherit" whiteSpace="nowrap">
				{children}
			</Text.Label18>
		);
	}

	return (
		<StyledLink {...commonProps} hoverStyles={hoverStyles} data-testid="link-style">
			{linkText}
		</StyledLink>
	);
};
