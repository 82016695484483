import { z } from 'zod';
import { Request, Response } from 'express';

import { PaginatedComment } from '../comments';
import { ErrorDetail, ErrorResponseBody } from '../errors';
import { InvestorApplicationSchema } from '../investorApplication';
import { UserProfileOrContentOnlyUserProfile, UserProfileSchema } from '../userProfile';
import {
	GetGlAccountsResponseSchema,
	GetTransactionCodesResponseSchema,
} from '../schemas/manualOperationsMovement';
import { CoreErrorSchema } from '../schemas/core';
import {
	AddNewCustomerTxnOnlyRequestSchema,
	AdjustPendingTxnOnlyRequestSchema,
	FixCustomerAndAddNewTxnRequestSchema,
	FixCustomerAndAddPendingTxnRequestSchema,
	FixCustomerOnlyRequestSchema,
	FixNonCustomerGlToGlRequestSchema,
} from '../schemas/mom-portal';
import { SandfordAccount } from '../schemas/sandford';
import {
	BankLinkSchema,
	IdentitySchema,
	Account,
} from '../investAccounts/investAccounts';
import { StashworksUserStatusResponse } from '../schemas/stashworks';
import { ExrAccountDataSchema } from '../schemas/user';

export type StashworksUserStatusResponseBody = StashworksUserStatusResponse['data'];

export type UserRequest = Request<{ userId: string }>;
export type UserAccountRequest = Request<{ userId: string; accountId: string }>;
export type UserDocumentRequestRequest = Request<{
	userId: string;
	documentRequestId: string;
}>;
export type UserEmailRequest = Request<{ userId: string; emailId: string }>;
export type UserAgreementRequest = Request<{
	userId: string;
	agreementId: string;
}>;

export type CommentsResponseBody = PaginatedComment | ErrorResponseBody;
export type CommentsResponse = Response<CommentsResponseBody>;

export type InvestorApplicationResponseBody = z.infer<typeof InvestorApplicationSchema>;
export type InvestorApplicationResponse = Response<
	InvestorApplicationResponseBody | ErrorResponseBody
>;

export type PermissionRequest = Request<{ app: string }>;
export type PermissionsResponseBody = { permissions: string[] };
export type PermissionsResponse = Response<PermissionsResponseBody>;

export type BasicUserProfileResponseBody = z.infer<typeof UserProfileSchema>;
export type UserProfileResponse = Response<
	UserProfileOrContentOnlyUserProfile | ErrorResponseBody
>;

// New Invest Account flow
const InvestAccountsResponseBodySchemaV2 = z.object({
	accounts: z.array(
		Account.extend({
			portfolio: z.string().nullable(),
		})
	),
	externalBankLink: ExrAccountDataSchema.nullable(),
});

export type InvestAccountsResponseBodyV2 = z.infer<
	typeof InvestAccountsResponseBodySchemaV2
>;
export type InvestAccountsResponseV2 = Response<
	InvestAccountsResponseBodyV2 | ErrorResponseBody
>;
// New Invest Account flow

// Old Invest Account flow
const InvestAccountsResponseBodySchema = SandfordAccount.extend({
	portfolio: z.string().nullable(),
	identity: IdentitySchema.nullable(),
	externalBankLink: BankLinkSchema.nullable(),
});

export type InvestAccountsResponseBody = z.infer<
	typeof InvestAccountsResponseBodySchema
>[];
export type InvestAccountsResponse = Response<
	InvestAccountsResponseBody | ErrorResponseBody
>;
// Old Invest Account flow

export type CoreErrorResponse = z.infer<typeof CoreErrorSchema> & ErrorDetail;

export type GetGlAccountsResponseBody = z.infer<typeof GetGlAccountsResponseSchema>;
export type GetGlAccountsResponse = Response<
	GetGlAccountsResponseBody | ErrorResponseBody
>;

export type GetTransactionCodesResponseBody = z.infer<
	typeof GetTransactionCodesResponseSchema
>;
export type GetTransactionCodesResponse = Response<
	GetTransactionCodesResponseBody | ErrorResponseBody
>;

export type FixCustomerAndAddNewTxnRequest = z.infer<
	typeof FixCustomerAndAddNewTxnRequestSchema
>;
export type FixCustomerAndAddPendingTxnRequest = z.infer<
	typeof FixCustomerAndAddPendingTxnRequestSchema
>;
export type FixNonCustomerGlToGlRequest = z.infer<
	typeof FixNonCustomerGlToGlRequestSchema
>;
export type AddNewCustomerTxnOnlyRequest = z.infer<
	typeof AddNewCustomerTxnOnlyRequestSchema
>;
export type AdjustPendingTxnOnlyRequest = z.infer<
	typeof AdjustPendingTxnOnlyRequestSchema
>;
export type FixCustomerOnlyRequest = z.infer<typeof FixCustomerOnlyRequestSchema>;

export const IsContentOnlySchema = z.object({
	isContentOnly: z.boolean(),
});
export type IsContentOnly = z.infer<typeof IsContentOnlySchema>;
export type IsContentOnlyResponse = Response<IsContentOnly | ErrorResponseBody>;
