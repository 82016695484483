import React, { Component, FC } from 'react';
import ReactDOM from 'react-dom';
import { Flex, Box } from '@stashinvest/ui';
import { Text, ArrowLeftIcon } from '@stashinvest/ui/dist/es/2';

import { VisibilityContext } from '../../providers/VisibilityProvider';
import { useEscape } from '../../hooks/useEscape';
import { NAVBAR_HEIGHT, UnstyledButton } from '..';
import { OverlayProps, OverlayComponentProps } from './Overlay.types';
import { OverlayWrapper } from './Overlay.styles';

let modalRoot = document.getElementById('modal-root');
if (!modalRoot) {
	modalRoot = document.createElement('div');
	modalRoot.setAttribute('id', 'modal-root');
	document.body.appendChild(modalRoot);
}

export class Overlay extends Component<OverlayProps> {
	el: HTMLElement = document.createElement('div');

	componentDidMount() {
		modalRoot?.appendChild(this.el);
	}

	componentWillUnmount() {
		modalRoot?.removeChild(this.el);
	}

	render(): React.ReactPortal {
		const { children, title, closeText, canEscape, topOffset } = this.props;
		return ReactDOM.createPortal(
			<VisibilityContext.Consumer>
				{(visibilityProps) => (
					<OverlayComponent
						title={title}
						closeText={closeText}
						canEscape={canEscape}
						topOffset={topOffset}
						{...visibilityProps}
					>
						{children}
					</OverlayComponent>
				)}
			</VisibilityContext.Consumer>,
			this.el
		);
	}
}

const OverlayComponent: FC<OverlayComponentProps> = ({
	children,
	open,
	setOpen,
	title,
	closeText,
	canEscape = false,
	topOffset = NAVBAR_HEIGHT,
}) => {
	useEscape(() => {
		if (canEscape) {
			setOpen(false);
		}
	});

	return (
		<OverlayWrapper open={open} topOffset={topOffset} data-testid="overlay-wrapper">
			<Flex justifyContent="center">
				<Box m="s" width="1300px">
					<Box>
						{closeText ? (
							<UnstyledButton onClick={() => setOpen(false)}>
								<Flex alignItems="center">
									<ArrowLeftIcon color="iconSelected" />
									<Text.Label14 color="iconSelected" ml="xs">
										{closeText}
									</Text.Label14>
								</Flex>
							</UnstyledButton>
						) : null}
						{title ? <Text.Title28>{title}</Text.Title28> : null}
					</Box>
					<Box>
						{children({
							setOpen: setOpen,
							open,
						})}
					</Box>
				</Box>
			</Flex>
		</OverlayWrapper>
	);
};
