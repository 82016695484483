import z from 'zod';

const invalidAmountMessage = 'Amount must be a valid number';

export const AmountSchema = z.object(
	{
		// z.coerce will turn '' --> 0, so we must first verify that the string is non-empty
		value: z
			.string()
			.nonempty({ message: invalidAmountMessage })
			.pipe(z.coerce.number({ invalid_type_error: invalidAmountMessage }))
			.transform((v) => String(v)),
		currency: z.enum(['USD']),
	},
	{
		errorMap: () => {
			// TODO: add more fine grained error-handling based on zod error code
			return { message: invalidAmountMessage };
		},
	}
);

export type Amount = z.infer<typeof AmountSchema>;
