import React, { FC, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Divider } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { TriangleIcon } from '../../icons';
import { UnstyledButton } from '../UnstyledButton';
import { ExpandableContentProps } from './ExpandableContent.types';

const IconContainer = styled(Box).attrs(() => ({
	ml: '7px',
	as: 'span',
}))`
	> span {
		transition: all 0.3s;
		transform: ${({ open }) =>
			open ? `translateY(5px) rotate(180deg)` : `translateY(0px) rotate(0deg)`};
	}
`;

const ExpandableChildren = styled(Box)`
	overflow: hidden;
	transition: height 0.1s ease-out;
	height: ${({ open, height }) => (open ? height : '0px')};
`;

export const ExpandableContent: FC<React.PropsWithChildren<ExpandableContentProps>> = ({
	label,
	children,
}) => {
	const ref = useRef<HTMLDivElement>();
	const [height, setHeight] = useState<string>('0px');
	const [open, toggleOpen] = useState(true);

	useEffect(() => {
		const scrollHeight = ref?.current && ref.current.scrollHeight;
		const heightValue = scrollHeight ? `${scrollHeight}px` : 'auto';
		setHeight(heightValue);
		toggleOpen(false);
	}, []);

	return (
		<Box width="100%">
			<Divider my="15px" />
			<UnstyledButton
				onClick={() => {
					toggleOpen(!open);
				}}
			>
				<Text.Label12 fontWeight="bold" caps>
					{label}
					<IconContainer open={open}>
						<TriangleIcon size="10px" as="span" color="textPrimary" />
					</IconContainer>
				</Text.Label12>
			</UnstyledButton>
			<ExpandableChildren
				open={open}
				height={height}
				ref={ref}
				data-testid="expandable-contents"
			>
				<Box pt="12px">
					<Text.Label14>{children}</Text.Label14>
				</Box>
			</ExpandableChildren>
		</Box>
	);
};
