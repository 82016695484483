import { FC } from 'react';
import { Flex, Text } from '@stashinvest/ui/dist/es/2';
import { UserPageSidebar } from '../UserPageSideBar';

export interface PageWrapperProps {
	/**
	 * title of the page body
	 */
	title: string;
}

export const PageWrapper: FC<React.PropsWithChildren<PageWrapperProps>> = ({
	title,
	children,
}) => {
	return (
		<Flex flexDirection="row" mt="l" width="100%">
			<UserPageSidebar />
			<Flex flexDirection="column" width="100%">
				<Text.Title36
					fontWeight="bold"
					as="span"
					color="textPrimary"
					ml="28px"
					data-testid="page-wrapper-title"
				>
					{title}
				</Text.Title36>
				{children}
			</Flex>
		</Flex>
	);
};
