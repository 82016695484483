import React from 'react';
import styled from 'styled-components';
import { Box } from '@stashinvest/ui';

import { LeftArrowIcon, RightArrowIcon } from '../../icons';
import { HiddenLabel } from '../HiddenLabel';

const ArrowButton = styled(Box)`
	background: 0;
	border: 0;
	margin: 0;
	opacity: ${({ disabled }) => (disabled ? '0.3' : 1)};
`;

interface PaginationNavLinkProps {
	type: 'next' | 'prev';
	disabled: boolean;
	onClick(): void;
}

export const PaginationNavLink = ({
	type,
	disabled,
	onClick,
}: PaginationNavLinkProps) => {
	const isNext = type === 'next';

	return (
		<ArrowButton as="button" pl="5px" disabled={disabled} onClick={onClick}>
			{isNext ? (
				<RightArrowIcon size="13px" color="textTertiary" />
			) : (
				<LeftArrowIcon size="13px" color="textTertiary" />
			)}
			<HiddenLabel>{isNext ? 'next' : 'previous'}</HiddenLabel>
		</ArrowButton>
	);
};
