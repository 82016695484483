import { FC } from 'react';
import { Tile, Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { SadFaceIcon } from '@stashinvest/shared-ui';

export const CommentsError: FC = () => (
	<Tile height="100%" pb="10px" mb="0">
		<Text.Label18 fontWeight="bold">Comments</Text.Label18>
		<Flex
			px="20px"
			pt="10px"
			pb="15px"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
		>
			<SadFaceIcon size="52px" color="textDisabled" />
			<Text.Label16 pt="20px" px="45px" align="center">
				Sorry, there was an issue getting comments for this user
			</Text.Label16>
		</Flex>
	</Tile>
);
