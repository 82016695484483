import React, { ReactNode } from 'react';
import { List, Link, SmartLink } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import styled from 'styled-components';
import { BreadCrumbsConfigProps, BreadCrumbsProps } from './BreadCrumbs.types';

export const BreadCrumbsUl = styled(List)`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0;
	margin: 0;
	list-style-type: none;
`;

type BreadCrumbsSeperatorType = {
	spacing?: string;
};

const BreadcrumbsSeparator = styled.li<BreadCrumbsSeperatorType>`
	display: flex;
	user-select: none;
	margin-left: ${({ theme, spacing }) =>
		spacing ? theme.space[spacing] : theme.space.s};
	margin-right: ${({ theme, spacing }) =>
		spacing ? theme.space[spacing] : theme.space.s};
`;

export const BreadCrumbs = (props: BreadCrumbsConfigProps) => {
	const { config } = props;
	return (
		<BreadCrumbsUI {...props}>
			{config.map((item) => {
				const { path, name, ...rest } = item;
				return (
					<Text.Label16 fontWeight="bold" color="bgActionPrimary" key={path}>
						<Link to={path} size="medium" mb="xxs" as={SmartLink} {...rest}>
							{name}
						</Link>
					</Text.Label16>
				);
			})}
		</BreadCrumbsUI>
	);
};

export const BreadCrumbsUI = (props: BreadCrumbsProps) => {
	const { children, ariaLabel, separator = '/', spacing } = props;

	return (
		<BreadCrumbsUl aria-label={ariaLabel}>
			{children.map((child: ReactNode, index: number) => {
				return (
					<React.Fragment key={index}>
						<li>{child}</li>
						{index !== children.length - 1 && (
							<BreadcrumbsSeparator
								aria-hidden={true}
								spacing={spacing}
								key={`separator-${index}`}
							>
								{separator}
							</BreadcrumbsSeparator>
						)}
					</React.Fragment>
				);
			})}
		</BreadCrumbsUl>
	);
};
