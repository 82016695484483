import { useOktaAuth } from '@okta/okta-react';
import { fetchUserSubscription } from 'src/api';
import { UserSubscription } from '@stashinvest/shared-types/schemas/subscriptions';
import { useQuery } from '@tanstack/react-query';

export const UseUserSubscriptionQueryOptions = (userId: string) => [
	'useUserSubscription',
	userId,
];

export const useUserSubscription = (userId: string) => {
	const { oktaAuth } = useOktaAuth();

	return useQuery<UserSubscription | undefined, string>(
		UseUserSubscriptionQueryOptions(userId),
		() => fetchUserSubscription(userId, oktaAuth.getAccessToken())
	);
};
