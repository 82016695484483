import { useQuery } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';
import { InvestorApplication } from '@stashinvest/shared-types/investorApplication';

import { fetchInvestorApplication } from 'src/api';
import { investorApplicationQueryKeys } from './queryKeys';

interface InvestorApplicationParams {
	userId: string;
}

export const useInvestorApplication = ({ userId }: InvestorApplicationParams) => {
	const { oktaAuth } = useOktaAuth();

	return useQuery<InvestorApplication>(investorApplicationQueryKeys.detail(userId), () =>
		fetchInvestorApplication(userId, oktaAuth.getAccessToken())
	);
};
