import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Logo } from '@stashinvest/ui';
import styled from 'styled-components';
import { NavbarProps } from './Navbar.types';

const LogoContainer = styled.div`
	width: 150px;
	margin: 0;
`;

export const NAVBAR_HEIGHT = '107px';

export const Navbar: FC<React.PropsWithChildren<NavbarProps>> = ({
	bg,
	children,
	subnav,
}) => (
	<>
		<Box height={NAVBAR_HEIGHT} bg={bg || 'bgHoverSecondary'}>
			<Flex
				height="100%"
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
				maxWidth="1440px"
				mx="auto"
				px="48px"
			>
				<LogoContainer>
					<Link to="/">
						<Logo />
					</Link>
				</LogoContainer>
				{children}
			</Flex>
		</Box>
		{subnav}
	</>
);
