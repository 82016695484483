import { ChangeEventHandler, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { FormikProps } from 'formik';
import { FormikTextField, Flex, Box } from '@stashinvest/ui/dist/es/2';

import {
	PatchUserProfileSchema,
	UserProfile,
	states,
	countries,
} from '@stashinvest/shared-types';

import { FormikCheckbox } from '@stashinvest/ui';
import { useToast } from 'src/hooks/useToast';
import { useUpdateUserProfile } from 'src/hooks/useUserProfileV2';
import { ModalForm } from 'src/components/ModalForm';
import { FormikCombobox } from '@stashinvest/shared-ui';
import { schemas } from 'src/utils/form/validation';

const patchMailingAddressSchema = PatchUserProfileSchema.pick({
	mailingStreetAddress: true,
	mailingStreetAddress2: true,
	mailingCity: true,
	mailingState: true,
	mailingCountry: true,
	mailingAddressIsSame: true,
}).merge(
	z.object({
		mailingPostalCode: z.pipeline(
			schemas.zipCode,
			PatchUserProfileSchema.shape.mailingPostalCode
		),
	})
);

type PatchUserProfileInfoPayload = z.infer<typeof patchMailingAddressSchema>;
type FormValues = Partial<z.input<typeof patchMailingAddressSchema>>;

interface EditMailingAddressFormProps {
	userProfile: UserProfile;
}
type UserPageParams = { userId: string };

const stateOptions = states.map((state) => {
	return { value: state.code, label: state.name };
});
const countryOptions = countries.map((country) => {
	return { value: country.code, label: country.name };
});

interface FormFieldProps {
	formikProps: FormikProps<FormValues>;
	primaryAddress: FormValues;
}

const FormFields: FC<FormFieldProps> = ({ formikProps, primaryAddress }) => {
	const { values } = formikProps;

	const [previousMailingAddress, setPreviousMailingAddress] = useState<
		FormValues | undefined
	>();
	const { mailingState, mailingCountry, mailingAddressIsSame } = values;
	const [previousAddressCountry, setPreviousAddressCountry] = useState(mailingCountry);
	const isAddressInUsa = mailingCountry === 'USA';
	const isCountryNotSet = mailingCountry === '';

	if (mailingCountry !== previousAddressCountry) {
		const previousAddressInUsa = previousAddressCountry === 'USA';
		if ((isAddressInUsa || previousAddressInUsa) && mailingState) {
			// if address changes from/to USA clear state field
			formikProps.setFieldValue('mailingState', '');
			formikProps.setFieldError('mailingState', undefined);
		}
		setPreviousAddressCountry(mailingCountry);
	}

	const handleSameAsPermanentAddressChange: ChangeEventHandler<HTMLInputElement> = (
		e
	) => {
		if (e.target.checked) {
			setPreviousMailingAddress(values);
			formikProps.setValues(primaryAddress);
		} else if (previousMailingAddress) {
			// prevent state field being zero'd out
			setPreviousAddressCountry(previousMailingAddress.mailingCountry);
			formikProps.setValues(previousMailingAddress);
		}
	};

	const disableFields = mailingAddressIsSame;
	return (
		<Box width="100%">
			<Box width="100%">
				<FormikCheckbox
					name="mailingAddressIsSame"
					onChange={handleSameAsPermanentAddressChange}
				>
					Same as permanent address
				</FormikCheckbox>
			</Box>
			<Box width="100%">
				<FormikTextField
					label="Street Address"
					name="mailingStreetAddress"
					disabled={disableFields}
				/>
			</Box>
			<Box width="100%">
				<FormikTextField
					label="Apartment, Suite etc."
					name="mailingStreetAddress2"
					disabled={disableFields}
				/>
			</Box>
			<Box width="100%">
				<FormikTextField label="City" name="mailingCity" disabled={disableFields} />
			</Box>
			<Flex
				width="100%"
				justifyContent="space-between"
				alignItems="baseline"
				position="relative"
			>
				<Box width="55%">
					{isAddressInUsa || isCountryNotSet ? (
						<FormikCombobox
							name="mailingState"
							label="State"
							options={stateOptions}
							placeholder="Select a state"
							selectProps={{ isDisabled: disableFields }}
						/>
					) : (
						<FormikTextField label="State" name="mailingState" disabled={disableFields} />
					)}
				</Box>
				<Box width="35%">
					<FormikTextField
						label="Zip Code"
						name="mailingPostalCode"
						disabled={disableFields}
					/>
				</Box>
			</Flex>
			<Box width="100%">
				<FormikCombobox
					name="mailingCountry"
					label="Country"
					options={countryOptions}
					placeholder="Select a country"
					selectProps={{ menuPlacement: 'top', isDisabled: disableFields }}
				/>
			</Box>
		</Box>
	);
};

const renderFormFields =
	(primaryAddress: FormValues) => (formikProps: FormikProps<FormValues>) => {
		return <FormFields formikProps={formikProps} primaryAddress={primaryAddress} />;
	};

const convertAddressToMailingAddress = (
	address: UserProfile['addresses']['primaryAddress']
) => {
	return {
		mailingStreetAddress: address?.streetAddress ?? '',
		mailingStreetAddress2: address?.streetAddress2 ?? '',
		mailingCity: address?.city ?? '',
		mailingState: address?.state ?? '',
		mailingCountry: address?.country ?? '',
		mailingPostalCode: address?.postalCode ?? '',
	};
};
export const EditMailingAddressForm: FC<EditMailingAddressFormProps> = ({
	userProfile,
}) => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	const { setToast } = useToast();
	const onUserProfileUpdateSuccess = () => {
		setToast({ message: 'Mailing Address updated' });
	};
	const updateUserProfile = useUpdateUserProfile(userId, {
		onSuccess: onUserProfileUpdateSuccess,
	});
	const mailingAddress = userProfile.addresses.mailingAddressIsSame
		? userProfile.addresses.primaryAddress
		: userProfile.addresses.mailingAddress;

	const initialValues = {
		mailingAddressIsSame: userProfile.addresses.mailingAddressIsSame ?? false,
		...convertAddressToMailingAddress(mailingAddress),
	};

	const handleSubmit = async (values: PatchUserProfileInfoPayload) => {
		await updateUserProfile(values);
	};
	return (
		<ModalForm
			title="Edit Mailing Address"
			initialValues={initialValues}
			validationSchema={patchMailingAddressSchema}
			handleSubmit={handleSubmit}
			renderContent={renderFormFields(
				convertAddressToMailingAddress(userProfile.addresses.primaryAddress)
			)}
		/>
	);
};
