import React, { FC } from 'react';
import { Box, Flex, PrimaryButton, TertiaryButton } from '@stashinvest/ui';

export interface ModalActionsProps {
	cancel?: () => void;
	cancelText?: string;
	confirmText: string;
	/**
	 * Sets the `onClick` event handler on the "confirm" button.
	 * Optional when `confirmButtonType` is set to "submit"
	 */
	confirm?: () => void;
	/**
	 * Sets the "confirm" button `type` attribute.
	 * Optional, default "button"
	 */
	confirmButtonType?: 'button' | 'submit';
	ctaDirection?: string;
	disabled?: boolean;
	loading?: boolean;
}

export enum CtaDirection {
	ROW = 'row',
	COLUMN = 'column',
}

export const ModalActions: FC<React.PropsWithChildren<ModalActionsProps>> = ({
	cancel,
	cancelText,
	confirmText,
	ctaDirection,
	confirm,
	disabled,
	loading,
	confirmButtonType = 'button',
}) => {
	if (confirmButtonType !== 'submit' && !confirm) {
		throw new Error(
			"The click handler (`confirm` prop) must be defined when the button type is not explictly set to 'submit'"
		);
	}

	return ctaDirection === CtaDirection.ROW ? (
		<Box mt="auto">
			<Flex flexDirection="row" justifyContent="flex-end">
				<Box mr={cancelText ? 'xs' : ''}>
					<PrimaryButton
						disabled={disabled}
						loading={loading ? 'loading' : ''}
						size="medium"
						onClick={confirm}
						type={confirmButtonType}
					>
						{confirmText}
					</PrimaryButton>
				</Box>
				{cancelText && cancel ? (
					<Box>
						<TertiaryButton size="medium" onClick={cancel}>
							{cancelText}
						</TertiaryButton>
					</Box>
				) : null}
			</Flex>
		</Box>
	) : (
		<Box mt="auto" mx="auto" width="100%">
			<Box maxWidth="16rem" mx="auto">
				<PrimaryButton
					disabled={disabled}
					loading={loading ? 'loading' : ''}
					size="large"
					onClick={confirm}
					type={confirmButtonType}
				>
					{confirmText}
				</PrimaryButton>
			</Box>
			{cancelText && cancel ? (
				<Box maxWidth="16rem" mx="auto" mt="xs">
					<TertiaryButton size="large" onClick={cancel}>
						{cancelText}
					</TertiaryButton>
				</Box>
			) : null}
		</Box>
	);
};
