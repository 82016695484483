export interface TrackingScriptProps {
	host: TrackingHosts;
	tag: string;
	groups: string;
	zendeskTicket: string;
	zendeskUser: string;
}

export interface TrackingProviderProps {
	googleAnalyticsTag: string;
	host: TrackingHosts;
}

export enum TrackingHosts {
	USER_HUB = 'user-hub',
	CX_PORTAL = 'cx-portal',
	BANK_PORTAL = 'bank-portal',
	ACTIVE_ADMIN = 'active-admin',
}
