import React, { FC } from 'react';
import { Box } from '@stashinvest/ui';

import { Navigation } from '../Navigation';
import { PageContainerProps } from './PageContainer.types';

export const PageContainer: FC<React.PropsWithChildren<PageContainerProps>> = ({
	subnav,
	children,
	name,
	userId,
	showSearchBox = false,
	onUserHub = false,
	clearResponse,
	clearSearchTerms,
}) => (
	<Box bg="bgPrimary" height="100%" minHeight="100vh">
		<Navigation
			name={name}
			showSearchBox={showSearchBox}
			userId={userId}
			onUserHub={onUserHub}
			clearResponse={clearResponse}
			clearSearchTerms={clearSearchTerms}
		>
			{subnav}
		</Navigation>
		<Box maxWidth="1440px" my="0" mx="auto" margin="0 auto" pb="100px" px="48px">
			{children}
		</Box>
	</Box>
);
