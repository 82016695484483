export const hasValidEmail = (value: string) => {
	const regex =
		// eslint-disable-next-line
		/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
	return regex.test(value);
};

export const hasValidUUID = (value: string) => {
	const regex = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
	return regex.test(value);
};

export const hasValidPhoneNumber = (value: string) => {
	const regex = /^(\+?0?1\s?)?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
	return regex.test(value);
};

//2684 part of validation: https://stashinvest.atlassian.net/wiki/spaces/PRODUCTDELIVERY/pages/3040477404/Bank+Account+Number+Generation
export const hasValidStrideAccountNumber = (value: string) => {
	const regex = /^2684\d{10}$/;
	return regex.test(value);
};

export const phoneNumberErrorMessage = 'The phone number is not valid';
export const validatePhoneNumber = (value: string) => {
	if (!hasValidPhoneNumber(value)) {
		return phoneNumberErrorMessage;
	}

	return false;
};

export const emailErrorMessage = 'The email address is not valid';
export const validateEmail = (value: string) => {
	if (!hasValidEmail(value)) {
		return emailErrorMessage;
	}

	return false;
};

export const uuidErrorMessage = 'The User UUID is not valid';
export const validateUUID = (value: string) => {
	if (!hasValidUUID(value)) {
		return uuidErrorMessage;
	}

	return false;
};

export const strideAccountNumberErrorMessage = 'The Stride Account Number is not valid';

export const validateStrideAccountNumber = (value: string) => {
	if (!hasValidStrideAccountNumber(value)) {
		return strideAccountNumberErrorMessage;
	}

	return false;
};

export const emailPhoneOrUUIDErrorMessage =
	'Please enter a valid email, phone number or User UUID';
export const validateSearchTerm = (value: string) => {
	const isValidEmail = hasValidEmail(value);
	const isValidUUID = hasValidUUID(value);
	const isValidPhoneNumber = hasValidPhoneNumber(value);

	if (isValidEmail || isValidUUID || isValidPhoneNumber) {
		return false;
	}

	return emailPhoneOrUUIDErrorMessage;
};
