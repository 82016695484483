export const ACTIVE_FLAGS = {
	nothing: 'nothing',
} as const;

export type Flags = {
	[Property in ActiveFlagsKeys]: boolean;
};

export const flagDefaults: Flags = {
	[ACTIVE_FLAGS.nothing]: false,
};

type ActiveFlagsKeys = (typeof ACTIVE_FLAGS)[keyof typeof ACTIVE_FLAGS];

export const flagsToRequest = Object.values(ACTIVE_FLAGS).reduce((accum, value) => {
	return {
		...accum,
		[value]: flagDefaults[value],
	};
}, {});
