import {
	Environments,
	BaseUrlConfig,
	StashApps as StashAppVariants,
} from '../../providers/EnvironmentProvider';
import { BaseTrackedLinkProps } from '../TrackedLink';

export enum BasicVariants {
	INTERNAL = 'INTERNAL',
	EXTERNAL = 'EXTERNAL',
}

export type LinkVariantsType = BasicVariants | StashAppVariants;

export interface BaseLinkVariantProps {
	/**
	 * Option to link internally, externally or to Active Admin, User Hub, Bank Portal
	 * @default "'Internal'"
	 */
	variant?: LinkVariantsType;
	/**
	 * String to link to, can be external or internal, the variant prop will handle
	 * passing this correctly
	 */
	path: string;
}

export interface LinkVariantProps extends BaseLinkVariantProps, BaseTrackedLinkProps {}

export interface GetBasePathProps extends BaseLinkVariantProps {
	environment: Environments;
	baseUrlConfig: BaseUrlConfig;
}
