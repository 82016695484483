import { FC } from 'react';
import { Flex } from '@stashinvest/ui';
import { TooltipEmoji, TableFooter, CardWithPropTable } from '@stashinvest/shared-ui';
import { ActiveAdminLink } from 'src/components/ActiveAdminLink';
import { ExrAccountData } from '@stashinvest/shared-types/schemas/user';
import { Text } from '@stashinvest/ui/dist/es/2';

type ExternalBankAccountProps = {
	externalAccount: ExrAccountData | null;
	userId: string | null;
};

export const ExternalBankAccount: FC<ExternalBankAccountProps> = ({
	externalAccount,
	userId,
}: ExternalBankAccountProps) => {
	if (externalAccount === null) {
		return null;
	}

	let description = '--';
	let colour = 'textPositivePrimary';
	switch (externalAccount.externalAccountStatus.toString()) {
		case 'activated':
			description =
				'Bank account created in the Monolith. Data sharing over the link is active. Plaid only.';
			break;
		case 'severed':
			description = `Equivalent to activated and only healthy state for Legacy Plaid/Quovo/Micros with no data shared across the link. Plaid: Bank account created but no data shared across the link. Cannot become ‘activated’ again, user needs to create a new link to enable data sharing.`;
			break;
		case 'severing':
			description = `User intends to disable data sharing. Typically up to 2 days before this transitions to severed. Plaid only.`;
			break;
		case 'deactivated':
			description = `Something on the user’s external account changed (i.e. password updated) requiring them to re-auth. No data will be received on the link until user relinks their account in app. We can still move funds. Can become ‘activated’ again by relinking (i.e. re-enter password). Plaid only.`;
			break;
		case 'registered':
			description = `Micro: Received account and routing number, fraud and operational checks complete - but still need to verify micro deposit amounts. Plaid: External account registered but fraud and operational checks pending. Can receive transactions and share data but unable to execute transfers.`;
			colour = 'textPrimary';
			break;
		case 'rejected':
		case 'deregistered':
			description = 'Link is no longer active. User needs to create a new link.';
			colour = 'textWarningPrimary';
	}

	let linkType = externalAccount.linkType.toString();
	switch (externalAccount.linkType.toString()) {
		case 'legacyMicroDeposit':
			linkType = 'Legacy Micro Deposit';
			break;
		case 'legacyPlaid':
			linkType = 'Legacy Plaid';
			break;
		case 'plaid':
			linkType = 'Plaid';
			break;
		case 'quovo':
			linkType = 'Quovo';
	}

	const statusCap =
		externalAccount.externalAccountStatus === 'rejected' ||
		externalAccount.externalAccountStatus === 'deregistered'
			? 'Needs review'
			: externalAccount.externalAccountStatus.charAt(0).toUpperCase() +
			  externalAccount.externalAccountStatus.slice(1);
	const accountTypeCap =
		externalAccount.accountType.charAt(0).toUpperCase() +
		externalAccount.accountType.slice(1);

	return (
		<Flex maxWidth="529px" width={{ mobile: '100%' }} data-testid="external-bank-account">
			<CardWithPropTable
				card={{ title: 'External Bank Account' }}
				tracking={{ page: 'User', component: 'external-bank-account-card' }}
				table={{
					rows: [
						{
							title: 'Bank name',
							value: externalAccount.institutionName,
							testId: 'bank_name',
						},
						{
							title: 'Account type',
							value: accountTypeCap,
							testId: 'account_type',
						},
						{
							title: 'Link type',
							value: linkType,
							testId: 'link_type',
						},
						{
							title: 'Masked account number',
							value: externalAccount.maskedAccountNumber,
							testId: 'masked_account_number',
						},
						{
							title: 'Status',
							value: (
								<>
									<Text.Label14 fontWeight="bold" color={colour}>
										{statusCap}
									</Text.Label14>
									{
										<TooltipEmoji
											emojiLabel={'Status info'}
											description={description}
											style={{
												position: 'absolute',
												right: 0,
												paddingLeft: '5px',
												top: '6px',
											}}
										>
											{'ℹ️'}
										</TooltipEmoji>
									}
								</>
							),
						},
					],
				}}
			>
				<TableFooter>
					<div style={{ display: 'flex', gap: '35px' }}>
						<ActiveAdminLink
							path={`admin/users/${userId}/external_accounts/${externalAccount.externalAccountId}`}
							tracking={{
								page: 'user',
								component: `external-bank-account-card`,
							}}
						>
							View link
						</ActiveAdminLink>
						<ActiveAdminLink
							path={`admin/users/${userId}/external_accounts`}
							tracking={{
								page: 'user',
								component: `external-bank-account-card`,
							}}
						>
							View all external bank links
						</ActiveAdminLink>
					</div>
				</TableFooter>
			</CardWithPropTable>
		</Flex>
	);
};
