import React, { FC, useState } from 'react';

import {
	VisibilityContextProps,
	VisibilityProviderProps,
} from './VisibilityProvider.types';

export const VisibilityContext = React.createContext<VisibilityContextProps>({
	open: false,
	setOpen: () => {},
});

export const VisibilityProvider: FC<React.PropsWithChildren<VisibilityProviderProps>> = ({
	isOpen = false,
	children,
}) => {
	const [open, setOpen] = useState<boolean>(isOpen);

	return (
		<VisibilityContext.Provider
			value={{
				open,
				setOpen,
			}}
		>
			{children}
		</VisibilityContext.Provider>
	);
};
