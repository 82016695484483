import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const CopyIcon: FC<React.PropsWithChildren<IconProps>> = (props) => (
	<IconContainer {...props}>
		<svg width="100%" height="100%" viewBox="0 0 13 15" fill="none">
			<path
				d="M11.593 3.54l.353-.353-.353.354zM9.626 1.575l-.354.354.354-.354zm2.32 1.613L9.98 1.221l-.708.707 1.967 1.966.707-.707zM9.98 1.221A1.891 1.891 0 008.643.667v1c.236 0 .462.094.63.26l.707-.706zM8.643.667H4.35v1h4.292v-1zm-4.292 0c-.66 0-1.196.535-1.196 1.195h1c0-.108.088-.195.196-.195v-1zm6.954 10.735c.66 0 1.195-.535 1.195-1.195h-1a.196.196 0 01-.195.195v1zm1.195-1.195V4.524h-1v5.683h1zm0-5.683c0-.501-.2-.982-.554-1.337l-.707.707a.891.891 0 01.261.63h1zM3.155 1.862v2.402h1V1.862h-1zm8.15 8.54H9.626v1h1.679v-1z"
				fill="currentColor"
			/>
			<path
				d="M8.937 6.639l-.353.353.353-.353zM6.971 4.672l.353-.354-.353.354zm2.32 1.613L7.324 4.318l-.707.707 1.967 1.967.707-.707zM7.324 4.318a1.891 1.891 0 00-1.337-.554v1c.236 0 .463.094.63.261l.707-.707zm-1.337-.554H1.695v1h4.292v-1zm-4.292 0C1.035 3.764.5 4.3.5 4.96h1c0-.108.088-.196.195-.196v-1zM.5 4.96v8.345h1V4.96h-1zm0 8.345c0 .66.535 1.195 1.195 1.195v-1a.196.196 0 01-.195-.195h-1zM1.695 14.5H8.65v-1H1.695v1zm6.954 0c.66 0 1.196-.535 1.196-1.195h-1a.196.196 0 01-.196.195v1zm1.196-1.195V7.622h-1v5.683h1zm0-5.683c0-.502-.2-.983-.554-1.337l-.707.707a.891.891 0 01.26.63h1z"
				fill="currentColor"
			/>
		</svg>
	</IconContainer>
);
