import React, { FC } from 'react';
import { PrimaryButton, SecondaryButton } from '@stashinvest/ui';

import styled from 'styled-components';
import { useDebounce } from '../../hooks';
import { gaEvent } from '../../utils';
import { UnstyledButton } from '../UnstyledButton';
import { TrackedButtonProps, TrackedButtonVariants } from './TrackedButton.types';

const LinkButton = styled(UnstyledButton)`
	color: ${({ theme }) => theme.colors.textActionPrimary};
	text-decoration: underline;
	display: inline;
`;

const NegativeLinkButton = styled(LinkButton)`
	color: ${({ theme }) => theme.colors.textNegativePrimary};
`;

export const TrackedButton: FC<React.PropsWithChildren<TrackedButtonProps>> = ({
	delay,
	tracking,
	type,
	onClick,
	children,
}) => {
	const { page, component, label = 'button' } = tracking;

	const callback = () => {
		gaEvent({ event: 'button', page, component, label });
		onClick();
	};

	const debouncedClickEvent = useDebounce({
		callback,
		delay: delay || 500,
	});

	const commonProps = {
		size: 'small',
		onClick: delay ? debouncedClickEvent : callback,
	};

	if (type === TrackedButtonVariants.LINK) {
		return <LinkButton {...commonProps}>{children}</LinkButton>;
	}

	if (type === TrackedButtonVariants.NEGATIVE_LINK) {
		return <NegativeLinkButton {...commonProps}>{children}</NegativeLinkButton>;
	}

	if (type === TrackedButtonVariants.SECONDARY_BUTTON) {
		return <SecondaryButton {...commonProps}>{children}</SecondaryButton>;
	}

	return <PrimaryButton {...commonProps}>{children}</PrimaryButton>;
};
