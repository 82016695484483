import React, { FC } from 'react';
import { Box } from '@stashinvest/ui/dist/es/2';
import { Tag } from '@stashinvest/ui';
import { FalseyTextProps } from './FalseyText.types';

export const FalseyText: FC<React.PropsWithChildren<FalseyTextProps>> = ({
	text,
	nullValue = 'Empty',
	prefix,
	suffix,
}: FalseyTextProps) => {
	if (text) {
		return (
			<>
				{prefix}
				{prefix ? ' ' : null}
				{text}
				{suffix}
			</>
		);
	}

	return (
		<Box as="span" mx="5px">
			<Tag variant="warning">{nullValue}</Tag>
		</Box>
	);
};
