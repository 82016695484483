import { config } from 'src/config/config';
import { StrictMode } from 'react';
import { oktaAuth } from 'src/config/okta';
import { createRoot } from 'react-dom/client';
import { Text } from '@stashinvest/ui/dist/es/2';
import * as Sentry from '@sentry/react';
import { datadogLogs } from '@datadog/browser-logs';
import { GlobalStyle, SnackbarProvider } from '@stashinvest/ui';
import {
	createFeatureFlagProvider,
	EnvironmentProvider,
	Environments,
	ThemeModeProvider,
} from '@stashinvest/shared-ui';
import { QueryClientProvider } from '@tanstack/react-query';

import * as serviceWorker from 'src/serviceWorker';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { datadogRum } from '@datadog/browser-rum';
import { flagsToRequest } from 'src/utils/activeFlags';
import {
	redactSensitiveParams,
	redactDataDogEvent,
	redactDataDogRumEvent,
} from 'src/utils';
import { queryClient } from './config/queryClient';
import { App } from './App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

const releaseHash = process.env.REACT_APP_RELEASE || 'dev';

Sentry.init({
	dsn: config.sentryDsn,
	environment: config.env || 'dev',
	release: `winchester-frontend@${releaseHash}`,
	maxBreadcrumbs: 0,
	attachStacktrace: false,
	beforeSend: redactSensitiveParams,
	denyUrls: [/.*\.datadoghq\..*/], // discard DataDog logging network errors
});

if (config.env === 'prod' || config.debug) {
	datadogLogs.init({
		clientToken: config.ddClientToken,
		site: 'datadoghq.com',
		service: config.ddService,
		env: config.env,
		forwardErrorsToLogs: true,
		sampleRate: 100,
		beforeSend: redactDataDogEvent,
	});

	datadogRum.init({
		applicationId: config.ddRumApplicationId,
		clientToken: config.ddRumClientToken,
		site: 'datadoghq.com',
		service: config.ddService,
		env: config.env,
		version: releaseHash,
		sampleRate: 100,
		trackInteractions: false,
		allowedTracingOrigins: [window.location.origin],
		beforeSend: (event) => {
			const [shouldSend] = redactDataDogRumEvent(event);
			return shouldSend;
		},
	});

	datadogLogs.logger.addContext('host', 'userhub');
	datadogLogs.logger.addContext('logger_name', 'Application');
}

if (config.error) {
	root.render(
		<ThemeModeProvider>
			<Text.Label14>
				Error loading environment config. Please Report this error and try reloading
			</Text.Label14>
		</ThemeModeProvider>
	);
	throw Error('Failed to load config');
}

const FeatureFlagProvider = createFeatureFlagProvider(
	config.launchDarklyClientId,
	flagsToRequest
	//to be fixed
) as any;

const setEnv = (env: String) => {
	switch (env) {
		case 'dev':
			return Environments.EDGE;
		case 'edge':
			return Environments.EDGE;
		case 'staging':
			return Environments.STAGING;
		case 'prod':
			return Environments.PROD;
		default:
			console.error(`Could not parse environment ${env}`); // eslint-disable-line no-console
			return Environments.PROD;
	}
};

root.render(
	<StrictMode>
		<ErrorBoundary>
			<EnvironmentProvider environmentToSet={setEnv(config.env)}>
				<FeatureFlagProvider>
					<ThemeModeProvider>
						<GlobalStyle withFonts />
						<SnackbarProvider>
							<QueryClientProvider client={queryClient}>
								<App oktaAuth={oktaAuth} />
							</QueryClientProvider>
						</SnackbarProvider>
					</ThemeModeProvider>
				</FeatureFlagProvider>
			</EnvironmentProvider>
		</ErrorBoundary>
	</StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
