import { TrackingProps } from '../../utils/analytics';

export enum TrackedButtonVariants {
	BUTTON = 'BUTTON',
	SECONDARY_BUTTON = 'SECONDARY_BUTTON',
	LINK = 'LINK',
	NEGATIVE_LINK = 'NEGATIVE_LINK',
}

export interface TrackedButtonProps {
	/**
	 * Debounce delay time for action, important as we make a tracking call
	 */
	delay?: number;
	/**
	 * Custom click event we want to fire
	 */
	onClick(): void;
	/**
	 * The properties used for tracking
	 */
	tracking: TrackingProps;
	/**
	 * The type of component we want to show
	 */
	type?: TrackedButtonVariants;
}
