import { useQuery } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';

import {
	UserSearchParamsType,
	SearchResponseSchemaType,
} from '@stashinvest/shared-types/schemas/user';
import { config } from 'src/config/config';

export const getUserSearch = async (
	params: UserSearchParamsType,
	accessToken: string
): Promise<SearchResponseSchemaType> => {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/search?` +
			new URLSearchParams({
				page: params.page,
				per_page: params.per_page,
				email: params.email ? params.email : '',
				first_name: params.first_name ? params.first_name : '',
				last_name: params.last_name ? params.last_name : '',
				phone_number: params.phone_number ? params.phone_number : '',
			}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		}
	);

	const responseBody = await response.json();

	if (!response.ok) {
		switch (response.status) {
			case 401:
				throw new Error('Unauthorized');
			default:
				// TODO: export the gateway error type from shared-types and passthrough the actual error message
				throw new Error('Something went wrong while searching for users');
		}
	}

	return responseBody;
};

export const useUserSearch = (params: UserSearchParamsType) => {
	const { oktaAuth } = useOktaAuth();

	return useQuery<SearchResponseSchemaType>(['userSearch', params], () =>
		getUserSearch(params, oktaAuth.getAccessToken())
	);
};
