import { FunctionComponent } from 'react';
import { Text } from '@stashinvest/ui/dist/es/2';
import { TooltipWithTag } from '@stashinvest/shared-ui';
import { ErrorType, PartialError } from '@stashinvest/shared-types/errors';

export interface DataFieldProps {
	partialObject: any;
	propertyName: string;
}

export const DataField: FunctionComponent<React.PropsWithChildren<DataFieldProps>> = ({
	partialObject,
	propertyName,
	children,
}) => {
	const fieldError = getFieldError(partialObject, propertyName);

	if (fieldError) {
		const variant = fieldError.type === ErrorType.Enum.ERROR ? 'danger' : 'warning';
		const redacted = fieldError?.type === ErrorType.Enum.REDACTED;

		if (redacted) {
			return (
				<Text.Label14 fontWeight="bold" as="div" data-testid="error-text">
					{fieldError.message}
				</Text.Label14>
			);
		} else {
			return (
				<TooltipWithTag
					description={fieldError.description}
					variant={variant}
					data-testid="error-text"
				>
					{fieldError.message}
				</TooltipWithTag>
			);
		}
	}

	return <>{children}</>;
};

const getFieldError = (partialObject: any, propertyName: string) => {
	const errors = partialObject['_errors'];
	if (errors) {
		const propertyError = errors[propertyName];
		if (propertyError && isPartialError(propertyError)) {
			return propertyError;
		}
	}
	return null;
};

const isPartialError = (input: unknown): input is PartialError => {
	return Boolean(
		typeof input === 'object' &&
			input !== null &&
			'message' in input &&
			'description' in input &&
			'type' in input
	);
};
