import { createContext, useState, FC } from 'react';

export const FIRST_NAME_TERM = 'firstName';
export const LAST_NAME_TERM = 'lastName';
export const PHONE_TERM = 'phone';
export const EMAIL_TERM = 'email';
export const ID_TERM = 'id';
export const STRIDE_ACCOUNT_NUMBER_TERM = 'strideAccountNumber';

export type AdvancedSearchValue = string;

export interface SearchTermProps {
	[FIRST_NAME_TERM]: AdvancedSearchValue;
	[LAST_NAME_TERM]: AdvancedSearchValue;
	[PHONE_TERM]: AdvancedSearchValue;
	[EMAIL_TERM]: AdvancedSearchValue;
	[ID_TERM]: AdvancedSearchValue;
	[STRIDE_ACCOUNT_NUMBER_TERM]?: AdvancedSearchValue;
}

interface SearchTermContextProps {
	searchTerms: SearchTermProps;
	setSearchTerms(value: SearchTermProps): void;
	clearSearchTerms(): void;
}

export const defaultState: SearchTermProps = {
	[FIRST_NAME_TERM]: '',
	[LAST_NAME_TERM]: '',
	[PHONE_TERM]: '',
	[EMAIL_TERM]: '',
	[ID_TERM]: '',
	[STRIDE_ACCOUNT_NUMBER_TERM]: '',
};

export const SearchTermContext = createContext<SearchTermContextProps>({
	searchTerms: defaultState,
	setSearchTerms: () => {},
	clearSearchTerms: () => {},
});

export const SearchTermProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [state, setState] = useState<SearchTermProps>(defaultState);
	const resetState = () => setState(defaultState);

	return (
		<SearchTermContext.Provider
			value={{
				searchTerms: {
					[FIRST_NAME_TERM]: state[FIRST_NAME_TERM],
					[LAST_NAME_TERM]: state[LAST_NAME_TERM],
					[PHONE_TERM]: state[PHONE_TERM],
					[EMAIL_TERM]: state[EMAIL_TERM],
					[ID_TERM]: state[ID_TERM],
					[STRIDE_ACCOUNT_NUMBER_TERM]: state[STRIDE_ACCOUNT_NUMBER_TERM],
				},
				setSearchTerms: setState,
				clearSearchTerms: resetState,
			}}
		>
			{children}
		</SearchTermContext.Provider>
	);
};
