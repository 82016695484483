import React, { FC } from 'react';
import { Card } from '../Card';
import { PropTable } from '../PropTable';
import { CardWithPropTableProps } from './CardWithPropTable.types';

export const CardWithPropTable: FC<React.PropsWithChildren<CardWithPropTableProps>> = ({
	card,
	table,
	tracking,
	children,
}) => (
	<Card {...card} tracking={tracking}>
		<PropTable {...table} tracking={tracking} />
		{children}
	</Card>
);
