import React, { FC } from 'react';
import { Flex, Tag } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { Tooltip } from '../Tooltip';
import { FormattedDate } from '../FormattedDate';
import { FormattedCurrency } from '../FormattedCurrency';
import { LinkVariant } from '../LinkVariant';
import { TrackedLinkVariants } from '../TrackedLink';

import { PropTableCellVariant, PropTableValProps } from './PropTable.types';

export const PropTableVal: FC<React.PropsWithChildren<PropTableValProps>> = ({
	value,
	variant,
	tooltipBody,
	tagVariant,
	link,
	tracking,
}) => {
	if (variant === PropTableCellVariant.LINK && link) {
		return (
			<LinkVariant
				type={TrackedLinkVariants.LINK}
				variant={link.variant}
				path={link.path}
				tracking={{
					page: tracking?.page || 'unknown',
					component: tracking?.component || 'prop-table',
					label: 'value-link',
				}}
			>
				{value}
			</LinkVariant>
		);
	}

	if (variant === PropTableCellVariant.CURRENCY) {
		return (
			<Text.Label14 as="span">
				<FormattedCurrency value={value?.toString()} />
			</Text.Label14>
		);
	}

	if (variant === PropTableCellVariant.DATE) {
		return (
			<Text.Label14 as="span">
				<FormattedDate timestamp={value?.toString()} shortMonthNames pureDate />
			</Text.Label14>
		);
	}

	if (variant === PropTableCellVariant.DATETIME) {
		return (
			<Text.Label14 as="span">
				<FormattedDate
					timestamp={value?.toString()}
					shortMonthNames
					showTimezone
					showTime
				/>
			</Text.Label14>
		);
	}

	if (variant === PropTableCellVariant.TAG) {
		return (
			<Tag variant={tagVariant || 'warning'} data-testid="tag">
				{value}
			</Tag>
		);
	}

	if (tooltipBody && variant === PropTableCellVariant.TOOLTIP) {
		return (
			<Flex alignItems="center">
				<Text.Label14 as="span">{value}</Text.Label14>
				<Tooltip description={tooltipBody} />
			</Flex>
		);
	}

	return <>{value}</>;
};
