import { z } from 'zod';
import { APISchema } from '../api';
import { ErrorResponseBody, StashGenericErrorResponse } from '../errors';

export const PhoneNumberTypes = z.enum(['HOME', 'MOBILE']);

const legacyVisaTypes = z.enum(['C1', 'H2B', 'H3', 'K1', 'Other', 'B1']); // these visa types are no longer accepted for new customers
export const visaTypes = z.enum(['E1', 'E2', 'E3', 'F1', 'H1B', 'L1', 'O1', 'TN1']);

const AddressMonolithSchema = z.object({
	public_id: z.string().uuid(),
	is_primary: z.boolean(),
	street_address: z.string().nullable(),
	street_address2: z.string().nullable(),
	city: z.string().nullable(),
	state: z.string().nullable(),
	postal_code: z.string().nullable(),
	country: z.string().length(3).nullable().or(z.literal('')),
	delivery_indicator: z.enum(['Residential', 'Commercial']).nullable().or(z.literal('')),
});

const AddressSchema = AddressMonolithSchema.transform((addr) => ({
	// omit public_id
	isPrimary: addr.is_primary,
	streetAddress: addr.street_address,
	streetAddress2: addr.street_address2,
	city: addr.city,
	state: addr.state,
	postalCode: addr.postal_code,
	country: addr.country,
	deliveryIndicator: addr.delivery_indicator,
}));

export const AddressesSchema = z.array(AddressSchema).transform((addresses) => {
	return calculateAddresses(addresses);
});

export type AddressMonolith = z.infer<typeof AddressSchema>;
export type Addresses = z.infer<typeof AddressesSchema>;

export const UserInfoMonolithSchema = z.object({
	user_id: z.string().uuid(),
	username: z.string(),
	email: z.string(),
	email_verified: z.boolean().nullable(),
	phone_number: z.string().nullable(),
	phone_number_type: PhoneNumberTypes.nullable(),
	phone_verified: z.boolean().nullable(),
	legacy_id: z.number(),
	created_at: z.string().datetime(),
});

// omits PII
export const ContentOnlyUserInfoSchema = UserInfoMonolithSchema.transform((info) => ({
	userId: info.user_id,
	legacyId: info.legacy_id,
	username: info.username,
	createdAt: info.created_at,
	emailVerified: info.email_verified,
	phoneVerified: info.phone_verified,
	phoneNumber: null,
	email: null,
	contentOnly: true as const,
}));

export const UserInfoSchema = UserInfoMonolithSchema.transform((info) => ({
	userId: info.user_id,
	legacyId: info.legacy_id,
	username: info.username,
	email: info.email,
	emailVerified: info.email_verified,
	phoneNumber: info.phone_number,
	phoneNumberType: info.phone_number_type,
	phoneVerified: info.phone_verified,
	createdAt: info.created_at,
}));

type ContentOnlyUserInfo = z.infer<typeof ContentOnlyUserInfoSchema>;

const UserBasicProfileSchema = z.object({
	firstName: z.string().nullable(),
	lastName: z.string().nullable(),
	dateOfBirth: z.string().nullable(),
	ssn: z.string().nullable(),
});

type UserBasicProfile = z.infer<typeof UserBasicProfileSchema>;
type ContentOnlyUserBasicProfile = UserBasicProfile & {
	ssn: null;
	dateOfBirth: null;
};

const UserNationalityMonolithSchema = z.object({
	citizenship_country: z.string().length(3).nullable().or(z.literal('')),
	birth_country: z.string().length(3).nullable().or(z.literal('')),
	permanent_resident: z.boolean().nullable(),
	visa_type: visaTypes.nullable().or(z.literal('')).or(legacyVisaTypes),
	visa_expiration_date: z.coerce.date().nullable(),
});

export const UserNationalitySchema = UserNationalityMonolithSchema.transform((n) => ({
	citizenshipCountry: n.citizenship_country,
	birthCountry: n.birth_country,
	permanentResident: n.permanent_resident,
	visaType: n.visa_type,
	visaExpirationDate: isoDateStringWithoutTz(n.visa_expiration_date),
}));

export const UserProfileSchema = z.object({
	info: UserInfoSchema,
	profile: UserBasicProfileSchema,
	addresses: AddressesSchema,
	nationality: UserNationalitySchema,
});

function convertAddress(address: AddressMonolith) {
	return {
		streetAddress: address.streetAddress,
		streetAddress2: address.streetAddress2,
		city: address.city,
		state: address.state,
		postalCode: address.postalCode,
		country: address.country,
		deliveryIndicator: address.deliveryIndicator,
	};
}

function calculateAddresses(addresses: AddressMonolith[]) {
	const primaryAddress = addresses.find((a) => a.isPrimary);
	const mailingAddress = addresses.find((a) => !a.isPrimary);

	return {
		mailingAddressIsSame: Object.entries(mailingAddress!).every(
			([k, v]) => k === 'isPrimary' || v === null
		),
		primaryAddress: primaryAddress ? convertAddress(primaryAddress) : null,
		mailingAddress: mailingAddress ? convertAddress(mailingAddress) : null,
	};
}

const GenericErrorSchema = z.object({
	status: z.number(),
	error: z.string(),
});

function mapGenericError(
	response: z.infer<typeof GenericErrorSchema>
): ErrorResponseBody {
	return {
		error: {
			message: response.error,
			code: 'INTERNAL_SERVER_ERROR',
		},
	};
}

function mapMonolithError(
	response: z.infer<typeof StashGenericErrorResponse>
): ErrorResponseBody {
	if (response.errors.length > 0) {
		return {
			error: {
				message: 'Invalid user profile payload',
				code: 'BAD_USER_INPUT',
				details: {
					'@type': 'biz-ops.api.v1.ValidationErrorMessage',
					message: response.errors[0].message,
				},
			},
		};
	} else {
		return {
			error: {
				message: 'Invalid user profile payload',
				code: 'BAD_USER_INPUT',
			},
		};
	}
}

const MonolithErrorsSchemaResponse =
	StashGenericErrorResponse.transform(mapMonolithError);

const GenericErrorSchemaResponse = GenericErrorSchema.transform(mapGenericError);

export const ErrorResponseSchema = MonolithErrorsSchemaResponse.or(
	GenericErrorSchemaResponse
);

export type ErrorResponse = z.infer<typeof ErrorResponseSchema>;

export const PatchUserProfileResponseSchema = UserProfileSchema.or(ErrorResponseSchema);

export const PatchUserProfileSchema = z.object({
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	dateOfBirth: z.coerce.date().optional(),
	email: z.string().email().optional(),
	phoneNumber: z.string().optional(),
	phoneNumberType: PhoneNumberTypes.optional(),
	homeStreetAddress: z.string().optional(),
	homeStreetAddress2: z.string().optional(),
	homeCity: z.string().optional(),
	homeState: z.string().optional(),
	homePostalCode: z.string().optional(),
	homeCountry: z.string().length(3).optional(),
	mailingStreetAddress: z.string().optional(),
	mailingStreetAddress2: z.string().optional(),
	mailingCity: z.string().optional(),
	mailingState: z.string().optional(),
	mailingPostalCode: z.string().optional(),
	mailingCountry: z.string().length(3).optional(),
	citizenshipCountry: z.string().length(3).optional(),
	birthCountry: z.string().length(3).optional(),
	permanentResident: z.boolean().optional(),
	visaType: visaTypes.optional(),
	visaExpirationDate: z.coerce.date().optional(),
	mailingAddressIsSame: z.boolean().optional(),
});

export const PatchUserProfileMonolithSchema = PatchUserProfileSchema.strict().transform(
	(p) => ({
		first_name: p.firstName,
		last_name: p.lastName,
		date_of_birth: p.dateOfBirth,
		email: p.email,
		phone_number: p.phoneNumber,
		phone_number_type: p.phoneNumberType,
		home_street_address: p.homeStreetAddress,
		home_street_address2: p.homeStreetAddress2,
		home_city: p.homeCity,
		home_state: p.homeState,
		home_postal_code: p.homePostalCode,
		home_country: p.homeCountry,
		mailing_street_address: p.mailingStreetAddress,
		mailing_street_address2: p.mailingStreetAddress2,
		mailing_city: p.mailingCity,
		mailing_state: p.mailingState,
		mailing_postal_code: p.mailingPostalCode,
		mailing_country: p.mailingCountry,
		citizenship_country: p.citizenshipCountry,
		birth_country: p.birthCountry,
		permanent_resident: p.permanentResident,
		visa_type: p.visaType,
		visa_expiration_date: p.visaExpirationDate,
		mailingAddressIsSame: p.mailingAddressIsSame, // Is not a part of the params that Monolith can take
	})
);

export type PatchUserProfilePayload = z.infer<typeof PatchUserProfileSchema>;
export type PatchUserProfileMonolithPayload = z.infer<
	typeof PatchUserProfileMonolithSchema
>;

export type PatchUserProfileResponse = z.infer<typeof PatchUserProfileResponseSchema>;

function isoDateStringWithoutTz(date: Date | null): string | null {
	return date ? date.toISOString().split('T')[0] : null;
}

export type BaseUserProfile = z.infer<typeof UserProfileSchema>;
export type UserProfile = BaseUserProfile & {
	info: { contentOnly: boolean };
};

export interface ContentOnlyUserProfile {
	info: ContentOnlyUserInfo;
	profile: ContentOnlyUserBasicProfile;
}
export type UserProfileOrContentOnlyUserProfile = ContentOnlyUserProfile | UserProfile;

/** Checks if `UserProfile` has contentOnly flag set to true */
export function isContentOnlyUserProfile(
	input: UserProfileOrContentOnlyUserProfile
): input is ContentOnlyUserProfile {
	return Boolean(
		typeof input === 'object' &&
			input &&
			'info' in input &&
			typeof input.info === 'object' &&
			input.info &&
			'contentOnly' in input.info &&
			input.info.contentOnly
	);
}

/** Returns same user profile with PII omitted */
export function getContentOnlyUserProfileFromUserProfile(
	userProfile: BaseUserProfile | UserProfile
): ContentOnlyUserProfile {
	return {
		info: {
			userId: userProfile.info.userId,
			legacyId: userProfile.info.legacyId,
			username: userProfile.info.username,
			createdAt: userProfile.info.createdAt,
			emailVerified: userProfile.info.emailVerified,
			phoneVerified: userProfile.info.phoneVerified,
			phoneNumber: null,
			email: null,
			contentOnly: true,
		},
		profile: {
			firstName: userProfile.profile.firstName,
			lastName: userProfile.profile.lastName,
			dateOfBirth: null,
			ssn: null,
		},
	};
}

export const userProfileApiSchema = {
	getUserProfile: {
		responseSchema: UserProfileSchema,
	},
	patchUserProfile: {
		requestSchema: {
			body: PatchUserProfileMonolithSchema,
		},
		responseSchema: PatchUserProfileResponseSchema,
	},
} as const satisfies Record<string, APISchema>;
