import { FormikProps, FormikValues } from 'formik';
import { Box } from '@stashinvest/ui';
import { FormikTextField, FormikSelect } from '@stashinvest/ui/dist/es/2';
import {
	InvestorApplication,
	InvestorApplicationGroup,
	InvestorApplicationInput,
	InvestorApplicationQuestionAnswerType,
	InvestorApplicationQuestionAnswerValue,
	InvestorApplicationQuestionGroups,
} from '@stashinvest/shared-types/investorApplication';
import { convertBoolStringToBool } from 'src/utils/convertBoolStringToBool';

export function filterQuestionsByGroup(
	investorApplication: InvestorApplication['investorApplication'],
	group: InvestorApplicationGroup
) {
	return investorApplication.find((application) => application.group === group)
		?.questions;
}

type FormFieldOptions = {
	disabled?: boolean;
	caption?: string;
	placeholder?: string;
};
const defaultFormFieldOptions = {
	disabled: false,
};
export function getFormFieldForQuestion(
	question: InvestorApplication['investorApplication'][number]['questions'][number],
	options: FormFieldOptions = {}
) {
	let fieldComponent: JSX.Element;
	const { disabled, caption, placeholder } = { ...defaultFormFieldOptions, ...options };
	switch (question.answer.type) {
		case 'String':
		case 'Number':
		case 'Array':
			fieldComponent = (
				<FormikTextField
					name={question.id}
					label={question.label}
					disabled={disabled}
					caption={caption}
					placeholder={placeholder}
				/>
			);
			break;
		case 'Enum':
			fieldComponent = (
				<FormikSelect
					name={question.id}
					label={question.label}
					disabled={disabled}
					caption={caption}
				>
					{question.answer.options?.map((option) => (
						<option key={option.label} value={option.value}>
							{option.label}
						</option>
					))}
				</FormikSelect>
			);
			break;
		case 'Boolean':
			const boolOptions = ['Yes', 'No'] as const;
			fieldComponent = (
				<FormikSelect
					name={question.id}
					label={question.label}
					disabled={disabled}
					caption={caption}
					defaultValue=""
				>
					{boolOptions.map((option) => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</FormikSelect>
			);
			break;
		default:
			fieldComponent = (
				<FormikTextField
					name={question.id}
					label={question.label}
					disabled={disabled}
					caption={caption}
					placeholder={placeholder}
				/>
			);
	}
	return (
		<Box width="100%" key={question.id}>
			{fieldComponent}
		</Box>
	);
}

export function getInitialValuesFromQuestionGroup(
	questionGroup: InvestorApplicationQuestionGroups
): InvestorApplicationInput {
	return questionGroup.reduce((acc, question) => {
		const answer = question.answer.value;
		let initialValue = answer;
		switch (question.answer.type) {
			case 'String':
				initialValue = answer ?? undefined;
				break;
			case 'Array':
				const answerIsArray = Array.isArray(answer);
				initialValue = answerIsArray ? answer.join(', ') : undefined;
				break;
			case 'Boolean':
				if (answer === null || answer === undefined) {
					initialValue = '';
				} else {
					initialValue = answer ? 'Yes' : 'No';
				}
				break;
			case 'Enum':
				initialValue ??= '';
		}

		return {
			...acc,
			[question.id]: initialValue,
		};
	}, {});
}

export type FieldHelpTextMap = Partial<
	Record<InvestorApplicationQuestionGroups[number]['id'], string>
>;

export function getFormRendererFromQuestionGroup(
	questionGroup: InvestorApplicationQuestionGroups,
	fieldOptions: {
		captionTextMap?: FieldHelpTextMap;
		placeholderTextMap?: FieldHelpTextMap;
	} = {}
) {
	const { captionTextMap, placeholderTextMap } = fieldOptions;
	return (formikProps: FormikProps<FormikValues>) => {
		const values = formikProps.values;
		let formFields: JSX.Element[] = [];
		const questionsToProcess = new Set(questionGroup.map((q) => q.id));

		questionGroup.forEach((question) => {
			if (question.readOnly || !questionsToProcess.has(question.id)) {
				return;
			}
			formFields.push(
				getFormFieldForQuestion(question, {
					caption: captionTextMap?.[question.id],
					placeholder: placeholderTextMap?.[question.id],
				})
			);
			const currentValue = getCurrentAnswer(values?.[question.id], question.answer.type);

			question.dependentQuestions.forEach((q) => {
				const disableDependentFields = q.value !== currentValue;
				if (questionsToProcess.has(q.id)) {
					const dependentQuestion = questionGroup.find(
						(question) => question.id === q.id
					);

					if (dependentQuestion) {
						const shouldClearDepdendentQuestionInput =
							disableDependentFields && Boolean(values?.[dependentQuestion.id]);
						if (shouldClearDepdendentQuestionInput) {
							formikProps.setFieldValue(dependentQuestion.id, '');
						}

						formFields.push(
							getFormFieldForQuestion(dependentQuestion, {
								disabled: disableDependentFields,
								caption: captionTextMap?.[q.id],
								placeholder: placeholderTextMap?.[q.id],
							})
						);
					}
					questionsToProcess.delete(q.id);
				}
			});
		});
		return <>{formFields}</>;
	};
}

const getCurrentAnswer = (
	answer: InvestorApplicationQuestionAnswerValue,
	answerType: InvestorApplicationQuestionAnswerType
) => {
	let currentAnswer = answer;
	if (answerType === 'Boolean' && typeof answer === 'string') {
		currentAnswer = convertBoolStringToBool(answer);
	}
	return currentAnswer;
};
