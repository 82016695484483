import { FraudReasonMap, FraudReasonKey } from '@stashinvest/shared-types/fraud';

type FraudReasonPropsMap = Record<
	(typeof FraudReasonMap)[FraudReasonKey],
	FraudReasonProps
>;

export type FraudReasonProps = {
	title: string;
	description: string;
	optionalTitle?: string;
	optionalDescription?: string;
	knowledgeBaseLink?: string;
};

const commonKnowledgeBaseLink =
	'https://stashinvesthelp.zendesk.com/hc/en-us/articles/360041136151';
const commonDescription =
	'Please reference comments for details and click the KB link for next steps.';
const commonOptionalTitle = '**INTERNAL ONLY - DO NOT REPEAT TO USER**';

export const fraudReasons: FraudReasonPropsMap = {
	[FraudReasonMap['fraud_review:under_review_lock']]: {
		title: 'FRAUD REVIEW - Under Review Lock',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_review:under_review_pause']]: {
		title: 'FRAUD REVIEW - Under Review Pause',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_review:under_review_reject']]: {
		title: 'FRAUD REVIEW - Under Review Reject',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_closure:first_party_fraud']]: {
		title: 'FRAUD CLOSURE - First Party Fraud',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_closure:id_theft']]: {
		title: 'FRAUD CLOSURE - ID Theft',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_closure:id_theft_cc']]: {
		title: 'FRAUD CLOSURE - ID Theft Customer Contact',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_closure:account_takeover']]: {
		title: 'FRAUD CLOSURE - Account Takeover',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_closure:account_takeover_cc']]: {
		title: 'FRAUD CLOSURE - Account Takeover Customer Contact',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_closure:account_handover']]: {
		title: 'FRAUD CLOSURE - Account Handover',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_closure:other']]: {
		title: 'FRAUD CLOSURE - Other',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_restrict:first_party_fraud']]: {
		title: 'FRAUD RESTRICT - First Party Fraud',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_restrict:id_theft']]: {
		title: 'FRAUD RESTRICT - ID Theft',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_restrict:id_theft_cc']]: {
		title: 'FRAUD RESTRICT - ID Theft Customer Contact',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_restrict:account_takeover']]: {
		title: 'FRAUD RESTRICT - Account Takeover',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_restrict:account_takeover_cc']]: {
		title: 'FRAUD RESTRICT - Account Takeover Customer Contact',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_restrict:account_handover']]: {
		title: 'FRAUD RESTRICT - Account Handover',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fraud_restrict:other']]: {
		title: 'FRAUD RESTRICT - Other',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fcc_dndbw:other']]: {
		title: 'FCC DNDBW',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['fcc_restriction:other']]: {
		title: 'FCC RESTRICTION',
		description: commonDescription,
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['1st-Party ACH Fraud']]: {
		title: 'FRAUD RESTRICT - First Party Fraud',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for 1st Party ACH Fraud',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap["3rd-Party ACH Fraud (Unauth'd Bank Link)"]]: {
		title: 'FRAUD RESTRICT - Other',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription:
			'Previously restricted for 3rd Party ACH Fraud (Unauth banklink)',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['Suspected Account Takeover']]: {
		title: 'FRAUD RESTRICT - Account Takeover',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Suspected Account Takeover',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['Account Takeover']]: {
		title: 'FRAUD RESTRICT - Account Takeover Customer Contact',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Account Takeover',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['Suspected ID Theft']]: {
		title: 'FRAUD RESTRICT - ID Theft',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Suspected ID Theft',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['Confirmed ID Theft']]: {
		title: 'FRAUD RESTRICT - ID Theft Customer Contact',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Confirmed ID Theft',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['Securities Free Riding']]: {
		title: 'FRAUD RESTRICT - First Party Fraud',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Securities Free Riding',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['Terminated by Apex']]: {
		title: 'FRAUD RESTRICT - Other',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Terminated by Apex',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap.Other]: {
		title: 'FRAUD RESTRICT - Other',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Other',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['Debit Card Funding Fraud']]: {
		title: 'FRAUD RESTRICT - Other',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Debit Card Funding Fraud',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['ACH Pull Fraud (Stash Debit)']]: {
		title: 'FRAUD RESTRICT - Other',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for ACH Pull Fraud (Stash Debit)',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap['Money Laundering']]: {
		title: 'FRAUD RESTRICT - Other',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Money Laundering',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap.Chargeback]: {
		title: 'FRAUD RESTRICT - Other',
		description: commonDescription,
		optionalTitle: commonOptionalTitle,
		optionalDescription: 'Previously restricted for Chargeback',
		knowledgeBaseLink: commonKnowledgeBaseLink,
	},
	[FraudReasonMap.unknown_reason]: {
		title: 'LOCK_REASON_UNKNOWN',
		description: 'Due to a technical error, we cannot display the fraud lock reason.',
	},
};
