import styled, { css } from 'styled-components';

import { TrackedLinkColors } from '../components/TrackedLink';

type Colors = TrackedLinkColors;

interface StyledLinkProps {
	px: string;
	py: string;
	color?: Colors;
	hoverStyles?: { color?: Colors };
}

export const StyledLink = styled.a.withConfig({
	shouldForwardProp: (prop) => !['hoverStyles'].includes(prop),
})<StyledLinkProps>`
	text-decoration: none;
	padding: ${({ py = '0px', px = '0px' }) => `${py} ${px}`};
	color: ${({ color, theme }) =>
		color && theme.colors.hasOwnProperty(color) ? theme.colors[color] : color};
	${({ hoverStyles, theme }) => {
		if (!hoverStyles) return;
		const { color } = hoverStyles;
		return css`
			&:hover {
				${color && theme.colors.hasOwnProperty(color)
					? `color: ${theme.colors[color]};`
					: ''}
			}
		`;
	}}
`;
