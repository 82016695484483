import React, { FC } from 'react';
import { Text } from '@stashinvest/ui/dist/es/2';
import styled from 'styled-components';

export interface PillToggleOptions {
	value: string;
	label: string;
}

export interface PillToggleProps {
	options: Array<PillToggleOptions>;
	selectedOption: string;
	onChange: (name: string) => void;
}

// TODO: these are hard-coded until we migrate to SDS
const colors = {
	'sys/inverse/inverseScrim': '#FFFFFF',
	'sys/secondary': '#354A53',
	'sys/onPrimaryContainer': '#001F29',
	'sys/onPrimary': '#FFFFFF',
};

const PillButton = styled.button<{ isSelected: boolean }>`
	border-radius: 46px;
	background: ${({ isSelected }) =>
		isSelected ? colors['sys/inverse/inverseScrim'] : colors['sys/secondary']};
	height: 24px;
	transition: all 0.2s ease-in;
	p {
		color: ${({ isSelected }) =>
			isSelected ? colors['sys/onPrimaryContainer'] : colors['sys/onPrimary']};
	}
`;

const PillContainer = styled.div`
	background: ${colors['sys/secondary']};
	border-radius: 46px;
	display: inline-block;
	padding: 4px;
`;

export const PillToggle: FC<PillToggleProps> = ({
	options,
	selectedOption,
	onChange,
}) => {
	return (
		<PillContainer>
			{options.map((opt) => (
				<PillButton
					key={opt.value}
					onClick={() => onChange(opt.value)}
					isSelected={opt.value === selectedOption}
					type="button"
				>
					<Text.Label14>{opt.label}</Text.Label14>
				</PillButton>
			))}
		</PillContainer>
	);
};
