import { FunctionComponent, ReactElement } from 'react';
import { Flex, Loader } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import * as Sentry from '@sentry/react';
import { ErrorContainer } from '@stashinvest/shared-ui';
import { PartialErrors } from '@stashinvest/shared-types/errors';

interface ErrorMessageProps {
	error: string;
}

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ error }) => (
	<Text.Label16
		fontWeight="bold"
		align="center"
		color="textNegativePrimary"
		pb="15px"
		data-testid="error-text"
	>
		{error}
	</Text.Label16>
);

export interface LoadingErrorWrapperProps {
	loading: Boolean;
	error?: Error | string | null;
	partialErrorsV2?: PartialErrors;
	cta?: ReactElement;
	footer?: ReactElement;
}

export const LoadingErrorWrapper: FunctionComponent<
	React.PropsWithChildren<LoadingErrorWrapperProps>
> = ({ loading, error, partialErrorsV2, children, cta, footer }) => {
	if (loading) {
		return (
			<Flex
				data-testid="loading"
				flexDirection={{ mobile: 'row' }}
				flexGrow="1"
				justifyContent="center"
				alignItems="center"
				mt="25px"
			>
				<Loader center />
			</Flex>
		);
	}

	if (error) {
		const errorMessage = error instanceof Error ? error.message : error;
		return (
			<ErrorContainer cta={cta} footer={footer}>
				<ErrorMessage error={errorMessage} />
			</ErrorContainer>
		);
	}

	if (partialErrorsV2 && Object.keys(partialErrorsV2).length) {
		return (
			<>
				<ErrorContainer cta={cta}>
					{Object.values(partialErrorsV2)
						.filter((e) => e.type === 'ERROR')
						.map((err) => {
							Sentry.captureException(err.description);

							return <ErrorMessage key={err.message} error={err.message} />;
						})}
				</ErrorContainer>
				{children}
			</>
		);
	}

	return <>{children}</>;
};
