import React, { FC, useState } from 'react';
import { DatePicker, TextInput, Box } from '@stashinvest/ui';
import { date as ydate, object as yobj } from 'yup';
import { DateTime } from 'luxon';
import { dateObjectToFormat, isValidDateTime } from '../../../../utils';
import { QuestionFieldAndFormProps } from '../QuestionTreeItem.types';
import { QUESTION_IDENTIFIER, QuestionForm } from '../QuestionForm';

const DateSchema = yobj().shape({
	[QUESTION_IDENTIFIER]: ydate().required('Required').typeError('Invalid Date'),
});

export const QuestionWithDatePicker: FC<
	React.PropsWithChildren<QuestionFieldAndFormProps>
> = ({ label, disabled = false, ...otherProps }) => {
	const [isDatePickerVisible, toggleDatePicker] = useState(false);
	const [selectedDayForCalendar, setSelectedDayForCalendar] = useState(
		DateTime.utc().toJSDate()
	);

	return (
		<QuestionForm disabled={disabled} {...otherProps} schema={DateSchema}>
			{({ values, errors, handleChange, setFieldValue }) => {
				// Convert ISO datetime string to display format
				const valueIn = values[QUESTION_IDENTIFIER];
				const value =
					typeof valueIn === 'string' && valueIn !== '' && isValidDateTime(valueIn)
						? dateObjectToFormat(new Date(valueIn))
						: undefined;

				return (
					<>
						<TextInput
							disabled={disabled}
							name={QUESTION_IDENTIFIER}
							label={label}
							placeholder="MM/DD/YYYY"
							maxWidth="200px"
							mx="auto"
							mb="none"
							mask="date"
							onChange={handleChange}
							onFocus={() => toggleDatePicker(true)}
							value={value}
							error={errors[QUESTION_IDENTIFIER]}
						/>
						{isDatePickerVisible && (
							<Box mb="s40">
								<DatePicker
									selectedDays={[selectedDayForCalendar]}
									onDayClick={(day: Date) => {
										setSelectedDayForCalendar(day);
										setFieldValue(QUESTION_IDENTIFIER, dateObjectToFormat(day));
									}}
								/>
							</Box>
						)}
					</>
				);
			}}
		</QuestionForm>
	);
};
