import React, { FC } from 'react';
import { Flex, Box, Tag } from '@stashinvest/ui';

import { TooltipVisibilityProvider, TooltipComponent } from '../Tooltip';
import { TooltipWithTagProps } from './TooltipWithTag.types';

export const TooltipWithTag: FC<React.PropsWithChildren<TooltipWithTagProps>> = ({
	variant = 'warning',
	inverted = true,
	children,
	...otherProps
}) => (
	<TooltipVisibilityProvider>
		{({ visible, toggleVisibility }) => (
			<Tag
				variant={variant}
				onMouseEnter={() => toggleVisibility(true)}
				onMouseLeave={() => toggleVisibility(false)}
				onFocus={() => toggleVisibility(true)}
				onBlur={() => toggleVisibility(false)}
			>
				<Flex alignItems="center">
					{children}
					<Box position="relative">
						<TooltipComponent
							{...otherProps}
							visible={visible}
							isTag
							inverted={inverted}
						/>
					</Box>
				</Flex>
			</Tag>
		)}
	</TooltipVisibilityProvider>
);
