import { useSnackbarContext } from '@stashinvest/ui';
import { useCallback, useState } from 'react';

export const defaultDuration = 3_000; // 3 seconds

export interface ToastParams {
	title?: string;
	message?: string;
	/**
	 * If true, modal will close after the default length of time.
	 * If other lengths of time are needed, we can add a `duration` property.
	 */
	autoClose?: boolean;
}

export interface useToastReturn {
	setToast: (params: ToastParams) => () => void;
	clearToast: () => void;
}

export const useToast = () => {
	// If the value passed to `useState` is a function, React assumes it's an initializer
	// So if we want to actually pass a function, we have to pass a function returning a function.
	const [clear, setClear] = useState<() => void>(() => () => {});
	// If/when we stop using snackbar, remove the `<SnackboxProvider>` from `index.tsx`
	const { setSnackbar } = useSnackbarContext();

	const setToast = useCallback(
		({ title, message, autoClose = true }: ToastParams) => {
			const clearSnack = setSnackbar({
				title,
				body: message,
				backgroundColor: 'bgElevated',
				// Couldn't find a color token that was light enough,
				// for some reason `textPrimary` was rendering as very dark.
				textColor: 'white',
				duration: autoClose ? defaultDuration : undefined,
			});

			setClear(() => clearSnack);

			return clearSnack;
		},
		[setSnackbar]
	);

	const clearToast = useCallback(() => clear(), [clear]);

	return {
		setToast,
		clearToast,
	};
};
