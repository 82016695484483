import { isErrorResponse } from '@stashinvest/shared-types/errors';

const defaultRequestErrorMessage = 'Something went wrong, please try again later';

export const getErrorMessageFromErrorResponse = (
	response: unknown,
	fallbackMessage = defaultRequestErrorMessage
) => {
	let errorMessage = fallbackMessage;
	if (isErrorResponse(response)) {
		errorMessage = response.error.message;
		// prefer displaying error from details object if its present
		if (response.error.details && 'message' in response.error.details) {
			errorMessage = response.error.details.message;
		}
	}
	return errorMessage;
};
