import { useState } from 'react';
import { useDebounce } from '../../hooks';

export const useRotation = () => {
	const [rotation, setRotation] = useState(0);

	const rotate = () => {
		setRotation(rotation + 90);
	};

	const handleRotate = useDebounce({
		callback: rotate,
		delay: 250,
	});

	return {
		rotation,
		handleRotate,
	};
};
