import React, { FC } from 'react';
import { Flex, Box, Tag } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { textComponentType, formatDateParts } from '../../utils';

import { FormattedDateProps } from './FormattedDate.types';

export const FormattedDate: FC<React.PropsWithChildren<FormattedDateProps>> = ({
	timeBelowDate = false,
	textType,
	...dateOptions
}) => {
	const { fallbackMsg, date, time, timezone } = formatDateParts(dateOptions);

	if (fallbackMsg) {
		return <Tag variant="warning">{fallbackMsg}</Tag>;
	}

	const TextComponent = textComponentType(textType);

	if (timeBelowDate) {
		return (
			<Flex flexDirection={'column'}>
				<Box color={'inherit'} pb={'3px'} whitespace={'nowrap'}>
					<TextComponent>{date}</TextComponent>
				</Box>
				<Box>
					<Text.Label12 color="inherit" whitespace={'nowrap'}>
						{time} {timezone}
					</Text.Label12>
				</Box>
			</Flex>
		);
	}

	return (
		<Box color={'inherit'} whitespace={'nowrap'}>
			<TextComponent>
				{date} {time} {timezone}
			</TextComponent>
		</Box>
	);
};
