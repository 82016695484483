import { Box } from '@stashinvest/ui';

import { ActiveAdminLink } from 'src/components/ActiveAdminLink';
import {
	TableFooter,
	CardWithPropTable,
	RowProps,
	getDateForTimezone,
	formatDateParts,
	TrackedLink,
} from '@stashinvest/shared-ui';
import {
	SubscriptionState,
	SubscriptionStateType,
	UserSubscription as IUserSubscription, // aliased to prevent clashing names between type and component
} from '@stashinvest/shared-types/schemas/subscriptions';
import { StashworksUserStatusResponseBody } from '@stashinvest/shared-types';
import {
	StringDataField,
	FormattedDateDataField,
} from '../../../../components/OptionalDataField';
import { useConfig } from '../../../../hooks/useConfig';

export type UserSubscriptionProps = {
	userSubscription: IUserSubscription;
	userId: string;
	trackingPage: string;
	stashworks: StashworksUserStatusResponseBody | undefined;
};

export const UserSubscription = ({
	userSubscription,
	userId,
	trackingPage,
	stashworks,
}: UserSubscriptionProps) => {
	const config = useConfig();
	const defaultSubscriptionLink = `admin/tier_subscription_management?user_id=${userId}`;
	const contentSubscriptionLink = `admin/users/${userId}/subscriptions_management`;
	const subscriptionLink = userSubscription.isV2Subscription
		? contentSubscriptionLink
		: defaultSubscriptionLink;

	const isClosingSubscription =
		userSubscription.subscriptionState === SubscriptionState.Enum.CLOSING;
	let formattedDate = '';
	if (isClosingSubscription && userSubscription.nextChargeOn) {
		const timestamp = userSubscription.nextChargeOn.toISOString();
		formattedDate = getDateForTimezone({ timestamp })!.toFormat('LLL d, yyyy ZZZZ');
	}

	let rows: RowProps[] = [
		{
			title: 'Status',
			value: (
				<StringDataField
					partialObject={userSubscription}
					propertyName={nameof<IUserSubscription>('subscriptionState')}
					postProcessFn={subscriptionStateToString}
				/>
			),
		},
		{
			title: 'Plan',
			value: (
				<StringDataField
					partialObject={userSubscription}
					propertyName={nameof<IUserSubscription>('tierName')}
					nullValue={'Undefined'}
				/>
			),
		},
		{
			title: 'Billing frequency',
			value: (
				<StringDataField
					partialObject={userSubscription}
					propertyName={nameof<IUserSubscription>('pricePeriod')}
				/>
			),
		},
		{
			title: 'Next payment date',
			value: isClosingSubscription ? (
				`No fee due on ${formattedDate}`
			) : (
				<FormattedDateDataField
					partialObject={userSubscription}
					propertyName={nameof<IUserSubscription>('nextChargeOn')}
					showTimezone
				/>
			),
		},
		{
			title: 'StashWorks',
			value: (
				<StringDataField
					partialObject={stashworks}
					propertyName="status"
					postProcessFn={stashworksStateToString}
				/>
			),
		},
		{
			title: 'Employer',
			value: (
				<StringDataField
					partialObject={stashworks}
					propertyName="employer"
					postProcessFn={stashworksEmployerToString}
				/>
			),
		},
	];

	return (
		<Box width={{ mobile: '100%' }}>
			<CardWithPropTable
				card={{ title: 'Subscriptions' }}
				tracking={{ page: trackingPage, component: 'user-subscription-card' }}
				table={{ rows: rows }}
			>
				<TableFooter>
					<div style={{ display: 'flex', gap: '35px' }}>
						<ActiveAdminLink
							path={subscriptionLink}
							tracking={{
								page: trackingPage,
								component: 'user-subscription-card',
							}}
						>
							View Subscription Details
						</ActiveAdminLink>
						{userSubscription.isV2Subscription && (
							<TrackedLink
								href={`${config.retoolPortalUrl}/app/preferred-payment#userId=${userId}`}
								tracking={{
									label: 'retool',
									page: trackingPage,
									component: 'user-subscription-card',
								}}
							>
								View Preferred Payment Method
							</TrackedLink>
						)}
					</div>
				</TableFooter>
			</CardWithPropTable>
		</Box>
	);
};

function subscriptionStateToString(str: SubscriptionStateType): string {
	switch (str) {
		case SubscriptionState.Enum.ACTIVE:
			return 'Active';
		case SubscriptionState.Enum.CLOSING:
			return 'Closing';
		case SubscriptionState.Enum.CLOSED:
			return 'Closed';
		case SubscriptionState.Enum.UNKNOWN:
			return 'Unknown';
		default:
			return str;
	}
}

function stashworksStatusToString(status: string) {
	switch (status) {
		case 'ACTIVE':
			return 'Active';
		case 'INACTIVE':
			return 'Inactive';
		case 'OFFBOARDING':
			return 'Offboarding';
		case 'NOT_FOUND':
			return 'No previous enrollment';
		default:
			return status;
	}
}

function stashworksStateToString(
	status: string,
	{ statusUpdatedOn }: StashworksUserStatusResponseBody
) {
	const formatted = stashworksStatusToString(status);
	const since = statusUpdatedOn ? formatDateParts({ timestamp: statusUpdatedOn }) : null;
	return since ? `${formatted} since ${since.date}` : formatted;
}

function stashworksEmployerToString(
	employer: string,
	{ status }: StashworksUserStatusResponseBody
) {
	switch (status) {
		case 'NOT_FOUND':
			return '--';
		default:
			return employer;
	}
}

function nameof<T>(name: keyof T) {
	return name;
}
