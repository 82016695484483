import { FC } from 'react';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import {
	InvestorApplication,
	InvestorApplicationInputSchema,
} from '@stashinvest/shared-types';

import { useUpdateInvestorApplication } from 'src/hooks/useInvestorApplication';
import { useToast } from 'src/hooks/useToast';
import { ModalForm } from 'src/components/ModalForm';
import {
	getInitialValuesFromQuestionGroup,
	filterQuestionsByGroup,
	getFormRendererFromQuestionGroup,
	FieldHelpTextMap,
} from '../utils/formUtils';

type UserPageParams = { userId: string };

const editInvestmentInfoFormSchema = InvestorApplicationInputSchema.pick({
	risk_tolerance: true,
	time_horizon: true,
	investing_experience: true,
});
const captionTextMap: FieldHelpTextMap = {
	risk_tolerance: 'Risk tolerance determines the investment objective of a customer.',
	time_horizon: 'Time horizon determines the liquidity needs of a customer.',
};
type EditInvestmentInfoPayload = z.infer<typeof editInvestmentInfoFormSchema>;
interface EditInvestmentInfoFormProps {
	investorApplication: InvestorApplication['investorApplication'];
}

export const EditInvestmentInfoForm: FC<EditInvestmentInfoFormProps> = ({
	investorApplication,
}) => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	const { setToast } = useToast();
	const onSuccess = () => {
		setToast({ message: 'Investment Info updated' });
	};
	const updateInvestorApplication = useUpdateInvestorApplication(userId, { onSuccess });
	const investmentInfo = filterQuestionsByGroup(investorApplication, 'investment');
	if (!investmentInfo) {
		return null;
	}
	const renderFormFields = getFormRendererFromQuestionGroup(investmentInfo, {
		captionTextMap,
	});
	const { risk_tolerance, time_horizon, investing_experience } =
		getInitialValuesFromQuestionGroup(investmentInfo);

	const handleSubmit = async (values: Partial<EditInvestmentInfoPayload>) => {
		// TODO: after the toast component is migrated from bank-portal to shared-ui display it on succesful submission using onSuccess option
		await updateInvestorApplication(values);
	};
	return (
		<ModalForm
			title="Edit Investment Info"
			initialValues={{ risk_tolerance, time_horizon, investing_experience }}
			validationSchema={editInvestmentInfoFormSchema}
			renderContent={renderFormFields}
			handleSubmit={handleSubmit}
		/>
	);
};
