import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const SadFaceIcon: FC<React.PropsWithChildren<IconProps>> = (props) => (
	<IconContainer {...props}>
		<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM7.286 12a1.572 1.572 0 110-3.144 1.572 1.572 0 010 3.144zm7.071 5.5a.786.786 0 01-.786-.786 1.572 1.572 0 00-3.142 0 .786.786 0 01-1.572 0A3.146 3.146 0 0112 13.571a3.146 3.146 0 013.143 3.143.786.786 0 01-.786.786zm2.357-5.5a1.572 1.572 0 11.001-3.144 1.572 1.572 0 010 3.144z"
				fill="currentColor"
			/>
		</svg>
	</IconContainer>
);
