import { useState } from 'react';

import { useArrowKeys, OnKeyPressOperator } from '../../hooks';
import { ContentSliderItems } from './ContentSlider';

export const useActiveItem = (items: ContentSliderItems[]) => {
	const [activeId, setActive] = useState(items[0].id);
	const activeIndex = items.findIndex((item) => item.id === activeId);
	const isPrevDisabled = activeIndex === 0;
	const isNextDisabled = activeIndex === items.length - 1;

	const handlePrev = () => {
		activeIndex > 0 ? setActive(items[activeIndex - 1].id) : setActive(items[0].id);
	};

	const handleNext = () => {
		activeIndex < items.length - 1
			? setActive(items[activeIndex + 1].id)
			: setActive(items[items.length - 1].id);
	};

	useArrowKeys((operator: OnKeyPressOperator) => {
		if (operator === -1) {
			handlePrev();
		}

		if (operator === 1) {
			handleNext();
		}
	});

	return {
		activeId,
		activeIndex,
		isPrevDisabled,
		isNextDisabled,
		handlePrev,
		handleNext,
	};
};
