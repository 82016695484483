import { useState, useEffect, MutableRefObject } from 'react';
import { preloadImage } from '../../utils';

export interface ImageHeightProps {
	containerDimensions: { width: number; height: number };
	titleHeight: number;
	footerHeight: number;
	isImageRotated: boolean;
	image?: string;
	imageRef: MutableRefObject<HTMLImageElement | null>;
}

export const useImageHeight = ({
	containerDimensions,
	titleHeight,
	footerHeight,
	image,
	isImageRotated,
	imageRef,
}: ImageHeightProps) => {
	const [loadedImg, setLoadedImg] = useState<HTMLImageElement | null>(null);
	const { width: containerWidth, height: containerHeight } = containerDimensions;
	const hasHeight = containerHeight > 0 && titleHeight > 0 && footerHeight > 0;
	let newHeight =
		loadedImg && hasHeight ? containerHeight - (titleHeight + footerHeight) : undefined;
	let newWidth: number | undefined;

	if (isImageRotated && newHeight) {
		// if image is rotated and clipping, reduce image height to container width
		if (newHeight > containerWidth) {
			newHeight = containerWidth;
		} else if (
			// if image is rotated and overflowing, reduce image width to container height
			imageRef?.current?.clientWidth &&
			imageRef.current.clientWidth > newHeight
		) {
			newWidth = newHeight;
			newHeight = undefined;
		}
	}

	useEffect(() => {
		if (!loadedImg && hasHeight && image) {
			(async () => {
				try {
					const img = await preloadImage(image);
					setLoadedImg(img);
				} catch {
					setLoadedImg(null);
					console.error('Error loading image'); // eslint-disable-line
				}
			})();
		}
	}, [containerHeight, titleHeight, footerHeight]); // eslint-disable-line

	return {
		loadedImg,
		newHeight,
		newWidth,
	};
};
