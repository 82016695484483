import React, { useState, FunctionComponent } from 'react';
import { ReferralContextProps, ReferralProps } from './ReferralProvider.types';

export const ReferralContext = React.createContext<ReferralContextProps>({
	zendeskTicket: null,
	zendeskUser: null,
	setReferral: () => {},
});

export const ReferralProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const [state, setState] = useState<ReferralProps>({
		zendeskTicket: null,
		zendeskUser: null,
	});

	return (
		<ReferralContext.Provider
			value={{
				zendeskTicket: state.zendeskTicket,
				zendeskUser: state.zendeskUser,
				setReferral: setState,
			}}
		>
			{children}
		</ReferralContext.Provider>
	);
};
