import styled from 'styled-components';
import { UnstyledButton } from '../UnstyledButton';

export const SelectorLayout = styled.div`
	position: relative;
	z-index: 3;
`;

export const SelectorButton = styled(UnstyledButton)`
	padding: 10px;
	border-radius: 5px;
	&:hover {
		background: ${({ theme }) => theme.colors.bgHoverSecondary};
	}
`;

export const DatePickerPositioning = styled.div`
	position: absolute;
	top: 95px;
	right: 0px;
`;

export const SelectorOverlay = styled(UnstyledButton)`
	cursor: pointer;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.colors.bgPrimary};
	opacity: 0.3;
	z-index: 2;
`;
