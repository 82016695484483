import { FC } from 'react';
import { Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import {
	UnstyledButton,
	TrackedLink,
	TrackedLinkVariants,
	TrackedLinkColors,
	LeftArrowIcon,
} from '@stashinvest/shared-ui';

import { HOME_ROUTE, NO_RESULTS_ROUTE } from 'src/constants/routes';

export interface BackButtonProps {
	history: string[];
	tracking: {
		page: string;
		component: string;
		label: string;
	};
	push(path: string, state?: unknown): void;
	goBack(): void;
}

export const BackButton: FC<
	React.PropsWithChildren<React.PropsWithChildren<BackButtonProps>>
> = ({ history, tracking, goBack, push, children }) => {
	const lastPage = history[history.length - 2];
	const backFn = () =>
		history.length >= 2 && lastPage.includes(NO_RESULTS_ROUTE)
			? push(HOME_ROUTE)
			: goBack();

	return (
		<Text.Label16 as="span" align="center">
			<UnstyledButton onClick={backFn} as="span">
				<TrackedLink
					color={TrackedLinkColors.textPrimary}
					type={TrackedLinkVariants.SECONDARY_BUTTON}
					tracking={tracking}
				>
					<Flex alignItems="center" mr="5px">
						<LeftArrowIcon color="textPrimary" />
					</Flex>
					{children}
				</TrackedLink>
			</UnstyledButton>
		</Text.Label16>
	);
};
