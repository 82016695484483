import { useQuery } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';

import { InvestAccountsResponseBodyV2 } from '@stashinvest/shared-types';
import { config } from 'src/config/config';

export const getInvestAccountsV2 = async (
	userId: string,
	accessToken: string
): Promise<InvestAccountsResponseBodyV2> => {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/${userId}/investAccountsV2`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		}
	);

	const responseBody = await response.json();

	if (!response.ok) {
		switch (response.status) {
			case 401:
				throw new Error('Unauthorized');
			default:
				// TODO: export the gateway error type from shared-types and passthrough the actual error message
				throw new Error('Something went wrong while searching for users');
		}
	}

	return responseBody;
};

export const useInvestAccountsV2 = (userId: string) => {
	const { oktaAuth } = useOktaAuth();

	return useQuery<InvestAccountsResponseBodyV2>(['userInvestAccountsV2', userId], () =>
		getInvestAccountsV2(userId, oktaAuth.getAccessToken())
	);
};
