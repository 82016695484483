import React, { FC, ReactNode } from 'react';
import { PrimaryButton } from '@stashinvest/ui';
import { VisibilityProvider } from '../../providers/VisibilityProvider';
import { VisibilityOpener } from '../VisibilityOpener';

export type OpenModalFn = VoidFunction;
export interface ModalOpenerProps {
	children: ReactNode;
	renderOpenButton?: (openModal: OpenModalFn) => JSX.Element;
	label?: ReactNode;
	dataTestId?: string;
}

export const ModalOpener: FC<ModalOpenerProps> = ({
	label,
	renderOpenButton,
	children,
}) => {
	return (
		<VisibilityProvider>
			<VisibilityOpener>
				{({ open, setOpen }) => {
					const openModal = () => setOpen(true);
					return (
						<>
							{renderOpenButton ? (
								renderOpenButton(openModal)
							) : (
								<PrimaryButton onClick={openModal} size="medium">
									{label}
								</PrimaryButton>
							)}
							{open ? children : null}
						</>
					);
				}}
			</VisibilityOpener>
		</VisibilityProvider>
	);
};
