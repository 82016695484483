import React, { FC } from 'react';
import { TertiaryIconButton } from '@stashinvest/ui/dist/es/2';
import { PencilIcon } from '@stashinvest/shared-ui';

export interface EditButtonProps {
	onClick: () => void;
	label?: string;
}

export const EditButton: FC<EditButtonProps> = ({ onClick, label }) => {
	return (
		<TertiaryIconButton size="small" label={label} onClick={onClick} m={0} py={0}>
			<PencilIcon size="24" />
		</TertiaryIconButton>
	);
};
