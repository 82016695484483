import { FC } from 'react';
import { Flex, Box } from '@stashinvest/ui/dist/es/2';
import { TrackedLinkColors, LinkVariants, LinkVariant } from '@stashinvest/shared-ui';

export interface SidebarProps {
	/**
	 * Sidebar links
	 */
	links: { title: string; url: string }[];
}

export const Sidebar: FC<SidebarProps> = ({ links }) => {
	const currentPath = window.location.pathname;

	return (
		<Box height="100%" position="sticky" top="80px">
			<Flex flexDirection="column">
				{links.map(({ title, url }) => (
					<Flex
						flexDirection="column"
						alignItems="left"
						px="10px"
						mr="40px"
						mt="xxs"
						data-testid={`sidebar-link-${title.toLowerCase().replace(' ', '-')}`}
						{...(currentPath.endsWith(url)
							? { borderLeft: '2px solid', borderColor: 'iconSelected' }
							: {})}
					>
						<LinkVariant
							key={url}
							color={
								currentPath.endsWith(url)
									? TrackedLinkColors.textActionPrimary
									: TrackedLinkColors.textPrimary
							}
							path={url}
							size="large"
							py="2px"
							variant={LinkVariants.EXTERNAL}
							tracking={{
								page: 'all',
								component: 'sidebar',
								label: title.toLowerCase(),
							}}
							hoverStyles={{ color: TrackedLinkColors.textPlaceholder }}
						>
							{title}
						</LinkVariant>
					</Flex>
				))}
			</Flex>
		</Box>
	);
};
