import styled from 'styled-components';
import { Box } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { CopyIcon } from '../../icons';
import { UnstyledButton } from '../UnstyledButton';

export const ErrorNotifier = styled(Box)`
	z-index: 1;
	position: absolute;
	top: -25px;
	left: 5px;
	width: 150px;
	background: ${({ theme }) => theme.colors.bgPrimary};
	border-radius: 5px;
`;

export const CopyBoxContainer = styled(Box)`
	position: relative;
	border-radius: 3px 0 0 3px;
`;

interface CopyButtonProps {
	bg: string;
}

export const CopyButton = styled(UnstyledButton)<CopyButtonProps>`
	position: relative;
	height: 28px;
	width: 30px;
	border-radius: 0 3px 3px 0;
	transition: box-shadow 0.09s linear;
	background: ${({ bg }) => bg};

	&:hover {
		box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.1);
	}

	&:active {
		box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.4);
	}
`;

export const CopyBoxIcon = styled(CopyIcon)`
	margin: 4px 0 0 0;
`;

interface CopyConfirmationProps {
	visible: boolean;
}

export const CopyConfirmation = styled(Text.Label12).attrs(() => ({
	p: '6px',
}))<CopyConfirmationProps>`
	transition: opacity 0.5s ease-out;
	opacity: ${({ visible }) => (visible ? 1 : 0)};
`;
