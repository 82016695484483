import { FC, ReactElement, useState } from 'react';

interface TooltipVisibilityStateProps {
	visible: boolean;
	toggleVisibility(newVisibility: boolean): void;
}

interface TooltipVisibilityProps {
	children(props: TooltipVisibilityStateProps): ReactElement;
}

export const TooltipVisibilityProvider: FC<TooltipVisibilityProps> = ({ children }) => {
	const [visible, setVisibility] = useState(false);
	const toggleVisibility: TooltipVisibilityStateProps['toggleVisibility'] = (
		newVisibility
	) => setVisibility(newVisibility);

	return children ? children({ visible, toggleVisibility }) : null;
};
