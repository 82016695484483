import styled from 'styled-components';
import { TextInput } from '@stashinvest/ui';
import { UnstyledButton } from '../../../UnstyledButton';

export const SizeButtons = styled(UnstyledButton)`
	padding: 10px 20px 20px;
	color: ${({ disabled, theme }) =>
		disabled ? theme.colors.textDisabled : theme.colors.textPrimary};

	&:hover {
		color: ${({ disabled, theme }) =>
			disabled ? theme.colors.textDisabled : theme.colors.bgActionPrimary};
	}
`;

export const NumberTextInput = styled(TextInput)`
	text-align: center;
	padding: 7px 0;
	-moz-appearance: textfield !important;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		appearance: none;
		margin: 0;
	}
`;
