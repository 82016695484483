import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';

import { InvestorApplication, InvestorApplicationInput } from '@stashinvest/shared-types';
import { config } from 'src/config/config';
import { investorApplicationQueryKeys } from './queryKeys';

export interface UseUpdatenvestorApplicationOptions {
	onSuccess?: () => void;
	onError?: (error?: string) => void;
}

// TODO: copy fetchHandler from bank portal and replace this with it
const patchInvestorApplication = async (
	userId: string,
	payload: Partial<InvestorApplicationInput>,
	accessToken: string
): Promise<InvestorApplication> => {
	const response = await fetch(
		`${config.expGatewayUrl}/api/v1/users/${userId}/investorApplication`,
		{
			body: JSON.stringify(payload) as BodyInit,
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		}
	);
	const responseBody = await response.json();
	if (!response.ok) {
		return Promise.reject(responseBody);
	}
	return responseBody;
};

export const useUpdateInvestorApplication = (
	userId: string,
	options: UseUpdatenvestorApplicationOptions = {}
) => {
	const { oktaAuth } = useOktaAuth();
	const queryClient = useQueryClient();

	return useMutation<InvestorApplication, unknown, InvestorApplicationInput>(
		(payload) => patchInvestorApplication(userId, payload, oktaAuth.getAccessToken()),
		{
			onSuccess: (response) => {
				queryClient.setQueryData(investorApplicationQueryKeys.detail(userId), response);
				options?.onSuccess?.();
			},
			onError: () => options?.onError?.(),
		}
	).mutateAsync;
};
