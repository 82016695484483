export const preloadImage = (src: string) =>
	new Promise<HTMLImageElement>((resolve, reject) => {
		const image = new Image();
		image.onload = () => {
			resolve(image);
		};
		image.onerror = (e) => {
			console.error(e); // eslint-disable-line
			reject(e);
		};
		image.src = src;
	});
