import React, { FC } from 'react';
import { Box } from '@stashinvest/ui';
import { CommentBodyType } from '@stashinvest/shared-types/comments';

import { Comment } from './components';

export type IsAuto = boolean;
export type AuthorEmail = string;

export interface CommentBodySectionProps {
	type: CommentBodyType;
	content: string;
	link?: string;
}

export interface CommentProps {
	id: number;
	isAuto: IsAuto;
	authorEmail?: AuthorEmail;
	body: CommentBodySectionProps[];
	createdAt?: string;
}

export interface CommentsProps {
	comments: CommentProps[];
}

export const Comments: FC<CommentsProps> = ({ comments }) => (
	<Box pt="15px">
		{comments.map(({ id, authorEmail, createdAt, ...otherProps }: CommentProps) => (
			<Comment
				key={id}
				id={id}
				authorEmail={authorEmail}
				createdAt={createdAt}
				{...otherProps}
			/>
		))}
	</Box>
);
