import { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Box } from '@stashinvest/ui';
import { PageContainer } from 'src/components/PageContainer';
import { LoadingErrorWrapper } from 'src/components/LoadingErrorWrapper';
import { BankingPartner } from 'src/types/global';
import { EnvironmentContext, StashApps } from '@stashinvest/shared-ui';
import { BankAccountsV2 } from 'src/components/BankAccounts';
import { datadogLogs } from '@datadog/browser-logs';
import { useUserProfileV2 } from 'src/hooks/useUserProfileV2';
import { useBankAccounts } from 'src/hooks/useBankAccounts';

type UserPageParams = { userId: string };

export const Bank: FC = () => {
	const { userId } = useParams() as UserPageParams;
	const { environment, baseUrlConfig } = useContext(EnvironmentContext);

	const {
		isLoading: userProfileLoading,
		data: userProfile,
		error: userProfileError,
	} = useUserProfileV2(userId);

	const {
		data: bankAccountsData,
		isLoading: bankAccountsLoading,
		error: bankAccountsError,
	} = useBankAccounts(userId);

	const fullName =
		userProfile?.profile?.firstName && userProfile.profile?.lastName
			? `${userProfile.profile.firstName} ${userProfile.profile.lastName}`
			: undefined;

	useEffect(() => {
		if (!bankAccountsLoading && !userProfileLoading) {
			const errorMessage = userProfileError || bankAccountsError?.message;
			if (errorMessage) {
				datadogLogs.logger.error('Bank accounts failed to load error', {
					message: errorMessage,
				});
			} else {
				datadogLogs.logger.info('Bank accounts load successful');
			}
		}
	}, [
		userProfileLoading,
		bankAccountsLoading,
		userProfileError,
		bankAccountsError?.message,
	]);

	const coreAccount = bankAccountsData?.accounts.find(
		(account) => account.partner === BankingPartner.CORE.toLowerCase()
	);

	// If the user has core accounts redirect to bank portal
	useEffect(() => {
		if (coreAccount) {
			window.location.assign(
				`${baseUrlConfig[StashApps.BANK_PORTAL][environment]}/users/${userId}/accounts/${
					coreAccount.id
				}`
			);
		}
	}, [baseUrlConfig, coreAccount, environment, userId]);

	return userProfile?.profile ? (
		<PageContainer name={fullName} showSearchBox>
			{bankAccountsData ? (
				<Box pt="30px">
					<Flex
						flexDirection={{ mobile: 'column', desktop: 'row' }}
						justifyContent="space-around"
					>
						<LoadingErrorWrapper loading={bankAccountsLoading} error={bankAccountsError}>
							<BankAccountsV2
								bankAccounts={bankAccountsData?.accounts}
								userId={userId}
								trackingPage="bank"
							/>
						</LoadingErrorWrapper>
					</Flex>
				</Box>
			) : null}
		</PageContainer>
	) : null;
};
