import React, { FC, Fragment, ReactNode } from 'react';
import { Box, Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { SearchIconButton } from '../SearchIconButton';
import { LinkVariant } from '../LinkVariant';
import { TrackedLinkVariants } from '../TrackedLink';
import { TrackedButton } from '../TrackedButton';
import { TooltipEmoji } from '../TooltipEmoji';
import { PropTableVal } from './PropTableVal';
import { PropTableProps, PropTableVariant, RowProps } from './PropTable.types';
import { RowDivider } from './PropTable.styles';

export const Row: FC<React.PropsWithChildren<RowProps>> = ({
	title,
	value,
	variant,
	tooltipBody,
	tagVariant,
	link,
	tracking = {
		page: 'unknown',
		component: 'prop-table',
	},
	search,
	emoji,
	action,
	testId,
	overflow,
	labelWidth,
	tableVariant,
}) => (
	<Box width="100%" style={{ position: 'relative' }}>
		<Flex
			flexDirection="row"
			alignItems="center"
			minHeight={tableVariant === PropTableVariant.COMPACT ? 28 : 40}
		>
			<span style={{ minWidth: labelWidth ? labelWidth + 'px' : 'auto' }}>
				<Text.Label14 color="textPrimary" margin-top="1px">
					{title}:
				</Text.Label14>
			</span>
			<Text.Label14
				as="div"
				pl="10px"
				color="textPrimary"
				data-testid={testId}
				style={{ overflow }}
			>
				<PropTableVal
					value={value}
					variant={variant}
					tooltipBody={tooltipBody}
					tagVariant={tagVariant}
					link={link}
				/>
				{search ? (
					<SearchIconButton
						to={search.link}
						tracking={{ ...tracking, label: 'search-icon-button' }}
					>
						{search.label}
					</SearchIconButton>
				) : null}
				{!action && emoji ? (
					<TooltipEmoji
						description={emoji.label}
						emojiLabel={emoji.accessibleLabel}
						style={{
							position: 'absolute',
							right: '5px',
							paddingLeft: '5px',
							top: '0px',
						}}
					>
						{emoji.icon}
					</TooltipEmoji>
				) : null}
				{action ? (
					<Box pl="8px" display="inline">
						{action.path && !action.onClick ? (
							<LinkVariant
								path={action.path}
								variant={action.variant}
								size="small"
								type={TrackedLinkVariants.LINK}
								tracking={{ ...tracking, label: 'action' }}
							>
								{action.label}
							</LinkVariant>
						) : null}
						{action.onClick && !action.path ? (
							<TrackedButton
								onClick={action.onClick!} // eslint-disable-line
								type={action.type}
								tracking={{ ...tracking, label: 'action' }}
							>
								{action.label}
							</TrackedButton>
						) : null}
					</Box>
				) : null}
			</Text.Label14>
		</Flex>
	</Box>
);

export const PropTable: FC<PropTableProps> = ({
	rows,
	tracking,
	children,
	labelWidth,
	showDividers = false,
	variant = PropTableVariant.NORMAL,
}) => (
	<Box py="5px" width="100%">
		{rows.map((row, index) => (
			<Fragment key={`proptable-row-${index}`}>
				<Row
					key={`${row.title}-${index}`}
					{...row}
					tracking={tracking}
					labelWidth={labelWidth}
					tableVariant={variant}
					testId={row.testId ?? buildRowTestId(row.title)}
				/>
				{showDividers && <RowDivider />}
			</Fragment>
		))}
		{children}
	</Box>
);

/**
 ** Builds test id from row title
 ** e.g., Row with title "First Name" has test id "prop-table-first-name-row"
 */
const buildRowTestId = (rowTitle: string | ReactNode): string | undefined => {
	if (typeof rowTitle === 'string') {
		return `prop-table-${rowTitle.split(' ').join('-').toLowerCase()}-row`;
	}
};
