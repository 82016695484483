import React, {
	FC,
	useCallback,
	useEffect,
	useRef,
	useState,
	useLayoutEffect,
} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Loader, PrimaryButton } from '@stashinvest/ui';
import { Text, Flex, CloseIcon } from '@stashinvest/ui/dist/es/2';

import { useEscape } from '../../hooks/useEscape';
import { VisibilityContext } from '../../providers/VisibilityProvider';
import { UnstyledButton } from '../UnstyledButton';
import { PermissionError } from '../../errors';
import {
	PDFDocumentViewerProps,
	PDFDocumentViewerContentProps,
} from './PDFDocumentViewer.types';

const defaultErrorText =
	'Something went wrong while retrieving the document, please try again later.';

export const PDFDocumentViewer: FC<React.PropsWithChildren<PDFDocumentViewerProps>> = ({
	onClose,
	getPdfUrl,
	title,
}) => {
	const el = useRef(document.createElement('div'));

	useLayoutEffect(() => {
		let modalRoot = document.getElementById('modal-root');
		if (!modalRoot) {
			modalRoot = document.createElement('div');
			modalRoot.setAttribute('id', 'modal-root');
			document.body.appendChild(modalRoot);
		}

		modalRoot?.appendChild(el.current);

		return () => {
			modalRoot?.removeChild(el.current); // eslint-disable-line
		};
	}, []);

	return ReactDOM.createPortal(
		<VisibilityContext.Consumer>
			{({ open, setOpen }) => {
				if (!open) {
					return null;
				}

				return (
					<PDFDocumentViewerContent
						getPdfUrl={getPdfUrl}
						onClose={onClose}
						title={title}
						open={open}
						setOpen={setOpen}
					/>
				);
			}}
		</VisibilityContext.Consumer>,
		el.current
	);
};

const PDFDocumentViewerContent: FC<
	React.PropsWithChildren<PDFDocumentViewerContentProps>
> = ({ getPdfUrl, onClose, title, open, setOpen }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(undefined);
	const [pdfUrl, setPdfUrl] = useState('');
	const focusedEl = useRef<HTMLButtonElement>(null);
	const [enableRetry, setEnableRetry] = useState(true);

	const handleClose = useCallback(() => {
		onClose && onClose();
		setOpen(false);
	}, []); // eslint-disable-line

	// calls `handleClose` when 'Escape' key is pressed
	useEscape(handleClose);

	const fetchPdf = async () => {
		!loading && setLoading(true);
		error && setError(undefined);

		try {
			const pdfUrl = await getPdfUrl();
			setPdfUrl(pdfUrl);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setLoading(false);
			setError(err.message !== '' ? err.message : defaultErrorText);
			if (err instanceof PermissionError) {
				setEnableRetry(false);
			}
		}
	};

	useEffect(() => {
		fetchPdf();
	}, []); // eslint-disable-line

	useEffect(() => {
		if (open) {
			focusedEl.current?.focus();
		}
	}, [open]);

	return (
		<FullPageContainer>
			<Flex flexDirection="column" width={'100%'} height={'100%'}>
				<ModalHeader>
					<Text.Title20 fontweight="bold" align="center">
						{title}
					</Text.Title20>
					<ModalCloseBtn onClick={handleClose} aria-label="close modal" ref={focusedEl}>
						<CloseIcon size="1.5em" />
					</ModalCloseBtn>
				</ModalHeader>
				<Flex
					width={'1100px'}
					height={'92%'}
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					margin={'0 auto'}
					bg="bgSecondary"
				>
					<object
						onLoad={() => pdfUrl && setLoading(false)}
						data={pdfUrl}
						style={
							loading || error
								? { width: 0, height: 0 }
								: { width: '100%', height: '100%' }
						}
						aria-label="document viewer"
						data-testid="pdf-viewer"
					/>
					{loading && <Loader></Loader>}
					{error && (
						<>
							<Text.Body18 mb="m">{error}</Text.Body18>
							{enableRetry && (
								<PrimaryButton size="medium" onClick={fetchPdf}>
									Try Again
								</PrimaryButton>
							)}
						</>
					)}
				</Flex>
			</Flex>
		</FullPageContainer>
	);
};

const FullPageContainer = styled.div`
	z-index: 6;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	backdrop-filter: blur(5px);
`;

const ModalHeader = styled.div`
	background: ${({ theme }) => theme.colors.bgPrimary};
	width: 100%;
	padding: 12px 0;
`;

const ModalCloseBtn = styled(UnstyledButton)`
	position: absolute;
	top: 10px;
	right: 15px;
`;
