import { LogsEvent } from '@datadog/browser-logs';

const resourcesToFilter = [/.*\/auth\/.*/, /.*\/oauth2\/default\/v1\/authorize.*/];

function shouldFilterResource(url: string) {
	return resourcesToFilter.some((filter) => {
		return filter.test(url);
	});
}

export const redactDataDogRumEvent = (event: any) => {
	const redacted: any = redactSensitiveParams(event);

	if (event.view) {
		event.view.url = redacted.view?.url || '';
		event.view.referrer = redacted.view?.referrer || '';
	}

	if (event.error) {
		event.error = {
			...event.error,
			message: redacted?.error?.message || '',
			stack: redacted?.error?.stack || '',
		};
	}

	if (event.error?.resource) {
		event.error.resource = {
			...event.error.resource,
			url: redacted?.error?.resource?.url || '',
		};
	}

	if (event.resource) {
		if (shouldFilterResource(event.resource.url)) {
			return [false, {}];
		}
		event.resource.url = redacted.resource?.url || '';
	}

	return [true, event];
};

export const redactDataDogEvent = (event: any) => {
	// discard Sentry logging network errors
	if (event.http?.url?.includes('.sentry.')) {
		return false;
	}

	const redacted: LogsEvent = redactSensitiveParams(event);

	if (event.http) {
		event.http.url = redacted.http?.url || '';
	}

	if (event.view) {
		event.view.url = redacted.view?.url || '';
		event.view.referrer = redacted.view?.referrer || '';
	}

	if (event.message) {
		event.message = redacted.message || '';
	}

	if (event.error) {
		event.error = {
			...event.error,
			stack: redacted.error?.stack || '',
		};
	}

	return event;
};

const matchSensitiveParams = /(email=|phone=|firstName=|lastName=)[^&"' ]*/g;
const matchEmail =
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g; // eslint-disable-line

export const redactSensitiveParams = (params: any) => {
	const serializedObj = JSON.stringify(params);

	const paramRedactedStr = serializedObj.replace(
		matchSensitiveParams,
		'redacted=redacted'
	);

	const emailRedactedStr = paramRedactedStr.replace(matchEmail, 'redacted');

	try {
		return JSON.parse(emailRedactedStr);
	} catch {
		return {};
	}
};
