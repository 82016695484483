import React, { FC } from 'react';
import { IconContainer, IconProps } from './IconContainer';

export const TooltipIcon: FC<React.PropsWithChildren<IconProps>> = ({
	inverted,
	...props
}) => {
	const bgColor = Boolean(inverted) ? '#000' : '#FFF';
	const iconColor = Boolean(inverted) ? '#FFF' : '#000';

	return (
		<IconContainer {...props}>
			<svg width="100%" height="100%" viewBox="0 0 18 18" fill="none">
				<circle cx="9" cy="9" r="7" fill={bgColor} data-testid="tooltip-icon-bg" />
				<path
					data-testid="tooltip-icon"
					d="M9 0a9 9 0 100 18A9 9 0 009 0zm-.66 6.017c-.216.126-.371.288-.484.496a1.569 1.569 0 00-.182.764.892.892 0 01-1.783 0c0-.572.128-1.115.396-1.612a3.025 3.025 0 011.161-1.193l.007-.003a3.4 3.4 0 011.703-.427c.842 0 1.633.201 2.225.744.604.54.86 1.278.86 2.084 0 .475-.086.939-.304 1.354-.17.33-.393.632-.687.865l-.004.003a4.884 4.884 0 01-.716.458c-.238.129-.391.23-.481.303a.403.403 0 00-.097.153c-.03.083-.065.251-.065.545a.892.892 0 11-1.783 0c0-.421.048-.836.188-1.2l.005-.01.005-.012c.136-.325.336-.628.625-.862.209-.17.469-.331.757-.487l.006-.003a3.13 3.13 0 00.455-.288.872.872 0 00.208-.285l.005-.008c.051-.098.1-.26.1-.526 0-.418-.12-.625-.267-.756l-.007-.006-.006-.006c-.15-.137-.44-.277-1.022-.277-.344 0-.608.073-.819.192zm1.619 7.099a.892.892 0 11-1.784 0 .892.892 0 011.784 0z"
					fill={iconColor}
					fillRule="evenodd"
					clipRule="evenodd"
				/>
			</svg>
		</IconContainer>
	);
};
