import { SupportedZones, isValidDateTime, getDateForTimezone } from './date';

export interface FormatDateOptions {
	/**
	 * Valid date in an ISO format:
	 * YYYY-MM-DD | YYYY-MM-DDTHH:MN:SS.MSSZ
	 */
	timestamp?: string;
	/**
	 * Timezone to render the date or datetime in
	 * @default "'America/New_York'"
	 */
	timezone?: SupportedZones;
	/**
	 * Should we render the time
	 * @default false
	 */
	showTime?: boolean;
	/**
	 * Should we render the timezone
	 * @default false
	 */
	showTimezone?: boolean;
	/**
	 * Is this a date only? I.e. does the backend only send `YYYY-MM-DD`
	 * If so this option should be enabled so the date doesn't encounter an offset
	 * @default false
	 */
	pureDate?: boolean;
	/**
	 * What to pass when the timestamp is undefined
	 * @default "'Unknown'"
	 */
	nullValue?: string;
	/**
	 * Should we render short month names
	 * E.g. Mar, Dec, Jan, Jun, Sep
	 * @default false
	 */
	shortMonthNames?: boolean;
}

interface ValidFormattedDateParts {
	date: string;
	time?: string;
	timezone?: string;
	fallbackMsg?: never;
}

interface InvalidFormattedDateParts {
	date?: never;
	time?: never;
	timezone?: never;
	fallbackMsg: string;
}

type FormattedDateParts = ValidFormattedDateParts | InvalidFormattedDateParts;

/**
 * Converts a date string to a FormattedDateParts object, which can be consumed
 * by `FormattedDate` or `formatDateString`
 */
export function formatDateParts({
	timestamp,
	timezone = 'America/New_York',
	showTime = false,
	showTimezone = false,
	pureDate = false,
	nullValue = 'Unknown',
	shortMonthNames = false,
}: FormatDateOptions): FormattedDateParts {
	if (!timestamp) {
		return { fallbackMsg: nullValue };
	}

	if (!isValidDateTime(timestamp)) {
		return { fallbackMsg: 'Invalid Date' };
	}

	const timezoneToFormat = pureDate ? 'Etc/UTC' : timezone;
	const dateObjectToFormat = getDateForTimezone({
		timestamp,
		timezone: timezoneToFormat,
	});

	if (!dateObjectToFormat) {
		return { fallbackMsg: 'Invalid Date' };
	}

	// build date string
	const monthPattern = shortMonthNames ? 'LLL' : 'MMMM';
	const datePattern = `${monthPattern} d, yyyy`;
	const dateString = dateObjectToFormat.toFormat(datePattern);

	// build time string
	const timePattern = 'h:mm a';
	const timeString = showTime ? dateObjectToFormat.toFormat(timePattern) : undefined;

	// build tz string
	const timezonePattern = 'ZZZZ';
	const timezoneString = showTimezone
		? dateObjectToFormat.toFormat(timezonePattern)
		: undefined;

	return { date: dateString, time: timeString, timezone: timezoneString };
}

/**
 * Formats a date string. Non-React version of `FormattedDate`.
 */
export function formatDateString(options: FormatDateOptions): string {
	const { date, time, timezone, fallbackMsg } = formatDateParts(options);

	return (
		fallbackMsg || date + (time ? ` ${time}` : '') + (timezone ? ` ${timezone}` : '')
	);
}
