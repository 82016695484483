import { FC } from 'react';

import { DataField } from '../../DataField';

type StringDataFieldProps = {
	partialObject: any;
	propertyName: string;
	postProcessFn?: (value: any, partialObject?: any) => string;
	nullValue?: string;
};

export const StringDataField: FC<StringDataFieldProps> = ({
	partialObject,
	propertyName,
	postProcessFn,
	nullValue = 'Unknown',
}: StringDataFieldProps) => {
	const rawValue = partialObject[propertyName];
	let value = rawValue as string;
	if (postProcessFn) {
		value = postProcessFn(rawValue, partialObject);
	}

	return (
		<DataField partialObject={partialObject} propertyName={propertyName}>
			{value || nullValue}
		</DataField>
	);
};
