import { useState, useEffect, useRef, useCallback } from 'react';
import { RefPropType } from '../../utils';
import { useDebounce } from '../../hooks';

export const useContainerDimensions = () => {
	const ref = useRef<RefPropType>(null);
	const [containerDimensions, setContainerDimensions] = useState({
		width: 0,
		height: 0,
	});

	const containerRef = useCallback((node: HTMLElement) => {
		//taken from https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
		if (node !== null) {
			setContainerDimensions({
				width: node.clientWidth,
				height: node.clientHeight,
			});
		}
		ref.current = node;
	}, []);

	const setContainerSizes = () => {
		setContainerDimensions({
			width: ref.current?.clientWidth || 0,
			height: ref.current?.clientHeight || 0,
		});
	};

	const handleResize = useDebounce({
		callback: setContainerSizes,
		delay: 250,
	});

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []); // eslint-disable-line

	return {
		containerRef,
		containerDimensions,
	};
};
