import { FC, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Flex } from '@stashinvest/ui';
import { AlertBanner, TrackedLink, TrackedLinkVariants } from '@stashinvest/shared-ui';
import { InvestAccountsResponseBodyV2 } from '@stashinvest/shared-types';
import { BankingPartner } from 'src/types/global';
import { useInvestAccountsV2 } from 'src/hooks/useInvestAccountsV2';
import { useUserSubscription } from 'src/hooks/useUserSubscription';
import { useComments } from 'src/hooks/useComments';
import { useUserFraudTags } from 'src/hooks/useUserFraudTags';
import { useUserLocks } from 'src/hooks/useUserLocks';
import { useBankAccounts } from 'src/hooks/useBankAccounts';

import { PageContainer } from 'src/components/PageContainer';
import { LoadingErrorWrapper } from 'src/components/LoadingErrorWrapper';
import { ActiveAdminLink } from 'src/components/ActiveAdminLink';
import { PageWrapper } from 'src/components/PageWrapper';
import { useUserProfileV2 } from 'src/hooks/useUserProfileV2';
import { useStashworks } from 'src/hooks/useStashworks/useStashworks';

import { datadogLogs } from '@datadog/browser-logs';
import { BankAccountsV2 } from '../../components/BankAccounts';
import { UserDetails } from './components/UserDetails';
import { InvestAccounts } from './components/InvestAccounts';
import { UserSubscription } from './components/UserSubscription';
import { UserLocks } from './components/UserLocks';
import { UserComments as UserCommentsV2 } from './components/UserComments';
import { ExternalBankAccount } from './components/ExternalBankAccount';

const TRACKING_PAGE = 'user';

type UserPageParams = { userId: string };

export const UserPage: FC = () => {
	const { pathname } = useLocation();
	const { userId } = useParams<UserPageParams>() as UserPageParams;

	const {
		isLoading: userProfileLoading,
		data: userProfile,
		error: userProfileError,
	} = useUserProfileV2(userId);
	const { data: investAccountsV2Data } = useInvestAccountsV2(userId);
	const { data: userSubscriptionData } = useUserSubscription(userId);
	const {
		data: userCommentsData,
		loading: userCommentsLoading,
		error: userCommentsError,
		setPage: setCommentsPage,
	} = useComments({
		userId,
		perPage: 5,
	});
	const { data: stashworksData } = useStashworks(userId);

	//TODO: Revisit the partial fail for user profile/bank profile
	//as Winchester does fail the user profile response if it couldn't fetch ContentOnly flag
	const isContentOnly = Boolean(userProfile?.info.contentOnly ?? true);
	const contentBannerTitle = isContentOnly ? 'Content User' : '';

	const {
		data: userFraudTagsResponse,
		isLoading: userFraudTagsLoading,
		error: userFraudError,
	} = useUserFraudTags(userId);
	const fraudTagsError = userFraudError as Error;

	const {
		data: userLocksData,
		isLoading: isUserLocksLoading,
		error: userLocksError,
	} = useUserLocks(userId);
	const locksError = userLocksError as Error;

	const {
		data: bankAccountsData,
		isLoading: bankAccountsLoading,
		error: bankAccountsError,
	} = useBankAccounts(userId);

	const fullName =
		userProfile?.profile?.firstName && userProfile.profile?.lastName
			? `${userProfile.profile.firstName} ${userProfile.profile.lastName}`
			: undefined;

	const legacyId = userProfile?.info?.legacyId
		? userProfile?.info?.legacyId.toString()
		: null;

	const investAccounts = investAccountsV2Data as InvestAccountsResponseBodyV2;

	useEffect(() => {
		if (!userProfileLoading) {
			if (userProfileError) {
				datadogLogs.logger.error('Full page load error', {
					message: userProfileError,
				});
			} else {
				datadogLogs.logger.info('Full page load success');
			}
		}
		// eslint-disable-next-line
	}, [userProfileLoading]);

	return (
		<PageContainer name={fullName} showSearchBox>
			<PageWrapper title="Overview">
				<LoadingErrorWrapper
					loading={userProfileLoading}
					error={userProfileError}
					cta={
						<TrackedLink
							href={pathname}
							type={TrackedLinkVariants.BUTTON}
							nativeLink
							tracking={{
								page: TRACKING_PAGE,
								component: 'page-error',
								label: 'retry-link',
							}}
						>
							Try Again
						</TrackedLink>
					}
					footer={
						<ActiveAdminLink
							path={`admin/users/${userId}`}
							tracking={{
								page: 'error-page',
								component: 'view-in-aa-escape-hatch',
							}}
						>
							View in Active Admin
						</ActiveAdminLink>
					}
				>
					{isContentOnly ? (
						<Flex flexDirection="column" width="100%" mt="30px">
							<AlertBanner title={contentBannerTitle} type="SUCCESS" />
						</Flex>
					) : null}

					<Flex width="100%">
						<LoadingErrorWrapper
							loading={isUserLocksLoading && userFraudTagsLoading}
							error={fraudTagsError || locksError}
							partialErrorsV2={userLocksData?._errors}
						>
							<UserLocks locks={userLocksData} fraudTags={userFraudTagsResponse} />
						</LoadingErrorWrapper>
					</Flex>

					<Flex flexDirection="column">
						<Flex flexDirection="row" justifyContent="space-between" alignItems="stretch">
							{userProfile ? (
								<UserDetails
									title="Account Details"
									userProfile={userProfile}
									userId={userId}
									trackingPage={TRACKING_PAGE}
									showQuickSearch
								/>
							) : null}

							{userSubscriptionData && stashworksData ? (
								<UserSubscription
									userId={userId}
									trackingPage={TRACKING_PAGE}
									userSubscription={userSubscriptionData}
									stashworks={stashworksData}
								/>
							) : null}
						</Flex>

						<Flex flexDirection="row" justifyContent="space-between" flexWrap="wrap">
							<ExternalBankAccount
								externalAccount={
									investAccounts !== undefined ? investAccounts.externalBankLink : null
								}
								userId={legacyId}
							/>
							<LoadingErrorWrapper
								loading={bankAccountsLoading}
								error={bankAccountsError}
							>
								<BankAccountsV2
									bankAccounts={bankAccountsData?.accounts.filter(
										(a) => a.partner !== BankingPartner.GREENDOT.toLowerCase()
									)}
									userId={userId}
									trackingPage={TRACKING_PAGE}
								/>
							</LoadingErrorWrapper>

							<InvestAccounts investAccountsV2={investAccounts} />

							<LoadingErrorWrapper
								loading={bankAccountsLoading}
								error={bankAccountsError}
							>
								<BankAccountsV2
									bankAccounts={bankAccountsData?.accounts.filter(
										(a) => a.partner === BankingPartner.GREENDOT.toLowerCase()
									)}
									userId={userId}
									trackingPage={TRACKING_PAGE}
								/>
							</LoadingErrorWrapper>
						</Flex>
					</Flex>

					<UserCommentsV2
						comments={userCommentsData}
						loading={userCommentsLoading}
						setPage={setCommentsPage}
						error={userCommentsError}
					/>
				</LoadingErrorWrapper>
			</PageWrapper>
		</PageContainer>
	);
};
