import styled from 'styled-components';
import { Flex } from '@stashinvest/ui';

export const RowDivider = styled.hr`
	height: 1px;
	background-color: ${({ theme }) => theme.colors.textTertiary};
	opacity: 0.5;
	border: 0;
	width: 100%;
	margin: 0px;
`;

export const TableFooter = styled(Flex).attrs(() => ({
	justifyContent: 'left',
	pt: '10px',
}))``;
