import { FC } from 'react';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import {
	InvestorApplication,
	InvestorApplicationInputSchema,
} from '@stashinvest/shared-types/investorApplication';

import { formErrorMessage } from 'src/utils/form/validation';
import { useUpdateInvestorApplication } from 'src/hooks/useInvestorApplication';
import { useToast } from 'src/hooks/useToast';
import { ModalForm } from 'src/components/ModalForm';
import {
	getInitialValuesFromQuestionGroup,
	filterQuestionsByGroup,
	getFormRendererFromQuestionGroup,
} from '../utils/formUtils';

const editEmploymentDetailsFormSchema = z
	.object({
		employment_status: InvestorApplicationInputSchema.shape.employment_status,
		employer: InvestorApplicationInputSchema.shape.employer
			.unwrap()
			.trim()
			.transform((value) => (['', null].includes(value) ? undefined : value))
			.optional(),
		position_employed: InvestorApplicationInputSchema.shape.employer
			.unwrap()
			.trim()
			.transform((value) => (['', null].includes(value) ? undefined : value))
			.optional(),
	})
	.superRefine(({ employment_status, employer, position_employed }, ctx) => {
		if (employment_status === 'EMPLOYED') {
			if (!employer) {
				ctx.addIssue({
					message: formErrorMessage.required,
					path: ['employer'],
					code: z.ZodIssueCode.custom,
				});
			}
			if (!position_employed) {
				ctx.addIssue({
					message: formErrorMessage.required,
					path: ['position_employed'],
					code: z.ZodIssueCode.custom,
				});
			}
		}
	});

type EditEmploymentFormPayload = z.infer<typeof editEmploymentDetailsFormSchema>;

type UserPageParams = { userId: string };

interface EditEmploymentDetailsFormProps {
	investorApplication: InvestorApplication['investorApplication'];
}

export const EditEmploymentDetailsForm: FC<EditEmploymentDetailsFormProps> = ({
	investorApplication,
}) => {
	const { userId } = useParams<UserPageParams>() as UserPageParams;
	const { setToast } = useToast();
	const onSuccess = () => {
		setToast({ message: 'Employment Details updated' });
	};
	const updateInvestorApplication = useUpdateInvestorApplication(userId, { onSuccess });
	const employmentQuestions = filterQuestionsByGroup(investorApplication, 'employment');
	if (!employmentQuestions) {
		return null;
	}
	const renderFormFields = getFormRendererFromQuestionGroup(employmentQuestions);
	const initialValues = getInitialValuesFromQuestionGroup(employmentQuestions);
	const handleSubmit = async (values: Partial<EditEmploymentFormPayload>) => {
		// TODO: after the toast component is migrated from bank-portal to shared-ui display it on succesful submission using onSuccess option
		await updateInvestorApplication(values);
	};

	return (
		<ModalForm
			title="Edit Employment Details"
			initialValues={initialValues}
			validationSchema={editEmploymentDetailsFormSchema}
			renderContent={renderFormFields}
			handleSubmit={handleSubmit}
		/>
	);
};
