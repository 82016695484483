import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReferralContext } from '../../providers';

export const useReferral = () => {
	const location = useLocation();
	const { zendeskTicket, zendeskUser, setReferral } = useContext(ReferralContext);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const zendeskTicket = searchParams.get('zendeskTicket');
		const zendeskUser = searchParams.get('zendeskUser');

		setReferral({
			zendeskTicket: zendeskTicket || '',
			zendeskUser: zendeskUser || '',
		});

		// eslint-disable-next-line
	}, []);

	return { zendeskTicket, zendeskUser, setReferral };
};
