interface ValueToEmojiMapProps {
	[key: string]: string;
}

const emojis = {
	greenTick: '\u2705',
	redCross: '\u274C',
};

const valueToEmojiMap = {
	PASS: emojis.greenTick,
	FAIL: emojis.redCross,
	TRUE: emojis.greenTick,
	FALSE: emojis.redCross,
} as ValueToEmojiMapProps;

export const valueToEmoji = (value: string) =>
	valueToEmojiMap[value.toUpperCase()] || value;
