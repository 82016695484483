import { useOktaAuth } from '@okta/okta-react';
import { StashworksUserStatusResponseBody } from '@stashinvest/shared-types';
import { useQuery } from '@tanstack/react-query';
import { fetchStashworksStatus } from 'src/api';

export function useStashworks(userId: string) {
	const { oktaAuth } = useOktaAuth();

	return useQuery<StashworksUserStatusResponseBody | undefined, string>(
		['stashworks', userId],
		() => fetchStashworksStatus(userId, oktaAuth.getAccessToken())
	);
}
