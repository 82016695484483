import { useState, useEffect, useRef } from 'react';

type LastCalledAt = null | number;

interface DebounceProps {
	callback(): void;
	delay: number;
}

export const useDebounce = ({ callback, delay }: DebounceProps) => {
	const latestCallback = useRef<VoidFunction>();
	const [lastCalledAt, setLastCalledAt] = useState<LastCalledAt>(null);

	useEffect(() => {
		latestCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		if (lastCalledAt) {
			const fire = () => {
				setLastCalledAt(null);
				latestCallback?.current && latestCallback.current();
			};

			const fireAt = lastCalledAt! + delay;
			const id = setTimeout(fire, fireAt - Date.now());
			return () => clearTimeout(id);
		}
	}, [lastCalledAt, delay]);

	return () => setLastCalledAt(Date.now());
};
