import React, { FC, useState } from 'react';
import { Box, Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';

import { WrapperTrackingProps } from '../../utils/analytics';
import { PropTable, RowProps } from '../PropTable';
import { DocViewerDivider, DocViewerVerticalDivider } from './DocumentViewer.styles';
import { DocumentViewerList } from './DocumentViewerList';

export interface DocumentToView {
	id: string;
	title: string;
	mimeType?: string;
	type?: string;
	image?: string;
	updatedAt?: string;
	unavailableReason?: string;
}

export interface DocumentViewerBodyProps {
	title: string;
	rows?: RowProps[] | RowProps[][];
	items: DocumentToView[];
	emptyText?: string;
	tracking: WrapperTrackingProps;
	canDownloadDocs: boolean;
}

export const DocumentViewerBody: FC<DocumentViewerBodyProps> = ({
	title,
	rows,
	items,
	emptyText = "Uh oh! We couldn't find this document",
	tracking,
	canDownloadDocs = true,
}) => {
	const [rowsData, setRowsData] = useState<RowProps[]>(
		Array.isArray(rows?.[0]) ? (rows?.[0] as RowProps[]) : (rows as RowProps[])
	);

	const rowsDataUpdate = (index: number) => {
		if (Array.isArray(rows?.[index])) {
			setRowsData(rows?.[index] as RowProps[]);
		}
	};

	const EmptyMessage = () => (
		<Flex width="100%" height="100%" alignItems="center" justifyContent="center">
			<Text.Label16 fontWeight="bold" align="center" display="block" flex="1">
				{emptyText}
			</Text.Label16>
		</Flex>
	);

	return (
		<Flex flexDirection="column" height="100%" width="100%">
			<Flex width="100%" justifyContent="space-between">
				<Text.Label16 fontWeight="bold" as="h2" pt="2px" pb="3px">
					{title}
				</Text.Label16>
			</Flex>
			<DocViewerDivider />
			{!rows?.length && !items?.length ? (
				<EmptyMessage />
			) : (
				<Flex height="100%" overflow="hidden">
					{rows && (
						<>
							<Box width="259px" pt="10px">
								<PropTable rows={rowsData} tracking={tracking} showDividers />
							</Box>
							<DocViewerVerticalDivider />
						</>
					)}
					{items?.length ? (
						<DocumentViewerList
							items={items}
							emptyText={emptyText}
							canDownloadDocs={canDownloadDocs}
							tracking={tracking}
							fullWidth={typeof rows === 'undefined'}
							rowsDataUpdate={rowsDataUpdate}
						/>
					) : (
						<EmptyMessage />
					)}
				</Flex>
			)}
		</Flex>
	);
};
