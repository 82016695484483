import { FC } from 'react';
import { Box, Flex } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { WarningIcon } from '@stashinvest/shared-ui';
import { SearchTermProps } from 'src/providers/SearchTermProvider';

export interface NoResultsProps {
	searchTerms: SearchTermProps;
}

export const NoResults: FC<NoResultsProps> = ({ searchTerms }) => {
	const { firstName: first, lastName: last, email, phone, id } = searchTerms;
	const idParts = id ? id.split('-') : null;
	const idLastFor = idParts ? idParts[idParts.length - 1] : null;

	const definedTerms = Object.values(searchTerms).filter((term) => Boolean(term));
	const termsText = definedTerms.length > 0 ? `for` : '';
	const nameText = first || last ? `${first} ${last}` : null;
	const emailText = email ? `Email: ${email}` : null;
	const phoneText = phone ? `Phone: ${phone}` : null;
	const idText = id ? `User ID ending in: ${idLastFor}` : null;

	return (
		<Box width="100%" p="l">
			<Flex flexDirection="column" justifyContent="center" alignItems="center">
				<WarningIcon size="22px" color="textPrimary" mb="10px" />
				<Text.Label16 as="span" fontWeight="bold" align="center">
					No results found {termsText} {nameText}
				</Text.Label16>
				{emailText ? (
					<Text.Label16 as="span" fontWeight="bold" align="center">
						{emailText}
					</Text.Label16>
				) : null}
				{phoneText ? (
					<Text.Label16 as="span" fontWeight="bold" align="center">
						{phoneText}
					</Text.Label16>
				) : null}
				{idText ? (
					<Text.Label16 as="span" fontWeight="bold" align="center">
						{idText}
					</Text.Label16>
				) : null}
			</Flex>
		</Box>
	);
};
