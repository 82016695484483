import { z } from 'zod';
import { APISchema } from '../api';
import { ErrorResponseBodySchema } from '../errors';

export enum CommentBodyType {
	'TEXT' = 'TEXT',
	'LINK' = 'LINK',
	'JSON' = 'JSON',
}
const commentBodyTypeSchema = z.nativeEnum(CommentBodyType);
const commentBodySchema = z.object({
	type: commentBodyTypeSchema,
	content: z.string(),
	link: z.string().optional(),
});
export const commentSchema = z.object({
	id: z.number(),
	authorEmail: z.string().optional(),
	isAuto: z.boolean(),
	body: z.array(commentBodySchema),
	createdAt: z.string().datetime().optional(),
});
export const paginatedCommentSchema = z.object({
	count: z.number(),
	total: z.number(),
	page: z.number(),
	perPage: z.number(),
	comments: z.array(commentSchema),
});

const monolithCommentSchema = z.object({
	id: z.number(),
	namespace: z.string(),
	body: z.string(),
	resource_id: z.string(),
	resource_type: z.string(),
	author_id: z.number().nullable(),
	author_type: z.string().nullable(),
	created_at: z.string().transform((str) => new Date(str)),
	updated_at: z.string().transform((str) => new Date(str)),
	author_email: z.string().email().nullable(),
});
export type MonolithComment = z.infer<typeof monolithCommentSchema>;

export const monolithPaginatedCommentsSchema = z.object({
	count: z.number(),
	total: z.number(),
	page: z.number(),
	perPage: z.number(),
	comments: z.array(monolithCommentSchema),
});
export type MonolithPaginatedComments = z.infer<typeof monolithPaginatedCommentsSchema>;

export type CommentBody = z.infer<typeof commentBodySchema>;
export type Comment = z.infer<typeof commentSchema>;
export type PaginatedComment = z.infer<typeof paginatedCommentSchema>;

export const commentsPaginationParamsSchema = z.object({
	page: z.coerce.number().transform((page) => String(page)),
	perPage: z.coerce.number().transform((perPage) => String(perPage)),
});
export type CommentsPaginationParams = z.infer<typeof commentsPaginationParamsSchema>;

export const getCommentsResponseSchema = paginatedCommentSchema.or(
	ErrorResponseBodySchema
);
export type GetCommentsResponse = z.infer<typeof getCommentsResponseSchema>;

export const commentAPISchema = {
	getComments: {
		responseSchema: getCommentsResponseSchema,
		requestSchema: {
			queryParams: commentsPaginationParamsSchema,
		},
	},
} as const satisfies Record<string, APISchema>;
