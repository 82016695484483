import React, { FC } from 'react';
import { Box } from '@stashinvest/ui';

import { JsonItems } from './JsonItems';
import { JsonListProps, LoggerFunction } from './JsonList.types';

const parseJson = (json: string, logger?: LoggerFunction) => {
	try {
		return JSON.parse(json);
	} catch (e) {
		// eslint-disable-next-line
		console.log('Failed to parse JSON');
		logger && logger(e as Error);
		return {
			content: json,
		};
	}
};

export const JsonList: FC<React.PropsWithChildren<JsonListProps>> = ({
	json,
	id,
	logger,
}) => {
	const list = parseJson(json, logger);

	return (
		<Box borderRadius="5px" bg="bgActionSecondary" p="10px 20px 10px 10px">
			<JsonItems id={id} items={list} />
		</Box>
	);
};
