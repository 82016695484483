import { useContext, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Box, Flex } from '@stashinvest/ui';
import { toFormikValidate } from 'zod-formik-adapter';
import { z } from 'zod';
import {
	AlertBanner,
	CtaDirection,
	Modal,
	ModalActions,
	VisibilityContext,
	WarningIcon,
} from '@stashinvest/shared-ui';
import { isErrorResponse } from '@stashinvest/shared-types/errors';

import { formErrorMessage } from 'src/utils/form/validation';
import { ActionModalHeader } from '../ActionModal/ActionModal';

type RenderContent<FormValues> = (
	formikProps: FormikProps<Partial<FormValues>>
) => JSX.Element;
type HandleSubmit<FormValues, TransformedOuput> = (
	payload: Partial<TransformedOuput>,
	formikHelpers: FormikHelpers<Partial<FormValues>>
) => void | Promise<unknown>;

interface ModalFormProps<FormValues, TransformedOuput> {
	title: string;
	renderContent: RenderContent<FormValues>;
	handleSubmit: HandleSubmit<FormValues, TransformedOuput>;
	validationSchema: z.Schema<TransformedOuput, z.ZodTypeDef, FormValues>;
	initialValues?: Partial<Record<keyof FormValues, unknown>>;
	subtitle?: string;
	width?: string;
	height?: string;
}

export enum FormError {
	missingInput = 'Complete all required fields',
	badInput = 'Invalid entry',
	submissionFailure = 'Form submission failure',
}
interface ErrorBanner {
	title: FormError;
	body?: string;
}

const overrideClose = (close: () => void, requireConfirmationOnClose = true) => {
	if (!requireConfirmationOnClose) {
		return close();
	}

	if (window.confirm('Are you sure? If you go back, all form progress will be lost.')) {
		return close();
	}
};

export function ModalForm<FormValues, TransformedOuput>({
	title,
	subtitle,
	renderContent,
	handleSubmit,
	validationSchema,
	initialValues,
	width = '650px',
	height = 'auto',
}: ModalFormProps<FormValues, TransformedOuput>) {
	const [requireConfirmationOnClose, setRequireConfirmationOnClose] = useState(false);

	return (
		<Modal
			height={height}
			width={width}
			overrideClose={requireConfirmationOnClose ? overrideClose : undefined}
		>
			<Box px="1.5rem" width="100%">
				<ActionModalHeader title={title} subtitle={subtitle} />
				<FormWrapper
					handleSubmit={handleSubmit}
					validationSchema={validationSchema}
					renderContent={renderContent}
					initialValues={initialValues}
					requireConfirmationOnCloseState={[
						requireConfirmationOnClose,
						setRequireConfirmationOnClose,
					]}
				/>
			</Box>
		</Modal>
	);
}

type FormWrapperProps<FormValues, TransformedOuput> = {
	renderContent: RenderContent<FormValues>;
	handleSubmit: HandleSubmit<FormValues, TransformedOuput>;
	validationSchema: z.Schema<TransformedOuput, z.ZodTypeDef, FormValues>;
	initialValues?: Partial<Record<keyof FormValues, unknown>>;
	requireConfirmationOnCloseState: [
		boolean,
		React.Dispatch<React.SetStateAction<boolean>>,
	];
};

type SetErrorBanner = React.Dispatch<React.SetStateAction<ErrorBanner | undefined>>;
const validate = (
	values: Partial<FormikValues>,
	validationSchema: z.Schema,
	setErrorBanner: SetErrorBanner
) => {
	const parsedValues = validationSchema.safeParse(values);
	if (!parsedValues.success) {
		const fieldErrors = Object.values(parsedValues.error.flatten().fieldErrors);
		const isMissingInput = fieldErrors?.flat().includes(formErrorMessage.required);
		if (isMissingInput) {
			setErrorBanner({ title: FormError.missingInput });
		} else {
			setErrorBanner({ title: FormError.badInput });
		}
	}

	return toFormikValidate(validationSchema)(values);
};

function FormWrapper<FormValues, TransformedOuput>({
	handleSubmit,
	validationSchema,
	requireConfirmationOnCloseState,
	renderContent,
	initialValues = {},
}: FormWrapperProps<FormValues, TransformedOuput>) {
	const { setOpen } = useContext(VisibilityContext);
	const [errorBanner, setErrorBanner] = useState<ErrorBanner>();

	return (
		<Formik
			initialValues={initialValues as Partial<FormValues>}
			onSubmit={async (
				values: Partial<FormValues>,
				formikHelpers: FormikHelpers<Partial<FormValues>>
			) => {
				try {
					const transformedValues = validationSchema.parse(values); // parse values with schema to apply transformations
					await handleSubmit(transformedValues, formikHelpers);
					setOpen(false);
				} catch (e) {
					console.error(e); // eslint-disable-line no-console
					let errorMessage;
					if (isErrorResponse(e) && e.error.details && 'message' in e.error.details) {
						// only display error messages from the details object
						errorMessage = e.error.details.message;
					}
					setErrorBanner({ title: FormError.submissionFailure, body: errorMessage });
				}
			}}
			validate={(values) => validate(values, validationSchema, setErrorBanner)}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{(formikProps) => (
				<FormContent
					renderContent={renderContent}
					requireConfirmationOnCloseState={requireConfirmationOnCloseState}
					formikProps={formikProps}
					errorBanner={errorBanner}
				/>
			)}
		</Formik>
	);
}

interface FormContentProps<FormValues> {
	formikProps: FormikProps<Partial<FormValues>>;
	renderContent: RenderContent<FormValues>;
	requireConfirmationOnCloseState: [
		boolean,
		React.Dispatch<React.SetStateAction<boolean>>,
	];
	errorBanner?: ErrorBanner;
}

export function FormContent<T>({
	formikProps,
	errorBanner,
	requireConfirmationOnCloseState,
	renderContent,
}: FormContentProps<T>) {
	const { setOpen } = useContext(VisibilityContext);
	const [requireConfirmationOnClose, setRequireConfirmationOnClose] =
		requireConfirmationOnCloseState;
	const { isSubmitting, dirty } = formikProps;

	if (requireConfirmationOnClose !== dirty) {
		setRequireConfirmationOnClose(dirty);
	}

	return (
		<Form>
			{errorBanner ? (
				<Box pt="1rem" pb="0.5rem">
					<AlertBanner
						title={errorBanner.title}
						icon={<WarningIcon />}
						body={errorBanner.body}
					/>
				</Box>
			) : null}
			<Flex alignItems="center" flexDirection="column" pt="1rem">
				{renderContent(formikProps)}
			</Flex>
			<ModalActions
				confirmText="Save"
				confirmButtonType="submit"
				cancelText="Cancel"
				cancel={() => overrideClose(() => setOpen(false), requireConfirmationOnClose)}
				ctaDirection={CtaDirection.ROW}
				loading={isSubmitting}
				disabled={!dirty || isSubmitting}
			/>
		</Form>
	);
}
