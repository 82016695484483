import React, { FC, ReactElement } from 'react';
import { Flex, Box } from '@stashinvest/ui';
import { Text } from '@stashinvest/ui/dist/es/2';
import { WarningIcon } from '../../icons';

import { errorForTypename } from '../../utils';
import type { ErrorForTypename } from '../../utils';

interface ErrorCardProps extends ErrorContainerProps, ErrorForTypename {}

export const ErrorCard: FC<React.PropsWithChildren<ErrorCardProps>> = ({
	typename,
	field,
	footer,
	cta,
	children,
}) => {
	const error = errorForTypename({ typename, field });

	return error ? (
		<ErrorContainer footer={footer} cta={cta}>
			<Text.Label16
				fontWeight="bold"
				align="center"
				color="textNegativePrimary"
				pb="15px"
			>
				{error.message}
			</Text.Label16>
		</ErrorContainer>
	) : (
		<>{children}</>
	);
};

interface ErrorContainerProps {
	cta?: ReactElement;
	footer?: ReactElement;
}

export const ErrorContainer: FC<React.PropsWithChildren<ErrorContainerProps>> = ({
	cta,
	footer,
	children,
}) => (
	<Box mx="30px" mt="30px">
		<Box
			maxWidth="530px"
			mx="auto"
			px="20px"
			py="30px"
			bg="bgActionSecondary"
			borderRadius="5px"
		>
			<Flex flexDirection="column" justifyContent="center" alignItems="center" px="60px">
				<WarningIcon size="22px" color="textPrimary" mb="10px" />
				{children}
				{cta ? <Box mt="5px">{cta}</Box> : null}
				{footer ? <Box mt="15px">{footer}</Box> : null}
			</Flex>
		</Box>
	</Box>
);
