export interface WrapperTrackingProps {
	page: string;
	component: string;
}

export interface TrackingProps {
	page: string;
	component: string;
	label: string;
}

interface EventProps extends TrackingProps {
	event: 'link' | 'button';
}
export const gaEvent = ({ event = 'link', page, component, label }: EventProps) => {
	if (window.gtag) {
		window.gtag('event', event, {
			event_label: `${page}|${component}|${label}`,
			event_category: page,
		});
	}
};
