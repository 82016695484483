import React, { FC } from 'react';
import { useUserProfileV2 } from 'src/hooks/useUserProfileV2';
import { UserProfileOrContentOnlyUserProfile } from '@stashinvest/shared-types';

interface UserDetailsState {
	loading: boolean;
	error: Error | undefined;
	data: UserProfileOrContentOnlyUserProfile | undefined;
}

export const UserDetailsContext = React.createContext<UserDetailsState>({
	loading: true,
	error: undefined,
	data: undefined,
});

interface UserDetailsProviderProps {
	userId: string;
}

export const UserDetailsProvider: FC<
	React.PropsWithChildren<UserDetailsProviderProps>
> = ({ userId, children }) => {
	const { isLoading: loading, error: error, data: userData } = useUserProfileV2(userId);
	const userError = error ? new Error(error) : undefined;

	return (
		<UserDetailsContext.Provider
			value={{ loading: loading, error: userError, data: userData }}
		>
			{children}
		</UserDetailsContext.Provider>
	);
};
