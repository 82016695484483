import React, { FC, Fragment } from 'react';

import { PaginationItem, PageLink } from './PaginationItem';
import { PaginationNavLink } from './PaginationNavLink';
import { getPageLinks } from './getPageLinks';
import { PaginationProps } from './Pagination.types';

export const Pagination: FC<React.PropsWithChildren<PaginationProps>> = ({
	currentPage,
	pageCount,
	onPageClick,
}) => {
	const pages = Array.from({ length: pageCount }, (value, index) => index + 1);
	const filteredPages = getPageLinks({ pages, pageCount, currentPage });
	const isFirstPage = currentPage === 1;
	const isLastPage = currentPage === pageCount;

	return (
		<>
			{pageCount > 1 ? (
				<PaginationNavLink
					type="prev"
					disabled={isFirstPage}
					onClick={() => onPageClick(currentPage - 1)}
				/>
			) : null}
			{filteredPages.map((page, index) => {
				const isActive = page === currentPage;
				const nextPageVal = filteredPages[index + 1];
				const nextPageDiff = Math.abs(page - nextPageVal);

				if (nextPageDiff >= 2) {
					const intermediate = Math.ceil(page + nextPageDiff / 2);

					return (
						<Fragment key={page}>
							<PaginationItem
								page={page}
								isActive={isActive}
								onClick={() => onPageClick(page)}
							/>
							<PageLink
								onClick={() => onPageClick(intermediate)}
								data-testid={`intermediate-${intermediate}`}
							>
								...
							</PageLink>
						</Fragment>
					);
				}

				return (
					<PaginationItem
						key={page}
						page={page}
						isActive={isActive}
						onClick={() => onPageClick(page)}
					/>
				);
			})}
			{pageCount > 1 ? (
				<PaginationNavLink
					type="next"
					disabled={isLastPage}
					onClick={() => onPageClick(currentPage + 1)}
				/>
			) : null}
		</>
	);
};
