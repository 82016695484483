import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { fetchComments } from 'src/api';
import { PaginatedComment } from '@stashinvest/shared-types/comments';
import { ZodError } from 'zod';

interface CommentsParams {
	userId: string;
	perPage: number;
}

interface CommentsResponseState {
	loading: boolean;
	error?: unknown;
	data?: PaginatedComment;
}

interface UseUserCommentReturn extends CommentsResponseState {
	setPage: React.Dispatch<React.SetStateAction<number>>;
	page: number;
}

export const useComments = ({
	userId,
	perPage = 5,
}: CommentsParams): UseUserCommentReturn => {
	const { oktaAuth } = useOktaAuth();
	const [page, setPage] = useState(1);
	const [commentsData, setComments] = useState<CommentsResponseState>({
		loading: true,
		error: undefined,
		data: undefined,
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchComments(
					{
						userId,
						page,
						perPage,
					},
					oktaAuth.getAccessToken()
				);
				setComments({ data: response, loading: false, error: undefined });
			} catch (error) {
				if (error instanceof ZodError) {
					// eslint-disable-next-line no-console
					console.error(
						'This response did not match the expected schema, server and client deployments are likely out of sync.'
					);
				}
				setComments({ data: undefined, loading: false, error });
			}
		};

		fetchData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, page, perPage]);

	return { ...commentsData, setPage, page };
};
