import { useQuery } from '@tanstack/react-query';
import { useOktaAuth } from '@okta/okta-react';

import { UserLocksSchemaType } from '@stashinvest/shared-types/schemas/userLocks';
import { config } from 'src/config/config';

export const getUserLocks = async (
	userId: string,
	accessToken: string
): Promise<UserLocksSchemaType> => {
	const response = await fetch(`${config.expGatewayUrl}/api/v1/users/${userId}/locks`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		},
	});

	const responseBody = await response.json();

	if (!response.ok) {
		switch (response.status) {
			case 401:
				throw new Error('Unauthorized');
			default:
				// TODO: export the gateway error type from shared-types and passthrough the actual error message
				throw new Error('Something went wrong while searching for users');
		}
	}

	return responseBody;
};

export const useUserLocks = (userId: string) => {
	const { oktaAuth } = useOktaAuth();

	return useQuery<UserLocksSchemaType>(['userLocks', userId], () =>
		getUserLocks(userId, oktaAuth.getAccessToken())
	);
};
